<template>
    <div class="index__admin" >
        <span>Работа № {{index+1}}</span>
        <span class="index__del" @click="deleteW(index)" >Удалить Х</span>
        <input type="text" v-model="editElement.number" @keyup="update" placeholder="Цифра">
        <input type="text" v-model="editElement.heading" @keyup="update" placeholder="Заголовок">
        <input type="text" v-model="editElement.text" @keyup="update" placeholder="Описание">
    </div>
</template>
<script>
import Vuex from 'vuex'
export default {
     components: {
    },
    props:['element', 'index'],
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            updateServiсesWorks: 'app/updateServiсesWorks',
            deleteServiсesWorks: 'app/deleteServiсesWorks',
        }),
         update() {
            this.updateServiсesWorks({idx: this.index, 
                number: this.editElement.number, 
                 heading: this.editElement.heading,
                text: this.editElement.text, 
               
                })
        },
        deleteW(){
             this.deleteServiсesWorks(this.index) 
        },
        uploadImg() {
            let image = this.$refs['file' + this.index].files[0]
            
            this.upload({file: image})
                .then((response) => {
                    this.editElement.img = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
    },
}
</script>