import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    messageStatus: null,
    form: {
        excludedIds: [],
        id_1c: "",
        id: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    reviews: [],
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    form: state => state.form,
    reviews: state => state.reviews,
}

const actions = {
    getReviews: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_review/search", getters.form)
                .then(response => {
                    commit("setReviews", response.data)
                    resolve(response)
                })
        })
    },
    createReview: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_review/admin", payload)
                .then(response => {
                    commit("pushReview", response.data)
                    resolve(response)
                })
        })
    },
    deleteReview: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_review/admin/" + id)
                .then(response => {
                    commit("spliceReview", id)
                    resolve(response)
                })
        })
    },
    updateReview: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_review/admin/" + payload.id, payload)
                .then(response => {
                    commit("changeReview", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setReviews: (state, val) => {
        state.reviews = val
    },
    spliceReview: (state, id) => {
        state.reviews.map((element, idx) => {
            if (id == element.id) {
                state.reviews.splice(idx, 1)
            }
        })
    },
    changeReview: (state, val) => {
        state.reviews.map((element) => {
            if (element.id == val.id) {
                element.link = val.link
            }
        })
    },
    pushReview: (state, val) => {
        state.reviews.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
