<template>
    <tr>
        <td class="left-td one_tr_name_block">
            <i 
                v-if="single_element" 
                class="fas fa-times one_tr_icon_delete" 
                title="Очистить поле" 
                @click="clear"
            ></i>
            <b>{{ getNameOfType() }}</b> 
            <br>
            {{ (single_element ? single_element.name : '') }}
        </td>
        <td>
            <div 
                class="search-field"
            >
                <input 
                    class="search-input input-for-id"
                    type="text" 
                    placeholder="Поиск по id_1c"
                    v-model="id_1c"
                    @keyup="update('id_1c')"
                >
                <input 
                    class="search-input input-for-name"
                    type="text" 
                    placeholder="Поиск по названию"
                    v-model="query"
                    @keyup="update('name')"
                >
                <button 
                    class="search-submit" 
                    @click="getElements()"
                >
                    <span class="search-submit-label">найти</span>
                </button>
            </div>
            <div 
                v-if="query.length > 3 || id_1c.length > 3"
                class="block-elements"
            >
                <div class="search-results"  data-search-results>
                    <div 
                        class="search-result"
                        v-for="product in elements"
                        :key="product.id"
                    >
                        <div class="search-result-title">
                            <a class="search-result-link">{{ product.name }}</a>
                        </div>
                        <div class="search-result-button">
                            <button 
                                class="button button--primary button--cart"
                                @click="setItem(product.id_1c)"
                            >
                                Выбрать
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isLengthWidth" class="block_length_and_width">
                <label 
                    class="cl-input-group cl-input-group_no"
                >
                    <input style="margin: 0" type="number" v-model="length" @keyup="updateParameter('length')">
                    <span data-title="Введите длину">a</span>
                </label>
                <label 
                    class="cl-input-group cl-input-group_no"
                >
                    <input style="margin: 0" type="number" v-model="width" @keyup="updateParameter('width')">
                    <span data-title="Введите ширину">a</span>
                </label>
            </div>
        </td>
    </tr>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        name: {
            type: String,
            default: "",
        },
        typeSearch: {
            type: String,
            default: "",
        },
        isLengthWidth: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            elements: [],
            query: '',
            id_1c: '',
            single_element: {},
            length: '',
            width: '',
        }
    },
    computed: {
        ...Vuex.mapGetters({
            calc_fence_body: 'calculator/calc_fence_body',
        }),
        currentParameter() {
            return this.calc_fence_body[this.name]
        },
    },
    watch: {
        currentParameter(val) {
            let is_group = false
            if (this.typeSearch == 'group') {
                is_group = true
            }else if (this.typeSearch == 'product') {
                is_group = false
            }else{
                is_group = null
            }
            this.searchNavbar({root_id: this.calc_fence_body.root_1c_id, is_group: is_group, id_1cs: [val], is_show_zero_price: false})
                .then((response) => {
                    this.single_element = response.data[0]
                })
        },
        calc_fence_body() {
            this.checkLengthWidth()
        }
    },
    mounted() {
        if (this.currentParameter) {
            let is_group = false
            if (this.typeSearch == 'group') {
                is_group = true
            }else if (this.typeSearch == 'product') {
                is_group = false
            }else{
                is_group = null
            }
            this.searchNavbar({root_id: this.calc_fence_body.root_1c_id, is_group: is_group, id_1cs: [this.currentParameter], is_show_zero_price: false})
                .then((response) => {
                    this.single_element = response.data[0]
                })
        }
        this.checkLengthWidth()
    },
    methods: {
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
        }),
        ...Vuex.mapMutations({
            changeBodyCalcFence: 'calculator/changeBodyCalcFence',
        }),
        checkLengthWidth() {
            if (this.isLengthWidth) {
                this.length = this.calc_fence_body[this.name + '_length']
                this.width = this.calc_fence_body[this.name + '_width']
            }
        },
        updateParameter(name){
            this.changeBodyCalcFence({type: this.name + '_' + name, value: Number(this[name])})
        },
        update(type) {
            if (type == 'name') {
                this.id_1c = ''
            }else if (type == 'id_1c') {
                this.query = ''
            }
            this.getElements()
        },
        getElements() {
            let is_group = false
            if (this.typeSearch == 'group') {
                is_group = true
            }else if (this.typeSearch == 'product') {
                is_group = false
            }else{
                is_group = null
            }
            this.searchNavbar({root_id: this.calc_fence_body.root_1c_id, id_1c: this.id_1c, query: this.query, is_group: is_group, is_show_zero_price: false})
                .then((response) => {
                    this.elements = response.data
                })
        },
        setItem(id_1c) {
            this.changeBodyCalcFence({type: this.name, value: id_1c})
            this.query = ''
        },
        clear() {
            this.changeBodyCalcFence({type: this.name, value: null})
        },
        getNameOfType() {
            let response = ''
            if (this.name == 'roulette') {
                response = 'Рулетка'
            }else if (this.name == 'cut_round') {
                response = 'Отрезной круг'
            }else if (this.name == 'scissors') {
                response = 'Ножницы'
            }else if (this.name == 'paint') {
                response = 'Краска'
            }else if (this.name == 'zaklep') {
                response = 'Заклепочник'
            }else if (this.name == 'nozzle') {
                response = 'Насадка'
            }else if (this.name == 'fasteners') {
                response = 'Крепеж'
            }else if (this.name == 'additional') {
                response = 'Доборные'
            }else if (this.name == 'clips') {
                response = 'Заклепки'
            }else if (this.name == 'pillar') {
                response = 'Столбы'
            }else if (this.name == 'vein') {
                response = 'Прожилины'
            }
            return response
        },
    },
}
</script>
<style lang="scss">
    .input-for-id{
        width: 30% !important;
        border-right: 1px solid red !important;
        border-radius: 0 !important;
    }
    .input-for-name{
        width: 70% !important;
    }
</style>
<style scoped src="../../../../css/nlk-style.css"></style>