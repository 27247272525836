<template>
    <main class="main">
			<div class="section section--no-pt section--no-pb section-nlk">
				<div 
					class="container pr-20"
					:class="[
						key == 'review' || key == 'claim' || key == 'profile' ? 'custom_height_from_height_screen' : '',
						key != 'organizations' && key != 'orders' && key != null ? 'vh-100' : '', 
					]"
				>
					<header class="nlk-header-custom">
						<div class="nlk-header__item nlk-list-links">
							<p
								v-if="isGetPermissions('orders')"
								class="list-item" 
								:class="{'active' : key === 'orders' || key == null}" 
								@click="push('nlk/orders')"
							>
								<a>Мои заказы </a>
							</p>
							<p 
								v-if="isGetPermissions('payment_schedule')"
								class="list-item" 
								:class="{'active' : key === 'chart'}" 
								@click="push('nlk/chart')"
							>
								<a>График оплат</a>
							</p>
							<p 
								v-if="isGetPermissions('price_list')"
								class="list-item" 
								:class="{'active' : key === 'prace'}" 
								@click="push('nlk/prace')"
							>
								<a>Прайс-листы</a>
							</p>
							<p 
								v-if="isGetPermissions('booking_report') || isGetPermissions('implementation_report')"
								class="list-item"
								:class="[
									isOpenButtonReports ? '-opened' : '',
									key === 'booking' && !isOpenButtonReports || key === 'realization' && !isOpenButtonReports ? 'active' : ''
								]"
							>
								<span class="span-for-list-link">
									<a 
										class="list-link" 
										@click="isOpenButtonReports = !isOpenButtonReports"
									>
										Отчеты
									</a>
									<button 
										class="nlk-accordion-btn" 
										:class="{'nlk-accordion-btn_active' : isOpenButtonReports}" 
										@click="isOpenButtonReports = !isOpenButtonReports"
									>
										<img src="@/assets/img/select-basic.svg" alt="">
									</button>
								</span>
								<ul class="list sub custom_list" v-if="isOpenButtonReports">
									<li 
										v-if="isGetPermissions('booking_report')"
										class="list-item" 
										:class="{'active' : key === 'booking'}" 
										@click="push('nlk/booking')"
									>
										<a class="list-link">Отчет по броням</a>
									</li>
									<li 
										v-if="isGetPermissions('implementation_report')"
										class="list-item" 
										:class="{'active' : key === 'realization'}" 
										@click="push('nlk/realization')"
									>
										<a class="list-link">Отчет по реализации</a>
									</li>
								</ul>
							</p>
							<p 
								v-if="isGetPermissions('booking_report') || isGetPermissions('implementation_report')"
								class="list-item"
								:class="[
									isOpenButtonRequests ? '-opened' : '',
									(key === 'review' || key === 'act' || key === 'claim' ) && !isOpenButtonRequests ? 'active' : ''
								]"
							>
								<span class="span-for-list-link">
									<a 
										class="list-link" 
										@click="isOpenButtonRequests = !isOpenButtonRequests"
									>
										Запросы
									</a>
									<button 
										class="nlk-accordion-btn" 
										:class="{'nlk-accordion-btn_active' : isOpenButtonRequests}" 
										@click="isOpenButtonRequests = !isOpenButtonRequests"
									>
										<img src="@/assets/img/select-basic.svg" alt="">
									</button>
								</span>
								<ul class="list sub custom_list" v-if="isOpenButtonRequests">
									<li 
										v-if="isGetPermissions('act_reconciliation')"
										class="list-item" 
										:class="{'active' : key === 'act'}" 
										@click="push('nlk/act')"
									>
										<a>Акт сверки</a>
									</li>
									<li 
										v-if="isGetPermissions('reviews')"
										class="list-item" 
										:class="{'active' : key === 'review'}" 
										@click="push('nlk/review')"
									>
										<a class="list-link">Оставить отзыв</a>
									</li>
									<li 
										v-if="isGetPermissions('claims')"
										class="list-item" 
										:class="{'active' : key === 'claim'}" 
										@click="push('nlk/claim')"
									>
										<a class="list-link">Отправить претензию</a>
									</li>
								</ul>
							</p>
						</div>
						<div class="test_row_manager">
							<p class="test_row_manager_item">
								<b>Ваш менеджер</b>
							</p>
							<p class="test_row_manager_item mb-0">{{ user.manager_surname }} {{ user.manager_name }}</p>
							<a :href="'mailto:' + user.manager_email" class="nlk-manager__email test_row_manager_item mt-0">{{ user.manager_email }}</a>
						</div>
						<div class="nlk-header__item nlk-header__item-user">
							<div class="nlk-header__name" @click="isShowProfileListLinks = !isShowProfileListLinks">
								<div class="nlk-header__ava">
									<img src="@/assets/img/ava.svg" alt="">
								</div>
								<div class="nlk-header__text">
									{{ user.surname }}
								</div>
							</div>
							<ul class="list sub custom_list" v-if="isShowProfileListLinks">
								<li 
									v-if="isGetPermissions('profile')"
									class="list-item" 
									:class="{'active' : key === 'profile'}" 
									@click="push('nlk/profile')"
								>
									<a>Профиль</a>
								</li>
								<li 
									class="list-item" 
									:class="{'active' : key === 'organizations'}" 
									@click="push('nlk/organizations')"
								>
									<a>Организации</a>
								</li>
								<li 
									class="list-item list-item_last" 
									@click="logout"
								>
									<a>Выйти</a>
								</li>
							</ul>
						</div>
					</header>
					
					<div class="section-nlk__grid">
						<profile-tab v-if="key === 'profile'" />
						<orders-tab v-if="key === 'orders' || key == null" />
						<chart-tab v-if="key === 'chart'" />
						<booking-tab v-if="key === 'booking'" />
						<realization-tab v-if="key === 'realization'" />
						<prace-tab v-if="key === 'prace'" />
						<review-tab v-if="key === 'review'" />
						<claim-tab v-if="key === 'claim'" />
						<act-tab v-if="key === 'act'" />
						<organization-tab v-if="key === 'organizations'" />
					</div>
				</div>
			</div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

import profileTab from './profile-tab.vue'
import ordersTab from './orders-tab.vue'
import chartTab from './chart-tab.vue'
import bookingTab from './booking-tab.vue'
import realizationTab from './realization-tab.vue'
import praceTab from './prace-tab.vue'
import reviewTab from './review-tab.vue'
import claimTab from './claim-tab.vue'
import actTab from './act-tab.vue'
import organizationTab from './organizations-tab.vue'

export default {
    components: {
		profileTab,
		ordersTab,
		chartTab,
		bookingTab,
		realizationTab,
		praceTab,
		reviewTab,
		claimTab,
        actTab,
		organizationTab,
    },
    mixins: [Functions],
	data() {
		return {
			isOpenButtonReports: false,
			isOpenButtonRequests: false,
			isShowProfileListLinks: false,
		}
	},
	watch: {
		key() {
			this.isOpenButtonReports = false
			this.isOpenButtonRequests = false
			this.isShowProfileListLinks = false
		},
		isOpenButtonReports(val) {
			if (val == true) {
				this.isOpenButtonRequests = false
				this.isShowProfileListLinks = false
			}
		},
		isOpenButtonRequests(val) {
			if (val == true) {
				this.isOpenButtonReports = false
				this.isShowProfileListLinks = false
			}
		},
		isShowProfileListLinks(val) {
			if (val == true) {
				this.isOpenButtonRequests = false
				this.isOpenButtonReports = false
			}
		},
	},
    computed: {
		...Vuex.mapGetters({
			user: 'auth/user',
			permissions: 'permissions/permissions',
			companies: 'companies/companies',
			companyId: 'auth/userCompanyId',
		}),
        key() {
            return this.$route.params.key
        },
		accountTypeOfCompany() {
			let res = null
			this.companies.map((element) => {
				if (element.id == this.companyId) {
					res = element.account_type
				}
			})
			return res
		},
    },
	mounted() {
		this.getPermissions()
	},
	methods: {
		...Vuex.mapActions({
			logoutStore: 'auth/logout',
			getPermissions: 'permissions/getPermissions',
		}),
		...Vuex.mapMutations({
            setOrder: 'basket/setOrder',
        }),
		getTextAccountType(type) {
			let string = null
			if (type == 10) {
				string = 'Частное лицо'
			}else if (type == 20) {
				string = 'Юр. лицо'
			}else if (type == 30) {
				string = 'Дилер'
			}
			return string
		},
		isGetPermissions(str) {
			let response = false
			let account_type = null
			if (this.accountTypeOfCompany) {
				account_type = this.accountTypeOfCompany
			}else{
				account_type = 10
			}
			this.permissions.map((element) => {
				if (element.role_group == account_type) {
					response = element[str]
				}
			})
			return response
		},
		logout() {
			this.logoutStore()
				.then(() => {
					this.setOrder({
                        id: null,
                        positions: [],
                        
                        account_type: null,
                        location: null,
                        postcode: null,

                        delivery_type: null,
                        pay_type: null,

                        organization_name: null,
                        organization_address: null,
                        inn: null,
                        kpp: null,

                        fio: null,
                        email:null,
                        phone: null,
                        comment: null,
                    })
					if (localStorage.mayakOrderId) {
						localStorage.removeItem("mayakOrderId")
					}
					this.$router.push('/login')
				})
		}
	}
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>
<style scoped>
	.active{
		background-color: unset;
	}
</style>
<style lang="scss">
	@media only screen and (max-height: 700px){
		.custom_height_from_height_screen{
			height: 100% !important;
		}
	}
	.vh-100{
		height: 100vh;
	}
	.pr-20{
		padding-right: 20px !important;
	}
	.nlk-header-custom{
		display: flex;

		.nlk-header__item{
			flex: auto;
			padding-left: .5rem;
			position: relative;
			padding-bottom: 0;

			.nlk-header__name{
				width: 21rem;
				cursor: pointer;
			}

			.custom_list{
				position: absolute;
				top: 6rem;
				left: 0;
				background: #fff;
				width: 21rem;
				z-index: 99;

				padding: 0.5rem;
				border: 1px solid grey;
				// border-radius: 0.5rem;
			}
		}

		.nlk-header__item-user{
			flex: unset;
			justify-content: end;
			padding-top: 0.8rem !important;
		}

		.nlk-list-links{
			display: flex;

			.list-item{
				margin-right: 2rem;
				margin-bottom: 0;
				position: relative;

				.custom_list{
					position: absolute;
					top: 2rem;
					left: 0;
					background: #fff;
					width: 25rem;
					z-index: 99;
				}
			}

			.span-for-list-link{
				position: relative;
				// width: 11rem;
				padding-right: 4rem;
				color: inherit;
				font-weight: 700;
				line-height: 1.25;

				.nlk-accordion-btn_active{
					transform: rotate(-180deg);
				}
			}
		}

		a{
			text-decoration: none;
			border: unset !important;

			&:hover{
				color: #EA292E;
			}
		}

		.active{
			color: #EA292E;
		}
	}

	.test_row_manager{
		display: flex;
		// justify-content: flex-end;
		margin-top: 1.4rem;
		// position: absolute;
		// right: 0;

		.test_row_manager_item{
			margin-right: 2rem;
		}
	}
</style>