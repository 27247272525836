<template>
    <div class="section section--no-pt section--videos">
        <div class="container">
            <div class="inner">
                <div class="heading">
                    <div class="heading-section">
                        <h2>Обучающие материалы</h2>
                    </div>
                    <div class="heading-aside"><a class="button button--default"  @click="push('support')">Смотреть еще</a></div>
                </div>
            </div>
            <div class="swiper-container slider slider--videos">
                <swiper  ref="swiperTop">
                    <swiper-slide v-for="obj , i in supports.dataVideos" :key="i"> 
                        <a class="video"  >
                            <div class="video-preview" @click="openVideo(obj.link)" :style="{ backgroundImage: 'url(' + obj.img + ')' }">
                                <div class="video-button">Смотреть</div>
                            </div>
                            <div class="video-body">
                                <div class="video-date">{{getDate(obj.dateAdd)}}</div>
                                <div class="video-title">{{obj.name}}</div>
                            </div>
                        </a>
                    </swiper-slide>
                </swiper>
            </div>
          </div>
        <div v-if="video != null" >
            <modal-video :video="video"/>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import ModalVideo from '../support/videoModal.vue'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
    mixins: [Functions],
    components: {
        Swiper,
        SwiperSlide,
        ModalVideo,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            object: {},
            objectsCat:[],
            swiperOption: {
                loop: true,
            },
        }
    },
    watch:{
        our_objects(){
            this.getObj()
            this.getObjectsCat()
        },
        $route(){
           this.getObj()
            this.getObjectsCat()
        }
        
    },
    computed: {
        ...Vuex.mapGetters({
            supports: 'app/supports',
            app:'appmodal/app',
            video:'videomodal/video'
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setVideo: 'videomodal/setVideo',
        }),
        getDate(datetime) {
            if (!datetime) {
                return null
            }
            var options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',

            }
            var date = new Date(datetime)
            var d = date.toLocaleString("ru", options)
            return d
        },
        openVideo(val){
            this.setVideo(val)
        }
    },
    mounted(){
        if(!this.our_objects){
            return
        }
        this.getObj()
        this.getObjectsCat()
         
    }
}
</script>