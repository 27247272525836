<template>
    <div class="block single_address_block">
        <div>
            <p>Подразделение</p>
            <input type="text" v-model="editElement.city" @change="updateAddress" style="width: 95%;">
        </div>
        <div>
            <p>Почта</p>
            <input type="text" v-model="editElement.email" @change="updateAddress" style="width: 95%;">
        </div>
        <i class="fas fa-times icon_delete_address" @click="deleteAddress"></i>
    </div>
</template>
<script>
export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        }
    },
    computed: {
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        deleteAddress() {
            this.$emit('deleteAddress', this.index)
        },
        updateAddress() {
            this.$emit('updateSingleAddress', this.index, this.editElement)
        }
    }
}
</script>
<style lang="scss">
.single_address_block{
    position: relative;

    .icon_delete_address{
        color: #ED1C24;
        position: absolute;
        top: 55%;
        right: 10%;
        cursor: pointer;
    }
}
</style>