<template>
    <div class="admin-settings index__admin" style="display: block">
        <div class="admin-index_header">
            <h3>Каталог товара</h3>
            <button 
                class="button button--primary save_btn" 
                @click="save"
            >
                Сохранить
            </button>
        </div>
        <div class="content">
            <div class="setting-cards">
                <h4>Настройка полей в карточках товара</h4>
                
                <block-categories 
                    :categories="topCategory" 
                    :isTopCategory="true"
                />

                <div 
                    class="formula" 
                    v-if="currentCategoryIsTop"
                >
                    <p class="header">Код региона*</p>
                    <div class="block">
                        <input 
                            type="text" 
                            v-model="region_code"
                            @change="updateCard"
                        >
                    </div>
                </div>
                <div 
                    class="formula" 
                    v-if="currentCategoryIsTop"
                >
                    <p class="header">Описание региона*</p>
                    <div class="block">
                        <input 
                            type="text" 
                            v-model="region_description"
                            @change="updateCard"
                        >
                    </div>
                </div>
                <div 
                    class="formula" 
                    v-if="currentCategoryIsTop"
                >
                    <p class="header">Телефон</p>
                    <div class="block">
                        <the-mask 
                            v-model="phone" 
                            mask="+# (###) ###-####" 
                            placeholder="+# (###) ###-####"
                            type="tel"
                            @change.native="updateCard"
                        />
                    </div>
                </div>
                <div 
                    class="formula" 
                    v-if="currentCategoryIsTop"
                >
                    <p class="header">Почта</p>
                    <div class="block">
                        <input 
                            type="text" 
                            v-model="email"
                            @change="updateCard"
                        >
                    </div>
                </div>
                <div 
                    class="formula" 
                    v-if="currentCategoryIsTop"
                >
                    <p class="header">Адреса</p>
                    <single-address 
                        v-for="singleAddress, idx in addressesArray" 
                        :key="idx" 
                        :index="idx" 
                        :element="singleAddress" 
                        @deleteAddress="deleteAddress"
                        @updateSingleAddress="updateSingleAddress"
                    />

                    <div v-if="isShowAddAddressForm" class="block single_address_block">
                        <div>
                            <p>Подразделение</p>
                            <input type="text" v-model="newAddressForm.city" style="width: 95%;">
                        </div>
                        <div>
                            <p>Почта</p>
                            <input type="text" v-model="newAddressForm.email" style="width: 95%;">
                        </div>
                        <button class="button buttonAddAddress" @click="addAddress" style="margin-top: 35px">Добавить</button>
                    </div>
                    <button 
                        v-if="!isShowAddAddressForm" 
                        class="button buttonAddAddress" 
                        @click="openAddAddressForm"
                    >
                        Добавить адрес
                    </button>
                </div>

                <!-- <button v-if="currentCategoryIsTop" class="button" @click="updateCard()">Обновить данные</button> -->

                <div class="formula" v-if="cardFormula" style="margin-bottom: 20px">
                    <p class="header">Формула</p>
                    <div class="block">
                        <p>{{ cardFormula }}</p>
                    </div>
                </div>
                
                <div 
                    v-if="cardStructure != null"
                    class="form" 
                >
                    <div class="header">
                        <p class="sort">СОРТИРОВКА</p>
                        <p class="name">НАИМЕНОВАНИЕ ПАРАМЕТРА</p>
                        <p class="placeholder">ПАРАМЕТР</p>
                        <p class="type">ТИП ПАРАМЕТРА</p>
                        <p class="visible">ВИДИМОСТЬ</p>
                        <p class="item-base_name"></p>
                    </div>
                    <ul class="list">
                        <single-card v-for="(card, idx) in cardStructure" :key="card.sort" :card="card" :index="idx" />
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Vue from 'vue'
import singleCard from './singleCard.vue'
import blockCategories from './block-categories.vue'
import singleAddress from './singleAddress.vue'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

export default {
    components: {
        singleCard,
        blockCategories,
        singleAddress,
    },
    data() {
        return {
            phone: null,
            email: null,
            region_code: null,
            region_description: null,
            addressesArray: [],
            currentRegionId1c: null,
            currentCardAddress: {},
            isShowAddAddressForm: false,
            newAddressForm: {
                city: null,
                email: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            cardStructure: 'catalog/cardStructure',
            cardFormula: 'catalog/cardFormula',
            topCategory: 'catalog/topCategory',
            regionAdresses: 'app/regionAdresses',
            adminRootId: 'catalog/adminRootId',
            adminCardId1c: 'catalog/adminCardId1c',
            cardsAddresses: 'ui_card_address/cardsAddresses',
            currentCard: 'ui_card_address/currentCard',
        }),
        currentCategoryIsTop() {
            let isTop = false
            this.topCategory.map((element) => {
                if (element.id_1c == this.adminCardId1c) {
                    isTop = true
                }
            })
            return isTop
        },
    },
    watch: {
        adminCardId1c(val) {
            if (!this.currentCategoryIsTop) {
                return
            }
            this.getCardById1c(val)
                .then(() => {
                    if (this.currentCard.id) {
                        this.setValuesCard()
                    }else{
                        this.createCardAddress({id_1c: val, region_code: val})
                            .then((response) => {
                                this.currentCardAddress.id = response.data.id
                                this.currentCardAddress.id_1c = response.data.id_1c 
                                console.log('new card created')
                            })
                        this.phone = null
                        this.email = null
                        this.region_code = val
                        this.region_description = null
                        this.addressesArray = []
                    }
                    console.log('check current card', this.currentCard.id)
                })
        },
        regionAdresses() {
            this.checkIssetAddressValue()
        },
        isShowAddAddressForm(val) {
            if (val == true) {
                this.newAddressForm.city = null
                this.newAddressForm.email = null
            }
        }
    },
    mounted() {
        this.getTopCategory()
    },
    methods: {
        ...Vuex.mapMutations({
            updateRegionAddresses: 'app/updateRegionAddresses',
            checkIssetAddressValue: 'app/checkIssetAddressValue',
            addAddressArrayValue: 'app/addAddressArrayValue',
            deleteAddressArrayValue: 'app/deleteAddressArrayValue',
        }),
        ...Vuex.mapActions({
            getCategories: 'catalog/getCategories',
            getTopCategory: 'catalog/getTopCategory',
            updateJson: 'app/updateJson',
            searchNavbar: 'catalog/searchNavbar',
            updateCardAddress: 'ui_card_address/updateCardAddress',
            createCardAddress: 'ui_card_address/createCardAddress',
            getCardById1c: 'ui_card_address/getCardById1c',
        }),
        setValuesCard() {
            this.currentCardAddress = Object.assign({}, this.currentCard)
            this.currentRegionId1c = this.currentCard.id_1c
            this.phone = this.currentCard.phone
            this.email = this.currentCard.email
            this.region_code = this.currentCard.region_code
            this.region_description = this.currentCard.region_description
            if (this.currentCard.address) {
                var array = []
                this.currentCard.address.map((el) => {
                    array.push(Object.assign({}, JSON.parse(el)))
                })
                this.addressesArray = array
            }else{
                this.addressesArray = []
            }
        },
        save() {
            this.updateJson()
        },
        openAddAddressForm() {
            this.isShowAddAddressForm = true
        },
        addAddress() {
            var copyNewAddress = Object.assign({}, this.newAddressForm)
            this.addressesArray.push(copyNewAddress)
            this.isShowAddAddressForm = false

            this.updateCard()
        },
        updateSingleAddress(index, object) {
            this.addressesArray.map((element, idx) => {
                if (idx == index) {
                    element.city = object.city
                    element.email = object.email
                }
            })

            this.updateCard()
        },
        deleteAddress(index) {
            this.addressesArray.splice(index, 1)

            this.updateCard()
        },
        updateCard() {
            var addresses = []
            this.addressesArray.map((element) => {
                addresses.push(JSON.stringify(element))
            })
            this.currentCardAddress.address = addresses
            this.updateCardAddress({
                id: this.currentCardAddress.id,
                id_1c: this.currentCardAddress.id_1c, 
                phone: this.phone, 
                email: this.email, 
                region_code: this.region_code, 
                region_description: this.region_description,
                address: addresses,
            })
        },
    }
}
</script>
<style>
    .buttonAddAddress{
        width: 20%;
        height: 35px;
        font-size: 1.5rem !important;
        line-height: 0.5rem !important;
    }
</style>