<template>
    <main class="main">
        <div class="section section--breadcrumbs">
          <div class="container">
            <div class="inner">
              <ul class="list list--flex breadcrumbs breadcrumbs--light">
                <li class="list-item"><a class="list-link" href="#">
                    <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                      <use xlink:href="@/assets/img/icons.svg#home"></use>
                    </svg></a></li>
                <li class="list-item"><a class="list-link" href="#">Каталог</a></li>
                <li class="list-item"><span class="list-current">Внутренняя отделка</span></li>
              </ul>
            </div>
          </div>
        </div>
        <section 
          class="subhero" 
          style="color: #fff;"
          :style="{ 'background-image': 'url(' + require('@/assets/img/subhero.jpg') + ')' }"
        >
          <div class="container">
            <div class="inner">
              <h1>Внутренняя отделка<br> помещений в Самаре</h1>
              <p>Группа компаний «Маяк» предлагает проведение<br> строительно-монтажных работ «под ключ»<br> в установленные сроки. В зависимости от потребностей<br> клиента здание может быть возведено с частичной<br> или полной внутренней отделкой</p>
            </div>
          </div>
        </section>
        <!-- section-->
        <div class="section section--no-pt section--cards">
          <div class="container">
            <ul class="list list--flex layout-cards -col-3">
              <li class="list-item"><a class="card card--medium card--long card--no-arrow" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#drawing_2"></use>
                      </svg>
                    </div>
                    <div class="card-title">Детальная разработка<br> эскизов заказчика </div>
                    <div class="card-description">Конструкторская разработка<br> эскизных вариантов предложения<br> заказчика в зависимости<br> от функционального назначения,<br> объемно-планировочного решения,<br> архитектурно-эстетических форм</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card card--medium card--long card--no-arrow" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#chat"></use>
                      </svg>
                    </div>
                    <div class="card-title">Подробные консультации<br> по всем услугам</div>
                    <div class="card-description">Консультирование заказчика<br> по выбору несущих, ограждающих<br> конструкций и их конструктивных<br> решений, технологии монтажных<br> работ и условиям производственно-<br>договорных отношений</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card card--medium card--long card--no-arrow" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#document_2"></use>
                      </svg>
                    </div>
                    <div class="card-title">Подробная<br> документация</div>
                    <div class="card-description">Разработка комплекта проектно-<br>сметной документации<br> по требованиям, разработанным<br> Росстроем</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card card--medium card--long card--no-arrow" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#lorry"></use>
                      </svg>
                    </div>
                    <div class="card-title">Удобная<br> логистика</div>
                    <div class="card-description">Доставка комплектующих изделий<br> на стройплощадку автомобильным<br> и ж/д транспортом</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card card--medium card--long card--no-arrow" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#calendar"></use>
                      </svg>
                    </div>
                    <div class="card-title">Оперативная<br> работа</div>
                    <div class="card-description">Монтажные работы «под ключ»<br> в сроки, установленные<br> техническими регламентами</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
            </ul>
            <div class="row row--button">
              <div class="col"><a class="button button--primary" href="#modal_callback" data-modal>Отправить заявку</a></div>
              <div class="col">
                <div class="label">Монтаж металлоконструкций проводится в соответствии с действующими<br> СНиПами и технический заданием Заказчика.</div>
              </div>
            </div>
          </div>
        </div>
        <!-- section-->
        <div class="section section--no-pt section--reviews">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Отзывы</h2>
                </div>
                <div class="heading-aside"><a class="button button--default" href="#" data-reviews-show-all>Смотреть все отзывы</a></div>
              </div>
            </div>
            <ul class="list list--flex layout-reviews" data-reviews>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_1" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_2" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_3" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_4" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_5" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
            </ul>
            <div class="heading-bottom"><a class="button button--default" href="#" data-reviews-show-all>Смотреть все отзывы</a></div>
          </div>
        </div>
        <!-- section-->
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Наши объекты</h2>
                </div>
                <div class="heading-aside"><a class="button button--default" href="#">Смотреть все</a></div>
              </div>
            </div>
            <ul class="list list--flex layout-objects">
              <li class="list-item"><a class="object" href="#">
                  <div class="object-image"><img src="@/assets/img/objects/1.jpg" alt=""/></div>
                  <div class="object-title">Здание склада<br> п.Новосемейкино</div></a>
              </li>
              <li class="list-item"><a class="object" href="#">
                  <div class="object-image"><img src="@/assets/img/objects/2.jpg" alt=""/></div>
                  <div class="object-title">Паровая котельная<br> г.Аксай</div></a>
              </li>
            </ul>
            <div class="heading-bottom"><a class="button button--default" href="#">Смотреть все</a></div>
          </div>
        </div>
        <!-- section-->
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Почему выбирают «Маякметалл»?</h2>
                </div>
              </div>
            </div>
            <div class="list-offset -col-1">
              <ul class="list list--flex layout-advantages -col-4">
                <li class="list-item">
                  <div class="advantage">
                    <div class="advantage-icon"><img src="@/assets/img/advantages/20.svg" alt=""></div>
                    <div class="advantage-title">Гарантия на все<br> виды работ</div>
                  </div>
                </li>
                <li class="list-item">
                  <div class="advantage">
                    <div class="advantage-icon"><img src="@/assets/img/advantages/5.svg" alt=""></div>
                    <div class="advantage-title">Поэтапная оплата<br> работ</div>
                  </div>
                </li>
                <li class="list-item">
                  <div class="advantage">
                    <div class="advantage-icon"><img src="@/assets/img/advantages/21.svg" alt=""></div>
                    <div class="advantage-title">Официальные<br> договоры</div>
                  </div>
                </li>
                <li class="list-item">
                  <div class="advantage">
                    <div class="advantage-icon"><img src="@/assets/img/advantages/7.svg" alt=""></div>
                    <div class="advantage-title">Не выходим<br> за рамки сметы</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- section-->
        <div class="section section--form-question">
          <div class="container">
            <div class="helper">
              <div class="helper-section">
                <div class="helper-title">Сергей Ищенко</div>
                <div class="helper-description">менеджер проектов</div>
              </div>
            </div>
            <!-- helper-->
            <form class="form" action="#" method="post">
              <h2>Нужна<br> консультация?</h2>
              <div class="form-fieldset">
                <div class="form-group form-row">
                  <div class="form-item">
                    <div class="field">
                      <div class="field-label">Имя *</div>
                      <div class="field-input">
                        <input type="text" placeholder="Введите имя" value="">
                      </div>
                    </div>
                    <!-- field-->
                  </div>
                  <div class="form-item">
                    <div class="field">
                      <div class="field-label">Телефон *</div>
                      <div class="field-input">
                        <input type="tel" placeholder="+7 (___) ___-__-__" value="">
                      </div>
                    </div>
                    <!-- field-->
                  </div>
                </div>
                <div class="form-group form-row form-row--align-bottom">
                  <div class="form-item">
                    <div class="field">
                      <div class="field-label">E-mail</div>
                      <div class="field-input">
                        <input type="email" placeholder="Введите ваш e-mail" value="">
                      </div>
                    </div>
                    <!-- field-->
                  </div>
                  <div class="form-item">
                    <label class="file" data-text="Прикрепить файл на рассчет">
                      <input type="file"><span>Прикрепить файл на рассчет</span>
                    </label>
                    <!-- field-->
                  </div>
                </div>
                <div class="form-group form-row">
                  <div class="form-item">
                    <div class="field">
                      <div class="field-label">Сообщение</div>
                      <div class="field-input">
                        <textarea rows="5" placeholder="Введите сообщение"></textarea>
                      </div>
                    </div>
                    <!-- field-->
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-item">
                    <label class="checkbox-plus">
                      <input type="checkbox" name="privacy" checked>
                      <b></b>
                      <span>
                          Согласен (согласна) на обработку моих персональных данных.
                          <br> Ознакомлен с 
                          <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/BeEwJiJ_Jc.pdf" target="_blank">Политикой</a>
                          и
                          <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/ZhlKe-a2dH.pdf" target="_blank">согласием</a>
                          на обработку персональных данных
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-fieldset">
                <div class="form-group">
                  <div class="form-item">
                    <button class="button button--primary">Заказать звонок</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- section-->
        <div class="section">
          <div class="container">
            <div class="inner">
              <div class="row row--contentframe">
                <div class="col">
                  <h2>Заказать<br> внутреннюю отделку<br> помещений<br> в Самаре</h2>
                </div>
                <div class="col">
                  <p>Внутренняя отделка стен – это завершающий этап обработки стен. К внутренним работам по отделке стен приступают тогда, когда все основные строительные мероприятия уже завершены: подошли к логическому концу работы над кровлей и внешними стенами здания, произведен монтаж строительных сетей и коммуникаций, установлены межкомнатные перегородки и др.</p>
                  <p>Внутренняя отделка – это достаточно важный этап в строительстве дома, ведь именно от нее зависит не только внешний вид и уют вашего жилища, но и комфорт проживания, а самое главное – благоприятный для здоровья климат в доме.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </main>
</template>

<script>
export default {

}
</script>

<style>

</style>