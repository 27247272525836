<template>
    <li class="list-item">
        <div class="position">
            <div class="sort">
                <i class="fas fa-bars"></i>
            </div>
            <p class="name">{{ editCard.name }}</p>
            <div class="placeholder">
                <p>{{ editCard.placeholder }}</p>
            </div>
            <div class="type">
                <p>{{ getNameType(editCard.type) }}</p>
            </div>
            <div class="visible">
                <label class="checkbox-plus">
                    <input 
                        type="checkbox" 
                        name="privacy"
                        v-model="editCard.value"
                        @change="update('visible')"
                    >
                    <b />
                </label>
            </div>
        </div>
        <p class="item-base_name">{{ castomBaseName }}</p>
    </li>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        card: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data () {
        return {
            arrayTypes: [
                {id: 10, name: 'Поле'},
                {id: 20, name: 'Список'},
                {id: 30, name: 'Цвет'},
            ],
            draggable: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            castomizeCardForm: 'catalog/castomizeCardForm',
        }),
        editCard() {
            return Object.assign({}, this.card)
        },
        castomBaseName() {
            return '{{ ' + this.editCard.base_name + ' }}'
        }
    },
    created() {
        
    },
    methods: {
        getNameType(type) {
            let name = null
            if (type == 10) {
                name = 'Поле'
            }else if (type == 20) {
                name = 'Список'
            }else if (type == 30) {
                name = 'Цвет'
            }
            return name
        },
    }
}
</script>