

const state = {
    offersData: [],
}

const getters = {
    offersData: state => state.offersData,
}

const actions = {
}


const mutations = {
    setOffersData: (state, offersData) => {
        state.offersData = offersData
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
