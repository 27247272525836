<template>
    <main class="main">
        <bread-crumbs :pages-array="pagesServices" />
        <services-blocks v-if="serviсes != null" />
        <prices-blocks v-if="serviсes != null" />
        <works-blocks v-if="serviсes != null" />
        <reasons-blocks v-if="serviсes != null" />
        <our-clients :additionalClass="'section--no-pt'" />
        <reviews-blocks v-if="serviсes != null" />
        <objects-blocks v-if="serviсes != null" />
        <consultation v-if="serviсes.consultation != null" />
    </main>
</template>
<script>
import Vuex from 'vuex'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import ServicesBlocks from './servicesBlocks.vue'
import PricesBlocks from './pricesBlocks.vue'
import WorksBlocks from './worksBlocks.vue'
import ReasonsBlocks from './reasonsBlocks.vue'
import ReviewsBlocks from './reviewsBlocks.vue'
import ObjectsBlocks from './objectsBlocks.vue'
import Consultation from './consultation.vue'
import OurClients from '../home/brands/brands.vue'
export default {
    components:{
        ServicesBlocks,
        PricesBlocks,
        WorksBlocks,
        ReasonsBlocks,
        ReviewsBlocks,
        ObjectsBlocks,
        Consultation,
        breadCrumbs,
        OurClients,
    },
    data() {
        return {
            activeCategory: 0,
            dataOffers: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            pagesServices: 'dictionary/pagesServices',
        }),
    },
    methods: {
    },
}
</script>