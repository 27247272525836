<template>
    <tr>
        <td>{{ nameGroup }}</td>
        <td>
            <label class="checkbox-plus">
                <input 
                    type="checkbox" 
                    name="privacy"
                    v-model="editPermission.profile"
                    @change="update"
                >
                <b />
            </label>
        </td>
        <td>
            <label class="checkbox-plus">
                <input 
                    type="checkbox" 
                    name="privacy"
                    v-model="editPermission.orders"
                    @change="update"
                >
                <b />
            </label>
        </td>
        <td>
            <label class="checkbox-plus">
                <input 
                    type="checkbox" 
                    name="privacy"
                    v-model="editPermission.payment_schedule"
                    @change="update"
                >
                <b />
            </label>
        </td>
        <td>
            <label class="checkbox-plus">
                <input 
                    type="checkbox" 
                    name="privacy"
                    v-model="editPermission.booking_report"
                    @change="update"
                >
                <b />
            </label>
        </td>
        <td>
            <label class="checkbox-plus">
                <input 
                    type="checkbox" 
                    name="privacy"
                    v-model="editPermission.implementation_report"
                    @change="update"
                >
                <b />
            </label>
        </td>
        <td>
            <label class="checkbox-plus">
                <input 
                    type="checkbox" 
                    name="privacy"
                    v-model="editPermission.act_reconciliation"
                    @change="update"
                >
                <b />
            </label>
        </td>
        <td>
            <label class="checkbox-plus">
                <input 
                    type="checkbox" 
                    name="privacy"
                    v-model="editPermission.price_list"
                    @change="update"
                >
                <b />
            </label>
        </td>
        <td>
            <label class="checkbox-plus">
                <input 
                    type="checkbox" 
                    name="privacy"
                    v-model="editPermission.reviews"
                    @change="update"
                >
                <b />
            </label>
        </td>
        <td>
            <label class="checkbox-plus">
                <input 
                    type="checkbox" 
                    name="privacy"
                    v-model="editPermission.claims"
                    @change="update"
                >
                <b />
            </label>
        </td>
    </tr>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        permission: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        editPermission() {
            return Object.assign({}, this.permission)
        },
        nameGroup() {
            let name = null
            if (this.permission.role_group == 10) {
                name = 'Частные лица'
            }else if (this.permission.role_group == 20) {
                name = 'Юр. лица'
            }else if (this.permission.role_group == 30) {
                name = 'Дилеры'
            }
            return name
        }
    },
    methods: {
        ...Vuex.mapActions({
            updatePermissions: 'permissions/updatePermissions',
        }),
        update() {
            this.updatePermissions(this.editPermission)
        }
    }
}
</script>
<style scoped src="@/css/nlk-style.css"></style>
<style scoped lang="css">
    .checkbox-plus{
        left: -15px !important;
        top: -18px !important;
    }
</style>