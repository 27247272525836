<template>
    <div class="single_banner">
        <span 
            class="index__del" 
            @click="deleteBanner(editElement.id)"
        >
            Удалить X
        </span>
        <span 
            class="header"
        >
            Баннер №{{ index + 1 }}
        </span>

        <label>Id1c</label>        
        <input 
            type="text" 
            v-model="editElement.id_1c" 
            @keyup="update"
        >

        <label>Регион</label>      
        <select name="root_id" id="" v-model="editElement.root_id" @change="update">
            <option :value="null">Выберите регион</option>
            <option v-for="region in topLevelCategory" :key="region.id" :value="region.id_1c">{{ region.name }}</option>
        </select>

        <label>Название</label>
        <input 
            type="text" 
            v-model="editElement.name" 
            @keyup="update"
        >

        <label>Ссылка</label>
        <input 
            type="text" 
            v-model="editElement.link" 
            @keyup="update"
        >

        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="editElement.img" 
            @keyup="update"
        >

        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'fileSingleBanner'+ index" 
                    type="file" 
                    @change="uploadImg"
                >
            </label>
            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
        </div>

        <div 
            v-if="editElement.img"
            class="img_block" 
        >
            <img 
                :src="editElement.img" 
                alt=""
            >
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...Vuex.mapGetters({
            topLevelCategory: 'dictionary/topLevelCategory',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            updateBanner: 'app/updateBanner',
            deleteStoreBanner: 'app/deleteBanner',
        }),
        deleteBanner() {
            this.deleteStoreBanner(this.index)
        },
        update() {
            this.updateBanner({
                index: this.index,
                name: this.editElement.name,
                id_1c: this.editElement.id_1c,
                img: this.editElement.img,
                link: this.editElement.link,
                root_id: this.editElement.root_id,
            })
        },
        uploadImg() {
            let img = this.$refs['fileSingleBanner' + this.index].files[0]
            this.upload({file: img})
                .then((response) => {
                    this.editElement.img = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
    },
}
</script>