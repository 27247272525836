<template>
    <div >
        <objects v-if="our_objects && pagesObjects.length != 0" />
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import Objects from  './objects.vue'
export default {
    mixins: [Functions],
    components:{
        Objects,
    },
    computed: {
        ...Vuex.mapGetters({
            our_objects: 'app/our_objects',
            pagesObjects: 'app/pagesObjects',
        }),
    },
}
</script>