<template>
    <div class="section section--reviews">
        <div class="container">
            <div class="inner">
                <div class="heading">
                    <div class="heading-section">
                        <h2>Отзывы</h2>
                    </div>
                    <div class="heading-aside">
                        <a class="button button--default all_reviews_btn"  @click="showAllReview">Смотреть все отзывы</a>
                    </div>
                </div>
            </div>
            <div class="zo_reviews_list">
                <ul 
                    class="list list--flex layout-reviews" 
                    style="margin-bottom:10px;" 
                    v-for="(group, i) in uiReviews" 
                    :key="i" v-show="(i == 0 && showAll == false) || showAll == true"
                >
                        <li 
                            class="list-item" 
                            v-for="obj, ind in uiReviews.slice(i * 3, (i + 1) * 3)" 
                            :key="ind+ 's'" 
                        >
                            <div class="review">
                                <div class="review-body">
                                    <p v-html="obj.comment"></p>
                                    <p  @click="openModalApp(obj)"><a >подробнее</a></p>
                                </div>
                                <div class="review-author">{{ obj.name }}</div>
                                <div class="review-source"></div>
                            </div>
                        </li>
                </ul>
            </div>
            <modal-rev v-if="reviewModal == true" />
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import ModalRev from '../../modals/review.vue'
export default {
    components:{
        ModalRev
    },
    data() {
        return {
            showAll: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            reviewModal:'appmodal/reviewModal',
            uiReviews: 'ui_review/reviews',
        }),
        
    },
    methods: {
         ...Vuex.mapMutations({
           setReviewModal:'appmodal/setReviewModal',
           setReviewObjForModal:'appmodal/setReviewObjForModal'
        }),
        openModalApp(obj){
            this.setReviewModal(true) 
            this.setReviewObjForModal(obj)
        },
        showAllReview(){
            this.showAll = true
        }
    },
}
</script>