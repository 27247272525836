<template>
    <section class="admin-coating-colors-page admin-index index__admin">
        <div class="admin-index_header">
            <h3>Цвета покрытий</h3>
        </div>

        <div class="main_block">
            <div class="button_block">
                <button 
                    class="button button--primary" 
                    @click="openModal"
                >
                    Добавить цвет
                </button>

                <button 
                    class="button button--primary" 
                    @click="push('admin/coatings')"
                >   Покрытия
                </button>
            </div>

            <table class="nlk-table nlk-table_w-100">
                <tr>
                    <td>Id</td>
                    <td>Название</td>
                    <td>Описание</td>
                    <td>Цвет</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr v-for="color, i in colors" :key="i">
                    <td><p>{{ color.id }}</p></td>
                    <td>
                        <p v-if="idEditColor != color.id">{{ color.name }}</p>
                        <input v-else type="text" v-model="editElement.name">
                    </td>
                    <td>
                        <p v-if="idEditColor != color.id">{{ color.sub_name }}</p>
                        <input v-else type="text" v-model="editElement.sub_name">
                    </td>
                    <td class="color-td">
                        <div v-if="idEditColor != color.id" class="color_code_block" :style="'background-color:' + color.code"></div>
                        <input v-else type="text" v-model="editElement.code">
                    </td>
                    <td v-if="idEditColor != color.id">
                        <i class="fas fa-pen" @click="setChangeElement(color)"></i>
                    </td>
                    <td v-if="idEditColor == color.id">
                        <i class="fas fa-save" @click="updateColor()"></i>
                    </td>
                    <td><i class="fas fa-trash" @click="deleteColor(color.id)"></i></td>
                </tr>
            </table>
        </div>

        <create-modal v-if="isShowModalCreate" />
    </section>
</template>
<script>
import Vuex from 'vuex'
import createModal from './modal/createColor.vue'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    components: {
        createModal,
    },
    data() {
        return {
            idEditColor: null,
            editElement: {
                name: null,
                sub_name: null,
                code: null,
                image: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            colors: 'coatingColors/colors',
            isShowModalCreate: 'coatingColors/isShowModalCreate',
        }),
    },  
    methods: {
        ...Vuex.mapActions({
            getColors: 'coatingColors/search',
            deleteStoreColor: 'coatingColors/deleteColor',
            updateStoreColor: 'coatingColors/updateColor',
        }),
        ...Vuex.mapMutations({
            setShowModalCreate: 'coatingColors/setShowModalCreate'
        }),
        openModal() {
            this.setShowModalCreate(true)
        },
        deleteColor(id) {
            this.deleteStoreColor(id)
        },
        setChangeElement(element) {
            this.idEditColor = element.id
            this.editElement.name = element.name
            this.editElement.sub_name = element.sub_name
            this.editElement.code = element.code
        },
        updateColor() {
            this.updateStoreColor(
                {
                    id: this.idEditColor,
                    object: this.editElement
                })
                .then(() => {
                    this.idEditColor = null
                })
        },
    },
    mounted() {
        this.getColors()
    }
}
</script>
<style scoped src="../../../../css/nlk-style.css"></style>