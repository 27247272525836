import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    form: {
        description: "",
        // excludedIds: [],
        // image: [],
        // link_image: [],
        // list_colors: [],
        // root_ids: [],
        ids: [],
        limit: 10,
        name: "",
        page: 1,
        query: "",
        short_description: ""
    },
    formMain: {
        description: "",
        // excludedIds: [],
        // image: [],
        // link_image: [],
        // list_colors: [],
        // root_ids: [],
        ids: [],
        limit: 10,
        name: "",
        page: 1,
        query: "",
        short_description: ""
    },
    createForm: {
        description: "",
        image: [],
        link_image: [],
        list_colors: [],
        list_chars: [],
        name: "",
        root_ids: [],
        short_description: ""
    },
    coatings: [],
    coatingsMain: [],
    isShowModalCreate: false
}

const getters = {
    message: state => state.message,
    form: state => state.form,
    formMain: state => state.formMain,
    createForm: state => state.createForm,
    coatings: state => state.coatings,
    coatingsMain: state => state.coatingsMain,
    isShowModalCreate: state => state.isShowModalCreate,
}

const actions = {
    search: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_premium_coating/search", getters.form)
                .then(response => {
                    commit("setCoatings", response.data)
                    resolve(response)
                })
        })
    },
    searchMain: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_coating_main/search", getters.formMain)
                .then(response => {
                    commit("setCoatingsMain", response.data)
                    resolve(response)
                })
        })
    },
    createCoating: ({commit}, payload) => {
        var object = Object.assign({}, payload)
        var list_colors = []
        var list_chars = []
        payload.list_colors.map(element => {
            list_colors.push(JSON.stringify(element))
        })
        payload.list_chars.map(element => {
            list_chars.push(JSON.stringify(element))
        })
        object.list_colors = list_colors
        object.list_chars = list_chars

        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/ui_premium_coating/admin", object)
                .then(response => {
                    commit("addCoating", response.data)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                    if (err.message) {
                        commit("setMessage", err.message)
                    }
                })
        })
    },
    createCoatingMain: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/ui_coating_main/admin", payload)
                .then(response => {
                    commit("addCoatingMain", response.data)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                    if (err.message) {
                        commit("setMessage", err.message)
                    }
                })
        })
    },
    deleteCoating: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_premium_coating/admin/" + id)
                .then(response => {
                    commit("spliceCoating", id)
                    resolve(response)
                })
        })
    },
    deleteCoatingMain: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_coating_main/admin/" + id)
                .then(response => {
                    commit("spliceCoatingMain", id)
                    resolve(response)
                })
        })
    },
    updateCoating: ({commit}, payload) => {
        var object = Object.assign({}, payload.object)
        var list_colors = []
        var list_chars = []
        payload.object.list_colors.map(element => {
            list_colors.push(JSON.stringify(element))
        })
        payload.object.list_chars.map(element => {
            list_chars.push(JSON.stringify(element))
        })
        object.list_colors = list_colors
        object.list_chars = list_chars

        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_premium_coating/admin/" + payload.id, object)
                .then(response => {
                    commit("patchCoatingObject", {id: payload.id, object: payload.obj})
                    resolve(response)
                })
        })
    },
    updateCoatingMain: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_coating_main/admin/" + payload.id, payload.obj)
                .then(response => {
                    commit("patchCoatingObjectMain", {id: payload.id, object: payload.obj})
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setCoatings: (state, val) => {
        val.sort((a, b) => Number(a.id) - Number(b.id))

        val.map((element) => {
            var list_colors = []
            element.list_colors.map((el) => {
                list_colors.push(JSON.parse(el))
            })
            element.list_colors = list_colors

            var list_chars = []
            element.list_chars.map((el) => {
                list_chars.push(JSON.parse(el))
            })
            element.list_chars = list_chars
        })

        state.coatings = val
    },
    setCoatingsMain: (state, val) => {
        state.coatingsMain = val
    },
    addCoatingMain: (state, val) => {
        state.coatingsMain.push(val)
    },
    spliceCoatingMain: (state, id) => {
        state.coatingsMain.map((element, i) => {
            if (id == element.id) {
                state.coatingsMain.splice(i, 1)
            }
        })
    },
    patchCoatingObjectMain: (state, payload) => {
        state.coatings.map((element) => {
            if (payload.id == element.id) {
                element.root_ids = payload.object.root_ids
                element.image = payload.object.image
            }
        })
    },
    spliceCoating: (state, id) => {
        state.coatings.map((element, i) => {
            if (id == element.id) {
                state.coatings.splice(i, 1)
            }
        })
    },
    patchCoatingObject: (state, payload) => {
        state.coatings.map((element) => {
            if (payload.id == element.id) {
                element.description = payload.object.description
                element.image = payload.object.image
                element.link_image = payload.object.link_image
                element.list_colors = payload.object.list_colors
                element.list_chars = payload.object.list_chars
                element.name = payload.object.name
                element.root_ids = payload.object.root_ids
                element.short_description = payload.object.short_description
            }
        })
    },
    setShowModalCreate: (state, val) => {
        state.isShowModalCreate = val
    },
    addCoating: (state, val) => {
        state.coatings.push(val)
    },
    pushColor: (state, payload) => {
        var isset_color = false
        var coating = null
        state.coatings.map((object) => {
            if (object.id == payload.coating_id) {
                coating = object
            }
        })

        if (coating == null) {
            return
        }

        coating.list_colors.map((element, idx) => {
            if (element.color_id == payload.color_id) {
                coating.list_colors.splice(idx, 1)
                isset_color = true
            }
        })

        if (!isset_color) {
            coating.list_colors.push({
                color_id: payload.color_id,
                sort: 1
            })
        }
    },
    clearCreateForm: (state) => {
        state.createForm.description = ""
        state.createForm.image = []
        state.createForm.link_image = []
        state.createForm.list_colors = []
        state.createForm.list_chars = []
        state.createForm.name = ""
        state.createForm.root_ids = []
        state.createForm.short_description = ""
    },
    changeFieldCreateForm: (state, object) => {
        state.createForm[object.field] = object.value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
