<template>
    <div class="nlk-accordion-table">
        <table>
            <tr>
                <td>Номер документа</td>
                <td>Состав по номенклатуре</td>
                <td>Ед.изм.</td>
                <td>Кол-во</td>
                <td>Дата по графику</td>
                <td>Подразделение</td>
                <td>Сумма</td>
                <td>Доставка Дата</td>
            </tr>
            <tr
                v-for="position in inProduction"
                :key="position.id"
            >
                <td>{{ position.document_number }}</td>
                <td class="td-wo_padding">
                    <table class="table-100">
                        <tr
                            v-for="(subposition, i) in position.sub_table"
                            :key="subposition.id"
                        >
                            <td :class="getClassForTd(i, 'left', position.sub_table.length)" style="font-size: 1rem; text-align: left;">{{ JSON.parse(subposition).nomenclature }}</td>
                        </tr>
                    </table>
                </td>
                <td class="td-wo_padding">
                    <table class="table-100">
                        <tr
                            v-for="(subposition, i) in position.sub_table"
                            :key="subposition.id"
                        >
                            <td :class="getClassForTd(i, 'center', position.sub_table.length)">{{ JSON.parse(subposition).unit }}</td>
                        </tr>
                    </table>
                </td>
                <td class="td-wo_padding">
                    <table class="table-100">
                        <tr
                            v-for="(subposition, i) in position.sub_table"
                            :key="subposition.id"
                        >
                            <td :class="getClassForTd(i, 'center', position.sub_table.length)">{{ JSON.parse(subposition).amount }}</td>
                        </tr>
                    </table>
                </td>
                <td class="td-wo_padding">
                    <table class="table-100">
                        <tr
                            v-for="(subposition, i) in position.sub_table"
                            :key="subposition.id"
                        >
                            <td :class="getClassForTd(i, 'center', position.sub_table.length)">{{ getParsedDate(JSON.parse(subposition).scheduled_date) }}</td>
                        </tr>
                    </table>
                </td>
                <td class="td-wo_padding">
                    <table class="table-100">
                        <tr
                            v-for="(subposition, i) in position.sub_table"
                            :key="subposition.id"
                        >
                            <td :class="getClassForTd(i, 'right', position.sub_table.length)">{{ JSON.parse(subposition).subdivision }}</td>
                        </tr>
                    </table>
                </td>
                <td><b>{{ position.sum }}</b></td>
                <td>{{ getTextDeliveryType(position.delivery_type) }} <br> {{ getParsedDate(position.delivery_at) }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        inProduction: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        getSumWithSpaces(sum) {
            let parts = sum.toString().split(".")
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            return parts.join(".")
        },
        getClassForTd(idx, type, length) {
            let className = null
            if (idx == 0 && idx != length - 1) {
                if (type == 'left') {
                    className = 'border_wo_left-top border_wo_right'
                }else if (type == 'right') {
                    className = 'border_wo_right-top border_wo_left'
                }else{
                    className = 'border_wo_top border_wo_left-right'
                }
            }else if (idx == length - 1 && idx != 0) {
                if (type == 'left') {
                    className = 'border_wo_left-bottom border_wo_right'
                }else if (type == 'right') {
                    className = 'border_wo_right-bottom border_wo_left'
                }else{
                    className = 'border_wo_bottom border_wo_left-right'
                }
            }else if (idx == 0 && idx == length - 1) {
                if (type == 'left') {
                    className = 'border_wo_left-top border_wo_right border_wo_bottom'
                }else if (type == 'right') {
                    className = 'border_wo_right-top border_wo_left border_wo_bottom'
                }else{
                    className = 'border_wo_top border_wo_left-right border_wo_bottom'
                }
            }else{
                if (type == 'left') {
                    className = 'border_wo_left border_wo_right'
                }else if (type == 'right') {
                    className = 'border_wo_right border_wo_left'
                }else{
                    className = 'border_wo_left-right'
                }
            }
            return className
        },
        getTextDeliveryType(type) {
            let val = null
            if (type == 10) {
                val = 'Самовывоз'
            }else if (type == 20) {
                val = 'Доставка'
            }
            return val
        },
        getParsedDate(date) {
            if (!date) {
                return null
            }
            let parsed = date.split('-')
            let parsedDay = parsed[2].split('T')
            return parsedDay[0] + '-' + parsed[1] + '-' + parsed[0]
        },
    },
}
</script>
<style scoped src="../../../../css/nlk-style.css"></style>
<style scoped>
	.active{
		background-color: unset;
	}
    .nlk-order-header__top .first-item{
        min-width: 13.3rem;
        max-width: 13.3rem;
        margin-right: 0;
    }
    .td-wo_padding{
        padding: 0 !important;
    }
    .table-100{
        height: 100% !important;
    }
</style>
<style lang="scss">
    .border_wo_left-top{
        border-left: unset !important;
        border-top: unset !important;
    }
    .border_wo_right-top{
        border-right: unset !important;
        border-top: unset !important;
    }
    .border_wo_top{
        border-top: unset !important;
    }
    .border_wo_left-bottom{
        border-left: unset !important;
        border-bottom: unset !important;
    }
    .border_wo_right-bottom{
        border-right: unset !important;
        border-bottom: unset !important;
    }
    .border_wo_bottom{
        border-bottom: unset !important;
    }
    .border_wo_left{
        border-left: unset !important;
    }
    .border_wo_right{
        border-right: unset !important;
    }
    .border_wo_left-right{
        border-left: unset !important;
        border-right: unset !important;
    }
</style>