<template>
    <div class="admin-index-slider">
        <h4 style="margin-bottom: 10px;">Слайдер</h4>
        <div class="slides">
            <button 
                v-if="!isShowForm"
                @click="isShowForm = true" 
            >
                Добавить слайд
            </button>

            <div 
                v-if="isShowForm"
                class="slide"
            >
                <div>
                    <label>Регион для слайда</label>
                    <select 
                        class="picker" 
                        v-model="newSlide.root_id"
                    >
                        <option value="null">Выберите регион</option>
                        <option v-for="reg, i in regions" :key="i" :value="reg.id_1c">{{reg.name}}</option>
                    </select>
                </div>
                <input 
                    v-model="newSlide.name" 
                    type="text" 
                    placeholder="Заголовок"
                >
                <input 
                    v-model="newSlide.link" 
                    type="text" 
                    placeholder="Ссылка на изображение"
                >
                <div class="row">
                    <label class="fl__img">
                        <input 
                            ref="fileDefaultSlide" 
                            type="file" 
                            @change="uploadImg"
                        >
                    </label>
                    <p>
                        Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. 
                        Для правильного отображения соблюдайте размер и формат
                    </p>
                </div>
                <img 
                    :src="newSlide.link" 
                    alt=""
                >

                <button 
                    @click="createSlide" 
                >
                    Добавить слайд
                </button>
            </div>

            <one-slide 
                v-for="(slide, idx) in uiSlides"
                :key="slide.id" 
                :element="slide"
                :index="idx"
            />
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import oneSlide from './slide.vue'

export default {
    components: {
        oneSlide,
    },
    data() {
        return {
            newSlide: {
                link: null,
                name: null,
                root_id: null,
            },
            isShowForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiSlides: 'ui_slide/slides',
            fileUrl: 'app/fileUrl',
            regions:'dictionary/topLevelCategory',
        }),
    },
    mounted() {
        // this.newSlide.root_id = this.regions[0].id_1c
    },
    created() {
        this.getUiSlides()
    },
    methods: {
        ...Vuex.mapActions({
            getUiSlides: 'ui_slide/getSlides',
            createUiSlide: 'ui_slide/createSlide',

            upload: 'app/upload',
        }),
        uploadImg() {
            let image = this.$refs['fileDefaultSlide'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newSlide.link = this.fileUrl + response.data[0].link
                })
        },
        createSlide() {
            if (this.newSlide.link == null || this.newSlide.name == null) {
                return
            }
            this.createUiSlide(this.newSlide)
                .then(() => {
                    this.isShowForm = false
                    this.newSlide.link = null
                    this.newSlide.name = null
                    // this.newSlide.root_id = this.regions[0].id_1c
                })
        },
    },
}
</script>