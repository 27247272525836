<template>
    <section class="container-l">
        <div class="vacancies_page">
            <p class="vacancies_header">Открытые вакансии</p>
            <div class="search_input_block">
                <input type="text" class="search_input" v-model="query" placeholder="Поиск вакансии">
                <img class="search_icon_vacancy" src="@/assets/img/vacancy/icon_search.png" alt="">
            </div>

            <div class="main_block">
                <div class="left_part">
                    <div class="filter_list">
                        <div @click="isOpenVacancyFilter = !isOpenVacancyFilter" class="filter_header">
                            <p>Вакансия</p>
                            <i class="fas fa-angle-down"></i>
                        </div>
                        <div v-if="isOpenVacancyFilter" class="filter_body">
                            <p 
                                v-for="vac, idx in vacanciesList"
                                :key="idx"
                                :class="selectedVacancy == vac ? '-active' : ''"
                                @click="selectVacancy(vac)"
                            >
                                {{ vac }}
                            </p>
                        </div>
                    </div>
                    <div class="filter_list">
                        <div @click="isOpenCityFilter = !isOpenCityFilter" class="filter_header">
                            <p>Город</p>
                            <i class="fas fa-angle-down"></i>
                        </div>
                        <div v-if="isOpenCityFilter" class="filter_body">
                            <p
                                v-for="city, idx in citiesList"
                                :key="idx"
                                :class="selectedCity == city ? '-active' : ''"
                                @click="selectCity(city)"
                            >
                                {{ city }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="right_part">
                    <div  
                        v-for="vacancy, idx in vacancies"
                        :key="idx"
                        v-show="checkFilters(vacancy)"
                        class="vacancy_row"
                    >
                        <img src="@/assets/img/vacancy/vacancy_single.png" alt="">
                        <div class="text_block_vacancy">
                            <p class="name">{{ vacancy.name }}</p>
                            <p class="city" v-if="vacancy.area">{{ vacancy.area.name }}</p>
                        </div>
                        <div class="info_block_vacancy">
                            <p class="summary" v-if="vacancy.salary">От {{ getSumWithSpaces(vacancy.salary.from) }} руб.</p>
                            <button class="button button--primary" @click="push('vacancy/' + vacancy.id)">Подробнее</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    data() {
        return {
            selectedVacancy: null,
            selectedCity: null,
            query: null,
            isOpenCityFilter: false,
            isOpenVacancyFilter: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            vacancies: 'vacancy/vacancies',
        }),
        citiesList() {
            var cities = []
            this.vacancies.map((element) => {
                if (element.area && element.area.name) {
                    if (!this.checkIssetValueInArray(cities, element.area.name)) {
                        cities.push(element.area.name)
                    }
                }
            })
            return cities
        },
        vacanciesList() {
            var vacanciesL = []
            this.vacancies.map((element) => {
                if (element.professional_roles && element.professional_roles[0]) {
                    if (!this.checkIssetValueInArray(vacanciesL, element.professional_roles[0].name)) {
                        vacanciesL.push(element.professional_roles[0].name)
                    }
                }
            })
            return vacanciesL
        },
    },
    mounted() {
        this.getVacancies()
    },
    methods: {
        ...Vuex.mapActions({
            getVacancies: 'vacancy/getVacancies',
        }),
        getSumWithSpaces(num) {
            var sum = 0
            if (num) {
                sum = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
            }
            return sum
        },
        checkIssetValueInArray(array, value) {
            var isset = false
            array.map((element) => {
                if (element == value) {
                    isset = true
                }
            })
            return isset
        },
        selectCity(city) {
            if (this.selectedCity == city) {
                this.selectedCity = null
            }else{
                this.selectedCity = city
            }
        },
        selectVacancy(vacancy) {
            if (this.selectedVacancy== vacancy) {
                this.selectedVacancy = null
            }else{
                this.selectedVacancy = vacancy
            }
        },
        checkFilters(vacancy) {
            var value = true
            if (vacancy.area && vacancy.area.name) {
                if (this.selectedCity && vacancy.area.name != this.selectedCity) {
                    value = false
                }
            }
            if (vacancy.professional_roles && vacancy.professional_roles[0]) {
                if (this.selectedVacancy && vacancy.professional_roles[0].name != this.selectedVacancy) {
                    value = false
                }
            }
            if (this.query) {
                var vacancy_name = vacancy.name.toLowerCase()
                var lower_query = this.query.toLowerCase()
                if (!vacancy_name.includes(lower_query)) {
                    value = false
                }
            }
            return value
        }
    },
}
</script>