<template>
  <div class="index__admin" >
      <span >Категория № {{index+1}}</span>
                    <span class="index__del" @click="deleteObj(index)" >Удалить Х</span>
                    <input type="text" v-model="editElement.name" @keyup="update" placeholder="Название">
                    <input type="text" v-model="editElement.link"  @keyup="update" placeholder="Ссылка">
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
     components: {
    },
    props:['element', 'index', 'type'],
    computed: {
        ...Vuex.mapGetters({
            our_objects: 'app/our_objects',
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            updateObjectsCats: 'app/updateObjectsCats',
            deleteObjectsCats: 'app/deleteObjectsCats',
        }),
         update() {
            this.updateObjectsCats({ 
                idx:this.index,
                name: this.editElement.name, 
                link: this.editElement.link, 
                objects:this.editElement.objects,
                })
        },
        deleteObj(){
             this.deleteObjectsCats(this.index) 
        },
    },
}
</script>