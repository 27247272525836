import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    form: {
        excludedIds: [],
        ids: [],
        limit: 100,
        page: 1,
        phone: "",
        query: "",
        root_1c_id: "",
        email: "",
        utm: ""
    },
    utmPositions: [],
}

const getters = {
    message: state => state.message,
    form: state => state.form,
    utmPositions: state => state.utmPositions,
}

const actions = {
    search: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/utm/search", getters.form, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setUtmPositions", response.data)
                    resolve(response)
                })
        })
    },
    createUtm: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/utm/admin", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", null)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                    if (err.message) {
                        commit("setMessage", err.message)
                    }
                })
        })
    },
    deleteUtm: ({commit}, id) => {
        let cid = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/utm/admin/" + id, {headers: {'X-Company-ID': cid}})
                .then(response => {
                    commit("spliceUtm", id)
                    resolve(response)
                })
        })
    },
    updateUtm: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/utm/admin/" + payload.id, payload.object, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("patchUtmObject", {id: payload.id, object: response.data})
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setUtmPositions: (state, val) => {
        val.sort((a, b) => Number(a.id) - Number(b.id))
        state.utmPositions = val
    },
    spliceUtm: (state, id) => {
        state.utmPositions.map((element, i) => {
            if (id == element.id) {
                state.utmPositions.splice(i, 1)
            }
        })
    },
    patchUtmObject: (state, payload) => {
        state.utmPositions.map((element) => {
            if (payload.id == element.id) {
                element.utm = payload.object.utm
                element.phone = payload.object.phone
                element.root_1c_id = payload.object.root_1c_id
            }
        })
    },
    updateSearchForm: (state, form) => {
        state.form = form
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
