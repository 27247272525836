

const state = {
    video: null,
}

const getters = {
    video: state => state.video,
}

const actions = {
}


const mutations = {
    setVideo: (state, video) => {
        state.video = video
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
