<template>
    <main class="admin-index index__admin admin_user_companies_page">
        <div class="admin-index_header">
            <h3>Компании</h3>
        </div>
        <div class="nlk-content">
            <form class="nlk-filter">
                <div class="field field_flex-bottom">
                    <div class="field-input">
                        <input type="text" placeholder="Название" v-model="form.query" @keyup="update" @change="search">
                    </div>
                    <div class="field-input">
                        <input type="text" placeholder="ИНН" v-model="form.inn" @keyup="update" @change="search">
                    </div>
                    <div class="field-input">
                        <input type="text" placeholder="id 1c" v-model="form.id_1c" @keyup="update" @change="search">
                    </div>
                </div>
            </form>
        </div>
        <div class="content">
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <td>id 1c</td>
                    <td>Название</td>
                    <td>Почта</td>
                    <td>ИНН</td>
                    <td>КПП</td>
                    <td>Телефон</td>
                    <td>Адрес</td>
                    <td>Почта менеджера</td>
                    <td>Менеджер</td>
                </tr>
                <tr v-for="company, idx in companies" :key="idx">
                    <td>{{ company.id_1c }}</td>
                    <td>{{ company.name }}</td>
                    <td>{{ company.email }}</td>
                    <td>{{ company.inn }}</td>
                    <td>{{ company.kpp }}</td>
                    <td>{{ company.phone }}</td>
                    <td>{{ company.address }}</td>
                    <td>{{ company.manageremail }}</td>
                    <td>{{ company.managersurname }} {{ company.managername }}</td>
                </tr>
            </table>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'

export default {
    computed: {
        ...Vuex.mapGetters({
            companies: 'companies/companies',
            searchCompaniesForm: 'companies/form',
        }),
        form() {
            return Object.assign({}, this.searchCompaniesForm)
        },
    },
    mounted() {
        this.search()
    },
    methods: {
        ...Vuex.mapActions({
            getCompanies: 'companies/getCompanies',
        }),
        ...Vuex.mapMutations({
            updateForm: 'companies/updateForm',
        }),
        update() {
            this.updateForm(this.form)
        },
        search() {
            this.getCompanies()
        },
    }
}
</script>
<style scoped src="@/css/nlk-style.css"></style>
<style lang="scss">
    .admin_user_companies_page{
        display: block !important;

        .nlk-content{
            margin-top: 50px;

            .field-input{
                margin-right: 20px;
            }
        }
    }
</style>