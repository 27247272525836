<template>
    <main class="admin-index index__admin">
        <div class="admin-index_header">
            <h3>Банеры</h3>
            <button 
                class="button button--primary save_btn" 
                @click="updateData"
            >
                Сохранить
            </button>
        </div>

        <button 
            v-if="!isShowForm"
            @click="isShowForm = true"
        >
            Добавить банер
        </button>

        <div 
            v-if="isShowForm"    
            class="single_banner"
        >
            <label>Id1c</label>        
            <input 
                type="text" 
                v-model="newBanner.id_1c" 
            >

            <label>Регион</label>      
            <select name="root_id" id="" v-model="newBanner.root_id">
                <option :value="null">Выберите регион</option>
                <option v-for="region in topLevelCategory" :key="region.id" :value="region.id_1c">{{ region.name }}</option>
            </select>

            <label>Название</label>
            <input 
                type="text" 
                v-model="newBanner.name" 
            >

            <label>Ссылка</label>
            <input 
                type="text" 
                v-model="newBanner.link" 
            >

            <label>Ссылка на изображение</label>
            <input 
                type="text" 
                v-model="newBanner.img" 
            >

            <div class="row">
                <label class="fl__img">
                    <input 
                        :ref="'fileDefaltBanner'+ index" 
                        type="file" 
                        @change="uploadImg"
                    >
                </label>
                <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
            </div>
            
            <div 
                v-if="newBanner.img"
                class="img_block" 
            >
                <img 
                    :src="newBanner.img" 
                    alt=""
                >
            </div>


            <button 
                @click="addBanner"
            >
                Сохранить
            </button>
        </div>

        <single
            v-for="banner, idx in homeCategoryBanners"
            :key="idx"
            :index="idx"
            :element="banner"
        />
    </main>
</template>
<script>
import Vuex from 'vuex'
import single from './single-banner.vue'

export default {
    components: {
        single,
    },
    data() {
        return {
            newBanner: {
                id_1c: null,
                name: null,
                img: null,
                link: null,
            },
            isShowForm: false,
        }
    },
    watch: {
        isShowForm(val) {
            if (val == true) {
                this.newBanner.id_1c = null
                this.newBanner.name = null
                this.newBanner.img = null
                this.newBanner.link = null
                this.newBanner.root_id = null
            }
        },
    },
    computed: {
        ...Vuex.mapGetters({
            homeCategoryBanners: 'app/homeCategoryBanners',
            topLevelCategory: 'dictionary/topLevelCategory',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            addStoreBanner: 'app/addBanner',
        }),
        updateData() {
            this.updateJson()
        },
        uploadImg() {
            let img = this.$refs['fileDefaltBanner'].files[0]
            this.upload({file: img})
                .then((response) => {
                    this.newBanner.img = this.fileUrl + response.data[0].link
                })
        },
        addBanner() {
            this.addStoreBanner(this.newBanner)
            this.isShowForm = false
        },
    },
}
</script>