<template>
    <div class="index__admin">
        <span>Нужна консультация?</span>

        <label>ФИО</label>
        <input 
            type="text" 
            v-model="editElement.name"
            placeholder="ФИО"
            @change="update" 
        >

        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="editElement.link"
            placeholder="Ссылка на изображение"
            @change="update" 
        >

        <div class="row">
            <label class="fl__img">
                <input 
                    ref="defaultFileConsultation" 
                    type="file" 
                    @change="uploadImg()"
                >
            </label>
            <p>Изображение должно быть размером 485х760 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат.</p>
        </div>
        <img 
            :src="editElement.link" 
            alt=""
        >
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {

        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiConsultations: 'ui_consultation/consultations',
        }),
        editElement() {
            return Object.assign({}, this.uiConsultations[0])
        }
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            createUiConsultation: 'ui_consultation/createConsultation',
            updateUiConsultation: 'ui_consultation/updateConsultation',
        }),
        update() {
            this.updateUiConsultation(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['defaultFileConsultation'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.updateUiConsultation({
                        id: this.editElement.id,
                        name: this.editElement.name,
                        link: this.fileUrl + response.data[0].link,
                        root_id: this.editElement.root_id,
                    })
                })
        },
    }
}
</script>