<template>
    <section class="vacancy_footer">
        <div class="container">
            <div class="vacancy_footer_block">
                <div class="left_part">
                    <img @click="push('')" class="footer_logo_vacancy" src="@/assets/img/vacancy/logo_footer.png" alt="">
                    <p class="vacancy_footer_link" @click="push('vacancies')">Вакансии</p>
                    <p class="vacancy_footer_link" @click="push('aboutCompany')">О компании</p>

                    <button 
                        v-if="currentMainPage.main_block" 
                        class="button button--primary" 
                        @click="openLink(currentMainPage.main_block.hh_link)"
                    >
                        Наши вакансии на hh.ru
                    </button>
                </div>
                <div class="right_part">
                    <p class="right_part_header">Заявка на обратный звонок</p>
                    <input type="text" v-model="phone" placeholder="+7 999 888 77 66">
                    <button class="button button--default" @click="sendCall">Заказать звонок</button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Functions from '@/utils/functions'
import Vuex from 'vuex'

export default {
    mixins: [Functions],
    data() {
        return {
            phone: '',
        }
    },
    computed: {
        ...Vuex.mapGetters({
            vacanciesMainPage: 'vacancy/vacanciesMainPage',
        }),
        currentMainPage() {
            var element = {}
            if (this.vacanciesMainPage[0]) {
                element = this.vacanciesMainPage[0]
            }
            return element
        }
    },
    methods: {
        ...Vuex.mapActions({
            sentAppBackCall: 'appmodal/sentAppBackCall',
        }),
        sendCall() {
            if (!this.phone || this.phone.length < 11) {
                return
            }
            let formData = new FormData()
            formData.append("type", 'backcall')
            formData.append("telephone", this.phone)
            this.sentAppBackCall(formData)
                .then(() => {
                    this.phone = null
                })
        },
        openLink(link) {
            window.open(link, '_blank')
        },
    },
}
</script>