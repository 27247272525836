<template>
  <div class="layout-section">
    <div class="section section--no-pt">
        <h3>Добрый день, Сергей!</h3>
        <ul class="list list--flex layout-cards -col-4 layout-cards--cabinet">
        <li class="list-item"><a class="card card--cabinet" href="#modal_partnerorder" data-modal>
            <div class="card-heading">
                <div class="card-icon">
                <svg class="icon" width="48.269" height="44.871" viewBox="0 0 48.269 44.871">
                    <use xlink:href="@/assets/img/icons.svg#shake_hands"></use>
                </svg>
                </div>
                <div class="card-title">Подать заявку<br> на партнерство</div>
            </div>
            <div class="card-arrow">
                <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                </svg>
            </div></a></li>
        <li class="list-item"><a class="card card--cabinet" href="#">
            <div class="card-heading">
                <div class="card-icon">
                <svg class="icon" width="38.864" height="45" viewBox="0 0 38.864 45">
                    <use xlink:href="@/assets/img/icons.svg#doctor"></use>
                </svg>
                </div>
                <div class="card-title">История<br> заказов</div>
            </div>
            <div class="card-arrow">
                <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                </svg>
            </div></a>
        </li>
        <li class="list-item"><a class="card card--cabinet" href="#">
            <div class="card-heading">
                <div class="card-icon">
                <svg class="icon" width="52.33" height="45.075" viewBox="0 0 52.33 45.075">
                    <use xlink:href="@/assets/img/icons.svg#paper_plane"></use>
                </svg>
                </div>
                <div class="card-title">Управление<br> рассылкой</div>
            </div>
            <div class="card-arrow">
                <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                </svg>
            </div></a>
        </li>
        <li class="list-item"><a class="card card--cabinet" href="#">
            <div class="card-heading">
                <div class="card-icon">
                <svg class="icon" width="50.096" height="45.632" viewBox="0 0 50.096 45.632">
                    <use xlink:href="@/assets/img/icons.svg#discuss_issue"></use>
                </svg>
                </div>
                <div class="card-title">Персональный<br> менеджер</div>
            </div>
            <div class="card-arrow">
                <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                </svg>
            </div></a>
        </li>
        </ul>
    </div>
    <div class="section section--no-pt section--no-pb">
        <h3>Мои заказы</h3>
        <div class="accordion accordion--orders">
        <div class="accordion-item order">
            <div class="accordion-header order-header">
            <div class="order-item">
                <div class="order-row">
                <div class="order-cell">
                    <div class="label">Заказ от 26.11.2018</div><b>Номер заказа 2406</b>
                </div>
                <div class="order-cell">
                    <div class="label">Статус заказа</div><b class="order-status">В работе</b>
                </div>
                <div class="order-cell">
                    <div class="label">Сумма заказа</div><b>8 940 руб</b>
                </div>
                </div>
            </div>
            </div>
            <div class="accordion-body order-body">
            <div class="order-info">
                <div class="order-item">
                <div class="order-row">
                    <div class="order-cell">
                    <div class="label">Дата доставки</div><span>28.11.2018</span>
                    </div>
                    <div class="order-cell">
                    <div class="label">Адрес доставки</div><span>Самовывоз:<br> г. Самара, ул. Заводское<br> шоссе, 5Б</span>
                    </div>
                </div>
                </div>
            </div>
            <div class="order-list">
                <div class="order-item order-item--labels">
                <div class="order-row order-row--labels">
                    <div class="order-cell">
                    <div class="label">Наименование</div>
                    </div>
                    <div class="order-cell">
                    <div class="label">Цена</div>
                    </div>
                    <div class="order-cell">
                    <div class="label">Количество</div>
                    </div>
                    <div class="order-cell">
                    <div class="label">Сумма</div>
                    </div>
                    <div class="order-cell order-cell--remove">
                    <div class="label">&nbsp;</div>
                    </div>
                </div>
                </div>
                <div class="order-item">
                <div class="order-row">
                    <div class="order-cell">
                    <div class="order-cell-label">
                        <div class="label">Наименование</div>
                    </div>
                    <div class="order-product">
                        <div class="order-product-image"><img src="@/assets/img/product.svg" alt=""></div>
                        <div class="order-product-title">Металлочерепица «Monterrey»</div>
                    </div>
                    </div>
                    <div class="order-cell">
                    <div class="order-cell-label">
                        <div class="label">Цена</div>
                    </div><b>490 руб/м2</b>
                    </div>
                    <div class="order-cell order-count">
                    <div class="order-cell-label">
                        <div class="label">Количество</div>
                    </div>10 шт
                    </div>
                    <div class="order-cell">
                    <div class="order-cell-label">
                        <div class="label">Сумма</div>
                    </div><b>4 900 руб</b>
                    </div>
                    <div class="order-cell order-cell--remove">
                    <button class="order-remove">Удалить</button>
                    </div>
                </div>
                </div>
                <div class="order-item">
                <div class="order-row">
                    <div class="order-cell">
                    <div class="order-cell-label">
                        <div class="label">Наименование</div>
                    </div>
                    <div class="order-product">
                        <div class="order-product-image"><img src="@/assets/img/product.svg" alt=""></div>
                        <div class="order-product-title">Металлочерепица «Monterrey»</div>
                    </div>
                    </div>
                    <div class="order-cell">
                    <div class="order-cell-label">
                        <div class="label">Цена</div>
                    </div><b>490 руб/м2</b>
                    </div>
                    <div class="order-cell order-count">
                    <div class="order-cell-label">
                        <div class="label">Количество</div>
                    </div>10 шт
                    </div>
                    <div class="order-cell">
                    <div class="order-cell-label">
                        <div class="label">Сумма</div>
                    </div><b>4 900 руб</b>
                    </div>
                    <div class="order-cell order-cell--remove">
                    <button class="order-remove">Удалить</button>
                    </div>
                </div>
                </div>
            </div>
            <div class="order-controls">
                <ul class="list list--flex layout-buttons">
                <li class="list-item"><a class="button button--primary" href="#">Повторить заказ</a></li>
                <li class="list-item"><a class="button button--default" href="#">Отменить заказ</a></li>
                <li class="list-item"><a class="button button--default" href="#">Квитанция заказа</a></li>
                </ul>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {

}
</script>