<template>
    <div class="admin-services-service">
        <div 
            v-for="(obj, i) in uiServices" 
            :key="i"
        >
            <single
                :key="i"
                :index="i"
                :element="obj"
            />
        </div>

        <div 
            v-if="!isShowForm"
            class="index__admin"
        >
            <button @click="isShowForm = true">
                Добавить услугу
            </button>
        </div>

        <div 
            v-if="isShowForm"
            class="index__admin"
        >
            <input 
                type="text" 
                v-model="newService.name"
                placeholder="Заголовок"
            >
            <div class="row">
                <div>
                    <label>Для кого услуга</label>
                    <select  
                        class="picker"  
                        v-model="newService.type"  
                    >
                        <option 
                            v-for="type in offersFrom" 
                            :key="type.id" 
                            :value="type.id"
                        >
                            {{ type.name }}
                        </option>
                    </select>
                </div>

                <div>
                    <label>Для какого региона услуга</label>
                    <select 
                        class="picker" 
                        v-model="newService.root_id" 
                    >
                        <option :value="null">Услуга для всех регионов</option>
                        <option v-for="reg, i in regions" :key="i" :value="reg.id_1c">{{ reg.name }}</option>
                    </select>
                </div>
            </div>
            <div>
                <vue-editor 
                    v-model="newService.descriptions"     
                    @text-change="update"
                />
            </div>

            <label>Ссылка на изображение</label>
            <input 
                type="text" 
                v-model="newService.link"
                placeholder="Ссылка на изображение"
            >

            <div class="row">
                <label class="fl__img">
                    <input 
                        :ref="'defaultFileService'" 
                        type="file" 
                        @change="uploadImg()"
                    >
                </label>
                <p>Изображение должно быть размером 80х90 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат.</p>
            </div>
            <img 
                :src="newService.link" 
                alt=""
            >

            <button @click="createService">
                Сохранить
            </button>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import single from './single.vue'
import { VueEditor } from "vue2-editor"

export default {
    components: {
        single,
        VueEditor,
    },
    data() {
        return {
            isShowForm: false,
            newService: {
                descriptions: null,
                link: null,
                name: null,
                root_id: null,
                type: 0,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiServices: 'ui_service/services',
            fileUrl: 'app/fileUrl',
            offersFrom:'dictionary/offersFrom',
            regions:'dictionary/topLevelCategory',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            createUiService: 'ui_service/createService',
            upload: 'app/upload',
        }),
        createService() {
            this.newService.type = Number(this.newService.type)
            this.newService.root_id = this.newService.root_id + ''
            this.createUiService(this.newService)
                .then(() => {
                    this.isShowForm = false
                    this.newService.descriptions = null
                    this.newService.link = null
                    this.newService.name = null
                    this.newService.root_id = null
                    this.newService.type = 0
                })
        },
        uploadImg() {
            let image = this.$refs['defaultFileService'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newService.link = this.fileUrl + response.data[0].link
                })
        },
    },
}
</script>