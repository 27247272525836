<template>
    <section>
        <div class="container-l">
            <div class="vacancy_single_page">
                <p class="vacancy_single_page_header">{{ currentVacancy.name }}</p>
                <div class="main_block">
                    <div class="left_part">
                        <div class="left_single" v-html="currentDescription">
                        </div>
                    </div>
                    <div class="right_part">
                        <div class="info_block">
                            <p class="info_name">Город</p>
                            <p class="info_value" v-if="currentVacancy.area">{{ currentVacancy.area.name }}</p>

                            <p class="info_name">Вакансия:</p>
                            <p class="info_value" v-if="currentVacancy.professional_roles && currentVacancy.professional_roles[0]">
                                {{ currentVacancy.professional_roles[0].name }}
                            </p>

                            <p class="info_name">Заработная плата (на руки):</p>
                            <p class="info_value" v-if="currentVacancy.salary">
                                от 
                                {{ getSumWithSpaces(currentVacancy.salary.from) }} 
                                до 
                                {{ getSumWithSpaces(currentVacancy.salary.to) }} 
                                ₽
                            </p>
                        </div>
                        <div class="single_page_buttons_block">
                            <button class="button button--primary" @click="scrollToBlock">Отправить резюме</button>
                            <button class="button button--default" @click="openLink">Откликнуться на hh.ru</button>
                        </div>
                    </div>
                </div>

                <resume-form />
            </div>
        </div>
    </section>
</template>
<script>
import resumeForm from './resume_form.vue'
import Vuex from 'vuex'

export default {
    components: {
        resumeForm,
    },
    computed: {
        ...Vuex.mapGetters({
            currentVacancy: 'vacancy/currentVacancy',
        }),
        currentDescription() {
            var string = null
            if (this.currentVacancy && this.currentVacancy.description) {
                string = this.currentVacancy.description
            }
            return string
        },
    },
    mounted() {
        this.getCurrent()
    },
    methods: {
        ...Vuex.mapActions({
            getCurrentVacancy: 'vacancy/getCurrentVacancy',
        }),
        getCurrent() {
            this.getCurrentVacancy(this.$route.params.id)
        },
        getSumWithSpaces(num) {
            var sum = 0
            if (num) {
                sum = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
            }
            return sum
        },
        scrollToBlock() {
            let topElement = document.querySelector('#resume_form_block')
            window.scrollTo({top: topElement.offsetTop, behavior: 'smooth'})
        },
        openLink() {
            window.open(this.currentVacancy.alternate_url, '_blank')
        },
    },
}
</script>