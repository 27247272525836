<template>
    <section class="single-coating-block">
        <div class="coating-list">
            <label for="">Название</label>
            <input 
                type="text" 
                v-model="editElement.name"
                @change="update"
            >
    
            <label for="">Короткое описание</label>
            <input 
                type="text" 
                v-model="editElement.short_description"
                @change="update"
            >
    
            <label for="">Описание</label>
            <input 
                type="text" 
                v-model="editElement.description"
                @change="update"
            >
    
            <label for="">Главное изображение</label>
            <img v-if="mainImage != null" :src="mainImage" alt="" class="coating_main_img">
            <input 
                v-model="mainImage" 
                type="text" 
                placeholder="Ссылка на изображение" 
            >
            <div class="row">
                <label class="fl__img">
                    <input 
                        :ref="'coatingMainImg' + editElement.id" 
                        type="file" 
                        @change="uploadImg"
                    >
                </label>
                <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
            </div>
    
            <label for="">Изображения</label>
            <div class="list_imgs">
                <div class="single_img" v-for="singleImg, idx in editElement.link_image" :key="idx">
                    <img :src="singleImg" alt="" class="coating_main_img">
                    <i class="fas fa-times" @click="deleteImgGallery(singleImg)"></i>
                </div>
            </div>
            <div class="row">
                <label class="fl__img">
                    <input 
                        :ref="'coatingMainGalleryImg' + editElement.id" 
                        type="file" 
                        @change="uploadGalleryImg"
                    >
                </label>
                <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
            </div>
    
            <label for="">Цвета</label>
            <div class="color_list">
                <div 
                    class="single_color"
                    v-for="color, i in colors"
                    :key="i" 
                    @click="addColor(color.id)"   
                >
                    <p>{{ color.sub_name }}</p>
                    <label class="checkbox-plus">
                        <div class="checkbox_nd">
                            <img v-if="!checkIssetColor(color.id)" class="check_false" src="~@/img/checkbox_false1.png" alt="">
                            <img v-if="checkIssetColor(color.id)" class="check_true" src="~@/img/checkbox_true.png" alt="">
                        </div>
                    </label>
                    <div v-if="color.code" class="background_color_block" :style="color.code == '#ffffff' ? 'background-color:' + color.code + '; border: 1px solid black' : 'background-color:' + color.code"></div>
                    <img v-if="color.image" :src="color.image" class="background_color_block" alt="">
                </div>
            </div>
    
            <label for="">Характеристики</label>
            <div class="form_new_char">
                <select name="" id="" v-model="selectedChar">
                    <option value="null">Выбрать характеристику</option>
                    <option :value="char.id" v-for="char, idx in chars" :key="idx" v-show="!checkIssetChar(char.id)">{{ char.name }}</option>
                </select>
    
                <button class="button button--primary" @click="addChar">Добавить</button>
            </div>
            <div class="char_list">
                <single-char 
                    v-for="charL, idx in editElement.list_chars" 
                    :key="idx" 
                    :element="charL" 
                    @updateValueChar="updateValueChar"
                    @deleteChar="deleteChar" 
                />
            </div>
    
            <label for="">Регионы</label>
            <div class="form_new_char">
                <select name="" id="" v-model="selectedRegion">
                    <option value="null">Выбрать регион</option>
                    <option :value="region.id_1c" v-show="!checkIssetRegion(region.id_1c)" v-for="region, idx in regions" :key="idx">{{ region.name }}</option>
                </select>
    
                <button class="button button--primary" @click="addRegion">Добавить</button>
            </div>
            <div class="region_list">
                <div v-for="singleRegion, idx in editElement.root_ids" :key="idx" class="single_region">
                    <p>{{ getRegionNameById1c(singleRegion) }}</p>
                    <i class="fas fa-times" @click="deleteRegion(singleRegion)"></i>
                </div>  
            </div>
    
            <button class="button button--primary delete_button" @click="deleteCoating">Удалить покрытие</button>
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'
import singleChar from './singleChar.vue'

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
    },
    components: {
        singleChar,
    },
    data() {
        return {
            selectedChar: null,
            selectedRegion: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            colors: 'coatingColors/colors',
            chars: 'coatingChars/chars',
            fileUrl: 'app/fileUrl',
            regions:'dictionary/topLevelCategory',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
        mainImage() {
            var link = null
            if (this.editElement.image[0]) {
                link = this.editElement.image[0]
            }
            return link
        },
    },
    mounted() {
        
    },
    methods: {
        ...Vuex.mapActions({
            deleteStoreCoating: 'coatings/deleteCoating',
            updateCoating: 'coatings/updateCoating',
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            pushColor: 'coatings/pushColor',
        }),
        addColor(color_id) {
            this.pushColor({coating_id: this.editElement.id, color_id: color_id})
            this.update()
        },
        checkIssetColor(id) {
            var isset = false
            this.editElement.list_colors.map((element) => {
                if (element.color_id == id) {
                    isset = true
                }
            })

            return isset
        },
        deleteCoating() {
            this.deleteStoreCoating(this.editElement.id)
        },
        update() {
            this.updateCoating({id: this.editElement.id, object: this.editElement})
        },
        uploadImg() {
            let image = this.$refs['coatingMainImg' + this.editElement.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editElement.image[0] = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
        uploadGalleryImg() {
            let image = this.$refs['coatingMainGalleryImg' + this.editElement.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editElement.link_image.push(this.fileUrl + response.data[0].link)
                    this.update()
                })
        },
        deleteImgGallery(link) {
            this.editElement.link_image.map((element, idx) => {
                if (element == link) {
                    this.editElement.link_image.splice(idx, 1)
                    this.update()
                }
            })
        },
        updateValueChar(object) {
            this.editElement.list_chars.map((element) => {
                if (element.char_id == object.char_id) {
                    element.value = object.value
                }
            })
            this.update()
        },
        deleteChar(id) {
            this.editElement.list_chars.map((element, idx) => {
                if (element.char_id == id) {
                    this.editElement.list_chars.splice(idx, 1)
                }
            })
        },
        deleteRegion(id_1c) {
            this.editElement.root_ids.map((element, idx) => {
                if (element == id_1c) {
                    this.editElement.root_ids.splice(idx, 1)
                }
            })
        },
        getRegionNameById1c(id_1c) {
            var name = null
            this.regions.map((element) => {
                if (element.id_1c == id_1c) {
                    name = element.name
                }
            })
            return name
        },
        checkIssetRegion(id_1c) {
            var isset = false
            this.editElement.root_ids.map((element) => {
                if (element == id_1c) {
                    isset = true
                }
            })
            return isset
        },
        checkIssetChar(id) {
            var isset = false
            this.editElement.list_chars.map((element) => {
                if (element.char_id == id) {
                    isset = true
                }
            })
            return isset
        },
        addRegion() {
            if (this.selectedRegion == null) {
                return
            }
            var region_id = JSON.stringify(this.selectedRegion)
            region_id = JSON.parse(region_id)
            this.editElement.root_ids.push(region_id)
            this.selectedRegion = null
            this.update()
        },
        addChar() {
            if (this.selectedChar == null) {
                return
            }
            var char_id = JSON.stringify(this.selectedChar)
            char_id = JSON.parse(char_id)
            this.editElement.list_chars.push({char_id: char_id, value: null, sort: 1})
            this.selectedChar = null
            this.update()
        },
    },
}
</script>