<template>
    <div class="index__admin" >
        <span>Стоимость № {{ index +1 }}</span>
        <span 
            class="index__del" 
            @click="deleteUiCost(element.id)" 
        >
            Удалить Х
        </span>
        
        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="editElement.link"
            placeholder="Ссылка на изображение"
            @change="update" 
        >
        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'singleFileCost'+ index" 
                    type="file" 
                    @change="uploadImg()"
                >
            </label>
            <p>Изображение должно быть размером 400х276 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат. </p>
        </div>
        <img 
            :src="editElement.link" 
            alt=""
        >
        <input type="text" v-model="editElement.cost" @change="update" placeholder="Цифра">
        <input type="text" v-model="editElement.header" @change="update" placeholder="Заголовок">
        <input type="text" v-model="editElement.button" @change="update" placeholder="Надпись на кнопке">
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    props:['element', 'index'],
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            updateUiCost: 'ui_cost/updateCost',
            deleteUiCost: 'ui_cost/deleteCost',
        }),
        update() {
            this.updateUiCost(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['singleFileCost' + this.index].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.updateUiCost({
                        id: this.editElement.id,
                        cost: this.editElement.cost,
                        button: this.editElement.button,
                        header: this.editElement.header,
                        root_id: this.editElement.root_id,
                        link: this.fileUrl + response.data[0].link
                    })
                })
        },
    },
}
</script>