<template>
    <main class="admin-index index__admin">
        <div class="admin-index_header">
            <h3>Наши объекты</h3>
            <button 
                class="button button--primary save_btn" 
                @click="updateData"
            >
                Сохранить
            </button>

        </div>
        <div class="index__admin">
            <div>
                <label>Категории объектов</label>
                <button @click="addCat">Создать категорию</button>
            </div>
            <category
                v-for="(obj, idx) in our_objects.categories" 
                :key="idx"
                :index="idx"
                :element="obj"
            />
        </div>
        <div class="index__admin">
            <div>
                <label>Oбъекты</label>
            </div>
             <one-object 
                v-show="catObject == obj.link"
                v-for="(obj, i) in objects" 
                :key="i"
                :index="i"
                :element="obj"
            />
            <div>
                <select class="picker" v-model="catObject" >
                    <option 
                        v-for="(obj, i) in our_objects.categories" 
                        :key="i" 
                        :value="obj.link"
                        @click="changeCat(obj.link)"  
                    >
                        {{obj.name}}
                    </option>
                </select>
                <button @click="addObj">Добавить объект</button>
            </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import Category from './singleCategoty.vue'
import OneObject from './singleObject.vue'
export default {
    components: {
        Category,
        OneObject,
    },
    data() {
        return {
            objects:[],
            catObject: '',
            dataObjects: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            our_objects: 'app/our_objects',
            fileUrl: 'app/fileUrl',
        }),
        
    },
    watch:{
        our_objects(){
            this.getFirstCat()
        },
        catObject(){
            this.getObjects()
        }
    },
    created(){
        this.getFirstCat()
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            pushObjectsCats: 'app/pushObjectsCats',
            pushObjects: 'app/pushObjects',
        }),
        updateData() {
            this.updateJson()
        },
        addCat(){
            this.pushObjectsCats({name: null, link: null, objects: []})
        },
        changeCat(val){
            this.catObject = val
        },
        addObj(){
            this.pushObjects(
                {
                    name: null, 
                    link: this.catObject,
                    material: null, 
                    location: null,
                    volume: null, 
                    works: null,
                    category: null, 
                    mainImg: null,
                    linkObj: null,
                    imgs: []
                })
        },
        getFirstCat(){
            if(this.our_objects.categories == null){
                return
            }else{
                this.catObject = this.our_objects.categories[0].link
            }
        },
        getObjects(){
            this.our_objects.categories.map((el)=>{
                if(el.link == this.catObject){
                    this.objects = el.objects
                }
            })
        },
    },
}
</script>
