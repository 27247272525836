<template>
    <div class="admin-services-cost">
        <span>Сколько это стоит?</span>
        <div class="index__admin">
            <button 
                v-if="!isShowForm"
                @click="isShowForm = true"
            >
                Добавить стоимость
            </button>
        </div>

        <div 
            v-if="isShowForm"
            class="new_cost"
        >
            <div class="index__admin" >
                <label>Ссылка на изображение</label>
                <input 
                    type="text" 
                    v-model="newCost.link"
                    placeholder="Ссылка на изображение"
                >
                <div class="row">
                    <label class="fl__img">
                        <input 
                            ref="defaultFileCost" 
                            type="file" 
                            @change="uploadImg()"
                        >
                    </label>
                    <p>Изображение должно быть размером 400х276 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат. </p>
                </div>
                <img 
                    :src="newCost.link" 
                    alt=""
                >
                <input type="text" v-model="newCost.cost" placeholder="Цифра">
                <input type="text" v-model="newCost.header" placeholder="Заголовок">
                <input type="text" v-model="newCost.button" placeholder="Надпись на кнопке">

                <button @click="createCost">
                    Сохранить
                </button>
            </div>
        </div>

        <div 
            v-for="(obj, idx) in uiCosts" 
            :key="idx"
        >
            <single
                :key="idx"
                :index="idx"
                :element="obj"
            />
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import single from './single.vue'

export default {
    components: {
        single,
    },
    data() {
        return {
            newCost: {
                cost: null,
                button: null,
                link: null,
                header: null,
                root_id: null,
            },
            isShowForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiCosts: 'ui_cost/costs',
            fileUrl: 'app/fileUrl',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            createUiCost: 'ui_cost/createCost',
            upload: 'app/upload',
        }),
        createCost() {
            this.newCost.cost = Number(this.newCost.cost)
            this.createUiCost(this.newCost)
                .then(() => {
                    this.isShowForm = false
                    this.newCost.cost = null
                    this.newCost.button = null
                    this.newCost.link = null
                    this.newCost.header = null
                    this.newCost.root_id = null
                })
        },
        uploadImg() {
            let image = this.$refs['defaultFileCost'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newCost.link = this.fileUrl + response.data[0].link
                })
        },
    }
}
</script>