import Http from "../utils/http"
import Config from "../config"
import Vue from "vue"
import axios from "axios"
import store from './index'

const state = {
    fileUrl: Config.fileUrl,
    yandexCloud: Config.yandexCloud,
    layout: 'main',
    message: null,
    indexEditedContactAddress: null,
    stateLeftMenu: '',
    currentWindowWidth: null,
    isOpenMobileNavigation: false,
    isOpenMobileSubNavigation: false,

    main: {},

    company: {},
    documents: {},
    offers: {},
    contacts: {},
    supports:{},
    our_objects: [],
    becomePartner: {},
    serviсes: {},
    legals:{},
    pagesOffers: [],
    pagesObjects: [],
    calculators: {
        calculatorRoof: {
            step1: {
                description: null
            },
            step1A: {
                description: null
            },
            step2: {
                description: null
            },
            step3: {
                description: null
            },
            step4: {
                description: null
            }
        },
        calculatorDrainage: {
            step1: {
                description: null
            },
            step1A: {
                description: null
            },
            step2: {
                description: null
            },
            step3: {
                description: null
            },
            step4: {
                description: null
            },
            step5: {
                description: null
            },
            generalDescription: {
                header: null,
                text: null,
            },
        },
        calculatorFence: {
            step1: {
                description: null
            },
            step2: {
                description: null
            },
            step3: {
                description: null
            },
        },
    },
    regionAdresses: [],
    claim_emails: [],
    review_emails: [],
    project_emails: [],
    common_emails: [],
    support_emails: [],
    reconciliation_act: [],
    hr_department: [],

    isShowAddUserModal: false,
    isShowCreateUtmModal: false,

    isShowDeleteOrderModal: false,
    deleteOrderId: null,

    isShowCleanBasketModal: false,

    isShowSuccessRegistry: false,
    successRegistryEmail: null,

    isShowSendedModal: false,
    isShowUserExistModal: false,

    isShowPdfPreviewModal: false,
    pdfPreviewModalLink: null,

    isShowOfferModal: false,

    isShowSendCodeSmsModal: false,
    registryModalPhone: '',

    windowPositionScroll: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    dealersPoints: [],
    deliveryPoints: [],



    sourceUtmPhone: null,
    sourceUtmEmail: null,

    widgetCode: null,
    currentWidgetCode: null,

    isShowRecoverySended: false,
    isShowErrorActivateUser: false,
    isShowSuccessCreateOrder: false,
    isShowBasketNotEmpty: false,
    isShowError500: false,
    isShowCallbackModalForm: false,

    homeCategoryBanners: [],
}

const getters = {
    layout: state => state.layout,
    message: state => state.message,
    fileUrl: state => state.fileUrl,
    yandexCloud: state => state.yandexCloud,
    company: state => state.company,
    documents: state => state.documents,
    offers: state => state.offers,
    contacts: state => state.contacts,
    main: state => state.main,
    supports: state => state.supports,
    our_objects: state => state.our_objects,
    becomePartner: state => state.becomePartner,
    indexEditedContactAddress: state => state.indexEditedContactAddress,
    stateLeftMenu: state => state.stateLeftMenu,
    serviсes: state => state.serviсes,
    legals: state => state.legals,
    calculators: state => state.calculators,
    pagesOffers: state => state.pagesOffers,
    pagesObjects: state => state.pagesObjects,
    regionAdresses: state => state.regionAdresses,

    claim_emails: state => state.claim_emails,
    review_emails: state => state.review_emails,
    project_emails: state => state.project_emails,
    common_emails: state => state.common_emails,
    support_emails: state => state.support_emails,
    reconciliation_act: state => state.reconciliation_act,
    hr_department: state => state.hr_department,

    currentWindowWidth: state => state.currentWindowWidth,
    isOpenMobileNavigation: state => state.isOpenMobileNavigation,
    isOpenMobileSubNavigation: state => state.isOpenMobileSubNavigation,
    isShowAddUserModal: state => state.isShowAddUserModal,
    isShowDeleteOrderModal: state => state.isShowDeleteOrderModal,
    isShowSuccessRegistry: state => state.isShowSuccessRegistry,
    successRegistryEmail: state => state.successRegistryEmail,
    isShowSendedModal: state => state.isShowSendedModal,
    isShowCleanBasketModal: state => state.isShowCleanBasketModal,
    deleteOrderId: state => state.deleteOrderId,
    isShowUserExistModal: state => state.isShowUserExistModal,
    isShowOfferModal: state => state.isShowOfferModal,
    windowPositionScroll: state => state.windowPositionScroll,
    dealersPoints: state => state.dealersPoints,
    isShowPdfPreviewModal: state => state.isShowPdfPreviewModal,
    pdfPreviewModalLink: state => state.pdfPreviewModalLink,
    isShowCreateUtmModal: state => state.isShowCreateUtmModal,
    isShowSendCodeSmsModal: state => state.isShowSendCodeSmsModal,
    registryModalPhone: state => state.registryModalPhone,


    sourceUtmPhone: state => state.sourceUtmPhone,
    sourceUtmEmail: state => state.sourceUtmEmail,
    widgetCode: state => state.widgetCode,
    currentWidgetCode: state => state.currentWidgetCode,


    isShowRecoverySended: state => state.isShowRecoverySended,
    isShowErrorActivateUser: state => state.isShowErrorActivateUser,
    isShowSuccessCreateOrder: state => state.isShowSuccessCreateOrder,
    isShowBasketNotEmpty: state => state.isShowBasketNotEmpty,
    isShowError500: state => state.isShowError500,
    isShowCallbackModalForm: state => state.isShowCallbackModalForm,

    deliveryPoints: state => state.deliveryPoints,

    homeCategoryBanners: state => state.homeCategoryBanners,
}

const actions = {
    getJson: ({commit}) => {
        return new Promise((resolve) => {
            Http.get(Config.yandexCloud + "json/indexmayak.json")
                .then(resp => {
                    commit("getPagesOffers",resp.offers.offers)
                    commit("getPagesObjects",resp.our_objects.categories)
                    commit("parseJson", resp)
                    // console.log(resp)
                    resolve(resp)
                })
        })
    },
    getDealersPoints: ({commit}, id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/dealers", {root_1c_id: id_1c}, {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setDealersPoints", resp.data)
                    resolve(resp)
                })
        })
    },
    updateJson: ({commit, getters}) => {
        if (
                Object.keys(getters.company).length == 0 &&
                Object.keys(getters.documents).length == 0 &&
                Object.keys(getters.offers).length == 0 &&
                Object.keys(getters.contacts).length == 0 &&
                Object.keys(getters.supports).length == 0 &&
                getters.our_objects.length == 0 &&
                Object.keys(getters.becomePartner).length == 0 &&
                Object.keys(getters.serviсes).length == 0 &&
                Object.keys(getters.main).length == 0 &&
                Object.keys(getters.legals).length == 0 &&
                getters.regionAdresses.length == 0 
            ) {
            console.log('Вы не можете сохранить пустой файл')
            return 
        }
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/file/data", 
              {
                company: getters.company,
                documents: getters.documents,
                offers: getters.offers,
                contacts: getters.contacts,
                supports: getters.supports,
                our_objects: getters.our_objects,
                becomePartner: getters.becomePartner,
                serviсes: getters.serviсes,
                main: getters.main,
                legals: getters.legals,
                calculators: getters.calculators,
                regionAdresses: getters.regionAdresses,
                widgetCode: getters.widgetCode,
                deliveryPoints: getters.deliveryPoints,
                homeCategoryBanners: getters.homeCategoryBanners,
              })
                .then(response => {
                    commit("setMessage", response)
                    resolve(response)
                })
        })
    },
    upload: ({commit}, obj) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            var formData = new FormData()
            formData.append("file", obj.file)
            formData.append("message_id", 1)
            Http.post(Config.apiUrl + "/v1/file/addfile",
                formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'X-Company-ID': id
                    },
                })
                .then(response => {
                    commit("setMessage", "success")
                    resolve(response)
                })
        })
    },
    getTechnicalEmails: ({commit}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/sendemails", {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setTechnicalEmails", response.data)
                    resolve(response)
                })
        })
    },
    updateTechnicalEmails: ({commit}, object) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.put(Config.apiUrl + "/v1/sendemails", object, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setTechnicalEmails", response.data)
                    resolve(response)
                })
        })
    },
    getSvgHtml: ({commit}, link) => {
        return new Promise((resolve) => {
            Http.get(link)
                .then(response => {
                    commit("setMessage", "success")
                    resolve(response)
                })
        })
    },
    getPDF: ({commit}) => {
        return new Promise((resolve) => {
            Http.get('https://cloud-api.yandex.net/v1/disk/public/resources?public_key=<LfGfTNhNy8>&[path=<dev-mayakmetall/images>]')
                .then(response => {
                    commit("setMessage", "success")
                    resolve(response)
                })
        })
    },
    getAddressFromCoords: ({commit}, payload) => {
        return new Promise((resolve) => {
            axios.get('https://geocode-maps.yandex.ru/1.x/?apikey=' + payload.key + '&geocode=' + payload.lng + ', ' + payload.lat + '&lang=ru_RU&format=json')
                .then(response => {
                    commit("setMessage", "success")
                    resolve(response)
                })
        })
    },
}


const mutations = {
    setShowCallbackModalForm: (state, val) => {
        state.isShowCallbackModalForm = val
    },
    pushDeliveryPoint: (state, val) => {
        state.deliveryPoints.push(val)
    },
    removeDeliveryPoint: (state, index) => {
        state.deliveryPoints.map((element, idx) => {
            if (idx == index) {
                state.deliveryPoints.splice(idx, 1)
            }
        })
    },
    setShowError500: (state, val) => {
        state.isShowError500 = val
    },
    setShowBasketNotEmpty: (state, val) => {
        state.isShowBasketNotEmpty = val
    },
    setShowSuccessCreateOrder: (state, val) => {
        state.isShowSuccessCreateOrder = val
    },
    setShowErrorActivateUser: (state, val) => {
        state.isShowErrorActivateUser = val
    },
    setShowRecoverySended: (state, val) => {
        state.isShowRecoverySended = val
    },
    updateWidgetCode: (state, val) => {
        state.widgetCode = val
    },
    setSourceUtmPhone: (state, val) => {
        state.sourceUtmPhone = val
    },
    setSourceUtmEmail: (state, val) => {
        state.sourceUtmEmail = val
    },
    setCreateUtmModal: (state, val) => {
        state.isShowCreateUtmModal = val
    },
    setPdfPreviewModal: (state, val) => {
        state.isShowPdfPreviewModal = val
    },
    setPdfPreviewModalLink: (state, val) => {
        state.pdfPreviewModalLink = val
    },
    setDealersPoints: (state, val) => {
        state.dealersPoints = val
    },
    setWindowPositionScroll: (state, val) => {
        state.windowPositionScroll = val
    },
    setShowOfferModal: (state, val) => {
        state.isShowOfferModal = val
    },
    setShowUserExistModal: (state, val) => {
        state.isShowUserExistModal = val
    },
    setDeleteOrderId: (state, val) => {
        state.deleteOrderId = val
    },
    setShowCleanBasketModal: (state, val) => {
        state.isShowCleanBasketModal = val
    },
    setShowSendedModal: (state, val) => {
        state.isShowSendedModal = val
    },
    setSuccessRegistryEmail: (state, val) => {
        state.successRegistryEmail = val
    },
    setShowSuccessRegistry: (state, val) => {
        state.isShowSuccessRegistry = val
    },
    setShowDeleteOrderModal: (state, val) => {
        state.isShowDeleteOrderModal = val
    },
    setShowAddUserModal: (state, val) => {
        state.isShowAddUserModal = val
    },
    setShowSendCodeSmsModal: (state, val) => {
        state.isShowSendCodeSmsModal = val
    },
    setRegistryModalPhone: (state, val) => {
        state.registryModalPhone = val
    },
    setMessage: (state, val) => {
        state.message = val
    },
    setLayout: (state, val) => {
        state.layout = val
    },
    parseJson: (state, val) => {
        if (val.documents) {
            state.documents = val.documents
        }
        if (val.offers) {
            state.offers = val.offers
        }
        if (val.contacts) {
            state.contacts = val.contacts
        }
        if (val.company) {
            state.company = val.company
        }
        if (val.supports) {
            state.supports = val.supports
        }
        if (val.becomePartner) {
            state.becomePartner = val.becomePartner
        }
        if (val.our_objects) {
            state.our_objects = val.our_objects
        }
        if (val.serviсes) {
            state.serviсes = val.serviсes
        }
        if (val.main) {
            state.main = val.main
        }
        if (val.legals) {
            state.legals = val.legals
        }
        if (val.widgetCode) {
            state.widgetCode = val.widgetCode
            state.currentWidgetCode = val.widgetCode
        }
        if (val.calculators) {
            state.calculators = val.calculators
            if (!state.calculators.calculatorDrainage.generalDescription) {
                state.calculators.calculatorDrainage.generalDescription = { header: null, text: null }
            }
        }
        if (val.regionAdresses) {
            state.regionAdresses = val.regionAdresses
        }

        if (val.deliveryPoints) {
            state.deliveryPoints = val.deliveryPoints
        }

        if (val.homeCategoryBanners) {
            state.homeCategoryBanners = val.homeCategoryBanners
        }
    },
    pushMainSlide: (state) => {
        if (!state.main.slides) {
            state.main.slides = []
        }
        state.main.slides.push({name: null, img: null})
    },
    updateMainSlide: (state, obj) => {
        state.main.slides.map((element, i) => {
            if (i == obj.idx) {
                element.name = obj.name
                element.img = obj.img
            }
        })
    },
    deleteMainSlide: (state, idx) => {
        state.main.slides.map((element, i) => {
            if (idx == i) {
                state.main.slides.splice(i, 1)
            }
        })
    },
    updateMainAboutCompany: (state, obj) => {
        state.main.aboutCompany.header = obj.header
        state.main.aboutCompany.description = obj.description
    },
    pushMainPopularProduct: (state) => {
        if (!state.main.popularProducts) {
            state.main.popularProducts = []
        }
        state.main.popularProducts.unshift({link: null})
    },
    deleteMainPopularProduct: (state, idx) => {
        state.main.popularProducts.map((element, i) => {
            if (idx == i) {
                state.main.popularProducts.splice(i, 1)
            }
        })
    },
    updateMainPopularProduct: (state, obj) => {
        state.main.popularProducts.map((element, i) => {
            if (obj.idx == i) {
                element.link = obj.link
            }
        })
    },
    updateMainStore: (state, obj) => {
        if (!state.main.blockInternetStore) {
            state.main.blockInternetStore = {header: null, description: null}
        }
        state.main.blockInternetStore.header = obj.header
        state.main.blockInternetStore.description = obj.description
    },
    addShare: (state) => {
        state.shares.push({link: null})
    },
    deleteShare: (state, idx) => {
        state.shares.map((element, i) => {
            if (idx == i) {
                state.shares.splice(i, 1)
            }
        })
    },
    updateShare: (state, obj) => {
        state.shares.map((element, i) => {
            if (obj.idx == i) {
                element.link = obj.link
            }
        })
    },
    pushMainClient: (state) => {
        if (!state.main.clients) {
            state.main.clients = []
        }
        state.main.clients.push({img: null})
    },
    deleteMainClient: (state, idx) => {
        state.main.clients.map((element, i) => {
            if (idx == i) {
                state.main.clients.splice(i, 1)
            }
        })
    },
    updateMainClient: (state, obj) => {
        state.main.clients.map((element, i) => {
            if (obj.idx == i) {
                element.img = obj.img
            }
        })
    },
    setDocumentFiles: (state, val) => {
        state.documents.files = val
    },
    updateDocumentFile: (state, obj) => {
        state.documents.files.map((element, i) => {
            if (i == obj.idx) {
                element.name = obj.name
                element.file = obj.file
                element.category = obj.category
                element.typeFile = obj.typeFile
            }
        })
    },
    pushOffer: (state, obj) => {
        if (!state.offers.offers) {
            state.offers.offers = []
        }
        state.offers.offers.unshift(obj)
    },
    updateOffer: (state, obj) => {
        state.offers.offers.map((element, i) => {
            if (i == obj.idx) {
                element.type = obj.type
                element.startDate = obj.startDate
                element.endDate = obj.endDate
                element.from = obj.from
                element.region = obj.region
                element.img = obj.img
                element.heading = obj.heading
                element.description = obj.description
                element.details = obj.details
                element.link = obj.link
            }
        })
    },
    deleteOffer: (state, index) => {
        state.offers.offers.map((element, i) => {
            if (index == i) {
                state.offers.offers.splice(i, 1)
            }
        })
    },
    getPagesOffers: (state, offers) => {
        state.pagesOffers.push(  { name: "Акции и предложения", url: "offers", fullUrl: 'offers'})
            if (offers !=  null) {
                offers.map((el) => {
                    state.pagesOffers.push({ name: el.heading, url: el.link , fullUrl: 'offers/'+ el.link})
            })
        }
    },
    
    pushObjectsCats: (state, obj) => {
        if (!state.our_objects.categories) {
            state.our_objects.categories = []
        }
        state.our_objects.categories.push(obj)
    },
    updateObjectsCats: (state, obj) => {
        state.our_objects.categories.map((element, i) => {
            if (i == obj.idx) {
                element.name = obj.name
                element.link = obj.link
                element.objects.map((el)=>{
                    el.link = obj.link
                })
            }
        })
    },
    deleteObjectsCats: (state, index) => {
        state.our_objects.categories.map((element, i) => {
            if (index == i) {
                state.our_objects.categories.splice(i, 1)
            }
        })
    },

    pushObjects: (state, obj) => {
        state.our_objects.categories.map((el) => {
            if (!el.objects) {
                el.objects = []
            }
            if(el.link == obj.link){
                el.objects.push(obj)
            }
            
        })
    },
    updateObjects: (state, obj) => {
        state.our_objects.categories.map((el) => {
            if (el.link == obj.link) {
                el.objects.map((element, i) => {
                    if (i == obj.idx) {
                        element.name = obj.name
                        element.link = obj.link
                        element.linkObj = obj.linkObj
                        element.material = obj.material
                        element.location = obj.location
                        element.volume = obj.volume
                        element.works = obj.works
                        element.category = obj.category
                        element.mainImg = obj.mainImg
                        element.imgs = obj.imgs
                        
                    }
                })
            }
        })
   
    },
    deleteObjects: (state, obj) => {
        state.our_objects.categories.map((element, i) => {
            if (element.link == obj.link) {
                element.objects.map((el, idx) => {
                    if (idx == obj.index) {
                        state.our_objects.categories[i].objects.splice(idx, 1)
                    }
                })
            }
        })
        
    },
    getPagesObjects: (state, categories) => {
        state.pagesObjects.push(  { name: "Наши объекты", url: "objects", fullUrl: 'objects'})
            if (categories !=  null) {
                categories.map((el) => {
                    state.pagesObjects.push({ name: el.name, url: el.link , fullUrl: 'objects/'+ el.link})
                    if(el.objects != null){
                        el.objects.map((ell) => {
                            state.pagesObjects.push({ name: ell.name, url: ell.linkObj , fullUrl: 'objects/'+ ell.link + '/' +  ell.linkObj })
                        })
                    }

                   })  
        }
          
    },
    updateCompanyObject: (state, val) => {
        state.company = val
    },
    pushCompanyHistory: (state, obj) => {
        if (!state.company.dataHistory) {
            state.company.dataHistory = []
        }
        state.company.dataHistory.unshift(obj)
    },
    updateCompanyHistory: (state, obj) => {
        state.company.dataHistory.map((element, i) => {
            if (i == obj.idx) {
                element.blockType = obj.blockType
                element.year = obj.year
                element.heading = obj.heading
                element.description = obj.description
                element.link = obj.link
            }
        })
    },
    deleteCompanyHistory: (state, index) => {
        state.company.dataHistory.map((element, i) => {
            if (index == i) {
                state.company.dataHistory.splice(i, 1)
            }
        })
    },
    pushCompanyBlock: (state, obj) => {
        if (!state.company.dataBlocks) {
            state.company.dataBlocks = []
        }
        state.company.dataBlocks.push(obj)
    },
    updateCompanyBlock: (state, obj) => {
        state.company.dataBlocks.map((element, i) => {
            if (i == obj.idx) {
                element.blockLink = obj.blockLink
                element.blockName = obj.blockName
            }
        })
    },
    deleteCompanyBlock: (state, index) => {
        state.company.dataBlocks.map((element, i) => {
            if (index == i) {
                state.company.dataBlocks.splice(i, 1)
            }
        })
    },
    setContactAddresses: (state, val) => {
        state.contacts.addresses = val
    },
    pushContactAddress: (state, obj) => {
        if (!state.contacts.addresses) {
            state.contacts.addresses = []
        }
        state.contacts.addresses.unshift(obj)
    },
    deleteContactAddress: (state, idx) => {
        state.contacts.addresses.map((element, i) => {
            if (i == idx) {
                state.contacts.addresses.splice(i, 1)
            }
        })
    },
    updateContactsAddress: (state, obj) => {
        state.contacts.addresses.map((element, i) => {
            if (obj.isMainOffice == true) {
                element.isMainOffice = false
            }

            if (i == obj.idx) {
                element.isMainOffice = obj.isMainOffice
                element.isOffice = obj.isOffice
                element.isProduction = obj.isProduction
                element.isWarehouse = obj.isWarehouse
                element.region = obj.region
                element.city = obj.city
                element.street = obj.street
                element.working_hours = obj.working_hours 
                element.lat = obj.lat
                element.lng = obj.lng
                element.region_id_1c = obj.region_id_1c
                element.email = obj.email
                element.phone = obj.phone 
                element.link_map = obj.link_map 
            }
        })
    },
    updateSupports: (state, supports) => {
        state.supports.dataTechs = supports.dataTechs
        state.supports.dataFAQ = supports.dataFAQ
    },
    deleteSupportsVideo: (state, idx) => {
        state.supports.dataVideos.map((element, index) => {
            if (index == idx) {
                state.supports.dataVideos.splice(idx, 1)
            }
        })
    },
    deleteSupportsVideoImg: (state, idx) => {
        state.supports.dataVideos.map((element, index) => {
            if (index == idx) {
                element.img = null
            }
        })
    },
    updateSupportsVideo: (state, obj) => {
        state.supports.dataVideos.map((element, idx) => {
            if (obj.index == idx) {
                element.name = obj.name
                element.link = obj.link
                element.img = obj.img
            }
        })
    },
    addSupportsVideo: (state, obj) => {
        state.supports.dataVideos.push(obj)
    },
    pushDocumentFiles: (state, obj) => {
        if (!state.documents.files) {
            state.documents.files = []
        }
        state.documents.files.push(obj)
    },
    deleteDocumentFile: (state, index) => {
        state.documents.files.map((element, idx) => {
            if (index == idx) {
                state.documents.files.splice(idx, 1)
            }
        })
    },
    addBenefitBecomePartner: (state, obj) => {
        if (!state.becomePartner.benefits) {
            state.becomePartner.benefits = []
        }
        state.becomePartner.benefits.push(obj)
    },
    updateBenefitBecomePartner: (state, obj) => {
        state.becomePartner.benefits.map((element, i) => {
            if (i == obj.idx) {
                element.name = obj.name
                element.img = obj.img
                element.type = obj.type
                element.imgType = obj.imgType
            }
        })
    },
    deleteBenefitBecomePartner: (state, index) => {
        state.becomePartner.benefits.map((element, idx) => {
            if (index == idx) {
                state.becomePartner.benefits.splice(idx, 1)
            }
        })
    },
    changeIndexEditedContactAddress: (state, val) => {
        state.indexEditedContactAddress = val
    },
    updateCoordinateAddress: (state, obj) => {
        state.contacts.addresses.map((element, i) => {
            if (i == obj.idx) {
                element.lat = obj.lat
                element.lng = obj.lng
            }
        })
    },
    filterAddressRegion: (state, val) => {
        state.contacts.addresses.map(element => {
            element.visible = true
        })
        if (!val) {
            return
        }
        state.contacts.addresses.map(element => {
            element.visible = false
            if (element.region_id_1c == val) {
                element.visible = true
            }
        })
    },
    filterAddressRegionCity: (state, obj) => {
        state.contacts.addresses.map(element => {
            element.visible = true
        })
        if (!obj.region && obj.city) {
            state.contacts.addresses.map(element => {
                element.visible = false
                if (element.city == obj.city) {
                    element.visible = true
                }
            })
        }else if (obj.region && obj.city) {
            state.contacts.addresses.map(element => {
                element.visible = false
                if (element.region_id_1c == obj.region && element.city == obj.city) {
                    element.visible = true
                }
            })
        }  
    },
    setStateLeftMenu: (state, val) => {
        state.stateLeftMenu = val
    },
    pushServiсesServiсes: (state, obj) => {
        if (!state.serviсes.serviсes) {
            state.serviсes.serviсes = []
        }
        state.serviсes.serviсes.push(obj)
    },
    updateServiсesServiсes: (state, obj) => {
        state.serviсes.serviсes.map((element, i) => {
            if (i == obj.idx) {
                element.heading = obj.heading
                element.text = obj.text
                element.img = obj.img
                element.from = obj.from
                element.region = obj.region
            }
        })
    },
    deleteServiсesServiсes: (state, index) => {
        state.serviсes.serviсes.map((element, i) => {
            if (index == i) {
                state.serviсes.serviсes.splice(i, 1)
            }
        })
    },

    pushServiсesPrices: (state, obj) => {
        if (!state.serviсes.prices) {
            state.serviсes.prices = []
        }
        state.serviсes.prices.push(obj)
    },
    updateServiсesPrices: (state, obj) => {
        state.serviсes.prices.map((element, i) => {
            if (i == obj.idx) {
                element.heading = obj.heading
                element.val = obj.val
                element.img = obj.img
                element.butName = obj.butName
            }
        })
    },
    deleteServiсesPrices: (state, index) => {
        state.serviсes.prices.map((element, i) => {
            if (index == i) {
                state.serviсes.prices.splice(i, 1)
            }
        })
    },

    pushServiсesWorks: (state, obj) => {
        if (!state.serviсes.works) {
            state.serviсes.works = []
        }
        state.serviсes.works.push(obj)
    },
    updateServiсesWorks: (state, obj) => {
        state.serviсes.works.map((element, i) => {
            if (i == obj.idx) {
                element.number = obj.number
                element.heading = obj.heading
                element.text = obj.text
            }
        })
    },
    deleteServiсesWorks: (state, index) => {
        state.serviсes.works.map((element, i) => {
            if (index == i) {
                state.serviсes.works.splice(i, 1)
            }
        })
    },

    pushServiсesReasons: (state, obj) => {
        if (!state.serviсes.reasons) {
            state.serviсes.reasons = []
        }
        state.serviсes.reasons.push(obj)
    },
    updateServiсesReasons: (state, obj) => {
        state.serviсes.reasons.map((element, i) => {
            if (i == obj.idx) {
                element.heading = obj.heading
                element.img = obj.img
            }
        })
    },
    deleteServiсesReasons: (state, index) => {
        state.serviсes.reasons.map((element, i) => {
            if (index == i) {
                state.serviсes.reasons.splice(i, 1)
            }
        })
    },
    pushMainMapPoint: (state, obj) => {
        if (!state.main.map_points) {
            state.main.map_points = []
        }
        state.main.map_points.push(obj)
    },
    pushServiсesReviews: (state, obj) => {
        if (!state.serviсes.reviews) {
            state.serviсes.reviews = []
        }
        state.serviсes.reviews.push(obj)
    },
    updateServiсesReviews: (state, obj) => {
        state.serviсes.reviews.map((element, i) => {
            if (i == obj.idx) {
                element.name = obj.name
                element.text = obj.text
            }
        })
    },
    deleteServiсesReviews: (state, index) => {
        state.serviсes.reviews.map((element, i) => {
            if (index == i) {
                state.serviсes.reviews.splice(i, 1)
            }
        })
    },
    pushServiсesObjects: (state, obj) => {
        if (!state.serviсes.objects) {
            state.serviсes.objects = []
        }
        state.serviсes.objects.push(obj)
    },
    updateServiсesObjects: (state, obj) => {
        state.serviсes.objects.map((element, i) => {
            if (i == obj.idx) {
                element.name = obj.name
                element.img = obj.img
                element.link = obj.link
            }
        })
    },
    deleteServiсesObjects: (state, index) => {
        state.serviсes.objects.map((element, i) => {
            if (index == i) {
                state.serviсes.objects.splice(i, 1)
            }
        })
    },
    pushServiсesConsultation: (state, obj) => {
        if (!state.serviсes.consultation) {
            state.serviсes.consultation = obj
        }
        state.serviсes.consultation = obj
    },
    updateServiсesConsultation: (state, obj) => {
        state.serviсes.consultation = obj
    },
    pushLegals: (state, obj) => {
        if (!state.legals) {
            state.legals = {}
        }
        state.legals = obj
    },
    updateLegals: (state, obj) => {
        
        state.legals.fio = obj.heading
        state.legals.img = obj.text
    },

    pushLegalsCatalog: (state, obj) => {
        if (!state.legals.catalogs) {
            state.legals.catalogs = []
        }
        state.legals.catalogs.push(obj)
    },
    updateLegalsCatalog: (state, obj) => {
        state.legals.catalogs.map((element, i) => {
            if (i == obj.idx) {
                element.link = obj.link
            }
        })
    },
    deleteLegalsCatalog: (state, index) => {
        state.legals.catalogs.map((element, i) => {
            if (index == i) {
                state.legals.catalogs.splice(i, 1)
            }
        })
    },
    pushMainCatalogCategory: (state) => {
        if (!state.main.catalogCategories) {
            state.main.catalogCategories = []
        }
        state.main.catalogCategories.unshift({id_1c: null, name: null, img: null, imgType: null, sort: null})
    },
    updateMainCatalogCategory: (state, obj) => {
        state.main.catalogCategories.map((element, i) => {
            if (i == obj.idx) {
                element.id_1c = obj.id_1c
                element.name = obj.name
                element.img = obj.img
                element.imgType = obj.imgType
                element.sort = obj.sort
            }
        })
    },
    deleteMainCatalogCategory: (state, index) => {
        state.main.catalogCategories.map((element, i) => {
            if (index == i) {
                state.main.catalogCategories.splice(i, 1)
            }
        })
    },
    setTechnicalEmails: (state, obj) => {
        console.log(obj, 'chekc tech emails')
        state.claim_emails = []
        if (obj.claim_emails) {
            if (Array.isArray(obj.claim_emails)) {
                if (obj.claim_emails.length != 0) {
                    obj.claim_emails.map((element, i) => {
                        state.claim_emails.push({id: i + 1, value: element})
                    })
                }
            }
        }
        state.review_emails = []
        if (obj.review_emails) {
            if (Array.isArray(obj.review_emails)) {
                if (obj.review_emails.length != 0) {
                    obj.review_emails.map((element, i) => {
                        state.review_emails.push({id: i + 1, value: element})
                    })
                }
            }
        }
        state.project_emails = []
        if (obj.project_emails) {
            if (Array.isArray(obj.project_emails)) {
                if (obj.project_emails.length != 0) {
                    obj.project_emails.map((element, i) => {
                        state.project_emails.push({id: i + 1, value: element})
                    })
                }
            }
        }
        state.common_emails = []
        if (obj.common_emails) {
            if (Array.isArray(obj.common_emails)) {
                if (obj.common_emails.length != 0) {
                    obj.common_emails.map((element, i) => {
                        state.common_emails.push({id: i + 1, value: element})
                    })
                }
            }
        }
        state.support_emails = []
        if (obj.support_emails) {
            if (Array.isArray(obj.support_emails)) {
                if (obj.support_emails.length != 0) {
                    obj.support_emails.map((element, i) => {
                        state.support_emails.push({id: i + 1, value: element})
                    })
                }
            }
        }
        state.reconciliation_act = []
        if (obj.reconciliation_act) {
            if (Array.isArray(obj.reconciliation_act)) {
                if (obj.reconciliation_act.length != 0) {
                    obj.reconciliation_act.map((element, i) => {
                        state.reconciliation_act.push({id: i + 1, value: element})
                    })
                }
            }
        }
        state.hr_department = []
        if (obj.hr_department) {
            if (Array.isArray(obj.hr_department)) {
                if (obj.hr_department.length != 0) {
                    obj.hr_department.map((element, i) => {
                        state.hr_department.push({id: i + 1, value: element})
                    })
                }
            }
        }
    },
    updateTechnicalEmail: (state, obj) => {
        if (!obj.emailName) {
            return
        }
        
        state[obj.emailName].map((element, i) => {
            if (i == obj.index) {
                Vue.set(state[obj.emailName], i , {id: element.id, value: obj.value})
            }
        })
    },
    addTechnicalEmail: (state, emailName) => {
        let newId = 0
        if (state[emailName][state[emailName].length - 1]) {
            newId = state[emailName][state[emailName].length - 1].id
        }
        state[emailName].push({id: newId + 1, value: ""})
    },
    deleteTechnicalEmail: (state, obj) => {
        if (!obj.emailName) {
            return
        }
        state[obj.emailName].map((element, i) => {
            if (element.id == obj.id) {
                state[obj.emailName].splice(i, 1)
            }
        })
    },
    changeCurrentWindowWidth: (state, val) => {
        state.currentWindowWidth = val
    },
    setOpenMobileNavigation: (state, val) => {
        state.isOpenMobileNavigation = val
    },
    setOpenMobileSubNavigation: (state, val) => {
        state.isOpenMobileSubNavigation = val
    },
    deleteSubdivision: (state, idx) => {
        state.main.map_points.map((element, i) => {
            if (i == idx) {
                state.main.map_points.splice(i, 1)
            }
        })
    },
    updateMainSocial: (state, obj) => {
        if (!state.main.social) {
            state.main.social = {
                vk: null,
                facebook: null,
                twitter: null,
            }
        }
        
        if (obj.type == 'vk') {
            state.main.social.vk = obj.value
        }
        if (obj.type == 'facebook') {
            state.main.social.facebook = obj.value
        }
        if (obj.type == 'twitter') {
            state.main.social.twitter = obj.value
        }
    },
    updateCalculators: (state, obj) => {
        state.calculators[obj.calculator][obj.step] = obj.form
    },
    updateRegionAddresses: (state, obj) => {
        let isset = false
        state.regionAdresses.map((element) => {
            if (element.id_1c == obj.id_1c) {
                isset = true
                element.address = obj.address
                element.phone = obj.phone
                element.email = obj.email
                element.region_code = obj.region_code
                element.region_description = obj.region_description
                element.addressesArray = obj.addressesArray
            }
        })

        if (isset == false) {
            state.regionAdresses.push(obj)
        }
    },
    updateCalculatorDrainageGeneralDescription: (state, obj) => {
        if (!state.calculators.calculatorDrainage.generalDescription) {
            state.calculators.calculatorDrainage.generalDescription = {}
        }
        state.calculators.calculatorDrainage.generalDescription.header = obj.header
        state.calculators.calculatorDrainage.generalDescription.text = obj.text
    },
    updateBanner: (state, obj) => {
        state.homeCategoryBanners.map((element, idx) => {
            if (idx == obj.index) {
                element.id_1c = obj.id_1c
                element.name = obj.name
                element.link = obj.link
                element.img = obj.img
                element.root_id = obj.root_id
            }
        })
    },
    deleteBanner: (state, index) => {
        state.homeCategoryBanners.splice(index, 1)
    },
    addBanner: (state, payload) => {
        state.homeCategoryBanners.push(payload)
    },
    checkIssetAddressValue: (state) => {
        console.log('check addresses', state.regionAdresses)
        state.regionAdresses.map((element) => {
            if (element.address) {
                delete element.address
            }
        })
    },
    addAddressArrayValue: (state, id_1c) => {
        var newObj = {
            city: null,
            email: null,
        }
        state.regionAdresses.map((element) => {
            if (element.id_1c == id_1c) {
                element.addressesArray.push(newObj)
            }
        })
    },
    deleteAddressArrayValue: (state, payload) => {
        state.regionAdresses.map((element) => {
            if (element.id_1c == payload.id_1c) {
                element.addressesArray.splice(payload.index, 1)
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
