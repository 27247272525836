<template>
  <div class="client">
    <h5>Лого №{{ index + 1 }}</h5>
    <div>
        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="editElement.link" 
            @change="update"
        >
        <label class="fl__img">
            <input 
                :ref="'fileSingleClient'+ index" 
                type="file" 
                @change="uploadImg"
            >
        </label>
        <p class="label_fileupload">
            Изображение должно быть размером до 300x200 px, формат PNG. Для правильного отображения соблюдайте размер и формат
        </p>
    </div>
    <img
        :src="editElement.link"
    >
    <button 
        class="client-delete-button"
        @click="deleteClient(element.id)"
    >
        Удалить
    </button>
  </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        }
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            updateClient: 'ui_client/updateClient',
            deleteClient: 'ui_client/deleteClient',
        }),
        update() {
            this.updateClient(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['fileSingleClient' + this.index].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.updateSlide({
                        id: this.element.id,
                        name: this.editElement.name,
                        link: this.fileUrl + response.data[0].link
                    })
                })
        },
    }
}
</script>
<style lang="scss">
    .client{
        display: grid;
        margin-top: 30px;
        margin-right: 30px;
        border: 1px solid grey;
        border-radius: 15px;
        padding: 10px;
        max-width: 45%;

        h5{
            margin-bottom: 10px;
        }

        img{
            width: 245px;
            height: 245px;
        }

        .upload-input{
            margin-bottom: 10px;
        }

        .client-delete-button{
            width: 100%;
        }

        .label_fileupload{
            margin-left: 0;
        }
    }
</style>