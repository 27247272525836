<template>
    <div class="modal-iframe color-create-modal">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup" onsubmit="return false;">
                            <label for="">Название</label>
                            <input type="text" v-model="form.name">

                            <label for="">Описание</label>
                            <input type="text" v-model="form.sub_name">

                            <label for="">Hex код цвета</label>
                            <input type="text" v-model="form.code">
                            
                            <label for="">Изображение</label>
                            <img v-if="form.image != null" :src="form.image" alt="" class="coating_main_img">
                            <input 
                                v-model="form.image" 
                                type="text" 
                            >
                            <div class="row">
                                <label class="fl__img">
                                    <input 
                                        ref="colorsImage" 
                                        type="file" 
                                        @change="uploadImg"
                                    >
                                </label>
                            </div>
                            

                            <button class="button button--primary" @click="create">Добавить</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    computed: {
        ...Vuex.mapGetters({
            createForm: 'coatingColors/createForm',
            fileUrl: 'app/fileUrl',
        }),
        form() {
            return Object.assign({}, this.createForm)
        },
    },
    methods: {
        ...Vuex.mapMutations({
            setShowModalCreate: 'coatingColors/setShowModalCreate',
            setCreateForm: 'coatingColors/setCreateForm',
        }),
        ...Vuex.mapActions({
            createColor: 'coatingColors/createColor',
            upload: 'app/upload',
        }),
        close() {
            this.setShowModalCreate(false)
        },
        update() {
            this.setCreateForm(this.form)
        },
        create() {
            this.createColor(this.form)
                .then(() => {
                    this.close()
                })
        },
        uploadImg() {
            let image = this.$refs['colorsImage'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.form.image = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
    }
}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>