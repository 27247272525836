import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    cardsAddresses: [],
    currentCard: {},
    form: {
        address: [],
        email: "",
        excludedIds: [],
        id_1c: "",
        ids: [],
        limit: 10,
        page: 1,
        phone: "",
        query: "",
        region_code: "",
        region_description: ""
    },
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    form: state => state.form,
    cardsAddresses: state => state.cardsAddresses,
    currentCard: state => state.currentCard,
}

const actions = {
    getCardAddresses: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_card_address/search", getters.form)
                .then(response => {
                    commit("setCardAddresses", response.data)
                    resolve(response)
                })
        })
    },
    getCardById1c: ({commit}, id_1c) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_card_address/search", {id_1c: id_1c})
                .then(response => {
                    commit("setCurrentCard", response.data)
                    resolve(response)
                })
        })
    },
    createCardAddress: ({commit}, payload) => {
        payload.sort = Number(payload.sort)
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_card_address/admin", payload)
                .then(response => {
                    commit("setMessage", response.data)
                    resolve(response)
                })
        })
    },
    deleteCardAddress: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_card_address/admin/" + id)
                .then(response => {
                    commit("spliceCategory", id)
                    resolve(response)
                })
        })
    },
    updateCardAddress: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_card_address/admin/" + payload.id, payload)
                .then(response => {
                    commit("setMessage", payload.id)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    }, 
    pushCategory: (state, val) => {
        state.categories.push(val)
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setCardAddresses: (state, val) => {
        state.cardsAddresses = val
    },
    spliceCategory: (state, id) => {
        state.categories.map((element, idx) => {
            if (id == element.id) {
                state.categories.splice(idx, 1)
            }
        })
    },
    setCurrentCard: (state, array) => {
        if (array.length != 0) {
            state.currentCard = array[0]
        }else{
            state.currentCard = {}
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
