<template>
    <div class="block-roof setting-cards">
        <div class="table table_wscroll">
            <div class="category" :class="{'-active' : calc_roof_body.root_1c_id == category.id_1c}" v-for="category in topCategory" :key="category.id" @click="getCalcRoof(category.id_1c)">
                <p>{{ category.name }}</p>
            </div>
        </div>
        <div 
            v-if="calc_roof_body.root_1c_id"
            class="cl-top-option nlk-horizontal-scroll"
        >
            <label 
                class="cl-icon-radio"
                v-for="mainType in mainTypes"
                :key="mainType.id"
                @click="type = mainType.id"
            >
                <input 
                    type="radio" 
                    name="option1" 
                    :checked="(type == mainType.id ? true : false)"
                >
                <div class="cl-icon-radio__btn">{{ mainType.name }}</div>
            </label>
        </div>

        <!-- <div class="block_array" v-if="type == 10 && calc_roof_body.insulation.length != 0">
            <label>Список утеплителей: </label>
            <p v-for="insulation, idx in calc_roof_body.insulation" :key="idx">
                <span>{{ insulation }} / {{ getOneInsulationName(insulation) }}</span>
                <span style="margin-left: 5px; color: red; cursor: pointer" @click="deleteFromList(insulation)">X</span>
            </p>
        </div> -->

        <div class="block_array" v-if="type == 20 && calc_roof_body.metal_tile.length != 0">
            <label>Список металлочерепиц: </label>
            <p v-for="metal_tile, idx in calc_roof_body.metal_tile" :key="idx">
                <span>{{ metal_tile }} / {{ getOneMetallTileName(metal_tile) }}</span>
                <span style="margin-left: 5px; color: red; cursor: pointer" @click="deleteFromList(metal_tile)">X</span>
            </p>
        </div>

        <div class="block_array" v-if="type == 30 && calc_roof_body.corrugated_board.length != 0">
            <label>Список профнастилов: </label>
            <p v-for="corrugated_board, idx in calc_roof_body.corrugated_board" :key="idx">
                <span>{{ corrugated_board }} / {{ getOneBoardName(corrugated_board) }}</span>
                <span style="margin-left: 5px; color: red; cursor: pointer" @click="deleteFromList(corrugated_board)">X</span>
            </p>
        </div>

        <div 
            v-if="type"
            class="search-field"
        >
            <input 
                class="search-input"
                type="text" 
                v-model="query"
                @keyup="getElements()"
            >
            <button 
                class="search-submit" 
                @click="getElements()"
            >
                <span class="search-submit-label">найти</span>
            </button>
        </div>
        <div 
            v-if="query.length > 3"
            class="block-elements mb-10"
        >
            <div class="search-results mt-0"  data-search-results>
                <div 
                    class="search-result"
                    v-for="product in elements"
                    :key="product.id"
                >
                    <div class="search-result-title">
                        <a class="search-result-link">{{ product.name }} // {{ product.id_1c }}</a>
                    </div>
                    <div class="search-result-button">
                        <button 
                            class="button button--primary button--cart"
                            @click="addInList(product.id_1c, product.is_group)"
                        >
                            Выбрать
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div 
            v-if="calc_roof_body.root_1c_id"
            class="additional-block mb-10 mt-5"
        >
            <label>Утеплитель:</label>
        
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <th>
                        Название
                    </th>
                    <th>
                        Поиск
                    </th>
                </tr>
                <one-tr-roof :name="'insulation_5'" :typeSearch="'product'" />
                <one-tr-roof :name="'insulation_10'" :typeSearch="'product'" />
                <one-tr-roof :name="'insulation_15'" :typeSearch="'product'" />
                <one-tr-roof :name="'insulation_20'" :typeSearch="'product'" />
            </table>
        </div>

        <div 
            v-if="calc_roof_body.root_1c_id"
            class="additional-block mb-10 mt-5"
        >
            <label>Гидроизоляция и пароизоляция:</label>
        
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <th>
                        Название
                    </th>
                    <th>
                        Поиск
                    </th>
                </tr>
                <one-tr-roof :name="'hydro'" :typeSearch="'product'" />
                <one-tr-roof :name="'para'" :typeSearch="'product'" />
                <one-tr-roof :name="'parahydro'" :typeSearch="'product'" />
            </table>
        </div>

        <div 
            v-if="calc_roof_body.root_1c_id"
            class="additional-block mb-10 mt-5"
        >
            <label>Софиты для расчёта:</label>
        
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <th>
                        Название
                    </th>
                    <th>
                        Поиск
                    </th>
                </tr>
                <one-tr-roof :name="'soffit_corrug'" :type="'category'" />
                <one-tr-roof :name="'soffit'" :type="'category'" />
                <one-tr-roof :name="'soffit_metall'" :type="'category'" />
                <one-tr-roof :name="'soffit_pvh'" :type="'category'" />
            </table>
        </div>

        <div 
            v-if="calc_roof_body.root_1c_id"
            class="additional-block mb-10 mt-5"
        >
            <label>Доборные элементы:</label>
        
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <th>
                        Название
                    </th>
                    <th>
                        Поиск
                    </th>
                </tr>
                <one-tr-roof :name="'skate_plank'" />
                <one-tr-roof :name="'end_plank'" :typeSearch="'product'" />

                <one-tr-roof :name="'metal_perimeter_plank'" />
                <one-tr-roof :name="'pvh_perimeter_plank'" />

                <one-tr-roof :name="'tapping_roof_wood'" :typeSearch="'group'" :isLengthWidth="true" />
                <one-tr-roof :name="'tapping_roof_metall'"  :typeSearch="'group'" :isLengthWidth="true" />
                <one-tr-roof :name="'tapping_cornice_prof'" :typeSearch="'group'" :isLengthWidth="true" />
                <one-tr-roof :name="'tapping_cornice_soffit'" :typeSearch="'group'" :isLengthWidth="true" />
                <one-tr-roof :name="'tapping_add_wood'" :typeSearch="'group'" :isLengthWidth="true" />
                <one-tr-roof :name="'tapping_add_metal'" :typeSearch="'group'" :isLengthWidth="true" />

                <one-tr-roof :name="'outer_corner'" :typeSearch="'product'" />

                <one-tr-roof :name="'top_seal'" :typeSearch="'product'" />

                <one-tr-roof :name="'fasteners_roof'" :typeSearch="'group'" :isLengthWidth="true" />
                <one-tr-roof :name="'fasteners_add'" :typeSearch="'group'" :isLengthWidth="true" />

                <one-tr-roof :name="'drill_bit'" :typeSearch="'product'" />
                <one-tr-roof :name="'sealant'" :typeSearch="'product'" />

                <one-tr-roof :name="'paint'" :typeSearch="'group'" />

                <one-tr-roof :name="'scissors'" :typeSearch="'product'" />
                <one-tr-roof :name="'stapler'" :typeSearch="'product'" />
                <one-tr-roof :name="'staples'" :typeSearch="'product'" />
                <one-tr-roof :name="'roulette'" :typeSearch="'product'" />
            </table>
        </div>
        <calculator-step :step="'step1'" :calculatorType="'calculatorRoof'" />
        <calculator-step :step="'step1A'" :calculatorType="'calculatorRoof'" />
        <calculator-step :step="'step2'" :calculatorType="'calculatorRoof'" />
        <calculator-step :step="'step3'" :calculatorType="'calculatorRoof'" />
        <calculator-step :step="'step4'" :calculatorType="'calculatorRoof'" />
    </div>
</template>
<script>
import calculatorStep from './calculator-step.vue'
import Vuex from 'vuex'
import oneTrRoof from './one-tr-roof.vue'

export default {
    components: {
        calculatorStep,
        oneTrRoof,
    },
    data() {
        return {
            type: '',
            mainTypes: [
				{id: 20, name: 'Металлочерепица'},
				{id: 30, name: 'Профнастил'},
			],
            query: '',
            elements: [],
            insulationArray: [],
            metal_tileArray: [],
            corrugated_boardArray: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            topCategory: 'catalog/topCategory',
            calc_roof_body: 'calculator/calc_roof_body',
        }),
        insulationArrayId1c() {
            return this.calc_roof_body.insulation
        },
        metal_tileArrayId1c() {
            return this.calc_roof_body.metal_tile
        },
        corrugated_boardArrayId1c() {
            return this.calc_roof_body.corrugated_board
        },
    },
    watch: {
        insulationArrayId1c() {
            this.getInsulationArray()
        },
        metal_tileArrayId1c() {
            this.getMetalTileArray()
        },
        corrugated_boardArrayId1c() {
            this.getBoardArray()
        },
    },
    methods: {
        ...Vuex.mapActions({
            getStoreCalcRoof: 'calculator/getCalcRoof',
            searchNavbar: 'catalog/searchNavbar',
        }),
        ...Vuex.mapMutations({
            changeBodyCalcRoof: 'calculator/changeBodyCalcRoof',
        }),
        getCalcRoof(id_1c) {
            this.getStoreCalcRoof(id_1c)
        },
        getElements() {
            if (this.query == null || this.query.length < 3) {
                return
            }
            this.searchNavbar({root_id: this.calc_roof_body.root_1c_id, query: this.query, is_group: true, is_show_zero_price: false})
                .then((response) => {
                    this.elements = response.data
                })
        },
        getInsulationArray() {
            this.searchNavbar({root_id: this.calc_roof_body.root_1c_id, id_1cs: this.insulationArrayId1c})
                .then((response) => {
                    this.insulationArray = response.data
                })
        },
        getMetalTileArray() {
            this.searchNavbar({root_id: this.calc_roof_body.root_1c_id, id_1cs: this.metal_tileArrayId1c})
                .then((response) => {
                    this.metal_tileArray = response.data
                })
        },
        getBoardArray() {
            this.searchNavbar({root_id: this.calc_roof_body.root_1c_id, id_1cs: this.corrugated_boardArrayId1c})
                .then((response) => {
                    this.corrugated_boardArray = response.data
                })
        },
        addInList(id_1c, is_group) {
            if (is_group == true && this.type == 10) {
                this.searchNavbar({root_id: this.calc_roof_body.root_1c_id, parent_id: id_1c})
                    .then((response) => {
                        if (response.data.length != 0) {
                            this.changeBodyCalcRoof({type: 'insulation', do: 'add', value: response.data[0].id_1c})
                        }
                    })
            }
            if (is_group == false && this.type == 10) {
                this.changeBodyCalcRoof({type: 'insulation', do: 'add', value: id_1c})
            }else if (this.type == 20) {
                this.changeBodyCalcRoof({type: 'metal_tile', do: 'add', value: id_1c})
            }else if (this.type == 30) {
                this.changeBodyCalcRoof({type: 'corrugated_board', do: 'add', value: id_1c})
            }
            this.query = ''
        },
        deleteFromList(id_1c) {
            if (this.type == 10) {
                this.changeBodyCalcRoof({type: 'insulation', do: 'delete', value: id_1c})
            }else if (this.type == 20) {
                this.changeBodyCalcRoof({type: 'metal_tile', do: 'delete', value: id_1c})
            }else if (this.type == 30) {
                this.changeBodyCalcRoof({type: 'corrugated_board', do: 'delete', value: id_1c})
            }
        },
        getOneInsulationName(id_1c) {
            let name = null
            this.insulationArray.map((element) => {
                if (id_1c == element.id_1c) {
                    name = element.name
                }
            })
            return name
        },
        getOneMetallTileName(id_1c) {
            let name = null
            this.metal_tileArray.map((element) => {
                if (id_1c == element.id_1c) {
                    name = element.name
                }
            })
            return name
        },
        getOneBoardName(id_1c) {
            let name = null
            this.corrugated_boardArray.map((element) => {
                if (id_1c == element.id_1c) {
                    name = element.name
                }
            })
            return name
        },
    },
}
</script>
<style scoped src="../../../../css/nlk-style.css"></style>
<style lang="scss">
    .block-roof{
        .mb-10{
            margin-bottom: 10rem;
        }
        .mt-5{
            margin-top: 5rem;
        }
        .mt-0{
            margin-top: 0;
        }
        .cl-top-option{
            margin-bottom: 10rem;

            .cl-icon-radio{
                max-width: 200px;
            
                .cl-icon-radio__btn{
                    height: 60px;
                }
            }
        }

        .block_array{
            margin-top: 20px;

            span{
                font-size: 15px;
            }
        }

        .search-field{
            width: 50%;

            .search-input{
                width: 100%;
                height: 48px;
                padding: 17px 0 17px 23px;
                background: #f5f6fa;
                color: #353b48;
                font-size: 1.2rem;
                margin: 0;
                border: 0;
                border-radius: 4px;
                font-weight: 700;
            }
            .search-submit{
                position: absolute;
                width: 18px;
                height: 18px;
                background: url('~@/img/search-button.svg') 0 0/100% 100% no-repeat;
                border: 0;
                padding: 0;
                margin: 0;
                top: 50%;
                margin-top: -9px;
                right: 26px;
            }
        }

        .block-elements{
            width: 50rem;
            
            .search-results{
                display: block;

                .search-result{
                    padding: 15px;
                    
                    .button{
                        padding: 5px;
                    }
                }
            }
        }

        .nlk-table{
            .search-field{
                width: 100%;
            }
            .left-td{
                width: 40%;
            }

            td{
                border: 1px solid #bdbec1;
            }

            .block-elements{
                position: absolute;
                background: #fff;
                z-index: 100;
                width: 63rem;

                .search-results{
                    margin-top: 0;
                    
                }
            }
        }
    }
</style>