<template>
<div>
    <div class="section section--no-pt">
         <bread-crumbs :pages-array="pagesSupports" />
        <div class="container">
            <div class="row row--faq">
                <div class="col">
                    <h2>Технические решения</h2>
                </div>
                <div class="col">
                    <div class="accordion accordion--faq">
                        <div class="accordion-item"  v-for="(obj, i) in uiSupportsTech" :key="i" >
                            <div class="accordion-header" @click="changeIndex(i)">{{ obj.header }}</div>
                            <div class="" v-if="i == index">
                                <p v-html="obj.link"></p>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
export default {
  mixins: [Functions],
  components:{
      breadCrumbs
  },
  data() {
        return {
            index:null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            supports: 'app/supports',
            pagesSupports: 'dictionary/pagesSupports',
            uiSupports: 'ui_support/supports',
        }),
        uiSupportsTech() {
            let tech_array = []
            this.uiSupports.map((element) => {
                if (element.type == 10) {
                    tech_array.push(element)
                }
            })
            return tech_array
        },
    },
    methods:{
        changeIndex(i){
            this.index = i 
        }
    }

}
</script>