<template>
    <div class="section section--costs section--no-pt">
        <div class="container">
            <div class="inner">
                <div class="heading">
                    <div class="heading-section">
                        <h2>Сколько это стоит?</h2>
                    </div>
                </div>
            </div>
            <ul class="list list--flex layout-costs">
                <li class="list-item"  v-for="obj, i in uiCosts" :key="i">
                    <div class="cost">
                        <div class="cost-media" :style="{ 'background-image': 'url(' + obj.link + ')' }">
                        </div>
                        <div class="cost-body">
                            <div class="cost-title">{{ obj.cost }}+</div>
                            <div class="cost-description">{{ obj.header }}</div>
                            <div class="cost-button"><a class="button button--primary modal-mounting-order-button" @click="openModalApp(obj)">Заказать</a></div>
                        </div>
                    </div>
                </li>
            </ul>
            <application v-if="appMail == true" />
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import application from '../../modals/appMail.vue'
export default {
    components:{
        application
    },
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            appMail:'appmodal/appMail',
            uiCosts: 'ui_cost/costs',
        }),
    },
    methods: {
         ...Vuex.mapMutations({
            setAppMail:'appmodal/setAppMail',
            setHrefApp:'appmodal/setHrefApp',
            setObjectForModal:'appmodal/setObjectForModal',
        }),
        openModalApp(obj){
            this.setObjectForModal(obj)
            this.setHrefApp(window.location.href)
            this.setAppMail(true) 
        },
    },
}
</script>