<template>
    <div style="background: #F5F6FA;">
        <div class="row mt-10 section--form-question"  :style="'background: url(' + uiConsultation[0].link  + ') 0 100% / auto 100% no-repeat;'" >
            <div class="container">
                <div class="helper">
                    <div class="helper-section">
                        <div class="helper-title">{{ uiConsultation[0].name }}</div>
                        <div class="helper-description">менеджер проектов</div>
                    </div>
                </div>
                <div class="form request-form" method="post">
                    <input type="hidden" name="iblock_id" value="30">
                    <input type="hidden" name="mail_type" value="REQUEST_FORM">
                    <input type="hidden" name="mail_template" value="171">
            
                    <h2>Нужна<br> консультация?</h2>
                    <div class="form-fieldset">
                        <div class="form-group form-row">
                            <div class="form-item">
                                <div class="field">
                                    <div class="field-label">Имя *</div>
                                    <div class="field-input">
                                        <input class="required" type="text" placeholder="Введите имя" v-model="name">
                                    </div>
                                </div>
                            </div>
                            <div class="form-item">
                                <div class="field">
                                    <div class="field-label">Телефон *</div>
                                    <div class="field-input">
                                        <the-mask v-model="phone" mask="+7 (###) ###-####" placeholder="Phone number" type="tel"  />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group form-row form-row--align-bottom">
                            <div class="form-item">
                                <div class="field">
                                    <div class="field-label">E-mail</div>
                                    <div class="field-input">
                                        <input  v-model="email"  class="col" placeholder="Введите e-mail"/>
                                    </div>
                                </div>
                            </div>
                            <div class="form-item">
                                <label class="file" data-text="Прикрепить файл на рассчет">
                                    <input type="file" name="import_file" @change="selectedFile($event)"><span>{{nameFile != null ? nameFile :'Прикрепить файл на рассчет'}}</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group form-row">
                            <div class="form-item">
                                <div class="field">
                                    <div class="field-label">Сообщение</div>
                                    <div class="field-input">
                                        <textarea rows="5" v-model="message" name="FIELDS[TEXT]" placeholder="Введите сообщение"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-item">
                                <label class="checkbox-plus">
                                    <input 
                                        v-model="check"
                                        type="checkbox" 
                                        name="privacy"
                                    >
                                    <b></b>
                                    <span>
                                        Согласен (согласна) на обработку моих персональных данных.
                                        <br> Ознакомлен с 
                                        <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/BeEwJiJ_Jc.pdf" target="_blank">Политикой</a>
                                        и
                                        <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/ZhlKe-a2dH.pdf" target="_blank">согласием</a>
                                        на обработку персональных данных
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-fieldset">
                        <div class="form-group">
                            <div class="form-item">
                                <button :class="onlyNumbers() && name != '' && check == true && isEmailValid(email) == true ? 'button button--primary' :'button button'" @click="sendApp()">Заказать звонок</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    components:{
    },
    data() {
        return {
            nameFile: null,
            file: '',
            name: '',
            phone:'',
            email:'',
            check: false,
            sended: false,
            message: '',
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            uiConsultation: 'ui_consultation/consultations',
        }),
        consultat(){
            return this.serviсes.consultation
        },
    },
    methods: {
        ...Vuex.mapActions({
            sentAppBackCall:'appmodal/sentAppBackCall'
        }),
        ...Vuex.mapMutations({
            setShowSendedModal: 'app/setShowSendedModal',
        }),
        close(){
            this.setAppMail(false)
        },
         onlyNumbers() {
            return !/^[\d]{10}$/.test(this.phone) ? false : true
        },
        sendApp(){
            if (this.onlyNumbers() && this.name != '' && this.check == true && this.isEmailValid(this.email) == true) {
				this.sended = true
                let formData = new FormData()
                formData.append('file', this.file)
                formData.append("email", this.email)
                formData.append("name", this.name)
                formData.append("telephone", this.phone)
                formData.append("type", 'backcall')
                formData.append("message", this.message)
                for(var pair of formData.entries()) {
                    console.log(pair[0]+ ', '+ pair[1])
                }
                this.sentAppBackCall(formData)
                    .then(() => {
                        this.setShowSendedModal(true)
                        this.clearForm()
                    })
			}
        },
        clearForm() {
            this.email = null
            this.name = null
            this.phone = null
            this.message = null
        },
        isEmailValid(val) {
            return (val == "")? "" : (this.reg.test(val)) ? true : false
        },

        selectedFile(event) {
            this.nameFile = event.target.files[0].name
            this.file = event.target.files[0]
        },
        uploadTodos() {
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append("email", this.email)
            formData.append("name", this.name)
            formData.append("telephone", this.phone)
            formData.append("type", 'backcall')
            formData.append("message", this.message)
            for(var pair of formData.entries()) {
                console.log(pair[0]+ ', '+ pair[1])
            }
            this.sentAppBackCall(formData)
                .then(() => {
                    this.setShowSendedModal(true)
                })
        }
    },
}
</script>