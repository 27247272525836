<template>
    <div>
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Каталог товаров</h2>
                </div>
              </div>
            </div>
            <ul class="list list--flex layout-cards -col-4">
              <li class="list-item" v-for="obj, i in legals.catalogs" :key="i">
                <a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#roof_systems"></use>
                      </svg>
                    </div>
                    <div class="card-title">{{obj.link}}</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
            </ul>
          </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
    components: {
    },
    // mixins: [Functions],
    data() {
        return {
            // legSrvices:[],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            legals: 'app/legals',
            serviсes: 'app/serviсes',
            fileUrl: 'app/fileUrl',
        }),
    },
}
</script>