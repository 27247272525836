<template>
    <main class="admin-widget admin-index">
        <div class="admin-widget_header">
            <h3>Настройка виджета</h3>
        </div>
        <div class="admin-widget_content">
            <h4 class="area_label">Текущий код</h4>
            <div class="current_code">
                <p>{{ currentWidgetCode }}</p> 
            </div>
            <h4 class="area_label">Новый код</h4>
            <textarea 
                name="" 
                class="code_area" 
                cols="30" 
                rows="10" 
                v-model="newWidgetCode"
                @keyup="updateValue"
                @paste="updateValue"
            >
            </textarea>
            <button class="button button--primary" @click="updateData">Обновить код</button>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            newWidgetCode: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            message: 'auth/message',
            user: 'auth/user',
            widgetCode: 'app/widgetCode',
            currentWidgetCode: 'app/currentWidgetCode',
        }),
        currentCode() {
            return this.widgetCode
        },
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
        }),
        ...Vuex.mapMutations({
            updateWidgetCode: 'app/updateWidgetCode',
        }),
        updateValue() {
            this.updateWidgetCode(this.newWidgetCode)
        },
        updateData() {


            this.updateJson()
                .then(() => {
                    
                })
        },
    }
}
</script>
<style lang="scss">
    .admin-widget_content{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        
        .code_area{
            width: 80%;
        }
        
        .button{
            width: 20%;
            margin-top: 20px;
        }

        .current_code{
            padding: 20px;
            border-radius: 15px;
            background-color: #dfd7d7;
        }
    }
    .area_label{
        margin-top: 25px;
        margin-bottom: 15px;
    }
</style>