<template>
    <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
					<div class="state-item">
						<h3>Добавить пользователя</h3>
						<div class="form" name="callback-form-popup">
							<h4 class="message-block" style="display: none; margin-top: -5px;"></h4>
							<div class="form-fieldset">
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Почта</div>
											<div class="field-input">
												<input type="text" v-model="form.Email" placeholder="Введите почту">
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">ФИО</div>
											<div class="field-input">
												<input type="text" v-model="fio" placeholder="Введите ФИО" @keyup="updateFIO">
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Телефон</div>
											<div class="field-input">
												<the-mask v-model="form.phone" mask="+7 (###) ###-####" placeholder="Phone number" type="tel" />
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Адрес</div>
											<div class="field-input">
												<input type="text" v-model="form.adress" placeholder="Введите адрес">
											</div>
										</div>
									</div>
								</div>
                                <div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">КПП</div>
											<div class="field-input">
												<input type="text" v-model="form.kpp" placeholder="Введите КПП">
											</div>
										</div>
									</div>
								</div>
                                <div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">ИНН</div>
											<div class="field-input">
												<input type="text" v-model="form.inn" placeholder="Введите ИНН">
											</div>
										</div>
									</div>
								</div>
                                <div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Организация</div>
											<div class="field-input">
												<input type="text" v-model="form.organization_name" placeholder="Введите название организации">
											</div>
										</div>
									</div>
								</div>
							</div>
							<div 
								v-if="message"
								class="message-error-block"
							>
								Пользователь с такой почтой уже существует
							</div>
							<div class="form-fieldset form-fieldset--medium">
								<div class="form-group">
									<div class="form-item">
										<!-- <button :class="onlyNumbers() && name != '' && check == true ? 'button button--primary' :'button button'" @click="sendApp()">Отправить заявку</button> -->
										<button class="button button--primary" @click="addUser">Добавить</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            fio: '',
            form: {
                midname: '',
                name: '',
                surname: '',
                phone: '',
                Email: '',
                kpp: '',
                inn: '',
                organization_name: '',
                adress: '',
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            isShowAddUserModal: 'app/isShowAddUserModal',
			message: 'users/message',
			messageStatus: 'users/messageStatus',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setShowAddUserModal: 'app/setShowAddUserModal',
        }),
        ...Vuex.mapActions({
            createUser: 'users/createUser',
			getUsers: 'users/getUsers',
        }),
        close() {
            this.setShowAddUserModal(false)
        },
        addUser() {
            this.createUser(this.form)
				.then(() => {
					this.close()
					this.getUsers()
				})
        },
        updateFIO() {
            if (this.fio.length != 0) {
                let str = this.fio.split(' ')
                if (str[0]) {
                    this.form.surname = str[0]
                }else{
                    this.form.surname = null
                }
                if (str[1]) {
                    this.form.name = str[1]
                }else{
                    this.form.name = null
                }
                if (str[2]) {
                    this.form.midname = str[2]
                }else{
                    this.form.midname = null
                }
            }else{
                this.form.name = null
                this.form.midname = null
                this.form.surname = null
            }
        },
    },
}
</script>
<style lang="scss">
	.message-error-block{
		background-color: lightcoral;
		border-radius: 10px;
		padding: 10px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
</style>