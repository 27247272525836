<template>
  <section class="hero hero-nd">
    <!-- <div class="container container-nd" v-if="firstPromo">
      <div class="inner">
        <div class="message hero-message" v-if="!isEmptyObject(firstPromo)">
          <h4 class="message-title">{{ firstPromo.name }}</h4>
          <p class="message-description" v-html="firstPromo.subheading"></p>
          <a class="message-button button button--primary" @click="push('offers/' + firstPromo.link)">Узнать подробнее</a>
        </div>
      </div>
    </div> -->
    <div class="swiper-container slider slider--hero">
      <div class="swiper-wrapper">
        <div 
          class="swiper-slide" 
          :style="'background-image: url(' + slide.link + ')'"
          v-for="(slide, idx) in currentRootSlides"
          :key="idx"
          v-show="idx == currentSlide"
        >
          <div class="container">
            <!-- <div class="inner">
              <div class="slider-title">Качественные материалы<br> для кровли, ограждений<br> и&nbsp;водосточных систем</div>
            </div> -->
          </div>
        </div>
      </div>
      
      <div class="swiper-button-prev" @click="prevSlide">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="8" height="8" viewBox="0 0 8 8">
          <path d="M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z"/>
        </svg>
        <span class="sr-only">Предыдущий</span>
      </div>
      <div class="swiper-button-next" @click="nextSlide">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="8" height="8" viewBox="0 0 8 8">
          <path d="M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z"/>
        </svg>
        <span class="sr-only">Следующий</span>
      </div>
    </div>
  </section>
</template>

<script>
import Functions from "@/utils/functions"
import Vuex from 'vuex'

export default {
  mixins: [Functions],
  data() {
    return {
      currentSlide: 0,
    }
  },
  computed: {
    ...Vuex.mapGetters({
      main: 'app/main',
      offers: 'app/offers',
      uiSlides: 'ui_slide/slides',
      uiPromotions: 'ui_promotion/promotions',
      region:'auth/city',
    }),
    currentPromotions() {
      let array = []
      this.uiPromotions.map((element) => {
        console.log('element promo: ' + element)
        if (element.root_id == this.region) {
          array.push(element)
        }
      })
      return array
    },
    firstPromo() {
      return Object.assign({}, this.currentPromotions[0])
    },
    slides() {
      return this.uiSlides
    },
    currentRootSlides() {
      let array = []

      this.uiSlides.map((element) => {
          if (element.root_id == this.region) {
            array.push(element)
          }
      })

      return array
    },
  },
  watch: {
    slides() {
      this.setAutoSlider()
    },
    region() {
      this.currentSlide = 0
    }
  },
  mounted() {
    if (this.slides) {
      this.setAutoSlider()
    }
  },
  methods: {
    nextSlide() {
      if (this.currentSlide == this.currentRootSlides.length - 1) {
        this.currentSlide = 0
        return
      }
      this.currentSlide++
    },
    prevSlide() {
      if (this.currentSlide == 0) {
        this.currentSlide = this.currentRootSlides.length - 1
        return
      }
      this.currentSlide--
    },
    setAutoSlider() {
      if (this.currentRootSlides.length == 0) {
        return
      }
      window.setInterval(() => {
        this.nextSlide()
      }, 10000)
    }
  },
}
</script>
<style scoped src="../../../../css/castom.css"></style>