import Http from "../utils/http"
import Config from "../config"

const state = {
    message: null,
    isAuthenticated: null,
    user: {},
    roles: [],
    city: null,
    cityName: '',
    from: 10,
    regionCode: null,
    isShowMessageError: false,
    sendedEmail: null,
    userCompanyId: null,
    localStorageCompanyId: JSON.parse(localStorage.getItem("mayak_company_id")),
    formSms: {
        number_sms: null,
        phone: null
    },
    formVerify: {
        account_type: 0,
        adress: "",
        email: "",
        inn: "",
        is_check: true,
        kpp: "",
        midname: "",
        name: "",
        order_id: 0,
        organization_name: "",
        phone: "",
        surname: ""
    },
    isVerifyPhoneNumber: false,
    loginPhone: null,
    loginEmail: null,
}

const getters = {
    message: state => state.message,
    isAuthenticated: state => state.isAuthenticated,
    user: state => state.user,
    roles: state => state.roles,
    city: state => state.city,
    from: state => state.from,
    regionCode: state => state.regionCode,
    isShowMessageError: state => state.isShowMessageError,
    sendedEmail: state => state.sendedEmail,
    userCompanyId: state => state.userCompanyId,
    localStorageCompanyId: state => state.localStorageCompanyId,
    formSms: state => state.formSms,
    formVerify: state => state.formVerify,
    isVerifyPhoneNumber: state => state.isVerifyPhoneNumber,
    loginPhone: state => state.loginPhone,
    loginEmail: state => state.loginEmail,
    cityName: state => state.cityName,
}

const actions = {
    auth: ({commit, getters}, form) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/auth", form, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", response.message)
                    commit("setIsAuthenticated", true)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                    commit("setMessage", err.response.data.error)
                    commit("setIsAuthenticated", false)
                    // commit("openErrorForm")
                })
        })
    },
    check: ({commit, getters}) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        return new Promise((resolve, reject) => {
            Http.get(Config.apiUrl + "/v1/check", {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setIsAuthenticated", true)
                    commit("setUser", response.data)
                    commit("setRoles", response.data.roles)
                    resolve(response)
                })
                .catch(err => {
                    localStorage.setItem('mayak_company_id', null) 
                    reject(err)
                    if (err.message) {
                        commit("setIsAuthenticated", false)
                    }
                    commit("setUser", {})
                })
        })
    },
    logout: ({commit, getters}) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        localStorage.setItem('mayak_company_id', null) 
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/logout", {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setIsAuthenticated", false)
                    resolve(resp)
                })
        })
    },
    registry: ({commit, getters}, form) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        var payload = Object.assign({}, form)
        payload.phone = '+7' + form.phone
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/auth/registry", payload, {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setIsAuthenticated", false)
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    changePassword: ({commit, getters}, payload) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/changepassword", payload, {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setMessage", 'Смена пароля прошла успешно')
                    resolve(resp)
                })
        })
    },
    recovery: ({commit, getters}, payload) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/auth/requestpassword", payload, {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setMessage", 'Проверьте почту')
                    resolve(resp)
                })
        })
    },
    activate: ({commit, getters}, payload) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/auth/activate", payload, {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setMessage", 'Пользователь успешно активирован')
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    checksms: ({commit, getters}) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/auth/checksms", getters.formSms, {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setMessage", 'Пользователь успешно активирован')
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    sms: ({commit, getters}) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/auth/sms", getters.formSms, {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setMessage", 'Пользователь успешно активирован')
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    verify: ({commit, getters}, payload) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/auth/verify", payload, {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setMessage", 'Пользователь успешно активирован')
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    sendsms: ({commit, getters}, payload) => {
        let id = (getters.userCompanyId ? getters.userCompanyId : getters.localStorageCompanyId)
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/auth/sendsms", payload, {headers: {'X-Company-ID': id}})
                .then(resp => {
                    commit("setMessage", '')
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}


const mutations = {
    setCityName: (state, val) => {
        state.cityName = val
    },
    setSendedEmail: (state, val) => {
        state.sendedEmail = val
    },
    setMessage: (state, val) => {
        state.message = val
    },
    setIsAuthenticated: (state, val) => {
        state.isAuthenticated = val
    },
    setUser: (state, val) => {
        state.user = val
    },
    setRoles: (state, val) => {
        state.roles = val
    },
    setCity: (state, city) => {
        state.city = city
    },
    setRegionCode: (state, object) => {
        object.array.map((element) => {
            if (element.id_1c == object.region) {
                state.regionCode = element.currency
            }
        })
    },
    setFrom: (state, from) => {
        state.from = from
    },
    closeErrorForm: (state) => {
        state.isShowMessageError = false
    },
    openErrorForm: (state) => {
        state.isShowMessageError = true
    },
    setUserCompanyId: (state, val) => {
        state.userCompanyId = val
    },
    setFormSms: (state, val) => {
        state.formSms = val
    },
    setFormVerify: (state, val) => {
        state.formVerify = val
    },
    setVerifyPhoneNumber: (state, val) => {
        state.isVerifyPhoneNumber = val
    },
    setLoginEmail: (state, val) => {
        state.loginEmail = val
    },
    setLoginPhone: (state, val) => {
        state.loginPhone = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
