<template>
  <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup">
                            <p v-if="sendedMessage == null">
                                Обращение зарегистрировано!
                                Ваше обращение успешно зарегистрировано в отделе продаж
                            </p>
                            <p v-else>
                                {{ sendedMessage }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    computed: {
        ...Vuex.mapGetters({
            sendedMessage: 'app/sendedMessage',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setShowSendedModal: 'app/setShowSendedModal',
            setSendedMessage: 'app/setSendedMessage',
        }),
        close() {
            this.setSendedMessage(null)
            this.setShowSendedModal(false)
        }
    }
}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>