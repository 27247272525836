import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    permissions: [],
}

const getters = {
    message: state => state.message,
    permissions: state => state.permissions,
}

const actions = {
    getPermissions: ({commit}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/permission", {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setPermissions", response.data)
                    resolve(response)
                })
        })
    },
    updatePermissions: ({commit}, object) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.put(Config.apiUrl + "/v1/permission", object, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success')
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setPermissions: (state, val) => {
        state.permissions = val
    }, 
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
