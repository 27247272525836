<template>
  <div 
    class="singleAddress"
    :class="(index == indexEditedContactAddress ? 'singleAddress_edited' : '')"
  >
    <div class="checkboxes">
      <div>
        <label class="checkbox-plus">
          <input 
            v-model="typesAddress.isMainOffice"
            type="checkbox" 
            name="privacy"
            @change="update"
          >
          <b />
          <span style="margin-top: 0;">Головной офис</span>
        </label>
      </div>
      <div>
        <label class="checkbox-plus">
          <input
            v-model="typesAddress.isOffice"
            type="checkbox" 
            name="privacy"
            @change="update"
          >
          <b />
          <span style="margin-top: 0;">Офис продаж</span>
        </label>
      </div>
      <div>
        <label class="checkbox-plus">
          <input 
            v-model="typesAddress.isProduction"
            type="checkbox" 
            name="privacy"
            @change="update"
          >
          <b />
          <span>Производство</span>
        </label>
      </div>
      <div>
        <label class="checkbox-plus">
          <input 
            v-model="typesAddress.isWarehouse"
            type="checkbox" 
            name="privacy"
            @change="update"
          >
          <b />
          <span>Склад</span>
        </label>
      </div>
    </div>
    <div
      class="region index__admin" 
    >
      <select 
        v-model="editElement.region" 
        @change="update"
      >
        <option value="">Выберите регион</option>
        <option 
          v-for="(category, idx) in topLevelCategory"
          :key="idx"
          :value="category.id_1c"
        >
          {{ category.name }}
        </option>
      </select>
    </div>
    <div
      class="index__admin" 
    >
      <input 
        v-model="editElement.sort"
        type="text" 
        placeholder="Сортировка"
        @change="update"
      >
      <input 
        v-model="editElement.link"
        type="text" 
        placeholder="Ссылка на карту"
        @change="update"
      >
      <input 
        v-model="editElement.city"
        type="text"
        placeholder="Город"
        @change="update"
      >
      <input 
        v-model="editElement.street"
        type="text"
        placeholder="Улица"
        @change="update"
      >
    </div>
    <div 
      class="index__admin"
    >
      <input 
        v-model="editElement.lat"
        type="text" 
        placeholder="Координаты: Широта"
        :class="requireElementsValueError.lat ? 'error_input' : ''"
        @change="update"
      >
      <p class="error_label" v-if="requireElementsValueError.lat && !requireElementsValueError.lng">Пожалуйста заполните поле "широта"</p>
      <input 
        v-model="editElement.lng"
        type="text" 
        placeholder="Координаты: Долгота"
        :class="requireElementsValueError.lng ? 'error_input' : ''"
        @change="update"
      >
      <p class="error_label" v-if="requireElementsValueError.lng && !requireElementsValueError.lat">Пожалуйста заполните поле "широта"</p>
      <p class="error_label" v-if="requireElementsValueError.lat && requireElementsValueError.lng">Пожалуйста заполните координаты</p>
      <input 
        v-model="editElement.working_hours"
        type="text"
        placeholder="Режим работы"
        @change="update"
      >
      <input 
        v-model="editElement.email"
        type="text"
        placeholder="Почта"
        @change="update"
      >
      <input 
        v-model="editElement.phone"
        type="text"
        placeholder="Телефон"
        @change="update"
      >
    </div>
    <div class="buttons">
      <button 
        class="delete_button"
        @click="deleteAddress()"
      >
        Удалить
      </button>
    </div>
  </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
    },
    data() {
      return {
        typesAddress: {
          isMainOffice: false,
          isOffice: false,
          isProduction: false,
          isWarehouse: false,
        },
        requireElementsValueError: {
          lat: false,
          lng: false,
        }
      }
    },
    computed: {
        ...Vuex.mapGetters({
            indexEditedContactAddress: 'app/indexEditedContactAddress',
            topLevelCategory: 'dictionary/topLevelCategory',
        }),
        editElement() {
            return Object.assign({}, this.element)
        }
    },
    created() {
        this.setTypesAddress()
    },
    methods: {
        ...Vuex.mapMutations({
            updateContactsAddress: 'app/updateContactsAddress',
            changeIndexEditedContactAddress: 'app/changeIndexEditedContactAddress',
        }),
        ...Vuex.mapActions({
            updateContact: 'ui_contacts/updateContact',
            deleteContact: 'ui_contacts/deleteContact',
        }),
        validation() {
          var value = true
          if (!this.editElement.lat) {
            this.requireElementsValueError.lat = true
            value = false
          }else{
            this.requireElementsValueError.lat = false
          }
          if (!this.editElement.lng) {
            this.requireElementsValueError.lng = true
            value = false
          }else{
            this.requireElementsValueError.lng = false
          }
          return value
        },
        update() {
            if (!this.validation()) {
              return
            }
            if (!this.editElement.is_type) {
              this.editElement.is_type = ['office']
            }
            if (this.editElement.visible == null || this.editElement.visible == '') {
              this.editElement.visible = true
            }
            var typesArray = []
            if (this.typesAddress.isMainOffice) {
              typesArray.push('isMainOffice')
            }
            if (this.typesAddress.isOffice) {
              typesArray.push('isOffice')
            }
            if (this.typesAddress.isProduction) {
              typesArray.push('isProduction')
            }
            if (this.typesAddress.isWarehouse) {
              typesArray.push('isWarehouse')
            }
            if (this.editElement.sort) {
              this.editElement.sort = Number(this.editElement.sort)
            }else{
              this.editElement.sort = 0
            }
            this.editElement.is_type = typesArray
            this.updateContact(this.editElement)
        },
        deleteAddress() {
          this.deleteContact(this.editElement.id)
        },
        setTypesAddress() {
          this.element.is_type.map((element) => {
            if (element == 'isMainOffice') {
              this.typesAddress.isMainOffice = true
            }
            if (element == 'isOffice') {
              this.typesAddress.isOffice = true
            }
            if (element == 'isProduction') {
              this.typesAddress.isProduction = true
            }
            if (element == 'isWarehouse') {
              this.typesAddress.isWarehouse = true
            }
          })
        }
    }
}
</script>