
<template>
    <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <h3>Вы действительно хотите очистить корзину?</h3>
                        <div class="form" name="callback-form-popup">
                            <div class="form-fieldset form-fieldset--medium">
                                <div class="form-group">
                                    <div class="form-item">
                                        <button class="button button--primary" @click="confirm">Да</button>
                                        <button class="button button" @click="close">Отмена</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Functions from '@/utils/functions'
import Vuex from 'vuex'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
export default {
	props:['route'],
    mixins: [Functions],
    data () {
      return {
          name: '',
          phone:'',
          email:'',
          check: false,
          sended: false,
      }
    },
    computed: {
        ...Vuex.mapGetters({
			order: 'basket/order',
		}),
	},
     methods: {
         ...Vuex.mapMutations({
            setShowCleanBasketModal: 'app/setShowCleanBasketModal',
        }),
		...Vuex.mapActions({
			deletePosition: 'basket/deletePosition',
        }),
        close(){
            this.setShowCleanBasketModal(false)
        },
        confirm() {
            if (this.order.positions.length == 0) {
                return
            }
            this.order.positions.map((element) => {
                this.deletePosition(element.id)
            })
            this.close()
        },
    },
  }
</script>