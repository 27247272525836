<template>
	<section class="nlk-content">
		<h1 class="nlk-content__title">ОТЧЕТ ПО РЕАЛИЗАЦИИ</h1>
		<form 
			class="nlk-filter"
			onsumbit="return false"
		>
			<div class="field field_data">
				<div class="field-label">Начальная дата</div>
				<div class="field-input">
					<input 
						type="date" 
						placeholder="Дата" 
						v-model="startDate" 
						@change="search"
					>
				</div>
			</div>
			<div class="field field_data">
				<div class="field-label">Конечная дата</div>
				<div class="field-input">
					<input 
						type="date" 
						placeholder="Дата" 
						v-model="endDate" 
						@change="search"
					>
				</div>
			</div>
		</form>

		<div class="nlk-horizontal-scroll nlk-vertical-scroll">
			<table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_pl-35 table_custom_border">
				<tr>
					<th>ТОВАРНОЕ НАПРАВЛЕНИЕ</th>
					<th>НОМЕНКЛАТУРА БЕЗ ХАРАКТЕРИСТИК</th>
					<th>ОБЪЕМ ЗАКУПОК</th>
					<th>СУММА ЗАКУПОК</th>
				</tr>

				<tr 
					v-for="(implement, idx) in implementation"
					:key="idx"
					v-show="implement.commodity_direction != 'not'"
				>
					<td><b>{{ implement.commodity_direction }}</b></td>
					<td>
						<table class="nlk-table nlk-table_w-100 table_in_table">
							<tr 
								v-for="(nomenclature, idx) in implement.nomunclatures"
								:key="idx"	
							>
								<td :class="getClassForTd(idx, 'center', implement.nomunclatures.length)">
									{{ getTextNomenclatureWithAmount(nomenclature.nomunclature, nomenclature.amount) }}	
								</td>
							</tr>
						</table>
					</td>
					<td class="text-center"><b>{{ getTrueBigSum(implement.volume_of_purchases) }}</b></td>
					<td class="text-center"><b>{{ getTrueBigSum(implement.amount_of_purchases) }}</b></td>
				</tr>
			</table>
		</div>
	</section>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
	mixins: [Functions],
	data() {
		return {
			startDate: null,
			endDate: null,
		}
	},
	computed: {
		...Vuex.mapGetters({
			implementation: 'reports/implementation',
			formImplementation: 'reports/formImplementation',
		}),
		searchForm() {
			return Object.assign({}, this.formImplementation)
		},
	},
	created() {
		this.search()
	},
	methods: {
		...Vuex.mapActions({
			getImplementation: 'reports/getImplementation',
		}),
		...Vuex.mapMutations({
			setImplementationForm: 'reports/setImplementationForm',
		}),
		getClassForTd(idx, type, length) {
            let className = null
            if (idx == 0 && idx != length - 1) {
                if (type == 'left') {
                    className = 'border_wo_left-top border_wo_right'
                }else if (type == 'right') {
                    className = 'border_wo_right-top border_wo_left'
                }else{
                    className = 'border_wo_top border_wo_left-right'
                }
            }else if (idx == length - 1 && idx != 0) {
                if (type == 'left') {
                    className = 'border_wo_left-bottom border_wo_right'
                }else if (type == 'right') {
                    className = 'border_wo_right-bottom border_wo_left'
                }else{
                    className = 'border_wo_bottom border_wo_left-right'
                }
            }else if (idx == 0 && idx == length - 1) {
                if (type == 'left') {
                    className = 'border_wo_left-top border_wo_right border_wo_bottom'
                }else if (type == 'right') {
                    className = 'border_wo_right-top border_wo_left border_wo_bottom'
                }else{
                    className = 'border_wo_top border_wo_left-right border_wo_bottom'
                }
            }else{
                if (type == 'left') {
                    className = 'border_wo_left border_wo_right'
                }else if (type == 'right') {
                    className = 'border_wo_right border_wo_left'
                }else{
                    className = 'border_wo_left-right'
                }
            }
            return className
        },
		search() {
			if (this.startDate) {
				let today = new Date(this.startDate)
				this.searchForm.date_start = today.toISOString()
			}else{
				this.searchForm.date_start = null
			}

			if (this.endDate) {
				let today = new Date(this.endDate)
				this.searchForm.date_end = today.toISOString()
			}else{
				this.searchForm.date_end = null
			}

			this.setImplementationForm(this.searchForm)
			this.getImplementation()
		},
		getTextNomenclatureWithAmount(nomenclature, amount) {
			let text = ''

			if (nomenclature) {
				if (amount) {
					text = nomenclature + ' - ' + amount + ' шт'
				}else{
					text = nomenclature
				}
			}else{
				if (amount) {
					text = amount + ' шт'
				}
			}

			return text
		},
	},
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>
<style scoped>
	.table_in_table{
		border-left: hidden;
		border-bottom: hidden;
		border-right: hidden;
	}
</style>