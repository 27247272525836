<template>
  <div class="layout-section">
    <div class="section section--no-pt section--no-pb">
      <div class="inner">
        <form
          onsubmit="return false" 
          class="form form--cabinet" 
          action="#" 
          method="post"
        >
          <div class="form-fieldset">
            <div class="form-group">
              <div class="form-item">
                <div class="user">
                  <div class="user-image"><img src="@/assets/img/user.svg" alt=""/></div>
                  <div class="user-section">
                    <div class="user-title">Сергей Андреенко</div>
                    <div class="user-info">Самара</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-fieldset" v-if="!isUpdate">
            <div class="form-group form-row">
              <div class="form-item">
                <div class="data">
                  <div class="data-label">ФИО</div>
                  <div class="data-value">Сергей Андреенко</div>
                </div>
              </div>
              <div class="form-item">
                <div class="data">
                  <div class="data-label">E-mail</div>
                  <div class="data-value">sandreenko@mail.ru</div>
                </div>
              </div>
              <div class="form-item">
                <div class="data">
                  <div class="data-label">Телефон</div>
                  <div class="data-value">+ 7 (846) - 000 - 11 - 22</div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-fieldset" v-else>
            <div class="form-group form-row">
              <div class="form-item">
                <div class="field">
                  <div class="field-label">ФИО</div>
                  <div class="field-input">
                    <input type="text" placeholder="" value="Сергей Андреенко">
                  </div>
                </div>
                <!-- field-->
              </div>
              <div class="form-item">
                <div class="field">
                  <div class="field-label">Адрес компании</div>
                  <div class="field-input">
                    <input type="text" placeholder="" value="г. Самара, пр-т Кирова, 8">
                  </div>
                </div>
                <!-- field-->
              </div>
              <div class="form-item">
                <div class="field">
                  <div class="field-label">ИНН</div>
                  <div class="field-input">
                    <input type="text" placeholder="" value="000000000000000000000">
                  </div>
                </div>
                <!-- field-->
              </div>
            </div>
          </div>

          <div class="form-fieldset" :class="{'form-fieldset--medium' : isUpdate}">
            <div class="form-group">
              <div class="form-item">
                <ul class="list list--flex layout-buttons">
                  <li class="list-item">
                    <a class="button button--primary" v-if="!isUpdate" @click="isUpdate = true">Редактировать профиль</a>
                    <button class="button button--primary" v-else @click="isUpdate = false">Сохранить изменения</button>
                  </li>
                  <li class="list-item">
                    <a class="button button--default" v-if="!isUpdate">Сменить пароль</a>
                    <a class="button button--default" v-else>Отменить</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isUpdate: false,
    }
  }
}
</script>