<template>
  <div class="index__admin" >
      <span>Каталог № {{index+1}}</span>
                    <span class="index__del" @click="deleteP(index)" >Удалить Х</span>
                    <input type="text" v-model="editElement.link" @keyup="update" placeholder="Ссылка">
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
     components: {
    },
    props:['element', 'index'],
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            updateLegalsCatalog: 'app/updateLegalsCatalog',
            deleteLegalsCatalog: 'app/deleteLegalsCatalog',
        }),
         update() {
            this.updateLegalsCatalog({idx: this.index, 
                link: this.editElement.link, 
                })
        },
        deleteP(){
             this.deleteLegalsCatalog(this.index) 
        },
    },
}
</script>