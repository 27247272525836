<template>
    <div class="admin-index-goods">
        <h4>Ссылки на социальные сети</h4>
        <button
            v-if="!isShowForm"
            @click="isShowForm = true"
        >
            Добавить ссылку
        </button>

        <div 
            v-if="isShowForm"
            class="network"
        >
            <label>Название</label>
            <input 
                type="text" 
                v-model="newNetwork.name"
            >
            <label>Ссылка</label>
            <input 
                type="text" 
                v-model="newNetwork.link"
            >
            <button
                @click="createNetwork"
            >
                Сохранить
            </button>
        </div>
        <div class="networks">
            <div 
                class="network"
                v-for="network in uiNetworks"
                :key="network.id"
            >
                <span 
                    class="index__del" 
                    @click="deleteUiNetwork(network.id)"
                >
                    Удалить X
                </span>
                <label>{{ network.name }}</label>
                <input 
                    type="text" 
                    v-model="network.link"
                >
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            newNetwork: {
                name: null,
                link: null,
                root_id: null,
            },
            isShowForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiNetworks: 'ui_network/networks',
            fileUrl: 'app/fileUrl',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            createUiNetwork: 'ui_network/createNetwork',
            deleteUiNetwork: 'ui_network/deleteNetwork',
        }),
        createNetwork() {
            if (this.newNetwork.link == null || this.newNetwork.name == null) {
                return
            }
            this.createUiNetwork(this.newNetwork)
                .then(() => {
                    this.isShowForm = false
                    this.newNetwork.link = null
                    this.newNetwork.name = null
                    this.newNetwork.root_id = null
                })
        },
    },
}
</script>