import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    form: {
        code: "",
        excludedIds: [],
        ids: [],
        image: "",
        limit: 60,
        name: "",
        page: 1,
        query: "",
        sub_name: ""
    },
    createForm: {
        "code": null,
        "image": null,
        "name": null,
        "sub_name": null,
    },
    colors: [],
    isShowModalCreate: false
}

const getters = {
    message: state => state.message,
    form: state => state.form,
    createForm: state => state.createForm,
    colors: state => state.colors,
    isShowModalCreate: state => state.isShowModalCreate,
}

const actions = {
    search: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_colors/search", getters.form)
                .then(response => {
                    commit("setColors", response.data)
                    resolve(response)
                })
        })
    },
    createColor: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/ui_colors/admin", payload)
                .then(response => {
                    commit("addColor", response.data)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                    if (err.message) {
                        commit("setMessage", err.message)
                    }
                })
        })
    },
    deleteColor: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_colors/admin/" + id)
                .then(response => {
                    commit("spliceColor", id)
                    resolve(response)
                })
        })
    },
    updateColor: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_colors/admin/" + payload.id, payload.object)
                .then(response => {
                    commit("patchColorObject", {id: payload.id, object: response.data})
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setColors: (state, val) => {
        val.sort((a, b) => Number(a.id) - Number(b.id))
        state.colors = val
    },
    spliceColor: (state, id) => {
        state.colors.map((element, i) => {
            if (id == element.id) {
                state.colors.splice(i, 1)
            }
        })
    },
    patchColorObject: (state, payload) => {
        state.colors.map((element) => {
            if (payload.id == element.id) {
                element.code = payload.object.code
                element.image = payload.object.image
                element.name = payload.object.name
                element.sub_name = payload.object.sub_name
            }
        })
    },
    setShowModalCreate: (state, val) => {
        state.isShowModalCreate = val
    },
    addColor: (state, val) => {
        state.colors.push(val)
    },
    setCreateForm: (state, val) => {
        state.createForm = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
