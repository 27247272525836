<template>
  <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup">
                            <p>Добавлено в корзину</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>