<template>
    <div class="section section--no-pt">
        <div class="container">
            <div class="inner">
                <div class="heading">
                    <div class="heading-section">
                        <h2>Почему мы?</h2>
                    </div>
                </div>
                <ul class="list list--flex layout-advantages -col-4">
                    <li class="list-item" v-for="obj, i in uiReasons" :key="i">
                        <div class="advantage">
                            <div class="advantage-icon"><img :src="obj.link" alt=""></div>
                            <div class="advantage-title">{{ obj.name }}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
    components:{
    },
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            app:'appmodal/app',
            uiReasons: 'ui_reason/reasons',
        }),
    },
    methods: {
         ...Vuex.mapMutations({
            setApp:'appmodal/setApp'
        }),
        openModalApp(){
            this.setApp(true) 
        },
    },
}
</script>