<template>
  <main class="main">
    <bread-crumbs :pages-array="pagesLK" />
    <div class="section section--no-pt">
      <div class="container">
        <div class="inner">
          <div class="heading">
            <div class="heading-section">
              <h2>Личные данные</h2>
            </div>
          </div>
        </div>
        <div class="layout layout--cabinet">
          <div class="layout-aside">
            <div class="navigation-cabinet-menu-toggle">
              <div class="navigation-cabinet-menu-toggle-section">
                <span>Меню профиля</span>
                <span>Свернуть меню</span>
              </div>
              <button class="navigation-cabinet-menu-toggle-button" />
            </div>
            <div class="navigation-cabinet">
              <div class="navigation-cabinet-title">
                Личный кабинет
              </div>
              <ul class="list navigation-cabinet-list">
                <li 
                  @click="push('lk/profile')"
                  class="list-item" 
                  :class="{'-active': key === 'profile'}"
                >
                  <a 
                    class="list-link"
                  >
                    <svg 
                      class="icon" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 18 18"
                    >
                      <use xlink:href="@/assets/img/icons.svg#cabinet_profile" />
                    </svg>
                    <span >Профиль</span>
                  </a>
                </li>
                <li 
                  @click="push('lk/personaldata')"
                  class="list-item"
                  :class="{'-active': key === 'personaldata' || !key}"
                >
                  <a 
                    class="list-link"
                  >
                    <svg 
                      class="icon" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 18 18"
                    >
                      <use xlink:href="@/assets/img/icons.svg#cabinet_settings" />
                    </svg>
                    <span >Личные данные</span>
                  </a>
                </li>
                <li  
                  @click="push('lk/history')"
                  class="list-item"
                  :class="{'-active': key === 'history'}"
                >
                  <a 
                    class="list-link"
                  >
                    <svg 
                      class="icon" 
                      width="16.214" 
                      height="18" 
                      viewBox="0 0 16.214 18"
                    >
                      <use xlink:href="@/assets/img/icons.svg#cabinet_timer" />
                    </svg>
                    <span>История заказов</span>
                  </a>
                </li>
                <li 
                  @click="push('lk/subscribe')"
                  class="list-item"
                  :class="{'-active': key === 'subscribe'}"
                >
                  <a 
                    class="list-link"
                  >
                    <svg 
                      class="icon" 
                      width="14.438" 
                      height="18.229" 
                      viewBox="0 0 14.438 18.229"
                    >
                      <use xlink:href="@/assets/img/icons.svg#cabinet_padnote" />
                    </svg>
                    <span>Подписки</span>
                  </a>
                </li>
                <li 
                  @click="push('lk/manager')"
                  class="list-item"
                  :class="{'-active': key === 'manager'}"
                >
                  <a 
                    class="list-link"
                  >
                    <svg 
                      class="icon" 
                      width="17" 
                      height="14.426" 
                      viewBox="0 0 17 14.426"
                    >
                      <use xlink:href="@/assets/img/icons.svg#cabinet_speech_bubble" />
                    </svg>
                    <span>Персональный менеджер</span>
                  </a>
                </li>
                <li 
                  class="list-item" 
                  @click="logout"
                >
                  <a 
                    class="list-link"
                  >
                    <svg 
                      class="icon" 
                      width="16.63" 
                      height="16.601" 
                      viewBox="0 0 16.63 16.601"
                    >
                      <use xlink:href="@/assets/img/icons.svg#cabinet_logout" />
                    </svg>
                    <span>Выйти</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <home-tab v-if="!key" />
          <personal-details-tab v-if="key === 'personaldata'" />
          <profile v-if="key === 'profile'" />
          <history-tab v-if="key === 'history'"  />
          <subscribe-tab v-if="key === 'subscribe'" />
          <manager-tab v-if="key === 'manager'" />

        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'

import homeTab from './home-tab.vue'
import PersonalDetailsTab from './personal-details-tab.vue'
import Profile from './profile.vue'
import HistoryTab from './history-tab.vue'
import subscribeTab from './subscribe-tab.vue'
import managerTab from './manager-tab.vue'

export default {
  mixins: [Functions],
    components: {
        breadCrumbs,
        homeTab,
        PersonalDetailsTab,
        Profile,
        HistoryTab,
        subscribeTab,
        managerTab,
    },
    data() {
      return {

      }
    },
    computed: {
        ...Vuex.mapGetters({
            pagesLK: 'dictionary/pagesLK',
        }),
        key() {
          return this.$route.params.key
        }
    },
    methods: {
      ...Vuex.mapActions({
        logoutStore: 'auth/logout',
      }),
      logout() {
        this.logoutStore()
          .then(() => {
            this.$router.push('/login')
          })
      }
    },
}
</script>