<template>
    <div class="section section--events">
              <div class="container">
                <div class="inner" v-if="subBlock">
                    <div class="heading">
                    <div class="heading-section">
                        <h2>Акции компании</h2>
                    </div>
                    </div>
                </div>
                  <ul class="list list--flex layout-events">
                      <li 
                        class="list-item"
                        v-for="(offer, idx) in uiPromotions"
                        :key="idx"
                        v-show="(!isShowAllOffers && idx < 5) || (isShowAllOffers)"
                      >
                        <div class="event">
                          <div class="event-header">
                            <div class="event-date">{{ getNormalFormatedDate(offer.close_date)}}</div>
                          </div>
                          <div class="event-body">
                            <div class="event-preview">
                              <img 
                                class="img_offer_small" 
                                :src="offer.linkimg" 
                                alt=""
                              >
                            </div>
                            <div class="event-title">{{ offer.name }}</div>
                            <div class="event-description" v-html="offer.subheading"></div>
                          </div>
                          <div class="event-footer">
                              <a 
                                  class="button button--primary" 
                                  @click="push('offers/' + offer.link)"
                              >
                                  Читать полностью
                              </a>
                          </div>
                        </div>
                      </li>
                  </ul>
                  <div class="load-more">
                      <a 
                          v-if="!isShowAllOffers"
                          class="button button--default" 
                          @click="isShowAllOffers = true"
                      >
                          Cмотреть еще
                      </a>
                  </div>
              </div>
          </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    props: {
        subBlock: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [Functions],
    data() {
        return {
            dataOffers: [],
            isShowAllOffers: false,
            currentSlide: 1,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            pagesOffers: 'app/pagesOffers',
            offersData:'offers/offersData',
            offers: 'app/offers',
            city: 'auth/city',
            from: 'auth/from',
            isAuthenticated:'auth/isAuthenticated',
            user:'auth/user',

            uiPromotions: 'ui_promotion/promotions',
        }),
        fromMayak(){
            return JSON.parse(localStorage.getItem("fromMayak"))
        }
    },
    watch:{
        offers(){
            this.getOffers()
        },
        city(){
            this.dataOffers = []
            this.getOffers()
        },
        from(){
            this.dataOffers = []
            this.getOffers()
        }
    },
    mounted() {
        if(this.offers == null){
            return
        }
        this.getOffers()
    },
    methods: {
        getOffers(){
            if (!this.offers || !this.offers.offers){
                return
            }
            this.offers.offers.map((el)=>{
                if(el == null){
                    return
                }
                if(this.isAuthenticated == true){
                    if((el.from == this.user.account_type || el.from == null) && (el.region == JSON.parse(localStorage.getItem("regionMayak")) || el.region == null)){
                        this.dataOffers.push(el)
                        return
                    }
                }else{
                    if((el.from == JSON.parse(localStorage.getItem("fromMayak")) || el.from == null) && (el.region == JSON.parse(localStorage.getItem("regionMayak")) || el.region == null)){
                        this.dataOffers.push(el)
                        return
                    }
                }
            })
        },
        nextSlide() {
          if (this.currentSlide == this.dataOffers.length) {
              this.currentSlide = 1
              return  
          }
          this.currentSlide += 1
        },
        prevSlide() {
          if (this.currentSlide == 1) {
              this.currentSlide = this.dataOffers.length
              return  
          }
          this.currentSlide -= 1
        }
    },
}
</script>
<style scoped src="../../../css/castom.css"></style>
<style scoped>
    .layout-events .list-item {
        max-width: 16.3% !important;
    }
</style>