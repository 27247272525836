<template>
    <main class="admin-index index__admin" style="display: block">
        <div class="admin-index_header">
            <h3>Права</h3>
            <button 
                class="button button--primary save_btn"
            >
                Сохранить
            </button>
        </div>
        <div class="content">
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <td>Группы</td>
                    <td>Профиль</td>
                    <td>Мои заказы</td>
                    <td>График оплат</td>
                    <td>Отчёт по броням</td>
                    <td>Отчёт по реализации</td>
                    <td>Акт сверки</td>
                    <td>Прайс-лист</td>
                    <td>Отзывы</td>
                    <td>Претензии</td>
                </tr>
                <single-permission 
                    v-for="permission in permissions" 
                    :key="permission.id" 
                    :permission="permission"
                />
            </table>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'

import singlePermission from './single-permission.vue'

export default {
    components: {
        singlePermission
    },
    computed: {
        ...Vuex.mapGetters({
            permissions: 'permissions/permissions',
        }),
    },
    created() {
        this.getPermissions()
    },
    methods: {
        ...Vuex.mapActions({
            getPermissions: 'permissions/getPermissions',
        }),
    },
}
</script>
<style scoped src="@/css/nlk-style.css"></style>