<template>
  <div class="admin-left-menu">
    <ul class="admin-list">
      <li 
        :class="{'-active' : currentRouteName === 'AdminMain'}"
        @click="push('admin/index')"
      >
        <p>Главная страница</p>
      </li>
      <li  
        :class="{'-active' : currentRouteName === 'AdminLegals'}"
        @click="push('admin/legals')"
      >
        <p>Юрлицам</p> 
      </li>
      <li 
        :class="{'-active' : currentRouteName === 'AdminCompany'}"
        @click="push('admin/company')"
      >
        <p>О компании</p> 
      </li>
      <li
        :class="{'-active' : currentRouteName === 'AdminContacts'}"  
        @click="push('admin/contacts')"
      >
        <p>Контакты</p> 
      </li>
      <li
        :class="{'-active' : currentRouteName === 'AdminServises'}"  
        @click="push('admin/servises')"
      >
        <p>Услуги</p> 
      </li>
      <li  
        :class="{'-active' : currentRouteName === 'AdminSupport'}"
        @click="push('admin/support')"
      >
        <p>Поддержка</p> 
      </li>
      <li  
        :class="{'-active' : currentRouteName === 'AdminBecomePartner'}"
        @click="push('admin/becomepartner')"
      >
        <p>Стань партнером</p> 
      </li>
      <li  
        :class="{'-active' : currentRouteName === 'AdminOurObjects'}"
        @click="push('admin/ourobjects')"
      >
        <p>Наши объекты</p> 
      </li>
      <li  
        :class="{'-active' : currentRouteName === 'AdminDocuments'}"
        @click="push('admin/documents')"
      >
        <p>Документы</p> 
      </li>
      <li  
        :class="{'-active' : currentRouteName === 'AdminOffers'}"
        @click="push('admin/offers')"
      >
        <p>Акции</p> 
      </li>
      <li 
        :class="{'-active' : currentRouteName === 'AdminCardProduct'}"
        @click="push('admin/cardproduct')"
      >
        <p>Каталог товара</p> 
      </li>
      <hr>
      <li  
        :class="{'-active' : currentRouteName === 'AdminBasketReport'}"
        @click="push('admin/basketreport')"
      >
        <p>Отчет по корзинам</p> 
      </li>
      <hr>
      <li
          :class="{'-active' : currentRouteName === 'AdminLogs'}"
          @click="push('admin/logs')"
      >
        <p>Логи событий</p>
      </li>
      <hr>
      <li
          :class="{'-active' : currentRouteName === 'AdminCalculators'}"
          @click="push('admin/calculators')"
      >
        <p>Калькуляторы</p>
      </li>
      <hr>
      <li
          :class="{'-active' : currentRouteName === 'AdminBanners'}"
          @click="push('admin/banners')"
      >
        <p>Баннеры</p>
      </li>
      <hr>
      <li
          :class="{'-active' : currentRouteName === 'AdminCoatings'}"
          @click="push('admin/coatings')"
      >
        <p>Покрытия</p>
      </li>
      <hr>
      <li
          :class="{'-active' : currentRouteName === 'AdminVacancy'}"
          @click="push('admin/vacancy')"
      >
        <p>Вакансии</p>
      </li>
    </ul>
  </div>
</template>
<script>
import Functions from "@/utils/functions"

export default {
  mixins: [Functions],
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  },
}
</script>

<style lang="scss">
    .admin-left-menu{
        border-left: 2px solid #a0a0a0;
        border-right: 2px solid #a0a0a0;
        height: 91vh;
        width: 18%;
        background-color: #e2e2e2;

        .admin-list{
            list-style: none;
            padding: 0;
            margin: 0; 

            font-size: 15px;
            font-weight: 600;

            overflow-x: auto;
            max-height: 100%;

            li{
              padding: 10px;
              padding-left: 25px;

              &:hover{
                background-color: #fff;
                cursor: pointer;
              }
            }

            .-active{
              color: #ed1c24;
            }
        }
    }
</style>