<template>
    <div class="single-catalog">
        <span 
            class="index__del" 
            @click="deleteUiCategory(editElement.id)"
        >
            Удалить X
        </span>

        <span 
            class="header"
        >
            Категория №{{ index + 1 }}
        </span>
        
        <label>Id1c</label>        
        <input 
            type="text" 
            v-model="editElement.id_1c" 
            @keyup="update"
        >
        
        <label>Название</label>
        <input 
            type="text" 
            v-model="editElement.name" 
            @keyup="update"
        >
        
        <label>Сортировка</label>
        <input 
            type="text" 
            v-model="editElement.sort" 
            @keyup="update"
        >
        
        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="editElement.link" 
            @keyup="update"
        >

        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'file'+ index" 
                    type="file" 
                    @change="uploadImg"
                >
            </label>
            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
        </div>

        <div 
            v-if="editElement.link"
            class="img_block" 
        >
            <img 
                :src="editElement.link" 
                alt=""
            >
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            svgCode: null,
            bannerUrl: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
            homeCategoryBanners: 'app/homeCategoryBanners',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
        currentBannerUrl() {
            let url = null
            this.homeCategoryBanners.map((element) => {
                if (element.id_1c == this.editElement.id_1c) {
                    url = element.img
                }
            })
            return url
        },
    },
    mounted() {
        this.setSvgHtml()
        this.bannerUrl = this.currentBannerUrl
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            deleteUiCategory: 'ui_category/deleteCategory',
        }),
        ...Vuex.mapMutations({
            updateMainCatalogCategory: 'app/updateMainCatalogCategory',
            changeHomeCategoryBannerUrl: 'app/changeHomeCategoryBannerUrl',
        }),
        update() {
            this.updateMainCatalogCategory({
                idx: this.index,
                name: this.editElement.name,
                id_1c: this.editElement.id_1c,
                link: this.editElement.link,
                imgType: this.editElement.imgType,
                sort: this.editElement.sort,
            })
        },
        uploadImgBanner() {
            let img = this.$refs['fileBanner' + this.index].files[0]
            this.upload({file: img})
                .then((response) => {
                    this.bannerUrl = this.fileUrl + response.data[0].link
                    this.changeHomeCategoryBannerUrl({id_1c: this.editElement.id_1c, img: this.bannerUrl})
                })
        },
        uploadImg() {
            let img = this.$refs['file' + this.index].files[0]
            this.upload({file: img})
                .then((response) => {
                    this.editElement.link = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
        deleteImg() {
            this.editElement.img = null
            this.update()
        },
        setSvgHtml() {
            if (this.editElement.imgType != 'image/svg+xml') {
                return
            }
            this.getSvgHtml(this.editElement.img)
                .then((response) => {
                    this.svgCode = response
                })
        },
    },
}
</script>
<style scoped>
    img{
        max-width: 565px;
    }
</style>