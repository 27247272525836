<template>
    <div
        v-if="category"
        class="col-4_3"
    > 
        <!-- :style="{ 'background-image': 'url(' + category.link + ')' }" -->
        <!-- style="background-size: cover" -->

        <a
            class="card card--media custom-opacity"
            @click="push('catalog/' + category.id_1c)" 
        >  
            <div class="bg1 content">
                <img :src="category.link" alt="">
            </div>
            <div class="card-heading">
                <div class="card-title">
                    {{ category.name.toUpperCase() }}
                </div>
            </div>
            <div class="card-arrow">
                <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                    <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                </svg>
            </div>
        </a>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    props: {
        category: {
            type: Object,
            default: () => {}
        },
        number: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            svgCode: null,
            prevSvg: "",
            exampleSvg: "https://svgsilh.com/svg_v2/1801287.svg"
        }
    },
    computed: {
        ...Vuex.mapGetters({
            main: 'app/main',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            getSvgHtml: 'app/getSvgHtml',
        }),
        getName(id_1c) {
            let name = null
            this.main.catalogCategories.map((element) => {
                if (id_1c == element.id_1c) {
                    name = element.name
                }
            })
            return name
        },
        getImg() {
            let img = ''
            if (this.categoryElement.imgType == 'image/svg+xml') {
                img = this.svgCode
            }else{
                img = this.categoryElement.img
            }
            return img
        },
        getSvg() {
            if (this.categoryElement.imgType != 'image/svg+xml') {
                return
            }
            this.getSvgHtml(this.categoryElement.img)
                .then((response) => {
                    this.svgCode = response
                })
        },
    }
}
</script>
<style lang="scss">
    .custom-opacity{
        &:before{
            opacity: 0.3 !important;
        }
    }
    .test-opacity0{
        &:before{
            opacity: .8 !important;
        }
    }
    .test-opacity1{
        &:before{
            opacity: .7 !important;
        }
    }
    .test-opacity2{
        &:before{
            opacity: .6 !important;
        }
    }
    .test-opacity3{
        &:before{
            opacity: .5 !important;
        }
    }
    .test-opacity4{
        &:before{
            opacity: .4 !important;
        }
    }
    .test-opacity5{
        &:before{
            opacity: .3 !important;
        }
    }
    .test-opacity6{
        &:before{
            opacity: .2 !important;
        }
    }
</style>