<template>
    <div class="index__admin">
        <span>Отзыв № {{index + 1}}</span>
        <span 
            class="index__del" 
            @click="deleteUiReview(element.id)" 
        >
            Удалить Х
        </span>
        
        <input 
            type="text" 
            v-model="editElement.name" 
            @change="update" 
            placeholder="ФИО"
        >
        <div >
            <vue-editor 
                v-model="editElement.comment"    
                @text-change="update"
            />
        </div>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor"
import Vuex from 'vuex'
export default {
    components: {
        VueEditor
    },
    props:['element', 'index'],
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',

            updateUiReview: 'ui_review/updateReview',
            deleteUiReview: 'ui_review/deleteReview',
        }),
        update() {
            this.updateUiReview(this.editElement)
        },
    },
  }
  </script>