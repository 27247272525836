<template>
	<section class="nlk-content">
		<h1 class="nlk-content__title">ОТЧЕТ ПО БРОНЯМ</h1>
		<form 
			class="nlk-filter"
			onsubmit="return false"
		>
			<div class="field field_data">
				<div class="field-label">Период создания брони</div>
				<div class="field-input">
					<input 
						type="date" 
						placeholder="Дата" 
						v-model="startDate"
						@change="search"
					>
				</div>
			</div>
			<div class="field field_data">
				<div class="field-label" style="min-height: 2rem"></div>
				<div class="field-input">
					<input 
						type="date" 
						placeholder="Дата" 
						v-model="endDate"
						@change="search"
					>
				</div>
			</div>
			<div class="field field_data">
				<div class="field-label">Выберите статус</div>
				<multiselect
					v-model="valueStatus"
					:options="statusArray"
					:multiple="true"
					placeholder="Статусы"
					class="custom-multi-select"
				>
				</multiselect>
			</div>
			<div class="field field_data">
				<div class="field-label">Номер бронирования</div>
				<div class="field-input wo-before">
					<input 
						type="text" 
						v-model="valueBookingId1c" 
						@change="search"
						@paste="onPaste"
					>
				</div>
			</div>
			<div class="field field_data">
				<div class="field-label">Выберите ответственного</div>
				<multiselect
					v-model="valueFio"
					:options="fioArray"
					:multiple="true"
					placeholder="Ответственный"
					class="custom-multi-select"
				>
				</multiselect>
			</div>
		</form>
		<div class="nlk-horizontal-scroll nlk-vertical-scroll">
			<table class="nlk-table nlk-table_w-100 nlk-table_fs-12 nlk-table_p-7 nlk-table_th-fs-14 important_table_fs table_custom_border">
				<tr>
					<th>НОМЕР БРОНИРОВАНИЯ</th>
					<th>ОБЪЕКТ</th>
					<th>АДРЕС ОБЪЕКТА</th>
					<th>ФИО</th>
					<th>КОНТАКТЫ</th>
					<th>НОМЕНКЛАТУРА В ЗАКАЗЕ</th>
					<th>СТАТУС</th>
				</tr>
				<tr
					v-for="(booking, i) in bookingList"
					:class="getClassForTrOfStatus(booking.status)"
					:key="i"
				>
					<td>{{ booking.id_1c }}</td>
					<td>
						<div>
							<b>
								{{ booking.object }}
							</b>
						</div>
					</td>
					<td>{{ booking.address }}</td>
					<td>{{ booking.fio }}</td>
					<td>
						{{ booking.phone }}
						<br>
						{{ booking.email }}
					</td>
					<td >
						<table class="nlk-table nlk-table_w-100 table_in_table">
							<tr 
								v-for="(nomenclature, idx) in booking.nomenclatures"
								:key="idx"	
							>
								<td style="font-size: 1.2rem" :class="getClassForTd(idx, 'center', booking.nomenclatures.length)">{{ nomenclature }}</td>
							</tr>
						</table>

					</td>
					<td style="overflow: hidden">{{ booking.status }}</td>
				</tr>
			</table>
		</div>
	</section>
</template>
<script>
import Vuex from 'vuex'
// import choicesSelect from '@/components/choices-select/choices-select'
import Multiselect from 'vue-multiselect'

export default {
	components: {
		// choicesSelect,
		Multiselect,
	},
	data() {
		return {
			startDate: null,
			endDate: null,
			statusArray: [
				"Забронировано",
				"Cделка проиграна",
				"Заключена сделка",
				"Пересечение",
				"Аннулирована",
			],
			fioArray: [],
			valueStatus: null,
			valueFio: null,
			valueBookingId1c: null,
			defaultValue: 'Выберите статус',
		}
	},
	computed: {
		...Vuex.mapGetters({
			bookingList: 'reports/bookingList',
			formBooking: 'reports/formBooking',
		}),
		form() {
			return Object.assign({}, this.formBooking)
		},
	},
	watch: {
		valueStatus() {
			this.search()
		},
		valueFio() {
			this.search()
		},
	},
	created() {
		this.getBookingList()
			.then(() => {
				this.setFioArray()
			})
	},
	methods: {
		...Vuex.mapActions({
			getBookingList: 'reports/getBookingList',
		}),
		...Vuex.mapMutations({
			setBookingForm: 'reports/setBookingForm',
		}),
		setFioArray() {
			let array = []
			if (this.bookingList.length != 0) {
				this.bookingList.map((element) => {
					if (!this.issetInArray(element.fio, array)) {
						array.push(element.fio)
					}
				})
			}
			this.fioArray = array
		},
		getClassForTd(idx, type, length) {
            let className = null
            if (idx == 0 && idx != length - 1) {
                if (type == 'left') {
                    className = 'border_wo_left-top border_wo_right'
                }else if (type == 'right') {
                    className = 'border_wo_right-top border_wo_left'
                }else{
                    className = 'border_wo_top border_wo_left-right'
                }
            }else if (idx == length - 1 && idx != 0) {
                if (type == 'left') {
                    className = 'border_wo_left-bottom border_wo_right'
                }else if (type == 'right') {
                    className = 'border_wo_right-bottom border_wo_left'
                }else{
                    className = 'border_wo_bottom border_wo_left-right'
                }
            }else if (idx == 0 && idx == length - 1) {
                if (type == 'left') {
                    className = 'border_wo_left-top border_wo_right border_wo_bottom'
                }else if (type == 'right') {
                    className = 'border_wo_right-top border_wo_left border_wo_bottom'
                }else{
                    className = 'border_wo_top border_wo_left-right border_wo_bottom'
                }
            }else{
                if (type == 'left') {
                    className = 'border_wo_left border_wo_right'
                }else if (type == 'right') {
                    className = 'border_wo_right border_wo_left'
                }else{
                    className = 'border_wo_left-right'
                }
            }
            return className
        },
		issetInArray(val, array) {
			let isset = false	
			array.map((element) => {
				if (val == element) {
					isset = true
				}
			})
			return isset
		},
		onPaste() {
			setTimeout(function(){
				this.search()
			}.bind(this), 300);
		},
		search() {
			if (this.startDate) {
				let today = new Date(this.startDate)
				this.form.date_start = today.toISOString()
			}else{
				this.form.date_start = null
			}

			if (this.endDate) {
				let today = new Date(this.endDate)
				this.form.date_end = today.toISOString()
			}else{
				this.form.date_end = null
			}
			this.form.status = this.valueStatus
			this.form.fio = this.valueFio
			if (this.valueBookingId1c) {
				this.form.id_1c = this.valueBookingId1c
			}else{
				this.form.id_1c = null
			}
			this.setBookingForm(this.form)
			this.getBookingList()
		},
		getClassForTrOfStatus(status) {
			let className = null
			if (status == 'Забронированно' || status == 'Забронировано') {
				className = 'tr_border_color_orange'
			}else if (status == 'Cделка проиграна') {
				className = 'tr_border_color_red'
			}else if (status == 'Заключена сделка') {
				className = 'tr_border_color_green'
			}else if (status == 'Аннулирована') {
				className = 'tr_border_color_purple'
			}
			return className
		},
	}
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped src="../../../css/nlk-style.css"></style>
<style lang="css" scoped>
	.br_text{
		word-spacing: 200px;
	}
	.object_td{
		max-width: 100px;
	}
</style>
<style lang="scss">
	.important_table_fs{
		font-size: 1.2rem !important;
	}
	.table_custom_border{
		td{
			border-color: #718093 !important;
		}
	}
	.custom-multi-select{
		.multiselect__select{
			top: 8px;
		}

		.multiselect__tags{
			border-radius: 0 !important;
			min-height: 5.5rem;
			border-color: #718093;

			.multiselect__tag{
				background: #EA292E;

				.multiselect__tag-icon{
					&:hover{
						background: black;
					}

					&:after{
						color: white;
					}
				}
			}

			.multiselect__placeholder{
				padding-top: 6px;
			}
		}
	}
	.wo-before{
		&:before{
			display: none !important;
		}
	}
	.tr_border_color_orange{
		td{
			border-color: #FA832E !important;
		}
	}
	.tr_border_color_red{
		td{
			border-color: #EA292E !important;
		}
	}
	.tr_border_color_green{
		td{
			border-color: #4C9F4A !important;
		}
	}
	.tr_border_color_purple{
		td{
			border-color: #872EFA !important;
		}
	}
</style>