<template>
	<section class="nlk-content">
		<h1 class="nlk-content__title mb-80">ПРАЙС-ЛИСТЫ</h1>
		<a 
			v-if="user.account_type == 30 && user.id_1c"
			:href="yandexCloud + 'prices/' + user.id_1c + '/price.pdf'"
		>
			<button 
				class="button button--primary mb-120"
			>
				Скачать прайс
			</button>
		</a>
		<a 
			v-else
			:href="yandexCloud + 'prices/' + currentRegion + '/price.pdf'"
		>
			<button 
				class="button button--primary mb-120"
			>
				Скачать прайс
			</button>
		</a> 
	</section>
</template>
<script>
import Vuex from 'vuex'
export default {
	computed: {
		...Vuex.mapGetters({
			region: 'auth/city',
			yandexCloud: 'app/yandexCloud',
			user: 'auth/user',
			topLevelCategory: 'dictionary/topLevelCategory',
		}),
		currentRegion() {
			let region = null
			if (this.region) {
				region = this.region
			}else{
				if (this.topLevelCategory.length != 0) {
					region = this.topLevelCategory[0].id_1c
				}
			}
			return region
		},
	},
	
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>