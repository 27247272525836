
<template>
    <div class="modal-iframe offer_modal">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <h3>Для продолжения оформления необходимо зарегистрироваться или авторизоваться.</h3>
                        <div class="form" name="callback-form-popup">
                            <div class="form-fieldset form-fieldset--medium">
                                <div class="form-group">
                                    <div class="form-item block_buttons">
                                        <button class="button button--primary" @click="goToOffer('registration')">Регистрация</button>
                                        <button class="button button--primary" @click="goToOffer('login')">Вход</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Functions from '@/utils/functions'
import Vuex from 'vuex'

export default {
	props:['route'],
    mixins: [Functions],
    methods: {
        ...Vuex.mapMutations({
            setShowOfferModal: 'app/setShowOfferModal',    
        }),
        close(){   
            this.setShowOfferModal(false)
        },
        goToOffer(str) {
            this.push(str)
            this.close()
        },
    },
  }
</script>
<style lang="scss">
    .offer_modal{
        .block_buttons{
            display: flex;
            justify-content: space-around;
        }
    }
</style>