<template>
    <section class="about_company-info_page-sp_map">
        <div class="map_block">
            <!-- <img src="@/img/info-sp/map.png" alt=""> -->

            <yandex-map
                id="map"
                :coords="center"
                :settings="settings"
                :zoom="zoom"
                :scroll-zoom="false"
            >
                <ymapMarker 
                    :coords="[ 53.188926, 50.097082 ]"
                />
            </yandex-map>
        </div>
    </section>
</template>
<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
    components: {
        yandexMap, 
        ymapMarker,
    },
    data () {
        return {
            center: [ 53.200238, 50.038374 ],
            zoom: 12,
            settings: {
                apiKey: '72b24e1d-06ad-424f-af9b-44d28d93d4d6',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            },
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: require('@/assets/img/map-marker.svg'),
                imageSize: [43, 43],
                imageOffset: [0, 0],
            }
        }
    },
}
</script>