import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.config.productionTip = false

import "@/css/app.min.css"
import "@/css/app.sass"
import "@/css/style.css"

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueYandexMetrika, {
  id: 94200742,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true,
  },
  // other options
})
