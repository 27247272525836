<template>
    <div class="admin-documents_single-benefit index__admin">
        <span>Блок № {{index+1}}</span>
        <span 
            class="index__del" 
            @click="deleteUiPartner(editElement.id)"
        >
            Удалить
        </span>

        <select 
            v-model="editElement.roles"
            name="listTypes" 
            class="list_type"
            @change="update"
        >
            <option 
                v-for="type in listBenefitTypes" 
                :key="type.id"
                :value="type.id" 
            >
                {{ type.name }}
            </option>
        </select>

        <label>Название</label>
        <input 
            v-model="editElement.name"
            type="text"
            placeholder="Название"
            @change="update"
        >

        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="editElement.link"
            placeholder="Ссылка на изображение"
            @change="update" 
        >
        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'singleFilePartner'+ index" 
                    type="file" 
                    @change="uploadFile"
                >
            </label>
            <p>
                Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG.
                Для правильного отображения соблюдайте размер и формат
            </p>
        </div>
        <div 
            v-if="editElement.link"
            class="img_block"
        >
            
        <div 
                v-if="svgCode"
                v-html="svgCode"
            />

            <img 
                v-else
                :id="'fileDisplay' + index"
                :src="editElement.link" 
                alt=""
            >
            
            <img 
                class="remove_icon"
                src="../../../../assets/img/remove.svg" 
                alt=""
                @click="deleteImg"
            >
        </div>        
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            svgCode: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            listBenefitTypes: 'dictionary/listBenefitTypes',
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    mounted() {
        this.setSvgHtml()
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            getSvgHtml: 'app/getSvgHtml',

            deleteUiPartner: 'ui_partner/deletePartner',
            updateUiPartner: 'ui_partner/updatePartner',
        }),
        update() {
            this.updateUiPartner(this.editElement)
        },
        uploadFile() {
            let img = this.$refs['singleFilePartner' + this.index].files[0]
            this.upload({file: img})
                .then((response) => {
                    this.updateUiPartner({
                        id: this.editElement.id,
                        name: this.editElement.name,
                        root_id: this.editElement.root_id,
                        roles: this.editElement.roles,
                        link: this.fileUrl + response.data[0].link,
                    })
                    this.getSvgHtml(this.fileUrl + response.data[0].link)
                        .then((response) => {
                            this.svgCode = response
                        })
                })
        },
        deleteImg() {
            this.editElement.link = null
            this.update()
        },
        setSvgHtml() {
            this.svgCode = null
            
            this.getSvgHtml(this.editElement.link)
                .then((response) => {
                    this.svgCode = response
                })
        },

    },
}
</script>
<style lang="scss">
    .img_block{
        position: relative;
        width: 100%;

        .remove_icon{
            position: absolute;
            top: 0;
            left: -1rem;
            width: 1.3rem;
            height: 1.3rem;
            filter: drop-shadow(1px 1px 0 rgba( 0, 0, 0, 1));

            &:hover{
                cursor: pointer;
            }
        }
    }
</style>