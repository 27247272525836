<template>
  <div class="single-email-block">
      <input type="text" v-model="editEmail" @keyup="update">
      <span 
      class="index__del"
      @click="deleteEmail"  
    >
      Удалить Х
    </span>
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    props: {
        email: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
        emailName: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            editEmail: null
        }
    },
    created() {
        this.editEmail = this.email.value
    },
    methods: {
        ...Vuex.mapMutations({
            updateTechnicalEmail: 'app/updateTechnicalEmail',
            deleteTechnicalEmail: 'app/deleteTechnicalEmail',
        }),
        update() {
            this.updateTechnicalEmail({
                index: this.index,
                emailName: this.emailName,
                value: this.editEmail
            })
        },
        deleteEmail() {
            this.deleteTechnicalEmail({
                emailName: this.emailName,
                index: this.index,
                id: this.email.id
            })
        },
    }
}
</script>