<template>
    <div class="header-container header-container--mobile">
        <div class="header-bar">
            <div 
                class="logotype-mobile" 
                @click="pushToProject('')"
            >
                <div class="logotype-mobile-image">
                    <a class="logotype-mobile-link">
                        <img src="@/assets/img/logotype-mobile.svg" alt="">
                    </a>
                </div>
                <div class="logotype-mobile-content">
                    <div class="logotype-mobile-title">
                        <img src="@/assets/img/logotype-mobile-title.svg" alt="">
                    </div>
                    <div class="logotype-mobile-phone">
                        <a class="logotype-mobile-link" href="#">8 (846) 276-16-16</a>
                    </div>
                </div>
                <div class="logotype-mobile-dropdown">
                    <img src="@/assets/img/logotype-mobile-white.svg" alt="">
                </div>
            </div>
            <button 
                class="header-dropdown-toggle" 
                @click="setOpenMobileNavigation(!isOpenMobileNavigation)" 
                data-navigation-mobile-toggle
            />
        </div>
        <div class="header-dropdown">
            <div class="header-dropdown-main">
                <div class="header-navigation-main-search">
                    <form 
                        class="search-mobile" 
                        action="#"
                        onsubmit="return false"
                    >
                        <input 
                            class="search-mobile-field" 
                            type="text" 
                            placeholder="Поиск по каталогу"
                            v-model="searchForm.query" 
                            @keyup="getProducts"
                        >
                        <button class="search-mobile-button">
                            <svg class="icon" width="17.758" height="17.758" viewBox="0 0 17.758 17.758">
                                <use xlink:href="@/assets/img/icons.svg#search"></use>
                            </svg>
                        </button>
                    </form>
                </div>
                <div class="header-dropdown-main-scroll">
                    <div class="header-navigation-main-body">
                        <ul class="list header-navigation-main-list">
                            <li class="list-item" @click="pushToCalculators">
                                <a class="navigation-link">
                                    <div class="navigation-link-icon">
                                        <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                            <use xlink:href="@/assets/img/icons.svg#navigation_calc"></use>
                                        </svg>
                                    </div>
                                    <div class="navigation-link-title">Конструктор</div>
                                </a>
                            </li>
                            <li 
                                class="list-item" 
                                @click="pushToProject('calculators/roof')"
                            >
                                <a class="navigation-link">
                                    <div class="navigation-link-icon">
                                        <svg class="icon" width="16.877" height="18" viewBox="0 0 16.877 18">
                                            <use xlink:href="@/assets/img/icons.svg#navigation_roof"></use>
                                        </svg>
                                    </div>
                                    <div class="navigation-link-title">Кровля</div>
                                </a>
                            </li>
                            <li 
                                class="list-item"
                                @click="pushToProject('calculators/drainage')"    
                            >
                                <a class="navigation-link">
                                    <div class="navigation-link-icon">
                                        <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                            <use xlink:href="@/assets/img/icons.svg#navigation_drop"></use>
                                        </svg>
                                    </div>
                                    <div class="navigation-link-title">Водостоки</div>
                                </a>
                            </li>
                            <li 
                                class="list-item"
                                @click="pushToProject('calculators/fencing')"    
                            >
                                <a class="navigation-link">
                                    <div class="navigation-link-icon">
                                        <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                            <use xlink:href="@/assets/img/icons.svg#navigation_fence"></use>
                                        </svg>
                                    </div>
                                    <div class="navigation-link-title">Ограждения</div>
                                </a>
                            </li>
                            <li 
                                class="list-item"
                                @click="pushToProject('paydelivery')"
                            >
                                <a class="navigation-link">
                                    <div class="navigation-link-icon">
                                        <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                            <use xlink:href="@/assets/img/icons.svg#navigation_delivery"></use>
                                        </svg>
                                    </div>
                                    <div class="navigation-link-title">Оплата и доставка</div>
                                </a>
                            </li>
                            <li 
                                class="list-item" 
                                @click="pushToLogin"
                            >
                                <a class="navigation-link">
                                    <div class="navigation-link-icon">
                                        <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                            <use xlink:href="@/assets/img/icons.svg#navigation_avatar"></use>
                                        </svg>
                                    </div>
                                    <div class="navigation-link-title">Личный кабинет</div>
                                </a>
                            </li>
                            <li 
                                class="list-item"
                                @click="pushToProject('basket')"
                            >
                                <a class="navigation-link">
                                    <div class="navigation-link-icon">
                                        <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                            <use xlink:href="@/assets/img/icons.svg#navigation_cart"></use>
                                        </svg>
                                    </div>
                                    <div class="navigation-link-title">Корзина</div>
                                </a>
                            </li>
                        </ul>
                        <button 
                            class="header-navigation-main-catalog-button" 
                            @click="setOpenMobileSubNavigation(!isOpenMobileSubNavigation)" 
                            data-navigation-mobile-sub-toggle
                        >
                            <svg class="icon" width="12" height="9" viewBox="0 0 12 9">
                                <use xlink:href="@/assets/img/icons.svg#navigation_arrow"></use>
                            </svg>Открыть каталог
                        </button>
                        <ul class="list header-navigation-main-sub">
                            <li 
                                class="list-item"
                                @click="pushToProject('clients')"     
                            >
                                <a class="list-link">Частным</a>
                            </li>
                            <li 
                                class="list-item"
                                @click="pushToProject('forcompanies')" 
                            >
                                <a class="list-link">Компаниям</a>
                            </li>
                            <li 
                                class="list-item"
                                @click="pushToProject('aboutcompany')"     
                            >
                                <a class="list-link">О компании</a>
                            </li>
                            <li 
                                class="list-item"
                                @click="pushToProject('contacts')"    
                            >
                                <a class="list-link">Контакты</a>
                            </li>
                        </ul>
                    </div>
                    <div class="header-navigation-main-footer">
                        <div class="header-navigation-main-contacts">
                            <a class="phone" href="#">8 (846) 221-94-42</a>
                            <a class="email" href="#">info@gkmayak.ru</a>
                        </div>
                        <div class="header-navigation-main-type">
                            <a  v-if="from == 10 && isAuthenticated == false" @click="changeFrom(20)" class="site-type site-type--toggle-left">
                                <div class="site-type-label">Частным</div>
                            </a>
                            <a v-if="from == 20 && isAuthenticated == false"  class="site-type site-type--toggle-right"  @click="changeFrom(10)">
                                <div class="site-type-label">Юрлицам</div>
                            </a>
                        </div>
                        <div class="header-navigation-main-city">
                            <region-select 
                                v-if="defaultValue"
                                data-select 
                                :elements="topLevelCategory" 
                                v-model="city"
                                @change="changeCity" 
                                :defaultValue="defaultValue" 
                                :from="'footer'"
                                :typeSelect="'location'" 
                            />
                            <address>443117, г. Самара,<br> ул. Заводское шоссе, 5Б</address>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-dropdown-sub">
                <div class="header-dropdown-sub-header">
                    <img src="@/assets/img/logotype-mobile-white.svg" alt="">
                </div>
                <div class="header-dropdown-sub-scroll">
                    <div class="header-dropdown-sub-body">
                        <div class="header-dropdown-sub-group">
                            <ul class="list header-navigation-sub-list">
                                <li 
                                    class="list-item" 
                                    v-for="category in secondLevelCategory" 
                                    :key="category.id"
                                    @click="pushToCatalog(category.id_1c)"
                                >
                                    <a class="navigation-link">
                                        <div class="navigation-link-icon">
                                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                                            <use xlink:href="@/assets/img/icons.svg#roof_systems"></use>
                                            </svg>
                                        </div>
                                        <div class="navigation-link-title">{{ category.name }}</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="header-dropdown-sub-group">
                            <div class="header-dropdown-sub-group-title">Каталог услуг</div>
                            <ul class="list header-navigation-sub-list">
                                <li 
                                    class="list-item"
                                    @click="pushToProject('finishing')"    
                                >
                                    <a class="navigation-link">
                                        <div class="navigation-link-icon">
                                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                                            <use xlink:href="@/assets/img/icons.svg#plastering"></use>
                                            </svg>
                                        </div>
                                        <div class="navigation-link-title">Внутренняя отделка</div>
                                    </a>
                                </li>
                                <li 
                                    class="list-item"
                                    @click="pushToProject('building')"
                                >
                                    <a class="navigation-link">
                                        <div class="navigation-link-icon">
                                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                                                <use xlink:href="@/assets/img/icons.svg#joint"></use>
                                            </svg>
                                        </div>
                                        <div class="navigation-link-title">Быстровозводимые здания</div>
                                    </a>
                                </li>
                                <li 
                                    class="list-item"
                                    @click="pushToProject('metalloconstructions')"    
                                >
                                    <a class="navigation-link">
                                        <div class="navigation-link-icon">
                                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                                                <use xlink:href="@/assets/img/icons.svg#beam_3"></use>
                                            </svg>
                                        </div>
                                        <div class="navigation-link-title">Металоконструкции</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                class="header-dropdown-search"
                :class="{'-active' : searchForm.query.length >= 3 && productsNavbar.length != 0}"
            >
                <ul class="list layout-search-results-mobile">
                    <li 
                        class="list-item"
                        v-for="product in productsNavbar" 
                        :key="product.id" 
                        v-show="product.is_card_product && product.price != 0"
                        @click="pushToProduct(product)"
                    >
                        <div class="search-result-mobile">
                            <a class="search-result-mobile-row">
                                <div class="search-result-mobile-image"><img src="@/assets/img/product.svg" alt=""></div>
                                <div class="search-result-mobile-title">{{ product.name }}</div>
                            </a>
                            <div class="search-result-mobile-row">
                                <div class="search-result-mobile-price">
                                    <div class="search-result-mobile-price-label">цена</div>
                                    <div class="search-result-mobile-price-value">{{ product.price }} {{ regionCurrency[regionCode].currency }}/м2</div>
                                </div>
                                <div class="search-result-mobile-button">
                                    <form 
                                        action="#"
                                        onsubmit="return false"
                                    >
                                        <button class="button button--primary button--cart">
                                            <svg class="icon" width="25" height="25" viewBox="0 0 25 25">
                                                <use xlink:href="@/assets/img/icons.svg#cart"></use>
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

import regionSelect from '@/components/choices-select/region-select.vue'

export default {
    mixins: [
        Functions
    ],
    components: {
        regionSelect,
    },
    data() {
        return {
            defaultValue: '',
            isSetDefaultValue: false,
            isReady: false,
            city: null,
            from: 10,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            isOpenMobileNavigation: 'app/isOpenMobileNavigation',
            isOpenMobileSubNavigation: 'app/isOpenMobileSubNavigation',
            region: 'auth/city',
            catalogFormStore: 'catalog/form',
            searchFormStore: 'search/form',
            productsNavbar: 'search/productsNavbar',
            topLevelCategory: 'dictionary/topLevelCategory',
            isAuthenticated:'auth/isAuthenticated',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
            secondLevelCategory: 'dictionary/secondLevelCategory',
        }),
        catalogForm() {
            return Object.assign({}, this.catalogFormStore)
        },
        searchForm() {
            return Object.assign({}, this.searchFormStore)
        },
    },
    watch: {
        topLevelCategory: {
            handler: function () {
                if (this.isSetDefaultValue == false) {
                    this.isReady = true
                }
            },
            deep: true
        },
    },
    mounted() {
        this.setDefaultCity()
    },
    methods: {
        ...Vuex.mapMutations({
            setOpenMobileNavigation: 'app/setOpenMobileNavigation',
            setOpenMobileSubNavigation: 'app/setOpenMobileSubNavigation',
            setProductsNavbar: 'search/setProductsNavbar',
            setCatalogForm: 'catalog/setForm',
            setSearchForm: 'search/updateForm',
            setCity:'auth/setCity',
            setFrom:'auth/setFrom',
        }),
        ...Vuex.mapActions({
            search: 'catalog/search',
            searchNavbar: 'search/searchNavbar',
        }),
        closeNavigation() {
            this.setOpenMobileNavigation(false)
            this.setOpenMobileSubNavigation(false)
        },
        pushWithNavigationClose(str) {
            this.closeNavigation()
            this.push(str)
        },
        pushToLogin() {
            this.$emit('pushToLogin')
        },
        pushToProject(str) {
            this.closeNavigation()
            this.push(str)
            this.clearSearchForm()
        },
        pushToCatalog(id_1c) {
            this.closeNavigation()
            this.catalogForm.parent_id = id_1c
            this.setCatalogForm(this.catalogForm)
            this.search()
            this.push('catalog/' + id_1c)
            this.clearSearchForm()
        },
        getProducts() {
            if (this.searchForm.query.length < 3) {
                this.setProductsNavbar([])
                return
            }
            this.setSearchForm(this.searchForm)
            this.searchNavbar()
        },
        pushToProduct(product) {
            this.closeNavigation()
            if (product.is_group && !product.is_sub_sign) {
                this.searchForm.parent_id = product.id_1c
                this.setSearchForm(this.searchForm)
                this.search()
                this.push('catalog/' + product.id_1c)
            }else{
                this.push('cardProduct/' + product.id)
            }
            this.clearSearchForm()
        },
        pushToCalculators() {
            this.closeNavigation()
            this.push('')
            setTimeout(() => {
                let element = document.getElementById('main_page_calcurators')
                let top = element.offsetTop
                window.scrollTo(0, top)
            }, 500)
        },
        clearSearchForm() {
            this.searchForm.query = ''
            this.searchForm.parent_id = null
            this.setSearchForm(this.searchForm)
        },
        setDefaultCity() {
            let name = ''

            if (!this.city) {
                name = 'Выберите регион'
            }else{
                this.topLevelCategory.map((element) => {
                    if (element.id_1c == this.city) {
                        name = element.name
                    }
                })
            }

            if (name) {
                this.defaultValue = name
                this.isSetDefaultValue = true
            }
        },
        changeCity(){
            this.setCity(this.city)
            localStorage.setItem('regionMayak', JSON.stringify(this.city))
        },
        changeFrom(val){
            this.from = val
            this.setFrom(val)
            localStorage.setItem('fromMayak', JSON.stringify(val))
        },
    },
}
</script>