<template>
    <li
        class="list-item" 
    >
        <div class="advantage">
            <div class="advantage-icon">
                <img 
                    v-if="benefit.imgType != 'image/svg+xml'"
                    :src="benefit.img" 
                    alt=""
                >
                <div 
                    v-else
                    v-html="svgCode"
                />
            </div>
            <div class="advantage-title">
                {{ benefit.name }}
            </div>
        </div>
    </li>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        benefit: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            svgCode: null,
        }
    },
    mounted() {
        this.getSvg()
    },
    methods: {
        ...Vuex.mapActions({
            getSvgHtml: 'app/getSvgHtml',
        }),
        getSvg() {
            if (this.benefit.imgType != 'image/svg+xml') {
                return
            }
            this.getSvgHtml(this.benefit.img)
                .then((response) => {
                    this.svgCode = response
                })
        },
    }
}
</script>