<template>
  <div class="layout-section">
    <div class="section section--no-pt section--no-pb">
        <div class="personal-manager">
        <div class="personal-manager-image"><img src="@/assets/img/personal-manager.png" alt=""></div>
        <div class="personal-manager-section">
            <h6>Есть вопросы?</h6>
            <p>Заполните форму с подробным описанием. Наш менеджер свяжется с вами в будний день с <b>8.00 до 17.00</b></p>
            <div class="helper">
            <div class="helper-section">
                <div class="helper-title">Суворова Екатерина</div>
                <div class="helper-description">персональный менеджер</div>
            </div>
            </div>
            <!-- helper-->
            <form 
                onsubmit="return false"
                class="form" 
                action="#" 
                method="post"
            >
            <div class="form-fieldset">
                <div class="form-group">
                <div class="form-item form-item--email">
                    <div class="field">
                    <div class="field-label">E-mail</div>
                    <div class="field-input">
                        <input type="email" placeholder="Введите e-mail" value="">
                    </div>
                    </div>
                    <!-- field-->
                </div>
                </div>
                <div class="form-group">
                <div class="form-item">
                    <div class="field">
                    <div class="field-label">Сообщение</div>
                    <div class="field-input">
                        <textarea rows="5" placeholder="Введите сообщение"></textarea>
                    </div>
                    </div>
                    <!-- field-->
                </div>
                </div>
            </div>
            <div class="form-fieldset form-fieldset--medium">
                <div class="form-group">
                <div class="form-item">
                    <button class="button button--primary">Отправить</button>
                </div>
                </div>
            </div>
            </form>
            <p>Или можно связаться с менеджером <b>по телефону</b> 8 (846) 221-94-42, в офисе <b>по адресу</b> г. Самара, ул. Заводское шоссе, 5Б</p>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {

}
</script>