<template>
        <main class="admin-index index__admin">
            <div class="admin-index_header ">
                <h3>Юрлицам</h3>
                <button 
                    class="button button--primary save_btn" 
                    @click="updateData"
                >
                    Сохранить
                </button>

            </div>
             <div class="index__admin" >
                <span> Блок юрлицам</span>
                    <input  type="text" v-model="legals.heading" @keyup="update" placeholder="Заголовок">
                    <div >
                        <vue-editor 
                        v-model="legals.text"   @text-change="update"
                    />
                    </div>
            </div>
            <div v-for="(obj, i) in legals.catalogs" :key="i"  >
                <single-catalog
                        :key="i"
                        :index="i"
                        :element="obj"/>
            </div>
            <div  class="index__admin ">
                <button @click="addCatalog">
                    Добавить каталог
                </button>
            </div>
        </main>
</template>

<script>
import Vuex from 'vuex'
import { VueEditor } from "vue2-editor"
import SingleCatalog from './singleCatalog.vue'
export default {
    components: {
        VueEditor,
        SingleCatalog,
    },
    // mixins: [Functions],
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters({
            legals: 'app/legals',
            fileUrl: 'app/fileUrl',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            pushLegals: 'app/pushLegals',
            pushLegalsCatalog: 'app/pushLegalsCatalog',
            updateLegals: 'app/updateLegals',
        }),
        updateData() {
            this.updateJson()
                .then(() => {
                   
                })
        },
        getLegal(){
            this.pushLegals({ heading: this.legals.heading,  text: this.legals.text})
        },
        addCatalog(){
            this.pushLegalsCatalog({ link: null})
        },
        update() {
            this.updateLegals({
                heading: this.legals.heading, 
                text: this.legals.text, 
                })
        },
    },
}
</script>
