<template>
    <main class="main">
        <div class="section section--no-pt section--no-pb">
            <div class="container">
                <div class="block_svg">
                    <div 
                        class="craf-grid__right"
                    >
                        <svg 
                            version="1.1" 
                            width="598" 
                            :style="'width: 600px; height: ' + heightraskladka + 'px'"
                        >
                            <g 
                                stroke="gray" 
                                stroke-width="1"
                            ></g>
                            <g 
                                stroke="blue" 
                                stroke-width="1"
                            >
                                <line 
                                    v-for="(bluline, i) in bluelines"
                                    :key="i"
                                    :x1="bluline.x1" 
                                    :y1="bluline.y1" 
                                    :x2="bluline.x2" 
                                    :y2="bluline.y2"
                                />
                            </g>
                            <g 
                                stroke="#CA0D27" 
                                stroke-width="4"
                            >
                                <line 
                                    v-for="(redline, i) in redlines"
                                    :key="i"
                                    :x1="redline.x1" 
                                    :y1="redline.y1" 
                                    :x2="redline.x2" 
                                    :y2="redline.y2"
                                ></line>
                            </g>
                            <g 
                                stroke="black" 
                                stroke-width="1"
                            >
                                <line 
                                    v-for="(line, idx) in structure"
                                    :key="idx"
                                    :x1="line.x1" 
                                    :y1="line.y1" 
                                    :x2="line.x2" 
                                    :y2="line.y2"
                                />
                            </g>
                            <g 
                                font-weight="normal" 
                                font-size="12" 
                                fill="blue"
                            >
                                <text
                                    v-for="(text, idx) in arrayTexts"
                                    :key="idx" 
                                    :x="text.x" 
                                    :y="text.y"
                                >
                                    {{ text.text }}
                                </text>
                            </g>
                        </svg>
                    </div>
                    <div class="input_skat">
                        <div class="roof_type_buttons">
                            <button @click="roof_type = 10">О</button>
                            <button @click="roof_type = 20">Д</button>
                            <button @click="roof_type = 30">В</button>
                            <button @click="roof_type = 40">М</button>
                            <button @click="roof_type = 50">Ш</button>
                        </div>
                        <label for="">Width</label>
                        <input type="text" v-model="calculateForm.width_1" class="mb-2" aria-autocomplete="off" @keyup="update">

                        <label for="">Height</label>
                        <input type="text" v-model="calculateForm.height_1" class="mb-2" @keyup="update">

                        <textarea name="" id="" cols="30" rows="10" v-model="skat_1" class="mb-2" @keyup="update"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    data() {
        return {
            bluelines: [],
			redlines: [],
			structure: [],
			arrayTexts: [],

            calculateForm: {
                width_1: 5,
                height_1: 3,
            },
            heightraskladka: 0,

            skat_1: {},
            roof_type: 10,

            example_skat_1: {
                fi: 1500,
                arr_listy_classic_len: [
                    [
                        1.5,
                        1.5,
                        0.5
                    ],
                    [
                        1.5,
                        1.5,
                        0.5
                    ],
                    [
                        1.5,
                        1.5,
                        0.5
                    ],
                    [
                        1.5,
                        1.5,
                        0.5
                    ],
                    [
                        1.5,
                        1.5,
                        0.5
                    ]
                ],
                arr_listy_classic: [
                    [
                        5,
                        0,
                        3.82,
                        -1.5,
                        5,
                        -1.4,
                        3.82,
                        -2.9,
                        5,
                        -2.8,
                        3.82,
                        -3.3
                    ],
                    [
                        3.9,
                        0,
                        2.72,
                        -1.5,
                        3.9,
                        -1.4,
                        2.72,
                        -2.9,
                        3.9,
                        -2.8,
                        2.72,
                        -3.3
                    ],
                    [
                        2.8,
                        0,
                        1.62,
                        -1.5,
                        2.8,
                        -1.4,
                        1.62,
                        -2.9,
                        2.8,
                        -2.8,
                        1.62,
                        -3.3
                    ],
                    [
                        1.7,
                        0,
                        0.52,
                        -1.5,
                        1.7,
                        -1.4,
                        0.52,
                        -2.9,
                        1.7,
                        -2.8,
                        0.52,
                        -3.3
                    ],
                    [
                        0.6,
                        0,
                        -0.58,
                        -1.5,
                        0.6,
                        -1.4,
                        -0.58,
                        -2.9,
                        0.6,
                        -2.8,
                        -0.58,
                        -3.3
                    ]
                ]
            }
        }
    },
    watch: {
        // skat_1(){
        //     this.drawRaskladka()
		// 	this.drawLists()
        // },
    },
    created() {
        // console.log(JSON.stringify(this.skat_1))
    },
    mounted() {
        this.skat_1 = JSON.stringify(this.example_skat_1) 
        this.update()
    },
    methods: {
        update() {
            this.redlines = [] 
            this.bluelines = []
            this.structure = []
            this.arrayTexts = []

            this.calculateForm.width_1 = Number(this.calculateForm.width_1)
            this.calculateForm.height_1 = Number(this.calculateForm.height_1)

            this.drawLists()
            this.drawRaskladka()
        },
        drawRaskladka() {
			this.setRedLines()
			this.setBlueLines()
		},
        setRedLines() {
			let xcellcount = this.calculateForm.width_1 + 3
			let ycellcount = this.calculateForm.height_1 + 2
			let widthforraskladka = 600
			let cellwh = widthforraskladka / xcellcount
			let CentrOsX = 1.5 * cellwh
			let CentrOsY = (ycellcount - 1)*cellwh

			this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY})
			this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
			this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY - this.calculateForm.height_1 * cellwh, x2: CentrOsX, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
			this.redlines.push({x1: CentrOsX, y1: CentrOsY - this.calculateForm.height_1 * cellwh, x2: CentrOsX, y2: CentrOsY})
		},
		setBlueLines() {
            console.log(this.calculateForm.width_1)
			let xcellcount = this.calculateForm.width_1 + 3
			let ycellcount = this.calculateForm.height_1 + 2
			let widthforraskladka = 600
			let cellwh = widthforraskladka / xcellcount
			let CentrOsX = 1.5 * cellwh
			let CentrOsY = (ycellcount - 1) * cellwh
			let ysmeshenie = 0

			this.bluelines.push({x1: CentrOsX, y1: 0 + ysmeshenie, x2: CentrOsX, y2: ycellcount * cellwh + ysmeshenie})
			this.bluelines.push({x1: 0, y1: CentrOsY + ysmeshenie, x2: xcellcount * cellwh, y2: CentrOsY + ysmeshenie})
		},
		drawLists() {
			let xcellcount = this.calculateForm.width_1 + 3
			let ycellcount = this.calculateForm.height_1 + 2
			let widthforraskladka = 600
			let cellwh = widthforraskladka / xcellcount
			let CentrOsX = 1.5 * cellwh
			let CentrOsY = (ycellcount - 1)*cellwh
            this.heightraskladka = ycellcount * cellwh - cellwh + 70
			var x1,y1,x2,y2

            let current_skat = JSON.parse(this.skat_1)

			if(current_skat.arr_listy_classic != null) {
				for (var r = 0; r < current_skat.arr_listy_classic.length; r++)
				{
					let mj_arlisty = current_skat.arr_listy_classic[r]
					let mj_arlisty_len = current_skat.arr_listy_classic_len[r]
                    
					let lcounter = 0
					for (var i = 0; i < mj_arlisty.length; i++) {
						x1 = CentrOsX + mj_arlisty[i] * cellwh;
						y1 = CentrOsY + mj_arlisty[i+1]* cellwh;
						x2 = CentrOsX + mj_arlisty[i+2]* cellwh;
						y2 = CentrOsY + mj_arlisty[i+3]* cellwh;

						this.structure.push({x1: x1, y1: y1, x2: x2, y2: y1})
						this.structure.push({x1: x2, y1: y1, x2: x2, y2: y2})
						this.structure.push({x1: x2, y1: y2, x2: x1, y2: y2})
						this.structure.push({x1: x1, y1: y2, x2: x1, y2: y1})

						this.arrayTexts.push({ x: x1 - (x1-x2)/1.5, y: y1 - (y1-y2)/2, text: mj_arlisty_len[lcounter] + ""});
						i+=3;
						lcounter ++;

					}
				}
			}
		}
    }
}
</script>
<style lang="scss">
    .block_svg{
        display: flex;
        justify-content: space-between;

        .input_skat{
            display: flex;
            flex-direction: column;
        }

        .mb-2{
            margin-bottom: 2rem;
        }
    }
</style>