<template>
  <main class="main">
        <div class="section section--breadcrumbs">
          <div class="container">
            <div class="inner">
              <ul class="list list--flex breadcrumbs breadcrumbs--light">
                <li class="list-item"><a class="list-link" href="#">
                    <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                      <use xlink:href="@/assets/img/icons.svg#home"></use>
                    </svg></a></li>
                <li class="list-item"><a class="list-link" href="#">Каталог</a></li>
                <li class="list-item"><span class="list-current">Быстровозводимые здания</span></li>
              </ul>
            </div>
          </div>
        </div>
        <section 
            class="subhero" 
            style="color: #fff;"
            :style="{ 'background-image': 'url(' + require('@/assets/img/subhero-2.jpg') + ')' }"
        >
          <div class="container">
            <div class="inner">
              <h1>Быстровозводимые<br> здания в Самаре</h1>
              <p>ГК «МАЯК» – это собственное производство всех необходимых материалов<br> для строительства быстровозводимых зданий и сооружений.</p>
            </div>
          </div>
        </section>
        <div class="section section--no-pt section--cards section--cards-tabs">
          <div class="container">
            <div class="tabs tabs--buttons tabs--cards">
              <div class="tabs-title">
                <div class="tabs-item -active">Сельскохозяйственное<br> назначение</div>
                <div class="tabs-item">Здания промышленного<br> назначения</div>
                <div class="tabs-item">Логистические<br> и складские комплексы</div>
                <div class="tabs-item">Спортивные<br> сооружения</div>
                <div class="tabs-item">Торговые<br> сооружения</div>
              </div>
              <div class="tabs-content">
                <div class="tabs-item -active">
                  <ul class="list list--flex layout-cards -col-4">
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#harvest"></use>
                            </svg>
                          </div>
                          <div class="card-title">Овощехранилище</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#flour"></use>
                            </svg>
                          </div>
                          <div class="card-title">Зерносклад</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#cow"></use>
                            </svg>
                          </div>
                          <div class="card-title">Коровник</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#chicken"></use>
                            </svg>
                          </div>
                          <div class="card-title">Птицефабрика</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#pig"></use>
                            </svg>
                          </div>
                          <div class="card-title">Свиноферма</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                  </ul>
                </div>
                <div class="tabs-item">
                  <ul class="list list--flex layout-cards -col-4">
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#harvest"></use>
                            </svg>
                          </div>
                          <div class="card-title">Овощехранилище</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#flour"></use>
                            </svg>
                          </div>
                          <div class="card-title">Зерносклад</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#cow"></use>
                            </svg>
                          </div>
                          <div class="card-title">Коровник</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#chicken"></use>
                            </svg>
                          </div>
                          <div class="card-title">Птицефабрика</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#pig"></use>
                            </svg>
                          </div>
                          <div class="card-title">Свиноферма</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                  </ul>
                </div>
                <div class="tabs-item">
                  <ul class="list list--flex layout-cards -col-4">
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#harvest"></use>
                            </svg>
                          </div>
                          <div class="card-title">Овощехранилище</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#flour"></use>
                            </svg>
                          </div>
                          <div class="card-title">Зерносклад</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#cow"></use>
                            </svg>
                          </div>
                          <div class="card-title">Коровник</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#chicken"></use>
                            </svg>
                          </div>
                          <div class="card-title">Птицефабрика</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#pig"></use>
                            </svg>
                          </div>
                          <div class="card-title">Свиноферма</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                  </ul>
                </div>
                <div class="tabs-item">
                  <ul class="list list--flex layout-cards -col-4">
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#harvest"></use>
                            </svg>
                          </div>
                          <div class="card-title">Овощехранилище</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#flour"></use>
                            </svg>
                          </div>
                          <div class="card-title">Зерносклад</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#cow"></use>
                            </svg>
                          </div>
                          <div class="card-title">Коровник</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#chicken"></use>
                            </svg>
                          </div>
                          <div class="card-title">Птицефабрика</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#pig"></use>
                            </svg>
                          </div>
                          <div class="card-title">Свиноферма</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                  </ul>
                </div>
                <div class="tabs-item">
                  <ul class="list list--flex layout-cards -col-4">
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#harvest"></use>
                            </svg>
                          </div>
                          <div class="card-title">Овощехранилище</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#flour"></use>
                            </svg>
                          </div>
                          <div class="card-title">Зерносклад</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#cow"></use>
                            </svg>
                          </div>
                          <div class="card-title">Коровник</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#chicken"></use>
                            </svg>
                          </div>
                          <div class="card-title">Птицефабрика</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                    <li class="list-item"><a class="card" href="#">
                        <div class="card-heading">
                          <div class="card-icon">
                            <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                              <use xlink:href="@/assets/img/icons.svg#pig"></use>
                            </svg>
                          </div>
                          <div class="card-title">Свиноферма</div>
                        </div>
                        <div class="card-arrow">
                          <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                          </svg>
                        </div></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section section--no-pt section--catalog-calculator">
          <div class="container">
            <div class="calculator">
              <div class="calculator-section">
                <h2>Калькулятор стоимости здания</h2>
                <div class="calculator-row">
                  <div class="calculator-form">
                    <form class="form" action="#" onsubmit="return false">
                      <div class="form-fieldset">
                        <div class="form-group form-row">
                          <div class="form-item">
                            <div class="form-group">
                              <div class="form-item">
                                <div class="field" :class="{ 'error_select' : issetError('type') }">
                                  <div class="field-label" :class="{ 'error_label' : issetError('type') }">Назначение постройки</div>
                                  <choices-select
                                      data-select 
                                      :elements="[{id: 10, name: 'Сельхохозяйственное'}]" 
                                      v-model="form.type" 
                                      :defaultValue="'Выберите назначение'"
                                      :typeSelect="'default'"
                                      @change="removeError('type')"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="form-item">
                                <div class="field">
                                  <div class="field-label" :class="{ 'error_label' : issetError('width') }">Ширина</div>
                                  <div class="field-input">
                                    <input 
                                      type="number" 
                                      value="" 
                                      placeholder="0,0 м"
                                      step="0.1"
                                      v-model="form.width"
                                      :class="{ 'error_input' : issetError('width') }"
                                      @change="removeError('width')"
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="form-item">
                                <div class="field">
                                  <div class="field-label" :class="{ 'error_label' : issetError('email') }">Почта</div>
                                  <div class="field-input">
                                    <input 
                                      type="text" 
                                      value=""
                                      v-model="form.email"
                                      :class="{ 'error_input' : issetError('email') }"
                                      @keyup="removeError('email')"
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="form-item">
                                <div class="field">
                                  <div class="field-label" :class="{ 'error_label' : issetError('phone') }">Телефон</div>
                                  <div class="field-input">
                                    <the-mask 
                                      v-model="form.phone" 
                                      mask="+7 (###) ###-####" 
                                      placeholder="+7 (###) ###-####"
                                      type="tel"  
                                      :class="{ 'error_input' : issetError('phone') }"
                                      @change.native="removeError('phone')"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-item">
                            <div class="form-group">
                              <div class="form-item">
                                <div class="field">
                                  <div class="field-label" :class="{ 'error_label' : issetError('length') }">Длина</div>
                                  <div class="field-input">
                                    <input 
                                      type="number" 
                                      value="" 
                                      placeholder="0,0 м"
                                      step="0.1"
                                      v-model="form.length"
                                      :class="{ 'error_input' : issetError('length') }"
                                      @change="removeError('length')"
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="form-item">
                                <div class="field">
                                  <div class="field-label" :class="{ 'error_label' : issetError('height') }">Высота</div>
                                  <div class="field-input">
                                    <input 
                                      type="number" 
                                      value="" 
                                      placeholder="0,0 м"
                                      step="0.1"
                                      v-model="form.height"
                                      :class="{ 'error_input' : issetError('height') }"
                                      @change="removeError('height')"
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="form-item">
                                <div class="field">
                                  <div class="field-label" :class="{ 'error_label' : issetError('fio') }">ФИО</div>
                                  <div class="field-input">
                                    <input 
                                      type="text" 
                                      value=""
                                      v-model="form.fio"
                                      :class="{ 'error_input' : issetError('fio') }"
                                      @keyup="removeError('fio')"
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-item">
                            <ul class="list list--flex layout-buttons">
                              <!-- <li class="list-item">
                                <button class="button button--primary">Рассчитать</button>
                              </li> -->
                              <li class="list-item"><a class="button button--default" @click="sendForm" data-modal>Заказать рассчет</a></li>
                            </ul>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form-item">
                            <div class="label">Чтобы получить развернутый расчет стоимости, <a @click="isShowEmailValue = true">оставьте Ваши контакты</a>, мы свяжемся с Вами и вышлем расчет на почту</div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="calculator-preview">
                    <div><img src="@/assets/img/calc_krovlya.png" alt=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Преимущества «Маякметалл»</h2>
                </div>
              </div>
            </div>
            <div class="list-offset -col-1">
              <ul class="list list--flex layout-advantages -col-4">
                <li class="list-item">
                  <div class="advantage">
                    <div class="advantage-icon"><img src="@/assets/img/advantages/4.svg" alt=""></div>
                    <div class="advantage-title">Собственное производство</div>
                  </div>
                </li>
                <li class="list-item">
                  <div class="advantage">
                    <div class="advantage-icon"><img src="@/assets/img/advantages/5.svg" alt=""></div>
                    <div class="advantage-title">Полный цикл строительных работ</div>
                  </div>
                </li>
                <li class="list-item">
                  <div class="advantage">
                    <div class="advantage-icon"><img src="@/assets/img/advantages/6.svg" alt=""></div>
                    <div class="advantage-title">Проверенные проектные решения</div>
                  </div>
                </li>
                <li class="list-item">
                  <div class="advantage">
                    <div class="advantage-icon"><img src="@/assets/img/advantages/7.svg" alt=""></div>
                    <div class="advantage-title">Квалифицированный персонал</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading heading--no-offset">
                <div class="heading-section">
                  <h2>Этапы расчета</h2>
                </div>
              </div>
            </div>
            <div class="list-offset -col-1">
              <ul class="list list--flex layout-iterators">
                <li class="list-item"><a class="iterator" href="#">
                    <div class="iterator-number">1</div>
                    <div class="iterator-content">
                      <div class="iterator-title">Получаем заявку,<br> согласовываем условия</div>
                      <div class="iterator-description">Определяем сроки<br> и стоимость предстоящих<br> работ</div>
                    </div></a></li>
                <li class="list-item"><a class="iterator" href="#">
                    <div class="iterator-number">2</div>
                    <div class="iterator-content">
                      <div class="iterator-title">Заключаем<br> договор</div>
                      <div class="iterator-description">Наш профессионал<br> сделает качественные<br> замеры</div>
                    </div></a></li>
                <li class="list-item"><a class="iterator" href="#">
                    <div class="iterator-number">3</div>
                    <div class="iterator-content">
                      <div class="iterator-title">Разрабатываем<br> проект</div>
                      <div class="iterator-description">Проектировщики<br> разработают проект по<br> точным параметрам.</div>
                    </div></a></li>
                <li class="list-item"><a class="iterator" href="#">
                    <div class="iterator-number">4</div>
                    <div class="iterator-content">
                      <div class="iterator-title">Производим<br> материал</div>
                      <div class="iterator-description">Профессиональные<br> строители выполнят<br> полный комплекс</div>
                    </div></a></li>
                <li class="list-item"><a class="iterator" href="#">
                    <div class="iterator-number">5</div>
                    <div class="iterator-content">
                      <div class="iterator-title">Получаем заявку,<br> согласовываем условия</div>
                      <div class="iterator-description">Определяем сроки<br> и стоимость предстоящих<br> работ</div>
                    </div></a></li>
                <li class="list-item"><a class="iterator" href="#">
                    <div class="iterator-number">6</div>
                    <div class="iterator-content">
                      <div class="iterator-title">Заключаем<br> договор</div>
                      <div class="iterator-description">Наш профессионал<br> сделает качественные<br> замеры</div>
                    </div></a></li>
                <li class="list-item"><a class="iterator" href="#">
                    <div class="iterator-number">7</div>
                    <div class="iterator-content">
                      <div class="iterator-title">Разрабатываем<br> проект</div>
                      <div class="iterator-description">Проектировщики<br> разработают проект по<br> точным параметрам.</div>
                    </div></a></li>
                <li class="list-item"><a class="iterator" href="#">
                    <div class="iterator-number">8</div>
                    <div class="iterator-content">
                      <div class="iterator-title">Производим<br> материал</div>
                      <div class="iterator-description">Профессиональные<br> строители выполнят<br> полный комплекс</div>
                    </div></a></li>
              </ul>
            </div>
          </div>
        </div>

        <objects-blocks v-if="serviсes != null" />

        <!-- <reviews-blocks v-if="serviсes != null" /> -->
        
        <consultation v-if="serviсes.consultation != null" />

        <div class="section">
          <div class="container">
            <div class="inner">
              <div class="row row--contentframe">
                <div class="col">
                  <h2>Быстровозводимые<br> здания в Самаре</h2>
                </div>
                <div class="col">
                  <p>Одним из направлений деятельности компании «Маяк» является проектирование и строительство быстровозводимых зданий и производство сооружений каркасного типа из легких металлоконструкций с выполнением полного комплекса работ.</p>
                  <p>Группа компаний «Маяк» на основе металлоконструкций производит быстровозводимые здания широкого спектра и занимается строительством ангаров, складов, павильонов, торговых центров, а также промышленных зданий и модульных быстровозводимых зданий и сельскохозяйственных сооружений.</p>
                  <p>Металлоконструкции выполняют роль несущих частей в зданиях, благодаря чему выполняется необходимая функциональность и надежность.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
</template>
<script>
import Consultation from '../services/consultation.vue'
import ObjectsBlocks from '../services/objectsBlocks.vue'
// import ReviewsBlocks from '../services/reviewsBlocks.vue'
import choicesSelect from '../../choices-select/choices-select.vue'
import Vuex from 'vuex'
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)


export default {
  components: {
      Consultation,
      ObjectsBlocks,
      // ReviewsBlocks,
      choicesSelect,
  },
  data() {
    return {
      isShowEmailValue: false,
      form: {
        type: "",
        length: null,
        height: null,
        width: null,
        email: "",
        phone: null,
        fio: null,
      },
      requireValues: [
        'type',
        'length',
        'height',
        'width',
        'email',
        'phone',
        'fio',
      ],
      arrayError: [],
    }
  },
  computed: {
      ...Vuex.mapGetters({
          serviсes: 'app/serviсes',
          pagesServices: 'dictionary/pagesServices',
          user: 'auth/user',
          isAuthenticated: 'auth/isAuthenticated',
      }),
  },
  mounted() {
    this.checkAuth()
  },
  methods: {
    ...Vuex.mapActions({
      sentApp: 'appmodal/sentApp',
    }),
    ...Vuex.mapMutations({
      setShowSendedModal: 'app/setShowSendedModal',
    }),
    removeError(string) {
        this.arrayError.map((element, idx) => {
          if (element == string) {
            this.arrayError.splice(idx, 1)
          }
        })
    },
    issetError(string) {
      let is_error = false
      this.arrayError.map((element) => {
        if (element == string) {
          is_error = true
        }
      })
      return is_error
    },
    validation() {
      let is_val = true
      
      this.requireValues.map((element) => {
        if (this.form[element] == null || this.form[element] == "") {
          if (!this.issetError(element)) {
            this.arrayError.push(element)
          }
          is_val = false
        }else{
          this.removeError(element)
        }
      })

      return is_val
    },
    sendForm() {
      if (!this.validation()) {
        return
      }
      var obj = {}
      obj.email = this.form.email
      obj.name = 'Калькулятор стоимости здания'
      if (this.form.phone) {
        obj.telephone = '+7' + this.form.phone
      } else {
        obj.telephone = this.form.phone
      }
      // obj.bannerTitle = ''
      // obj.pageTitle = '' 
      obj.type = 'backcall'
      obj.message = 'ФИО: ' + this.form.fio + ', Назначение постройки: Сельхохозяйственное, Длина: ' + this.form.length + 'м , Ширина: ' + this.form.width + 'м , Высота: ' + this.form.height + 'м'
      obj.file = ''
      this.sentApp(obj)
        .then(() => {
          this.setShowSendedModal(true)
        })
    },
    checkAuth() {
      if (!this.isAuthenticated) {
        return
      }
      this.form.email = this.user.email
      this.form.fio = this.user.surname + ' ' + this.user.name + ' ' + this.user.midname
      this.form.pnone = this.user.phone
    },
  },
}
</script>
<style lang="scss">
  .error_input{
    border-color: red !important;
  }
  .error_label{
    color: red !important;
  }
  .error_select{
    .choices__item{
      border-color: red !important;
    }
  }
</style>