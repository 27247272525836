<template>
    <div class="admin-index-catalog">
        <h4>Категории каталога</h4>
        <button 
            v-if="!isShowForm"
            @click="isShowForm = true"
        >
            Добавить категорию
        </button>


        <div 
            v-if="isShowForm"
            class="single-catalog"
        >
            <label>Id1c</label>        
            <input 
                type="text" 
                v-model="newCategory.id_1c"
            >
            
            <label>Название</label>
            <input 
                type="text" 
                v-model="newCategory.name" 
            >
            
            <label>Сортировка</label>
            <input 
                type="text" 
                v-model="newCategory.sort" 
            >
            
            <label>Ссылка на изображение</label>
            <input 
                type="text" 
                v-model="newCategory.link" 
            >

            <div class="row">
                <label class="fl__img">
                    <input 
                        ref="fileDefaultCategory" 
                        type="file" 
                        @change="uploadImg"
                    >
                </label>
                <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
            </div>

            <div 
                v-if="newCategory.link"
                class="img_block" 
            >
                <img 
                    :src="newCategory.link" 
                    style="max-width: 565px;"
                    alt=""
                >
            </div>

            <button
                @click="createCategory"
            >
                Сохранить
            </button>
        </div>

        <catalog-category 
            v-for="(category, idx) in uiCategories" 
            :key="idx" 
            :element="category"
            :index="idx"
        />
    </div>
</template>
<script>
import Vuex from 'vuex'
import CatalogCategory from './catalogCategory.vue'

export default {
    components: {
        CatalogCategory,
    },
    data() {
        return {
            newCategory: {
                id_1c: null,
                name: null,
                sort: null,
                link: null,
            },
            isShowForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiCategories: 'ui_category/categories',
            fileUrl: 'app/fileUrl',
            homeCategoryBanners: 'app/homeCategoryBanners',
        }),
    },
    created() {
        this.getUiCategories()
    },
    methods: {
        ...Vuex.mapActions({
            getUiCategories: 'ui_category/getCategories',
            createUiCategory: 'ui_category/createCategory',
            upload: 'app/upload',
        }),
        createCategory() {
            this.createUiCategory(this.newCategory)
                .then(() => {
                    this.newCategory.id_1c = null
                    this.newCategory.name = null
                    this.newCategory.sort = null
                    this.newCategory.link = null
                    this.isShowForm = false
                })
        },
        uploadImg() {
            let img = this.$refs['fileDefaultCategory'].files[0]
            this.upload({file: img})
                .then((response) => {
                    this.newCategory.link = this.fileUrl + response.data[0].link
                })
        },
    },
}
</script>