<template>
    <main class="admin-index index__admin" style="display: block">
        <div class="admin-index_header">
            <h3>Пользователи</h3>
            <button 
                class="button button--primary save_btn"
                @click="openAddUserModal"
            >
                Добавить
            </button>
        </div>
        <div class="content">
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <td>ID 1C</td>
                    <td>ФИО</td>
                    <td>Почта</td>
                    <td>ИНН</td>
                    <td>КПП</td>
                    <td>Адрес</td>
                    <td>Телефон</td>
                    <td>Тип аккаунта</td>
                    <td>Название организации</td>
                </tr>
                <tr v-for="user in users" :key="user.id">
                    <td>{{ user.id_1c }}</td>
                    <td>{{ getFullName(user) }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.inn }}</td>
                    <td>{{ user.kpp }}</td>
                    <td>{{ user.adress }}</td>
                    <td>{{ user.phone }}</td>
                    <td>{{ getNameByAccountType(user.account_type) }}</td>
                    <td>{{ user.organization_name }}</td>
                </tr>
            </table>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'

export default {
    computed: {
        ...Vuex.mapGetters({
            users: 'users/users',
        }),
    },
    created() {
        this.getUsers()
    },
    methods: {
        ...Vuex.mapActions({
            getUsers: 'users/getUsers',
        }),
        ...Vuex.mapMutations({
            setShowAddUserModal: 'app/setShowAddUserModal',
        }),
        getFullName(user) {
            let str = ''

            if (user.surname) {
                str += user.surname + ' '
            }
            if (user.name) {
                str += user.name + ' '
            }
            if (user.midname) {
                str += user.midname + ' '
            }
            return str
        },
        getNameByAccountType(type) {
            let name = null

            if (type == 10) {
                name = 'Частное лицо'
            }else if (type == 20) {
                name = 'Юридическое лицо'
            }else if (type == 30) {
                name = 'Дилер'
            }
            
            return name
        },
        openAddUserModal() {
            this.setShowAddUserModal(true)
        },
    }
}
</script>
<style scoped src="@/css/nlk-style.css"></style>