<template>
    <main class="admin-index index__admin">
        <div class="admin-index_header">
            <h3>Стань партнером</h3>
        </div>
        <div  class="index__admin">
            <button v-if="!isShowForm" @click="isShowForm = true">
                Добавить преимущество
            </button>

            <div
                v-if="isShowForm"
                class="admin-documents_single-benefit index__admin"
            >
                <select 
                    v-model="newPartner.roles"
                    name="listTypes" 
                    class="list_type"
                >
                    <option 
                        v-for="type in listBenefitTypes" 
                        :key="type.id"
                        :value="type.id" 
                    >
                        {{ type.name }}
                    </option>
                </select>

                <label>Название</label>
                <input 
                    v-model="newPartner.name"
                    type="text"
                    placeholder="Название"
                >

                <label>Ссылка на изображение</label>
                <input 
                    type="text" 
                    v-model="newPartner.link"
                    placeholder="Ссылка на изображение" 
                >
                <div class="row">
                    <label class="fl__img">
                        <input 
                            ref="defaultFilePartner" 
                            type="file" 
                            @change="uploadFile"
                        >
                    </label>
                    <p>
                        Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. 
                        Для правильного отображения соблюдайте размер и формат
                    </p>
                </div>
                <div 
                    v-if="newPartner.link"
                    class="img_block"
                >
                    <!-- <img 
                        v-if="editElement.imgType != 'image/svg+xml'"
                        :id="'fileDisplay' + index"
                        :src="editElement.img" 
                        alt=""
                    > -->
                    <div
                        v-html="svgCode"
                    />
                </div>
                
                <button 
                    @click="createPartner"
                >
                    Сохранить
                </button>
            </div>

            <single-benefit 
                v-for="(benefit, idx) in uiPartners"
                :key="idx"
                :index="idx"
                :element="benefit"
            />
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import singleBenefit from './singleBenefit.vue'
export default {
    components: {
        singleBenefit,
    },
    data () {
        return {
            isShowForm: false,
            newPartner: {
                link: null,
                name: null,
                root_id: null,
                roles: 0,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            becomePartner: 'app/becomePartner',
            listBenefitTypes: 'dictionary/listBenefitTypes',
            fileUrl: 'app/fileUrl',
            uiPartners: 'ui_partner/partners',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            createUiPartner: 'ui_partner/createPartner',
        }),
        ...Vuex.mapMutations({
            addBenefitBecomePartner: 'app/addBenefitBecomePartner',
        }),
        createPartner() {
            this.createUiPartner(this.newPartner)
                .then(() => {
                    this.isShowForm = false
                    this.newPartner.link = null
                    this.newPartner.name = null
                    this.newPartner.root_id = null
                    this.newPartner.roles = 0
                }) 
        },
        uploadFile() {
            let img = this.$refs['defaultFilePartner'].files[0]
            this.upload({file: img})
                .then((response) => {
                    this.newPartner.link = this.fileUrl + response.data[0].link
                })
        },
    },
}
</script>