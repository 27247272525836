<template>
    <div class="index__admin" >
        <span>Причина № {{index + 1}}</span>
        <span 
            class="index__del" 
            @click="deleteUiReason(element.id)" 
        >
            Удалить Х
        </span>
        
        <label>Заголовок</label>
        <input 
            type="text" 
            v-model="editElement.name" 
            @change="update" 
            placeholder="Заголовок"
        >
        
        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="editElement.link"
            placeholder="Ссылка на изображение"
            @change="update" 
        >
        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'singleFileReason'+ index" 
                    type="file" 
                    @change="uploadImg()"
                >
            </label>
            <p> Изображение должно быть размером 60х60 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат. </p>
        </div>
        <img 
            :src="editElement.link" 
            alt=""
        >
    </div>
</template>
<script>
import Vuex from 'vuex'
export default {
     components: {
    },
    props:['element', 'index'],
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            updateUiReason: 'ui_reason/updateReason',
            deleteUiReason: 'ui_reason/deleteReason',
        }),
        update() {
            this.updateUiReason(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['singleFileReason' + this.index].files[0]
            
            this.upload({file: image})
                .then((response) => {
                    this.updateUiReason({
                        id: this.editElement.id,
                        name: this.editElement.name,
                        root_id: this.editElement.root_id,
                        link: this.fileUrl + response.data[0].link
                    })
                })
        },
    },
}
</script>