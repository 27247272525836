<template>
    <main class="nlk-content admin-index index__admin admin_utm_page" style="display: block">
        <div class="admin-index_header">
            <h3>UTM</h3>
            <button 
                class="button button--primary save_btn"
                @click="openCreateUtmModal"
            >
                Добавить
            </button>
        </div>

        <form class="nlk-filter">
            <div class="field">
                <div class="field-label">Регион</div>
                <div class="nlk-field-select">
                    <select v-model="searchForm.root_1c_id" class="region_utm_select" @change="search">
                        <option :value="''">Выберите регион</option>
                        <option 
                            :value="region.id_1c" 
                            v-for="region in topLevelCategory" 
                            :key="region.id"
                        >
                            {{ region.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="field">
                <div class="field-label">Utm</div>
                <div class="field-input">
                    <input type="text" placeholder="utm" value="" v-model="searchForm.utm" @keyup="search">
                </div>
            </div>
            <div class="field">
                <div class="field-label">Phone</div>
                <div class="field-input">
                    <input type="text" placeholder="phone" value="" v-model="searchForm.phone" @keyup="search">
                </div>
            </div>
            <div class="field">
                <div class="field-label">Email</div>
                <div class="field-input">
                    <input type="text" placeholder="phone" value="" v-model="searchForm.email" @keyup="search">
                </div>
            </div>
        </form>

        <div class="content">
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <td>ID</td>
                    <td id="header_phone_td" :style="'max-width:' + widthPhoneTd + 'px'">Телефон</td>
                    <td id="header_root1c_td" :style="'max-width:' + widthRoot1cTd + 'px'">Корневой каталог</td>
                    <td id="header_utm_td" :style="'max-width:' + widthUtmTd + 'px'">Utm</td>
                    <td id="header_email_td" :style="'max-width:' + widthEmailTd + 'px'">Email</td>
                    <td></td>
                    <td></td>
                </tr>
                <single-utm 
                    v-for="position in positions" 
                    :key="position.id" 
                    :position="position" 
                    :changeRowId="changeRowId"
                    :widthUtmTd="widthUtmTd - 100"
                    :widthPhoneTd="widthPhoneTd - 100"
                    :widthRoot1cTd="widthRoot1cTd - 100"
                    :widthEmailTd="widthEmailTd - 100"
                    @setRowId="setRowId"
                    @searchUtm="searchUtm"
                />
            </table>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import singleUtm from './single-utm.vue'

export default {
    components: {
        singleUtm,
    },
    data() {
        return {
            status: '',
            createForm: {
                phone: "",
                root_1c_id: "",
                utm: "",
                email: "",
            },
            widthUtmTd: null,
            widthPhoneTd: null,
            widthRoot1cTd: null,
            widthEmailTd: null,
            changeRowId: '',
        }
    },
    computed: {
        ...Vuex.mapGetters({
            positions: 'utm/utmPositions',
            form: 'utm/form',
            topLevelCategory: 'dictionary/topLevelCategory',
        }),
        searchForm() {
            return Object.assign({}, this.form)
        },
    },
    mounted() {
        this.setMountedWidth()
    },
    created() {
        this.searchUtm()
    },
    methods: {
        ...Vuex.mapActions({
            searchUtm: 'utm/search',
            createUtm: 'utm/createUtm',
        }),
        ...Vuex.mapMutations({
            setCreateUtmModal: 'app/setCreateUtmModal',
            updateSearchForm: 'utm/updateSearchForm',
        }),
        setRowId(id) {
            this.changeRowId = id
        },
        setMountedWidth() {
            let widthUtm = document.getElementById('header_utm_td').offsetWidth
            this.widthUtmTd = widthUtm
            let widthPhone = document.getElementById('header_phone_td').offsetWidth
            this.widthPhoneTd = widthPhone
            let widthRoot1c = document.getElementById('header_root1c_td').offsetWidth
            this.widthRoot1cTd = widthRoot1c
            let widthEmail = document.getElementById('header_email_td').offsetWidth
            this.widthRoot1cTd = widthEmail
        },
        create() {
            this.createUtm(this.createForm)
                .then(() => {
                    this.searchUtm()
                    this.createForm.phone = ""
                    this.createForm.root_1c_id = ""
                    this.createForm.utm = ""
                    this.createForm.email = ""
                })
        },
        openCreateUtmModal() {
            this.setCreateUtmModal(true)
        },
        updateForm() {
            this.updateSearchForm(this.searchForm)
        },
        search() {
            this.updateForm()
            this.searchUtm()
        },
    }
}
</script>
<style scoped src="@/css/nlk-style.css"></style>
<style lang="scss">
    .admin_utm_page{
        .region_utm_select{
            height: 5.5rem; 
            padding-right: 35px !important;
        }

        i{
            color: red;
            &:hover{
                cursor: pointer;
            }
        }
    }
</style>