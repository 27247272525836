<template>
    <div 
        class="good-col nd_good_col"
        :class="{'good-col_color' : parameter.type == 30}"
    >
        <div 
            class="field" 
            :class="(issetCurrentParameterInErrorArray ? 'select_red_error' : '')" v-if="parameter.type != 30"
        >
            <div class="field-label" :class="(issetCurrentParameterInErrorArray ? 'label_red_error' : '')">{{ (parameter.placeholder ? parameter.placeholder : parameter.name) }}</div>
            <div 
                class="field-input" 
                :class="{'disable-select' : currentList.length == 0}"
                @click="getList"
            >
                <choices-select
                    v-if="parameter.type == 20 && !isSubSign && defaultValue"
                    data-select 
                    :elements="currentList" 
                    v-model="value" 
                    :defaultValue="defaultValue" 
                    @change="changedValues"
                    :typeSelect="'default'" 
                    :typeOfElementList="typeOfElementList"
                />
                
                <choices-select
                    v-if="parameter.type == 20 && isSubSign && defaultValue"
                    data-select 
                    :elements="currentList" 
                    v-model="value" 
                    :defaultValue="defaultValue" 
                    @change="changedValues"
                    :typeSelect="'default'" 
                    :isDisableList="isDisableList"
                    :typeOfElementList="typeOfElementList"
                />
                <input 
                    v-if="parameter.type == 10"
                    type="text" 
                    :placeholder="placeholder"
                    v-model="value"
                    @change="checkValue"
                    @keyup="updateFormCardProduct"
                >
            </div>
        </div>

        <div v-if="parameter.type == 30 && (localArrayColors.length != 0 || arrayCardProductColors.length != 0) && currentNameParameter == 'color'">
            <div class="label" :class="(!form['colors'] ? 'label_red_error' : '')">{{ (parameter.placeholder ? parameter.placeholder : parameter.name) }} {{ color }} {{ colorName }}</div>
            <ul 
                v-if="localArrayColors.length != 0"
                class="list list--flex layout-colors"
            >
                <li 
                    class="list-item" 
                    v-for="cardColor in localArrayColors" 
                    :key="cardColor.id" 
                >
                    <label class="color">
                        <input 
                            type="radio"
                            :name="'color' + numBlockParameters" 
                            @click="setColor(cardColor)"  
                            :checked="(color == cardColor.id_1c ? true : false)"
                        >
                        <span 
                            v-if="cardColor.code"
                            class="color-preview" 
                            :style="'border-color: ' + cardColor.code + '; background: ' + cardColor.code + ';'"
                        >
                            <span 
                                :style="'border-color: ' + cardColor.code + ';'"
                            />
                        </span>
                        <img 
                            v-else 
                            class="color-preview color_img_replace_span" 
                            :src="yandexCloud + cardColor.link"
                        >
                        <span class="color-label">{{ cardColor.id_1c }}</span>
                    </label>
                </li>
            </ul>
            <ul 
                v-else
                class="list list--flex layout-colors"
            >
                <li 
                    class="list-item" 
                    v-for="cardColor in arrayCardProductColors" 
                    :key="cardColor.id" 
                >
                    <label class="color">
                        <input 
                            type="radio"
                            name="color3" 
                            @click="setColor(cardColor)"  
                            :checked="(color == cardColor.id_1c ? true : false)"
                        >
                        <span 
                            v-if="cardColor.code"
                            class="color-preview" 
                            :style="'border-color: ' + cardColor.code + '; background: ' + cardColor.code + ';'"
                        >
                            <span 
                                :style="'border-color: ' + cardColor.code + ';'"
                            />
                        </span>
                        <span 
                            v-else
                            class="color-preview" 
                            :style="'border-color: ' + cardColor.code + '; background: ' + cardColor.code + ';'"
                        >
                            <span 
                                style="border-color: rgb(161, 161, 160)"
                            />
                            <img  
                                :src="yandexCloud + cardColor.link"
                            >
                        </span>
                        <span class="color-label">{{ cardColor.id_1c }}</span>
                    </label>
                </li>
            </ul>

            <button
                @click="setColor()"
                class="button button--default mt-3"
                type="button"
            >
                Сбросить цвет
            </button>
        </div>

        <div v-if="parameter.type == 30 && arrayCardProductColorsIn.length != 0 && currentNameParameter == 'colors_in'">
            <div class="label" :class="(!form['colors_in'] ? 'label_red_error' : '')">{{ (parameter.placeholder ? parameter.placeholder : parameter.name) }} {{ color }} {{ colorName }}</div>
            <ul class="list list--flex layout-colors">
                <li 
                    class="list-item" 
                    v-for="cardColor in arrayCardProductColorsIn" 
                    :key="cardColor.id" 
                >
                    
                    <label class="color">
                        <input 
                            type="radio"
                            name="color2" 
                            @click="setColor(cardColor)"  
                            :checked="(color == cardColor.id_1c ? true : false)"
                        >
                        <span 
                            v-if="cardColor.code"
                            class="color-preview" 
                            :style="'border-color: ' + cardColor.code + '; background: ' + cardColor.code + ';'"
                        >
                            <span 
                                :style="'border-color: ' + cardColor.code + ';'"
                            />
                        </span>
                        <img 
                            v-else 
                            class="color-preview" 
                            :src="yandexCloud + cardColor.link"
                        >
                        <span class="color-label">{{ cardColor.id_1c }}</span>
                    </label>
                </li>
            </ul>

            <button
                @click="setColor()"
                class="button button--default mt-3"
                type="button"
            >
                Сбросить цвет
            </button>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import choicesSelect from '@/components/choices-select/choices-select.vue'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    props: {
        parameter: {
            type: Object,
            default: () => {}
        },
        product: {
            type: Object,
            default: () => {}
        },
        changeParameter: {
            type: String,
            default: ""
        },
        form: {
            type: Object,
            default: () => {}
        },
        cardForm: {
            type: Object,
            default: () => {}
        },
        isSubSign: {
            type: Boolean,
            default: false,
        },
        subElementsProuducts: {
            type: Array,
            default: () => []
        },
        typeCard: {
            type: String,
            default: null
        },
        localArrayColors: {
            type: Array,
            default: () => []
        },
        numBlockParameters: {
            type: Number,
            default: 1
        },
        parameterErrorArray: {
            type: Array,
            default: () => []
        },
    },
    components: {
        choicesSelect,
    },
    data() {
        return {
            color: null,
            colorName: null,
            defaultValue: null,
            valueFromProductList: [],
            currentChangeParameterName: null,
            subElements: [],
            isDisableList: true,
            localValue: '',
            currentList: [],
            currentColorsArray: [],
            typeOfElementList: "",
        }
    },
    computed: {
        ...Vuex.mapGetters({
            cardColors: 'catalog/cardColors',
            newPosition: 'basket/newPosition',
            arrayCardProductColors: 'catalog/arrayCardProductColors',
            arrayCardProductColorsIn: 'catalog/arrayCardProductColorsIn',
            yandexCloud: 'app/yandexCloud',
            filters: 'catalog/filters',
            cardProductErrorArray: 'catalog/cardProductErrorArray',
        }),
        issetCurrentParameterInErrorArray() {
            let isset = false
            let array = []
            if (this.parameterErrorArray.length != 0) {
                array = this.parameterErrorArray
            }else{
                array = this.cardProductErrorArray
            }
            array.map((element) => {
                if (element == this.currentNameParameter) {
                    isset = true
                }
            })

            // if (this.product.is_group == false && this.product[this.currentNameParameter].length == 0) {
            //     isset = false
            // }

            // if (this.parameter.type == 10 && this.value == null) {
            //     isset = true
            // }else if (this.parameter.type == 20 && this.product[this.currentNameParameter].length != 0 && this.value == null) {
            //     isset = true
            // }else{
            //     isset = false
            // }

            return isset
        },
        currentNameParameter() {
            let name = this.parameter.base_name
            let lower_name = name[0].toLowerCase() + name.substring(1)

            lower_name = lower_name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

            lower_name = lower_name.trim()
            return lower_name
        },
        // currentColorsArray() {
        //     let array = []
        //     let local_filters = this.filters['colors']
        //     for (let property in local_filters) {
        //         array.push(local_filters[property])
        //     }
        //     return array
        // },
        value: {
            get() {
                let name = this.parameter.base_name
                let lower_name = name[0].toLowerCase() + name.substring(1)
                let response = null
                if (this.parameter.type == 20 && this.isSubSign) {
                    response = 'Выберите ' + this.getWordForList(this.parameter)
                }else{
                    response = this.newPosition[lower_name]
                }
                return response
            },
            set(val) {
                let name = this.parameter.base_name
                let lower_name = name[0].toLowerCase() + name.substring(1)
                if (lower_name == 'length' || lower_name == 'width' || this.currentNameParameter == 'diameter' || this.currentNameParameter == 'shelf_a' || this.currentNameParameter == 'shelf_b') {
                    val = Number(val)
                }
                this.localValue = val
                this.currentChangeParameterName = lower_name
                if (val) {
                    if ((typeof val === 'string' && val.indexOf('Выберите') == 0)) {
                        if (this.currentNameParameter == 'length_of_characteristics') {
                            this.form[this.currentNameParameter] = null
                            this.changeNewPosition({type: 'length', value: null})
                        }else{
                            this.form[this.currentNameParameter] = null
                            this.changeNewPosition({type: this.currentNameParameter, value: null})
                        }
                    }else{
                        if (this.currentNameParameter == 'length_of_characteristics') {
                            this.form['length_of_characteristics'] = val
                            this.changeNewPosition({type: 'length', value: val})
                        }else{
                            this.form[this.currentNameParameter] = val
                            this.changeNewPosition({type: this.currentNameParameter, value: val})
                            // this.$emit('updateLocalForm', this.currentNameParameter, val)
                        }
                    }
                }else{
                    this.form[this.currentNameParameter] = null
                    this.changeNewPosition({type: this.currentNameParameter, value: null})
                    // this.$emit('updateLocalForm', this.currentNameParameter, null)
                }

                // this.$emit('getSubElements', lower_name)
                this.$emit('updateCalculatorPosition', lower_name, val)
            }
        },
        valueFromProduct() {
            let name = this.parameter.base_name
            let lower_name = name[0].toLowerCase() + name.substring(1)
            let array = []
            if (!this.product.is_sub_sign) {
                if (this.parameter.type == 20) {
                    if (Array.isArray(this.product[this.currentNameParameter])) {
                        let param = this.product[this.currentNameParameter]
                        param.map((element) => {
                            array.push({id: element, name: element})
                        })
                    }else if (Number(this.product[this.currentNameParameter])) {
                        array = [{id: Number(this.product[this.currentNameParameter]), name: this.product[this.currentNameParameter]}]
                    }else{
                        array = [{id: this.product[this.currentNameParameter], name: this.product[this.currentNameParameter]}]
                    }
                    if (array.length == 1) {
                        this.setDefaultValue(array[0].name)
                        if (this.currentNameParameter == 'length_of_characteristics') {
                            this.changeNewPosition({type: 'length', value: array[0].name})
                        }else{
                            this.changeNewPosition({type: this.currentNameParameter, value: array[0].name})
                        }
                    }
                    return array
                }
            }else{
                this.subElements.map((element) => {
                    if (!this.isIssetInArray(array, 'Выберите ' + this.parameter.name)) {
                        array.push({id: 'Выберите ' + this.parameter.name, name: 'Выберите ' + this.parameter.name})
                    }
                    if (!this.isIssetInArray(array, element[this.currentNameParameter]) && element[this.currentNameParameter] != null && element[this.currentNameParameter] != 'null') {
                        array.push({id: element[this.currentNameParameter] + "", name: element[this.currentNameParameter] + ""})
                    }
                })

                return array
            }
            
            return this.product[lower_name]
        },
        minValue() {
            let name = this.parameter.base_name
            let lower_name = name[0].toLowerCase() + name.substring(1) 
            let min = 0

            if (this.product) {
                if (this.product['min_normative_' + lower_name]) {
                    min = this.product['min_normative_' + lower_name]
                }else if (this.product['minimum_' + lower_name]) {
                    min = this.product['minimum_' + lower_name] 
                }
            }

            return min
        },
        maxValue() {
            let name = this.parameter.base_name
            let lower_name = name[0].toLowerCase() + name.substring(1) 
            let max = 0
            if (this.product) {
                if (this.product['max_normative_' + lower_name]) {
                    max = this.product['max_normative_' + lower_name]
                }else if (this.product['maximum_' + lower_name]) {
                    max = this.product['maximum_' + lower_name] 
                }
            }
            return max
        },
        placeholder() {
            let str = ''
            if (this.minValue && this.minValue != null && this.minValue != 0) {
                str += 'от ' + this.minValue + ' '
            }

            if (this.maxValue && this.maxValue != null && this.maxValue != 0) {
                str += 'до ' + this.maxValue
            }
            

            return str
        },
        productId() {
            return this.$route.params.id
        },
    },
    watch: {
        minValue() {
            this.checkValue()
        },
        maxValue() {
            this.checkValue()
        },
    },
    mounted() {
        if (this.isSubSign != true && this.parameter.type == 20) {
            if (this.product && this.product[this.currentNameParameter].length != 0) {
                this.setDefaultValue(this.product[this.currentNameParameter])
            }else{
                this.setDefaultValue('Выберите ' + this.getWordForList(this.parameter))
            }
        }else{
            this.setDefaultValue('Выберите ' + this.getWordForList(this.parameter))
        }
    },
    created() {
        this.setDefaultValueLocal()
    },
    methods: {
        ...Vuex.mapMutations({
            changeNewPosition: 'basket/changeNewPosition',
            setColorCardProduct: 'catalog/setColorCardProduct',
        }),
        ...Vuex.mapActions({
            calculate: 'basket/calculate',
            searchNavbar: 'catalog/searchNavbar',
            getColorsArray: 'catalog/getColorsArray',
            getFilters: 'catalog/getFilters',
        }),
        changedValues() {
            this.$emit('getSubElements', this.currentNameParameter)
        },
        setCurrentList() {
            let array = []
            if (!this.filters) {
                return
            }
            let local_filters = this.filters[this.currentNameParameter]
            array.push({id: 'Выберите ' + this.parameter.name, name: 'Выберите ' + this.getWordForList(this.parameter)})
            for (let property in local_filters) {
                if (local_filters[property]) {
                    this.typeOfElementList = typeof local_filters[property]
                    array.push({id: local_filters[property], name: local_filters[property]})
                }else if (typeof local_filters[property] === 'number'){
                    let fixedPrice = 0
                    fixedPrice = ( (local_filters[property].toString().includes('.')) ? (local_filters[property].toString().split('.').pop().length) : (0) )
                    fixedPrice = (fixedPrice >= 2 ? local_filters[property].toFixed(2) : local_filters[property])
                    if (fixedPrice > 0) {
                        array.push({id: fixedPrice, name: fixedPrice})
                    }
                }else{
                    if (property) {
                        array.push({id: property, name: property})
                    }
                }
            }
            this.currentList = array
        },
        setCurrentValue() {
            if (this.product[this.currentNameParameter]) {
                if (this.product[this.currentNameParameter].length != 0) {
                    this.currentList = [{id: this.product[this.currentNameParameter], name: this.product[this.currentNameParameter]}]
                }
            }
        },
        setDefaultValueLocal() {
            this.localValue = 'Выберите ' + this.getWordForList(this.parameter)
        },
        setColor(cardColor) {
            if (!cardColor) {
                this.color = null
                this.colorName = null
                if (this.currentNameParameter == 'color') {
                    this.changeNewPosition({type: 'color', value: null})
                    this.changeNewPosition({type: 'color_external', value: null})

                }else if (this.currentNameParameter == 'colors_in'){
                    this.changeNewPosition({type: 'color_inner', value: null})
                }
                this.$emit('getSubElements', this.currentNameParameter)
                this.$emit('setPositionColor', {}, this.currentNameParameter)
                this.setColorCardProduct(null)  

                return
            }
            this.color = cardColor.id_1c
            this.colorName = cardColor.name
            this.$emit('setPositionColor', cardColor, this.currentNameParameter)
            if (this.currentNameParameter == 'color') {
                    this.changeNewPosition({type: 'color', value: cardColor.id_1c})
                    this.changeNewPosition({type: 'color_external', value: cardColor.id_1c})

                }else if (this.currentNameParameter == 'colors_in'){
                    this.changeNewPosition({type: 'color_inner', value: cardColor.id_1c})
                }
            this.$emit('getSubElements', this.currentNameParameter)
            if (this.parameter.base_name != 'Color') {
                return
            }
            this.setColorCardProduct(cardColor.code)           
        },
        setDefaultValue(val) {
            this.value = val
            this.defaultValue = val
        },
        isIssetInArray(array, value) {
            let response = false
            array.map((element) => {
                if (element.name == value) {
                    response = true
                }
            })
            return response
        },
        setList() {
            this.setColorsArray('colors')
        },
        getList() {
            if (!this.isSubSign) {
                this.setCurrentValue()
                return
            }
            this.currentList = []
            this.valueFromProductList = []
            let object = JSON.parse(JSON.stringify(this.form))
            object[this.currentNameParameter] = null
            this.getFilters(object)
                .then(() => {
                    this.setCurrentList()
                })
        },
        getWordForList(parameter) {
            let name = null
            if (parameter.placeholder) {
                name = parameter.placeholder
            }else{
                name = this.parameter.name
            }
            let word = ''
            if (name == 'Толщина') {
                word = 'толщину'
            }else if (name == 'Покрытие') {
                word = 'покрытие'
            }else if (name == 'Сторона прокатки') {
                word = 'сторону прокатки'
            }else if (name == 'Монтажная ширина') {
                word = 'монтажную ширину'
            }else if (name == 'Длина') {
                word = 'длину'
            }else if (name == 'Ширина') {
                word = 'ширину'
            }else if (name == 'Геометрия') {
                word = 'геометрию'
            }else if (name == 'Накатка на наружной поверхности') {
                word = 'накатку на наружной поверхности'
            }else if (name == 'Накатка на внутренней поверхности') {
                word = 'накатку на внутренней поверхности'
            }else if (name == 'Толщина утеплителя') {
                word = 'толщину утеплителя'
            }else if (name == 'Толщина внутренней облицовки') {
                word = 'толщину внутренней облицовки'
            }else if (name == 'Полка (A)') {
                word = 'полку (A)'
            }else if (name == 'Полка (B)') {
                word = 'полку (B)'
            }else if (name == 'Длина характеристик (мм.)') {
                word = 'длину характеристик'
            }else if (name == 'Диаметр') {
                word = 'диаметр'
            }else if (name == 'Тип наконечника') {
                word = 'тип наконечника'
            }else if (name == 'Тип головки') {
                word = 'тип головки'
            }else{
                word = name
            }
            return word
        },
        checkValue() {
            let name = this.parameter.base_name
            let lower_name = name[0].toLowerCase() + name.substring(1)
            if (this.value != 0 && this.value != null) {
                if (this.minValue != 0 && this.value < this.minValue) {
                    this.changeNewPosition({type: lower_name, value: this.minValue})
                    this.$emit('updateCalculatorPosition', lower_name, this.minValue)
                }
                if (this.maxValue != 0 && this.value > this.maxValue) {
                    this.changeNewPosition({type: lower_name, value: this.maxValue})
                    this.$emit('updateCalculatorPosition', lower_name, this.maxValue)
                }
                
                this.calculate()
            }
        },
        updateFormCardProduct() {
            this.$emit('updateLocalForm', this.currentNameParameter, this.value)
        },
        isIssetColor(array, value) {
            let response = false
            array.map((element) => {
                if (element == value) {
                    response = true
                }
            })
            return response
        },
        setColorsArray(str) {
            let array = []
            let colors = ["0"]
            array = this.filters[str]
            for (let property in array) {
                colors.push(property)
            }
            if (colors.length != 0) {
                this.getColorsArray({type: str, colors: colors, componentFrom: 'single-parameter'})
            }
        }
    },
}
</script>
<style lang="scss">
    .mt-3{
        margin-top: 3rem;
    }
    .label_red_error{
        color: red !important;
    }
    .select_red_error{
        .choices__item--selectable{
            border-color: red !important;
        }

        input{
            border-color: red !important;
        }
    }
    .color-preview{
        img{
            width: 38px;
            height: 38px;
            border-radius: 50%;
        }
    }

    .nd_good_col{
        position: relative;
        min-height: 10rem;

        .field-input{
            position: absolute;
            bottom: 0;
            width: 86%;
        }
    }
</style>