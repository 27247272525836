<template>
    <div class="admin-services-reason">
        <span>Почему мы?</span>
        
        <div 
            v-if="!isShowForm"
            class="index__admin"
        >
            <button @click="isShowForm = true">
                Добавить причину
            </button>
        </div>

        <div 
            v-if="isShowForm"
            class="index__admin"
        >
            <label>Заголовок</label>
            <input 
                type="text" 
                v-model="newReason.name" 
                placeholder="Заголовок"
            >

            <label>Ссылка на изображение</label>
            <input 
                type="text" 
                v-model="newReason.link"
                placeholder="Ссылка на изображение" 
            >
            <div class="row">
                <label class="fl__img">
                    <input 
                        :ref="'defaultFileReason'" 
                        type="file" 
                        @change="uploadImg()"
                    >
                </label>
                <p> Изображение должно быть размером 60х60 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат. </p>
            </div>
            <img 
                :src="newReason.link" 
                alt=""
            >

            <button @click="createReason">
                Сохранить
            </button>
        </div>

        <div 
            v-for="(obj, ind) in uiReasons" 
            :key="ind"
        >
            <single
                :key="ind"
                :index="ind"
                :element="obj"
            />
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import single from './single.vue'

export default {
    components: {
        single,
    },
    data() {
        return {
            isShowForm: false,
            newReason: {
                name: null,
                link: null,
                root_id: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiReasons: 'ui_reason/reasons',
            fileUrl: 'app/fileUrl'
        }),
    },
    methods: {
        ...Vuex.mapActions({
            createUiReason: 'ui_reason/createReason',
            upload: 'app/upload',
        }),
        createReason() {
            this.createUiReason(this.newReason)
                .then(() => {
                    this.isShowForm = false
                    this.newReason.name = null
                    this.newReason.root_id = null
                    this.newReason.link = null
                })
        },
        uploadImg() {
            let image = this.$refs['defaultFileReason'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newReason.link = this.fileUrl + response.data[0].link
                })
        },
    },
}
</script>