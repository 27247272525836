<template>
    <div class="section section--no-pt">
        <div class="container">
            <div class="inner">
                <div class="heading">
                    <div class="heading-section">
                        <h2>Наши объекты</h2>
                    </div>
                    <div class="heading-aside"><a class="button button--default"  @click="push('objects')">Смотреть все</a></div>
                </div>
            </div>
            <ul class="list list--flex layout-objects">
                <li class="list-item"  v-for="obj, i in uiObjects" :key="i">
                    <a class="object" @click="push('objects/'+ obj.link)">
                    <div class="object-image"><img :src="obj.linkimg" alt=""></div>
                    <div class="object-title">{{ obj.name }}</div></a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    components:{
    },
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            uiObjects: 'ui_object/objects',
        }),
    },
    methods: {
    },
}
</script>