<template>
    <div class="slide">
        <span 
            class="index__del" 
            @click="deleteSlide(element.id)"
        >
            Удалить X
        </span>
        <span class="header">Слайд №{{ index + 1 }}</span>
        <div>
            <label>Регион для слайда</label>
            <select 
                class="picker" 
                v-model="editElement.root_id"
                @change="update"
            >
                <option value="null">Выберите регион</option>
                <option v-for="reg, i in regions" :key="i" :value="reg.id_1c">{{reg.name}}</option>
            </select>
        </div>
        <input 
            v-model="editElement.name" 
            type="text" 
            placeholder="Заголовок" 
            @change="update"
        >
        <input 
            v-model="editElement.link" 
            type="text" 
            placeholder="Ссылка на изображение" 
            @paste="update"
            @change="update"
        >
        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'fileSingleSlide'+ index" 
                    type="file" 
                    @change="uploadImg"
                >
            </label>
            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
        </div>
      <img 
        :src="editElement.link" 
        alt=""
      >
    </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
            regions:'dictionary/topLevelCategory',
        }),
        editElement() {
            return Object.assign({}, this.element)
        }
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            deleteSlide: 'ui_slide/deleteSlide',
            updateSlide: 'ui_slide/updateSlide',
        }),
        update() {
            this.updateSlide(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['fileSingleSlide' + this.index].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.updateSlide({
                        id: this.element.id,
                        name: this.editElement.name,
                        link: this.fileUrl + response.data[0].link
                    })
                })
        },
    },
}
</script>