<template>
	<section class="nlk-content">
		<h1 class="nlk-content__title mb-30">ОТПРАВИТЬ ПРЕТЕНЗИЮ</h1>
		<div class="nlk-content-claim">
			<form 
				class="nlk-form form form--checkout"
				onsubmit="return false"	
			>
				<div class="nlk-form__col-2">
					<div class="field">
						<div class="field-label">Ваше имя</div>
						<div class="field-input">
							<input 
								type="text" 
								placeholder="Введите Ваше имя" 
								v-model="form.name"
							>
						</div>
					</div>
					<div class="field">
						<div class="field-label">E-mail</div>
						<div class="field-input">
							<input 
								type="text" 
								placeholder="Введите адрес эл.почты" 
								v-model="form.email"
							>
						</div>
					</div>
				</div>

				<div class="nlk-form__col-2">
					<div class="field">
						<div class="field-label">Телефон</div>
						<div class="field-input">
							<the-mask 
								v-model="form.telephone" 
								mask="+7 (###) ###-####" 
								placeholder="+7 (###) ###-####" 
								type="tel" 
							/>
						</div>
					</div>
					<!-- <div class="field">
						<div class="field-label">E-mail</div>
						<div class="field-input">
							<input type="text" placeholder="Введите адрес эл.почты" value="">
						</div>
					</div> -->
				</div>

				<div class="field">
					<div class="field-label">Отзыв</div>
					<div class="field-input">
						<textarea 
							placeholder="Введите сообщение"
							v-model="form.message"
						/>
					</div>
				</div>

				<div class="field-file">
					<input 
						type="file" 
						class="field-file__item" 
						id="upload_file" 
						ref="upload_file" 
						@change="setFile"
						multiple
					>
					<label 
						for="upload_file" 
						class="field-file__btn"
					>
						Прикрепить файл
					</label>
				</div>

				<label class="checkbox-plus">
					<input 
						type="checkbox" 
						name="privacy"
						v-model="isAgree"
					>
						<b></b>
						<span>
                          Согласен (согласна) на обработку моих персональных данных.
                          <br> Ознакомлен с 
                          <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/BeEwJiJ_Jc.pdf" target="_blank">Политикой</a>
                          и
                          <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/ZhlKe-a2dH.pdf" target="_blank">согласием</a>
                          на обработку персональных данных
                        </span>
				</label>

				<button 
					class="button button--primary"
					:disabled="!isAgree"
					@click="send"	
				>
					Отправить отзыв
				</button>
			</form>

			<div class="nlk-content-claim__text">
				<h2 class="nlk-h2">Для рассмотрения претензии необходимо прикрепить файлы:</h2>
				<ol>
					<li>
						1. Претензия (подписанная уполномоченным лицом) с указанием:
						<ul>
							<li> - наименования</li>
							<li>- количества</li>
							<li>- характера дефекта продукции</li>
							<li>- номер накладной, по которой оформлена претензия</li>
							<li>- номер договора</li>
							<li>- номер пачки</li>
							<li>- номер заказа</li>
						</ul>
					</li>
					<li>Скан оплаченного счета по которому приобреталась продукция</li>
					<li>Скан товарной накладной по которой получена продукция</li>
					<li>Фотоматериал, содержащий бирку пачки, в которой находилась продукция</li>
					<li>Фото и видеоматериал (по возможности) дефектов претензионной продукции</li>
				</ol>
			</div>
		</div>

	</section>
</template>
<script>
import Vuex from 'vuex'
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
export default {
	data() {
		return {
			form: {
				name: null,
				email: null,
				telephone: null,
				type: 'claim',
				message: null,
				file: null,
			},
			isAgree: false,
		}
	},
	computed: {
		...Vuex.mapGetters({
			message: 'appmodal/message',
			isAuthenticated: 'auth/isAuthenticated',
			user: 'auth/user',
		}),
	},
	mounted() {
		this.checkAuth()
	},
	methods: {
		...Vuex.mapMutations({
			setShowSendedModal: 'app/setShowSendedModal',
		}),
		...Vuex.mapActions({
			sendReviewOrClaim: 'appmodal/sendReviewOrClaim',
		}),
		send() {
			this.sendReviewOrClaim(this.form)
				.then(() => {
					this.form = {
						name: null,
						email: null,
						telephone: null,
						type: 'claim',
						message: null,
						file: null,
					}

					this.setShowSendedModal(true)
				})
		},
		setFile() {
			let file = this.$refs.upload_file.files
			this.form.file = file
		},
		checkAuth() {
			if (!this.isAuthenticated) {
				return
			}
			this.form.email = this.user.email
			this.form.name = this.user.surname + ' ' + this.user.name + ' ' + this.user.midname
			this.form.pnone = this.user.phone
		},
	},
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>
<style lang="scss" scoped>
	.nlk-form{
		.field-file{
			.field-file__btn{
				display: inline-block;

				&:hover{
					cursor: pointer;
				}
			}
		}
	}
</style>