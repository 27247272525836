<template>
    <div class="nlk-accordion-table">
        <table>
            <tr>
                <td>Состав по номенклатуре</td>
                <td>Ед.изм.</td>
                <td>Кол-во</td>
                <td>В производстве</td>
                <td>На складе</td>
                <td>Отгружены</td>
                <td>Остаток</td>
                <td>Цена</td>
                <td>Сумма</td>
            </tr>
            <tr 
                v-for="position in inOrder"
                :key="position.id"
            >   
                <td style="font-size: 1rem; text-align: left;">{{ getFullNamePosition(position) }}</td>
                <td>{{ position.base_unit_of_measure }}</td>
                <td>{{ position.count_base_unit_of_measure }}</td>
                <td>{{ position.amount_in_progress ? position.amount_in_progress : 0 }}</td>
                <td>{{ position.amount_in_stock ? position.amount_in_stock : 0 }}</td>
                <td>{{ position.amount_shipped ? position.amount_shipped : 0 }}</td>
                <td>{{ position.amount_balance ? position.amount_balance : 0 }}</td>
                <td>{{ position.price }}</td>
                <td><b>{{ position.sell }}</b></td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        inOrder: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        getSumWithSpaces(sum) {
            let parts = sum.toString().split(".")
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            return parts.join(".")
        },
        getTextDeliveryType(type) {
            let val = null
            if (type == 10) {
                val = 'Самовывоз'
            }else if (type == 20) {
                val = 'Доставка'
            }
            return val
        },
        getParsedDate(date) {
            if (!date) {
                return null
            }
            let parsed = date.split('-')
            let parsedDay = parsed[2].split('T')
            return parsedDay[0] + '-' + parsed[1] + '-' + parsed[0]
        },
        getFirstPositionName(name) {
            let splited = name.split(' ')
            if (splited[0] == '' || splited[0] == ' ') {
                return splited[1] + ' ' + splited[2]
            }else{
                return splited[0] + ' ' + splited[1]
            }
        },
        getFullNamePosition(pos) {
            let full_name = ''
            if (this.getFirstPositionName(pos.name)) {
                full_name += this.getFirstPositionName(pos.name)
            }
            if (pos.length) {
                full_name += ', Длина: ' + pos.length
            }
            if (pos.coating) {
                full_name += ', ' + pos.coating
            }
            if (pos.color_inner) {
                full_name += ', Цвет: ' + pos.color_inner
            }else if (pos.color_external) {
                full_name += ', Цвет: ' + pos.color_external
            }
            return full_name
        },
    },
}
</script>
<style scoped src="../../../../css/nlk-style.css"></style>
<style scoped>
	.active{
		background-color: unset;
	}
    .nlk-order-header__top .first-item{
        min-width: 13.3rem;
        max-width: 13.3rem;
        margin-right: 0;
    }
    .td-wo_padding{
        padding: 0 !important;
    }
    .table-100{
        height: 100% !important;
    }
</style>