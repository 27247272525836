import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    form: {
        excludedIds: [],
        ids: [],
        limit: 10,
        name: "",
        page: 1,
        query: "",
        type: ""
    },
    createForm: {
        "name": null,
        "type": null,
    },
    chars: [],
    isShowModalCreate: false
}

const getters = {
    message: state => state.message,
    form: state => state.form,
    createForm: state => state.createForm,
    chars: state => state.chars,
    isShowModalCreate: state => state.isShowModalCreate,
}

const actions = {
    search: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_features_coating/search", getters.form)
                .then(response => {
                    commit("setChars", response.data)
                    resolve(response)
                })
        })
    },
    createChar: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/ui_features_coating/admin", payload)
                .then(response => {
                    commit("addChar", response.data)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                    if (err.message) {
                        commit("setMessage", err.message)
                    }
                })
        })
    },
    deleteChar: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_features_coating/admin/" + id)
                .then(response => {
                    commit("spliceChar", id)
                    resolve(response)
                })
        })
    },
    updateChar: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_features_coating/admin/" + payload.id, payload.object)
                .then(response => {
                    commit("patchCharObject", {id: payload.id, object: response.data})
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setChars: (state, val) => {
        val.sort((a, b) => Number(a.id) - Number(b.id))
        state.chars = val
    },
    spliceChar: (state, id) => {
        state.chars.map((element, i) => {
            if (id == element.id) {
                state.chars.splice(i, 1)
            }
        })
    },
    patchCharObject: (state, payload) => {
        state.chars.map((element) => {
            if (payload.id == element.id) {
                element.name = payload.object.name
                element.type = payload.object.type
            }
        })
    },
    setShowModalCreate: (state, val) => {
        state.isShowModalCreate = val
    },
    addChar: (state, val) => {
        state.chars.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
