<template>
  <div class="modal-iframe pdf-preview-modal">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup">
                            <a 
                                class="list-link"
                                :href="pdfPreviewModalLink"
                            >
                                <b>
                                    Скачать документ
                                </b>
                            </a>
                            <embed
                                :src="'https://drive.google.com/viewerng/viewer?embedded=true&amp;url=' + pdfPreviewModalLink + '#toolbar=0&amp;scrollbar=0'"
                                type="application/pdf" 
                                frameborder="0" 
                                scrolling="auto"
                                class="embed_pdf_file"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
            pdfPreviewModalLink: 'app/pdfPreviewModalLink',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setPdfPreviewModal: 'app/setPdfPreviewModal',
            setPdfPreviewModalLink: 'app/setPdfPreviewModalLink',
        }),
        close() {
            this.setPdfPreviewModal(false)
            this.setPdfPreviewModalLink(null)
        },
        forget() {
            this.close()
            this.push('recovery')
        },
    }
}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>
<style lang="scss">
    .pdf-preview-modal{
        .modal-container{
            height: 600px;
            overflow: hidden;
        }

        .embed_pdf_file{
            width: 400px;
            height: 480px;
        }

        .list-link{
            &:hover{
                color: #ED1C24;
            }
        }
    }
</style>