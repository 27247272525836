<template>
    <main class="admin-index index__admin" style="display: block">
        <div class="admin-index_header">
            <h3>Точки самовывоза</h3>

            <button 
                class="button button--primary save_btn" 
                @click="updateData"
            >
                Сохранить
            </button>
        </div>

        <div class="index__admin">
            <div>
                <label>Регионы доставки</label>
                <select   
                    class="picker" 
                    v-model="newDeliveryPoint.root_id"  
                >
                    <option :value="null">Выберите регион</option>
                    <option 
                        v-for="reg in regions" 
                        :key="reg.id" 
                        :value="reg.id_1c"
                    >
                        {{ reg.name }}
                    </option>
                </select>
            </div>

            <input 
                type="text"  
                placeholder="Точка доставки" 
                v-model="newDeliveryPoint.address"
            >

            <button @click="addPoint">
                Добавить точку
            </button>
        </div>

        <div class="delivery_points">
            <div 
                v-for="point, idx in deliveryPoints"
                :key="idx"    
                class="single_point"
                v-show="point.root_id == newDeliveryPoint.root_id"
            >
                <span 
                    class="index__del" 
                    @click="removeDeliveryPoint(idx)" 
                >
                    Удалить Х
                </span>
                <input 
                    type="text"  
                    placeholder="Точка доставки" 
                    v-model="point.address"
                    readonly
                >
            </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            newDeliveryPoint: {
                root_id: null,
                address: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            regions:'dictionary/topLevelCategory',
            deliveryPoints: 'app/deliveryPoints',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            pushDeliveryPoint: 'app/pushDeliveryPoint',
            removeDeliveryPoint: 'app/removeDeliveryPoint',
        }),
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
        }),
        addPoint() {
            let point = {
                root_id: null,
                address: null,
            }
            point.root_id = this.newDeliveryPoint.root_id
            point.address = this.newDeliveryPoint.address
            this.pushDeliveryPoint(point)
            this.newDeliveryPoint.address = null
        },
        updateData() {
            this.updateJson()
                .then(() => {
                   
                })
        },
    },
}
</script>