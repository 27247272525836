<template>
    <select ref="select">
        <option value="">{{ textValue }}</option>
    </select>
</template>
<script>
import Choices from 'choices.js'
export default {
    props: ['elements', 'value', 'defaultValue', 'typeSelect', 'from', 'id_1c', 'typeOfElementList', 'componentFrom'],
    data() {
        return {
            selectOptions: {
                classNames: {
                    containerOuter: 'choices choices--' + this.typeSelect,
                    openState: 'is-open',
                },
                shouldSort: true,
                sorter: function(a, b) {
                    if (Number(a.value) > Number(b.value)) {
                        return 1;
                    } else if (Number(b.value) > Number(a.value)) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
                searchEnabled: true,
            },
            amountChangesText: 0,
            changedTextValue: null,
        }
    },
    computed: {
        textValue() {
            let response = null
            this.elements.map(element => {
                if (element.id == this.value) {
                    response = element.name
                }
            })
            if (!response) {
                response = this.defaultValue
                return response
            }
            let test = document.querySelector('.choices__item')
            if (test && this.amountChangesText == 0 && this.typeSelect == 'location') {
                test.innerText = response
                test.classList.remove('choices__placeholder')
                this.setAmountChanges()
            }
            return response
        },
    },
    watch: {
        elements() {
            this.setChoices()  
        },
    },
    mounted() {
        if (this.componentFrom == 'contactsSelects' || this.componentFrom == 'navbar') {
            this.selectOptions.searchEnabled = false
        }
        this.choicesInstance = new Choices(this.$refs.select, this.selectOptions)
        this.$refs.select.addEventListener('addItem', this.handleSelectChange)
        this.setChoices()
    },
    destroyed: function () {
        this.choicesInstance.destroy();
    },
    methods: {
        handleSelectChange(e) {
            if (this.typeOfElementList === 'number') {
                this.$emit('input', Number(e.target.value))
            }else{
                this.$emit('input', e.target.value)
            }
            // if (Number(e.target.value)) {
            //     this.$emit('input', Number(e.target.value))
            // }else{
            //     this.$emit('input', e.target.value)
            // }
            
            this.$emit('change')
        },
        setChoices() {
            if (this.id_1c && this.id_1c == true){
                this.choicesInstance.setChoices(this.elements, 'id_1c', 'name', true);
            }else{
                this.choicesInstance.setChoices(this.elements, 'id', 'name', true);
            }
        },
        setAmountChanges() {
            this.amountChangesText++
        },
    },

}
</script>
<style lang="scss">
    .disable-select{
        .choices__list--dropdown{
            display: none !important;
        }
    }
</style>