<template>
    <div class="admin-services-review">
        <span>Отзывы</span>
        
        <div 
            v-if="!isShowForm"
            class="index__admin"
        >
            <button @click="isShowForm = true">
                Добавить отзыв
            </button>
        </div>

        <div 
            v-if="isShowForm"
            class="index__admin"
        >
            <input 
                type="text" 
                v-model="newReview.name"
                placeholder="ФИО"
            >
            <div>
                <vue-editor 
                    v-model="newReview.comment"
                />
            </div>

            <button @click="createReview">
                Сохранить
            </button>
        </div>
        
        <div 
            v-for="(obj, ind) in uiReviews" 
            :key="ind"
        >
            <single
                :key="ind"
                :index="ind"
                :element="obj"
            />
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import { VueEditor } from "vue2-editor"
import single from './single.vue'

export default {
    components: {
        VueEditor,
        single, 
    },
    data() {
        return {
            isShowForm: false,
            newReview: {
                comment: null,
                name: null,
                root_id: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiReviews: 'ui_review/reviews',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            createUiReview: 'ui_review/createReview',
        }),
        createReview() {
            this.createUiReview(this.newReview)
                .then(() => {
                    this.isShowForm = false
                    this.newReview.name = null
                    this.newReview.comment = null
                    this.newReview.root_id = null
                })
        },
    },
}
</script>