<template>
    <main class="admin-index index__admin">
        <div class="admin-index_header ">
            <h3>Услуги</h3>
            <button 
                class="button button--primary save_btn" 
                @click="updateData"
            >
                Сохранить
            </button>

        </div>

        <ui-service />

        <ui-costs />

        <span> Как мы работаем</span>
            <div v-for="(obj, idx) in serviсes.works" :key="idx + 's'">
            <single-work
                :key="idx"
                :index="idx"
                :element="obj"
            />
        </div>
        <div  class="index__admin ">
            <button @click="addWork">
                Добавить работу
            </button>
        </div>

        <ui-reason />

        <ui-review />

        <ui-object />

        <ui-consultation />
    </main>
</template>

<script>
import Vuex from 'vuex'

import uiCosts from './blocks/ui_cost'
import uiConsultation from './blocks/ui_consultation'
import uiObject from './blocks/ui_object'
import uiReason from './blocks/ui_reason'
import uiReview from './blocks/ui_review'
import uiService from './blocks/ui_service'

import SingleWork from './singleWork.vue'

export default {
    components: {
        uiCosts,
        uiConsultation,
        uiObject,
        uiReason,
        uiReview,
        uiService,

        SingleWork,
    },
    // mixins: [Functions],
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            fileUrl: 'app/fileUrl',
        }),
        editServicesConsultation() {
            return Object.assign({}, this.serviсes.consultation)
        }
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            pushServiсesServiсes: 'app/pushServiсesServiсes',
            pushServiсesPrices: 'app/pushServiсesPrices',
            pushServiсesWorks: 'app/pushServiсesWorks',
            pushServiсesReasons: 'app/pushServiсesReasons',
            pushServiсesReviews: 'app/pushServiсesReviews',
            pushServiсesObjects: 'app/pushServiсesObjects',
            updateServiсesConsultation: 'app/updateServiсesConsultation',
            pushServiсesConsultation: 'app/pushServiсesConsultation',
        }),
        addService(){
          this.pushServiсesServiсes({  heading: null,   text: null,  img: null, from: null, region: null  })
        },
        addPrice(){
          this.pushServiсesPrices({  heading: null,   val: null,  img: null, butName: null, })
        },
        addWork(){
          this.pushServiсesWorks({  number: null,   text: null,  heading: null })
        },
        addReason(){
          this.pushServiсesReasons({ img: null,  heading: null })
        },
        addReview(){
             this.pushServiсesReviews({ name: null,  text: null })
        },
        addObject(){
             this.pushServiсesObjects({ name: null,  img: null,  link: null })
        },
        updateData() {
            this.updateJson()
                .then(() => {
                   
                })
        },
        uploadImg() {
             let image = this.$refs['file'].files[0]
            
            this.upload({file: image})
                .then((response) => {
                    this.serviсes.consultation.img = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
        getConsultat(){
            this.pushServiсesConsultation({ fio: this.serviсes.consultation.fio,  img: this.serviсes.consultation.img})
        },
        update() {
            this.updateServiсesConsultation(this.editServicesConsultation)
        },
    },
    mounted(){
        if( !this.serviсes){
            return
        }
        // this.getConsultat()
    }
}
</script>
