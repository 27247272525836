
<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal-v"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
       
        <header class="modal-header " >
           <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              ×
            </button>
        </header>
        <section
          class="modal-body"
          id="modalDescription"
        >
          // {{ video }}
           <div class="modal-iframe"><iframe width="560" height="315" :src="video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        </section>
        </div>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex'
  export default {
    data () {
      return {
      }
    },
    computed: {
        ...Vuex.mapGetters({
            video:'videomodal/video'
        }),
    },
     methods: {
         ...Vuex.mapMutations({
            setVideo: 'videomodal/setVideo',
        }),
        close(){
            this.setVideo(null)
        }
    },
  }
</script>


<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .modal-v {
    background: none;
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    display: flex;
  }
  .modal-header {
    color: #222C40;
    justify-content: space-between;
    
  }

  .modal-footer {
    border-top: 1px solid #222C40;
    justify-content: flex-end;
  }

    .modal-body {
    position: relative;
    min-width: 800px;
    min-height: 400px;
  }
  .btn-close{
        border: none;
        margin-left: auto;
        margin-right: 0;
        font-size: 4rem;
        cursor: pointer;
        font-weight: bold;
        color: #000000;
        background: transparent;
  }
    .btn-close:hover {
        color: #393c41;
  }

	
</style>