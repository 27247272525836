<template>
    <div class="layout-section">
        <form class="form form--checkout" action="#" method="post" onsubmit="return false">
            <div class="form-fieldset">
                <div class="form-group">
                    <div class="form-item">
                        <p>Выберите тип доставки</p>
                    </div>
                </div>
                <div class="form-group form-row form-group--checkboxes">
                    <div class="form-item" @click="updateDeliveryType(10)">
                        <label class="radio radio--primary">
                            <input type="radio" name="type" :checked="(order.delivery_type == 10 ? true : false)" data-tab-toggle="1"><span>Самовывоз</span>
                        </label>
                    </div>
                    <div class="form-item" @click="updateDeliveryType(20)">
                        <label class="radio radio--primary">
                            <input type="radio" name="type" :checked="(order.delivery_type == 20 ? true : false)" data-tab-toggle="2"><span>Доставка</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-fieldset">
                <div class="tabs tabs--form tabs--form--delivery">
                    <div class="tabs-content">
                        <div class="tabs-item" :class="{'-active' : order.delivery_type == 10}">
                            <div class="form-group">
                                <div class="form-item">
                                    <p><b>Адреса пункта самовывоза:</b></p>

                                    <div class="form-group form-row form-group--checkboxes" style="flex-direction: column;">
                                        <div 
                                            v-for="deliveryAddress, idx in deliveryPoints"
                                            :key="idx"
                                            v-show="region == deliveryAddress.root_id"
                                            class="form-item" 
                                            @click="setDeliveryAddress(deliveryAddress.address)"

                                            style="margin-bottom: 10px;"
                                        >
                                            <label class="radio radio--primary">
                                                <input 
                                                    type="radio" 
                                                    :name="'type' + idx" 
                                                    :checked="(editOrder.delivery_address == deliveryAddress.address ? true : false)" 
                                                    :data-tab-toggle="idx + 10"
                                                >
                                                <span>{{ deliveryAddress.address }}</span>
                                            </label>
                                        </div>
                                    </div>

                                    <!-- <p>Самарская область, пгт. Алексеевка, с. Сырейка, Промышленная зона, Заводской проезд, 8</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="tabs-item" :class="{'-active' : order.delivery_type == 20}">
                            <div class="form-group">
                                <div class="form-item">
                                    <p><b>Адрес доставки:</b></p>

                                    <yandex-map
                                        id="map"
                                        class="map_checkout_page"
                                        :coords="center"
                                        :settings="settings"
                                        :zoom="zoom"
                                        :scroll-zoom="false"
                                        @click="setPoint"
                                    >
                                        <ymapMarker 
                                            :coords="[coordMarker.lat, coordMarker.lng]"
                                            :icon="markerIcon"
                                            markerId="23"
                                        />
                                    </yandex-map>

                                    <div class="field">
                                        <div class="field-label">Адрес</div>
                                        <div class="field-input">
                                            <input type="text" placeholder="Куда доставить" v-model="editOrder.delivery_address" @keyup="update">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="form-fieldset form-fieldset--medium">
                <div class="form-group">
                    <div class="form-item">
                        <ul class="list list--flex layout-buttons">
                            <li class="list-item" @click="push('basket/checkout/pay')">
                                <button class="button button--primary">Далее</button>
                            </li>
                            <li class="list-item" @click="push('basket/checkout/region')">
                                <a class="button button--default" href="#">Назад</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </form>
        <!-- <text-block :tab-name="'delivery'" /> -->
    </div>
</template>
<script>
import Vuex from 'vuex'
// import textBlock from '../text-block.vue'
import Functions from '@/utils/functions'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
export default {
    mixins: [Functions],
    components: {
        // textBlock,

        yandexMap, 
        ymapMarker,
    },
    data() {
        return {
            center: [ 52.56543518842296, 70.05213952539054 ],
            zoom: 4, 
            cities: [],
            selectedRegion: null,
            selectedCity: null,
            coordMarker: {lat: null, lng: null},

            settings: {
                apiKey: '72b24e1d-06ad-424f-af9b-44d28d93d4d6',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            },
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: require('@/assets/img/map-marker.svg'),
                imageSize: [43, 43],
                imageOffset: [0, 0],
            },

            deliveryAddress: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            order: 'basket/order',
            deliveryPoints: 'app/deliveryPoints',
            region:'auth/city',
        }),
        editOrder() {
            return Object.assign({}, this.order)
        }
    },
    methods: {
        ...Vuex.mapMutations({
            changeOrder: 'basket/changeOrder',
        }),
        ...Vuex.mapActions({
            getAddressFromCoords: 'app/getAddressFromCoords',
        }),
        setDeliveryAddress(val) {
            this.editOrder.delivery_address = val

            this.update()
        },
        update() {
            this.changeOrder(this.editOrder)
        },
        updateDeliveryType(val) {
            this.editOrder.delivery_type = val
            this.editOrder.delivery_address = null
            this.update()
        },
        clear() {
            this.editOrder.location = null
            this.update()
        },
        setPoint(event) {
            let coords = []
            
            if (event.get('coords')) {
                coords = event.get('coords')
            }

            this.coordMarker.lat = coords[0]
            this.coordMarker.lng = coords[1]

            this.getAddressFromCoords({
                key: this.settings.apiKey,
                lat: coords[0],
                lng: coords[1],
            }).then((response) => {
                this.editOrder.delivery_address = response.data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.text
                this.update()
            })
        },
    }
}
</script>
<style lang="scss">
    .map_checkout_page{
        width: 800px;
    }
</style>