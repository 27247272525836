<template>
    <section class="nlk-content admin_user_companies_page">
        <h1 class="nlk-content__title">Организации</h1>

        <div class="nlk-content">
            <form class="nlk-filter">
                <div class="field field_flex-bottom">
                    <div class="field-input">
                        <input type="text" placeholder="Название" v-model="form.query" @keyup="update" @change="search">
                    </div>
                    <div class="field-input">
                        <input type="text" placeholder="ИНН" v-model="form.inn" @keyup="update" @change="search">
                    </div>
                    <div class="field-input">
                        <input type="text" placeholder="id 1c" v-model="form.id_1c" @keyup="update" @change="search">
                    </div>
                </div>
            </form>
        </div>
        <div class="content mb-50">
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <td>id 1c</td>
                    <td>Название</td>
                    <td>Почта</td>
                    <td>ИНН</td>
                    <td>КПП</td>
                    <td>Телефон</td>
                    <!-- <td>Адрес</td> -->
                    <!-- <td>Почта менеджера</td>
                    <td>Менеджер</td> -->
                    <td></td>
                </tr>
                <tr v-for="company, idx in companies" :key="idx">
                    <td>{{ company.id_1c }}</td>
                    <td>{{ company.name }}</td>
                    <td>{{ company.email }}</td>
                    <td>{{ company.inn }}</td>
                    <td>{{ company.kpp }}</td>
                    <td>{{ company.phone }}</td>
                    <!-- <td>{{ company.address }}</td> -->
                    <!-- <td>{{ company.manageremail }}</td>
                    <td>{{ company.managersurname }} {{ company.managername }}</td> -->
                    <td><button @click="getUsers(company.id)" class="button">Пользователи</button></td>
                </tr>
            </table>
        </div>
        <user-modal 
            v-if="isShowModalUser" 
            :users="users" 
            :company_id="openedCompanyId" 
            @spliceUser="spliceUser"
            @addUser="addUser" 
        />
    </section>
</template>
<script>
import Vuex from 'vuex'
import userModal from '@/components/modals/companieUsers.vue'

export default {
    components: {
        userModal,
    },
    data() {
        return {
            users: [],
            isOpened: false,
            openedCompanyId: null,
        }
    },
    watch: {
        isShowModalUser(val) {
            if (val == false) {
                this.openedCompanyId = null
            }
        },
    },
    computed: {
        ...Vuex.mapGetters({
            companies: 'companies/companies',
            searchCompaniesForm: 'companies/form',
            isShowModalUser: 'companies/isShowModalUser',
        }),
        form() {
            return Object.assign({}, this.searchCompaniesForm)
        },
    },
    created() {
        this.search()
    },
    methods: {
        ...Vuex.mapActions({
            getCompanies: 'companies/getCompanies',
            getUsersByCompany: 'companies/getUsersByCompany',
        }),
        ...Vuex.mapMutations({
            updateForm: 'companies/updateForm',
            setShowModalUser: 'companies/setShowModalUser',
        }),
        getUsers(company_id) {
            this.openedCompanyId = company_id
            this.getUsersByCompany(company_id)
                .then((response) => {
                    this.users = response.data
                    this.setShowModalUser(true)
                })
        },
        update() {
            this.updateForm(this.form)
        },
        search() {
            console.log('get companies in org tab | lk')
            this.getCompanies()
        },
        spliceUser(id) {
            this.users.map((element, idx) => {
                if (id == element.id) {
                    this.users.splice(idx, 1)
                }
            })
        },
        addUser(user) {
            this.users.push(user)
        },
    }
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>
<style>
    .mb-50{
        margin-bottom: 5rem;
    }
</style>