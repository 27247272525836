<template>
    <li class="list-item">
        <div class="product" @click="toProduct($event, product)">
            <div class="product-header">
                <div 
                    v-if="false"
                    class="product-label" 
                >
                    Акция
                </div>
                <div class="product-image">
                    <a class="product-link" v-if="product.links">
                        <img
                            :src="yandexCloud + product.links[0]" 
                            alt=""
                        />
                    </a>
                </div>
            </div>
            <div class="product-body">
                <div class="product-title">
                    <a class="product-link">{{ product.name }}</a>
                </div>
                <div class="product-price">от {{ product.price }} {{ regionCurrency[regionCode].currency }}/м2</div>
            </div>
            <div class="product-footer">
                <button class="button button--primary button--cart">
                    <svg class="icon" width="25" height="25" viewBox="0 0 25 25">
                        <use xlink:href="@/assets/img/icons.svg#cart"></use>
                    </svg>В корзину
                </button>
            </div>
        </div>
    </li>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    props: {
        product: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        ...Vuex.mapGetters({
            newPosition: 'basket/newPosition',
            order: 'basket/order',
            yandexCloud: 'app/yandexCloud',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
        }),
        editNewPosition() {
            return Object.assign({}, this.newPosition)
        },
    },
    methods: {
        ...Vuex.mapActions({
            addPosition: 'basket/addPosition',
            createPosition: 'basket/create',
        }),
        ...Vuex.mapMutations({
            clearNewPosition: 'basket/clearNewPosition',
        }),
        toProduct(ev, product) {
            if (ev.target.tagName == 'svg' || ev.target.tagName == 'BUTTON') {
                this.push('cardProduct/' + product.id) 
            }else{
                this.push('cardProduct/' + product.id)
            }
        }
    },
}
</script>
<style >
    .product-image{
        min-height: 292px;
        height: 292px;
    } 
    .product-image img{
        max-height: 292px;
    }
</style>