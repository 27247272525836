<template>
    <main class="admin-index index__admin admin-settings" style="display: block">
        <div class="admin-index_header">
            <h3>Калькуляторы</h3>
             <button 
                class="button button--primary save_btn" 
                @click="updateData"
            >
                Сохранить
            </button>
        </div>
        <div class="admin-calculators-content content">
            <div class="buttons">
                <button 
                    class="btn-default"
                    :class="{'-active' : currentCalculator == 1}"
                    @click="currentCalculator = 1"
                >
                    Калькулятор кровли
                </button>
                <button 
                    class="btn-default"
                    :class="{'-active' : currentCalculator == 2}"
                    @click="currentCalculator = 2"
                >
                    Калькулятор водостока
                </button>
                <button 
                    class="btn-default"
                    :class="{'-active' : currentCalculator == 3}"
                    @click="currentCalculator = 3"
                >
                    Калькулятор ограждения
                </button>
            </div>

            <div class="blocks">
                <calculator-roof v-if="currentCalculator == 1" />
                <calculator-drainage v-if="currentCalculator == 2" />
                <calculator-fence v-if="currentCalculator == 3" />
            </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'

import calculatorRoof from './calculatorRoof.vue'
import calculatorDrainage from './calculatorDrainage.vue'
import calculatorFence from './calculatorFence.vue'

export default {
    components: {
        calculatorRoof,
        calculatorDrainage,
        calculatorFence,
    },
    computed: {
        ...Vuex.mapGetters({
            calculators: 'app/calculators',
        }),
    },
    data() {
        return {
            currentCalculator: null,
        }
    },
    mounted() {
        this.setCurrentCalculator(1)
        this.getTopCategory()
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            getTopCategory: 'catalog/getTopCategory',
            updateCalcFence: 'calculator/updateCalcFence',
            updateCalcDrain: 'calculator/updateCalcDrain',
            updateCalcRoof: 'calculator/updateCalcRoof',
        }),
        setCurrentCalculator(num) {
            this.currentCalculator = num
        },
        updateData() {
            this.updateJson()
            if (this.currentCalculator == 1) {
                this.updateCalcRoof()
            }else if (this.currentCalculator == 2) {
                this.updateCalcDrain()
            }else if (this.currentCalculator == 3){
                this.updateCalcFence()
            }
        }
    }
}
</script>
<style lang="scss">
    .admin-calculators-content{
        .buttons{
         
            .btn-default{
                background: #efefef;
                color: black;
                font-size: 1.4rem;
                width: 25rem;

                &:hover{
                    background: #ee3b2b;
                    color: #fff;
                }
            }

            .-active{
                background: #ee3b2b;
                color: #fff;
            }
        }
        .blocks{
            margin-top: 4rem;

            .block-step{
                margin-bottom: 3rem;
            }
        }
    }
    .one_tr_name_block{
        position: relative;

        .one_tr_icon_delete{
            position: absolute;
            color: red;
            right: 15px;
            cursor: pointer;
        }
    }
    .block_length_and_width{
        display: flex;
    }
</style>