<template>
	<section class="nlk-content">
		<!-- <h1 class="nlk-content__title mb-50">ГРАФИК ОПЛАТ</h1> -->
		<form class="nlk-filter">
			<div class="field field_data">
				<div class="field-label">Начальная дата</div>
				<div class="field-input">
					<input type="date" placeholder="Дата" v-model="startDate" @change="search">
				</div>
			</div>
			<div class="field field_data">
				<div class="field-label">Конечная дата</div>
				<div class="field-input">
					<input type="date" placeholder="Дата" v-model="endDate" @change="search">
				</div>
			</div>
			
			<div class="nlk-filter-info">
				<div v-if="payments.pay_balance" class="nlk-filter-info__item">
					<span>На сегодня к оплате</span>
					{{ getSumWithSpaces(payments.pay_balance) }} р.
				</div>
				<div v-if="payments.pay_sum" class="nlk-filter-info__item">
					<span>Всего</span>
					{{ getSumWithSpaces(payments.pay_sum) }} р.
				</div>
			</div>
		</form>

		<div class="nlk-horizontal-scroll nlk-vertical-scroll" id="chart_block_scroll" @wheel="checkPositionWindow">
			<table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac table_custom_border">
				<tr>
					<th>НОМЕР ЗАКАЗА</th>
					<th>НОМЕР СЧЕТА</th>
					<th>СУММА ОПЛАТЫ</th>
					<th>ОСТАТОК ПЛАТЕЖА ПО СЧЕТУ</th>
					<th>ДАТА ОТГРУЗКИ</th>
					<th>ДАТА ПЛАТЕЖА</th>
				</tr>
				<tr v-for="(payment, idx) in payments.payments" :key="idx">
					<td><b>№ {{ payment.order_id_1c }}</b></td>
					<td><b>№{{ payment.pay_id_1c }}</b></td>
					<td><b>{{ (payment.pay_sum ? getSumWithSpaces(payment.pay_sum) : 0) }} {{ regionCurrency[regionCode].currency }}.</b></td>
					<td><b>{{ (payment.pay_balance ? getSumWithSpaces(payment.pay_balance) : 0) }} {{ regionCurrency[regionCode].currency }}.</b></td>
					<td><b>{{ getDateParsedJson(payment.date_shipment) }}</b></td>
					<td><b>{{ getDateParsedJson(payment.pay_date) }}</b></td>
				</tr>
			</table>
		</div>
	</section>
</template>
<script>
import Vuex from 'vuex'

export default {
	data() {
		return {
			startDate: null,
			endDate: null,
		}
	},
	watch: {
		windowPositionScroll() {
			this.checkPositionWindow()
		},
	},
	computed: {
		...Vuex.mapGetters({
			payments: 'catalog/payments',
			formPayments: 'catalog/formPayments',
			regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
			windowPositionScroll: 'app/windowPositionScroll',
		}),
		searchFormPayments() {
			return Object.assign({}, this.formPayments)
		},
	},
	destroyed() {
		this.setPayments([])
	},
	created() {
		this.setCanSearchPayments(true)
		this.searchFormPayments.page = 1
		this.setFormPayments(this.searchFormPayments)
		this.getPayments()
			.then(() => {
				this.setScrollListener()
			})
	},
	methods: {
		...Vuex.mapActions({
			getPayments: 'catalog/getPayments',
			getNewPagePayments: 'catalog/getNewPagePayments',
		}),
		...Vuex.mapMutations({
			setFormPayments: 'catalog/setFormPayments',
			setCanSearchPayments: 'catalog/setCanSearchPayments',
			setPayments: 'catalog/setPayments',
		}),
		setScrollListener() {
			let scrollBlock = document.getElementById('chart_block_scroll')
			scrollBlock.addEventListener('scroll', this.checkPositionWindow)
		},
		getSumWithSpaces(sum) {
            let parts = sum.toString().split(".")
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            return parts.join(".")
        },
		checkPositionWindow() {
			// let positionScroll = document.getElementById('chart_block_scroll')

			// if (positionScroll.scrollHeight - positionScroll.scrollTop === positionScroll.clientHeight) {
			// 	this.searchFormPayments.page += 1
			// 	this.setFormPayments(this.searchFormPayments)
			// 	this.getNewPagePayments()
			// }
		},
		getDateParsedJson(date) {
			let response = null
			if (date) {
				let splitedDate = date.split('-')
				let splitedTime = splitedDate[2].split('T')
				response = splitedTime[0] + '.' + splitedDate[1] + '.' + splitedDate[0]
			}
			return response
		},
		search() {
			this.searchFormPayments.page = 1
			this.setCanSearchPayments(true)
			if (this.startDate) {
				let today = new Date(this.startDate)
				this.searchFormPayments.pay_date_create = today.toISOString()
			}else{
				this.searchFormPayments.pay_date_create = null
			}

			if (this.endDate) {
				let today = new Date(this.endDate)
				this.searchFormPayments.pay_date_before = today.toISOString()
			}else{
				this.searchFormPayments.pay_date_before = null
			}

			this.setFormPayments(this.searchFormPayments)
			this.getPayments()
		}
	}
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>