<template>
    <main class="main custom_basket_full">
        <div class="cart">
            <div class="cart-header">
                <div class="cart-title">В корзине {{ order.positions.length }} товара</div>
                <div class="cart-search" />
                <div class="cart-clear">
                    <a class="button button--primary" @click="clearProudcts">Очистить корзину</a>
                </div>
            </div>
          <div class="cart-body">
              <div class="cart-list">
                <div class="cart-item cart-item--labels">
                    <div class="cart-row cart-row--labels">
                        <div class="cart-cell">
                            <div class="label">Наименование</div>
                        </div>
                        <div class="cart-cell max-w12">
                            <div class="label">Цвет</div>
                        </div>
                        <div class="cart-cell">
                            <div class="label">Розничная цена</div>
                        </div>
                        <div class="cart-cell">
                            <div class="label">Количество</div>
                        </div>
                        <div class="cart-cell max-w12">
                            <div class="label">Сумма</div>
                        </div>
                        <div class="cart-cell cart-cell--remove">
                            <div class="label">&nbsp;</div>
                        </div>
                    </div>
                </div>
                 
                <single-position 
                    v-for="position in order.positions" 
                    :key="position.id" 
                    :position="position" 
                />
              </div>
          </div>
          <div class="cart-footer">
              <div class="cart-total-price">
                  <div class="cart-total-price-label">Стоимость</div>
                  <div class="cart-total-price-value">{{ fullSum }} {{ regionCurrency[regionCode].currency }}</div>
              </div>
              <div class="cart-button-submit">
                  <button class="button button--primary" @click="goCheckout">Оформить заказ</button>
              </div>
          </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

import singlePosition from './single-position.vue'

export default {
    mixins: [Functions],
    components: {
        singlePosition,
    },
    computed: {
        ...Vuex.mapGetters({
            pagesBasket: 'dictionary/pagesBasket',
            basket: 'basket/basket',
            order: 'basket/order',
            isAuthenticated: 'auth/isAuthenticated',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
        }),
        fullSum() {
            let sum = 0
            if (this.order.positions.length != 0) {
                this.order.positions.map((element) => {
                    sum += element.sell
                })
            }
            sum = sum.toFixed(2)
            return sum
        }
    },
    methods: {
        ...Vuex.mapMutations({
            setProducts: 'basket/setProducts',
            deleteProduct: 'basket/deleteProduct',
            setShowCleanBasketModal: 'app/setShowCleanBasketModal',
            setShowOfferModal: 'app/setShowOfferModal',
        }),
        ...Vuex.mapActions({
            deletePosition: 'basket/deletePosition',
            updatePosition: 'basket/updatePosition',
        }),
        clearProudcts() {
            this.setShowCleanBasketModal(true)
        },
        delProduct(id) {
            this.deletePosition(id)
        },
        goCheckout() {
            if (this.isAuthenticated) {
                this.push('basket/checkout')
            }else{
                this.setShowOfferModal(true)
            }
        }
    }
}
</script>
<style lang="scss">
    .custom_basket_full{
      .max-w12{
        max-width: 12%;
      }
      .max-w10{
        max-width: 10%;
      }

      .cart-cell:first-child{
        max-width: 30% !important;
      }
    }
</style>