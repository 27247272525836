<template>
  <main class="main">
        <div class="section section--breadcrumbs">
          <div class="container">
            <div class="inner">
              <ul class="list list--flex breadcrumbs breadcrumbs--light">
                <li class="list-item"><a class="list-link" href="#">
                    <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                      <use xlink:href="@/assets/img/icons.svg#home"></use>
                    </svg></a></li>
                <li class="list-item"><a class="list-link" href="#">Каталог</a></li>
                <li class="list-item"><span class="list-current">Металлоконструкции в Самаре</span></li>
              </ul>
            </div>
          </div>
        </div>
        <section 
            class="subhero"
            style="color: #fff;"
            :style="{ 'background-image': 'url(' + require('@/assets/img/subhero-1.jpg') + ')' }"
        >
          <div class="container">
            <div class="inner">
              <h1>Металлоконструкции<br> в Самаре</h1>
              <p>Группа компаний «Маяк» является одним из крупнейших производителей<br> металлопродукции для зданий и сооружений</p>
            </div>
          </div>
        </section>
        <!-- section-->
        <div class="section section--no-pt section--cards">
          <div class="container">
            <ul class="list list--flex layout-cards -col-4">
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#beam_3"></use>
                      </svg>
                    </div>
                    <div class="card-title">Металлоконструкции<br> каркасов рамного типа</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#electric_tower"></use>
                      </svg>
                    </div>
                    <div class="card-title">Мачты прожекторные,<br> опоры сотовой связи</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#brace"></use>
                      </svg>
                    </div>
                    <div class="card-title">Опоры под<br> трубопроводы</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#beam"></use>
                      </svg>
                    </div>
                    <div class="card-title">Эстакады</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#allen"></use>
                      </svg>
                    </div>
                    <div class="card-title">Газоходы</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
            </ul>
            <div class="row row--button">
              <div class="col"><a class="button button--primary" href="#modal_callback" data-modal>Отправить заявку</a></div>
              <div class="col">
                <div class="label">Монтаж металлоконструкций проводится в соответствии с действующими<br> СНиПами и технический заданием Заказчика.</div>
              </div>
            </div>
          </div>
        </div>
        <!-- section-->
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Цена изготовления</h2>
                </div>
              </div>
            </div>
            <div class="table">
              <div class="table-head">
                <div class="table-row">
                  <div class="table-cell">Название</div>
                  <div class="table-cell">Единица измерения</div>
                  <div class="table-cell">Сумма, руб.</div>
                </div>
              </div>
              <div class="table-body">
                <div class="table-row">
                  <div class="table-cell table-cell--th" data-label="Название">Фермы</div>
                  <div class="table-cell" data-label="Единица измерения">1000 кг</div>
                  <div class="table-cell" data-label="Сумма, руб.">от 23 — 25 тыс. руб / т</div>
                </div>
                <div class="table-row">
                  <div class="table-cell table-cell--th" data-label="Название">Колонны (грунт)</div>
                  <div class="table-cell" data-label="Единица измерения">1000 кг</div>
                  <div class="table-cell" data-label="Сумма, руб.">от 12 — 18 тыс. руб / т</div>
                </div>
                <div class="table-row">
                  <div class="table-cell table-cell--th" data-label="Название">Колонны решетчатые (грунт)</div>
                  <div class="table-cell" data-label="Единица измерения">1000 кг</div>
                  <div class="table-cell" data-label="Сумма, руб.">от 15 тыс. руб / т</div>
                </div>
                <div class="table-row">
                  <div class="table-cell table-cell--th" data-label="Название">Балки (грунт)</div>
                  <div class="table-cell" data-label="Единица измерения">1000 кг</div>
                  <div class="table-cell" data-label="Сумма, руб.">от 10 — 17 тыс. руб / т</div>
                </div>
                <div class="table-row">
                  <div class="table-cell table-cell--th" data-label="Название">Вертикальные и горизонтальные связи (грунт)</div>
                  <div class="table-cell" data-label="Единица измерения">1000 кг</div>
                  <div class="table-cell" data-label="Сумма, руб.">от 15 — 20 тыс. руб. / т</div>
                </div>
                <div class="table-row">
                  <div class="table-cell table-cell--th" data-label="Название">Прогоны</div>
                  <div class="table-cell" data-label="Единица измерения">1000 кг</div>
                  <div class="table-cell" data-label="Сумма, руб.">от 10 — 15 тыс. руб / т</div>
                </div>
                <div class="table-row">
                  <div class="table-cell table-cell--th" data-label="Название">Обшивка зданий профлистом</div>
                  <div class="table-cell" data-label="Единица измерения">метр²</div>
                  <div class="table-cell" data-label="Сумма, руб.">оот 180 — 220 руб. / м²</div>
                </div>
                <div class="table-row">
                  <div class="table-cell table-cell--th" data-label="Название">Обшивка зданий сэндвич-панелями</div>
                  <div class="table-cell" data-label="Единица измерения">метр²</div>
                  <div class="table-cell" data-label="Сумма, руб.">от 400 — 750 руб. / м²</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- section-->
        <div class="section section--no-pt">
          <div class="container">
            <div class="row row--advantages">
              <div class="col">
                <h3>Преимущества<br> металлоконструкций<br> «Маякметалл»</h3>
              </div>
              <div class="col">
                <ul class="list list--flex layout-advantages -col-3">
                  <li class="list-item">
                    <div class="advantage">
                      <div class="advantage-icon"><img src="@/assets/img/advantages/22.svg" alt=""></div>
                      <div class="advantage-title">Широкие возможности<br> применения</div>
                    </div>
                  </li>
                  <li class="list-item">
                    <div class="advantage">
                      <div class="advantage-icon"><img src="@/assets/img/advantages/6.svg" alt=""></div>
                      <div class="advantage-title">Легкая установка<br> и оперативный монтаж</div>
                    </div>
                  </li>
                  <li class="list-item">
                    <div class="advantage">
                      <div class="advantage-icon"><img src="@/assets/img/advantages/9.svg" alt=""></div>
                      <div class="advantage-title">Экономичная<br> транспортировка</div>
                    </div>
                  </li>
                  <li class="list-item">
                    <div class="advantage">
                      <div class="advantage-icon"><img src="@/assets/img/advantages/23.svg" alt=""></div>
                      <div class="advantage-title">Значительный срок<br> службы</div>
                    </div>
                  </li>
                  <li class="list-item">
                    <div class="advantage">
                      <div class="advantage-icon"><img src="@/assets/img/advantages/24.svg" alt=""></div>
                      <div class="advantage-title">Качество<br> и надежность</div>
                    </div>
                  </li>
                  <li class="list-item">
                    <div class="advantage">
                      <div class="advantage-icon"><img src="@/assets/img/advantages/25.svg" alt=""></div>
                      <div class="advantage-title">Прочность, устойчивость<br> к атмосферным<br> воздействиям</div>
                    </div>
                  </li>
                  <li class="list-item">
                    <div class="advantage">
                      <div class="advantage-icon"><img src="@/assets/img/advantages/26.svg" alt=""></div>
                      <div class="advantage-title">Ниже, чем у стандартного<br> строительства, цены<br> застройки</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- section-->
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Наши объекты</h2>
                </div>
                <div class="heading-aside"><a class="button button--default" href="#">Смотреть все</a></div>
              </div>
            </div>
            <ul class="list list--flex layout-objects">
              <li class="list-item"><a class="object" href="#">
                  <div class="object-image"><img src="@/assets/img/objects/1.jpg" alt=""/></div>
                  <div class="object-title">Здание склада<br> п.Новосемейкино</div></a>
              </li>
              <li class="list-item"><a class="object" href="#">
                  <div class="object-image"><img src="@/assets/img/objects/2.jpg" alt=""/></div>
                  <div class="object-title">Паровая котельная<br> г.Аксай</div></a>
              </li>
            </ul>
            <div class="heading-bottom"><a class="button button--default" href="#">Смотреть все</a></div>
          </div>
        </div>
        <!-- section-->
        <!-- <div class="section section--no-pt section--reviews">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Отзывы</h2>
                </div>
                <div class="heading-aside"><a class="button button--default" href="#" data-reviews-show-all>Смотреть все отзывы</a></div>
              </div>
            </div>
            <ul class="list list--flex layout-reviews" data-reviews>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_1" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_2" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_3" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_4" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
              <li class="list-item">
                <div class="review">
                  <div class="review-body">
                    <p>Покупал профлист в Тольятти, Обводное шоссе 9А. Заказал 18.08.2018, обслужили хорошо. 28.08.2018 получил смс, что мой заказ 1969 готов и бесплатный срок хранения до 02.09.2018. На следующий день мне перезвонила менеджер из офиса на Обводном шоссе 9А…</p>
                    <p><a href="#modal_review_5" data-modal>подробнее</a></p>
                  </div>
                  <div class="review-author">Юрий<br> Алексеев</div>
                  <div class="review-source">ЗАО «ТольяттиДизайн»</div>
                </div>
              </li>
            </ul>
            <div class="heading-bottom"><a class="button button--default" href="#" data-reviews-show-all>Смотреть все отзывы</a></div>
          </div>
        </div> -->

        <consultation v-if="serviсes.consultation != null" />

        <div class="section">
          <div class="container">
            <div class="inner">
              <div class="row row--contentframe">
                <div class="col">
                  <h2>Заказать<br> металлоконструкции<br> в Самаре</h2>
                </div>
                <div class="col">
                  <p>Основными несущими конструкциями являются одно и многопролетные рамы из сварных двутавров симметричного и моносимметричного сечения, размером стенки от 200мм до 2000мм по высоте стенки, длиной до 16000мм. Сварка двутавров выполняется в заводских условиях на автоматических линиях под слоем флюса.</p>
                  <p>Каркас рассчитан на нагрузки от подвесных кранов грузоподъемностью до 5т и от мостовых опорных кранов грузоподъемностью 5,10,16,20 т. Устойчивость и геометрическая неизменяемость здания обеспечивается: в поперечном направлении – конструкциями несущих рам;в продольном направлении – системой вертикальных связей и распорок.</p>
                  <p>Жесткость покрытия обеспечивается системой горизонтальных связей и распорок по верхнему поясу ригеля рамы; жесткость торцевых стен – системой вертикальных связей и распорок по тойкам фахверка.</p>
                  <p>Прогоны кровли выполняются по разрезной и неразрезной схемам. Основной шаг прогонов покрытия принимается равным 3, и 1,5. При разрезной схеме прогоны приняты из прокатных и гнутых швеллеров; при неразрезной схеме –из прокатных и гнутых швеллеров, а также Z- образных гнутых профилей.</p>
                  <p>Горизонтальные и вертикальные связи по каркасу и фахверку выполняются из круглой стали с предварительным натяжением или без натяжения. Вертикальные связи по подкрановым эстакадам – жесткими из прокатных профилей. Распорки между рамами выполняются одноветвевыми или двухветвевыми решетчатого типа. Допускается применение гибких растяжек вместо однопоясных распорок по нижним поясам ригелей рам, за исключением связевых блоков.</p>
                  <p>Все заводские соединения – сварные. Монтажные соединения на обычных и высокопрочных болтах.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- section-->
      </main>
</template>
<script>
import Consultation from '../services/consultation.vue'
import Vuex from 'vuex'

export default {
  components: {
      Consultation,
  },
  computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            pagesServices: 'dictionary/pagesServices',
        }),
    },
}
</script>