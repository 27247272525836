<template>
    <div class="block-step">
        <h4>{{ getStringOfStep(step) }}</h4>

        <label>Описание</label>
        <vue-editor 
            v-model="editForm.description" 
            @text-change="update"
        />
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor"
import Vuex from 'vuex'

export default {
    components: {
        VueEditor,
    },
    props: {
        step: {
            type: String,
            default: ""
        },
        calculatorType: {
            type: String,
            default: ""
        },
    },
    computed: {
        ...Vuex.mapGetters({
            calculators: 'app/calculators',
        }),
        editForm() {
            return Object.assign({}, this.calculators[this.calculatorType][this.step])
        },
    },
    methods: {
        ...Vuex.mapMutations({
            updateCalculators: 'app/updateCalculators',
        }),
        update() {
            this.updateCalculators({calculator: this.calculatorType, step: this.step, form: this.editForm})
        },
        getStringOfStep(val) {
            let response
            if (val == 'step1') {
                response = 'Шаг 1'
            }
            if (val == 'step1A') {
                response = 'Шаг 1A'
            }
            if (val == 'step2') {
                response = 'Шаг 2'
            }
            if (val == 'step3') {
                response = 'Шаг 3'
            }
            if (val == 'step4') {
                response = 'Шаг 4'
            }
            if (val == 'step5') {
                response = 'Шаг 5'
            }
            return response
        }
    },

}
</script>