<template>
    <main class="admin-index index__admin admin-offers-page">
        <div class="admin-index_header">
            <h3>Акции и предложения</h3>
            <!-- <button 
                class="button button--primary save_btn" 
                @click="updateData"
            >
                Сохранить
            </button> -->

        </div>
        
        <div 
            v-if="!isShowForm" 
            class="index__admin"
        >
            <button @click="changeForm(true)">
                Добавить акцию
            </button>
        </div>

        <create-offer 
            v-if="isShowForm" 
            @changeForm="changeForm" 
        />

        <div 
            v-for="(obj, ind) in uiPromotions" 
            :key="obj.id"
        >
            <offer-single 
                :key="obj.id"
                :index="ind"
                :element="obj"
            />
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import offerSingle from './singleOffer.vue'
import 'vue2-datepicker/index.css';
import createOffer from './createOffer.vue'

export default {
    components: {
        offerSingle,
        createOffer,
    },
    data() {
        return {
            isShowForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            offers: 'app/offers',
            fileUrl: 'app/fileUrl',

            uiPromotions: 'ui_promotion/promotions',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            pushOffer: 'app/pushOffer',
        }),
        changeForm(val) {
            this.isShowForm = val
        },
    },
}
</script>
<style lang="scss">
    .admin-offers-page{
        display: block !important;
    }
</style>
