import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    formCalculatorRoof: {
        cornice_type: 10,
        height_1: null,
        height_2: null,
        insulation_thickness: null,
        is_cornice: false,
        is_heat: false,
        heater: null,
        k: null,
        left_shift_1: null,
        left_shift_2: null,
        length_list_1: null,
        length_list_2: null,
        m: null,
        mounting_type: 10,
        manual_adjustment: false,
        roof_type: 10,
        view_material: "",
        width_1: null,
        width_2: null,
        width_3: null,
        root_1c_id: null,
        region_code: null,
        region_description: null,
        position: {},
        soffit_position: {},
    },
    formCalculatorFence: {
        amount_vein: null,
        distance_pole: null,
        double: false,
        fence_type: 10,
        height: null,
        height_pole: null,
        is_metal_poles: false,
        is_spacing_panks: false,
        order_id: null,
        perimeter: null,
        spacing_panks: null,
        position: {},
        region_code: null,
        region_description: null,
        view_material: "",
    },
    formCalculatorDrainage: {
        cornice_lentgh: null,
        color: null,
        distance: 0.6,
        facade_material: 10,
        height: null,
        is_mounted: false,
        region_code: null,
        region_description: null,
        roof_type: 10,
        view_gutter: null,
        width: null,
        width_2: null,
        root_1c_id: null
    },
    calculateResponse: {},
    positions: [],

    calc_fence_body: {},
    default_calc_fence_body: {
        id: 0,
        root_1c_id: null,
        prof: [],
        stack: [],
        additional: null,
        fasteners: null,
        fasteners_length: null,
        fasteners_width: null,
        nozzle: null,
        zaklep: null,
        clips: null,
        clips_length: null,
        clips_width: null,
        paint: null,
        scissors: null,
        cut_round: null,
        roulette: null,
        pillar: null,
        vein: null,
    },

    calc_drain_body: {
        root_1c_id: null,
        systems: [],
        corrector: null,
        pistol: null,
        sealant: null,
    },
    default_calc_drain_body: {
        root_1c_id: null,
        systems: [],
        corrector: null,
        pistol: null,
        sealant: null,
    },

    calc_roof_body: {
        corrugated_board: [],
        drill_bit: "",
        end_plank: "",
        skate_plank: "",

        metal_perimeter_plank: "",
        pvh_perimeter_plank: "",

        tapping_roof_wood: "",
        tapping_roof_wood_length: "",
        tapping_roof_wood_width: "",
        tapping_roof_metall: "",
        tapping_roof_metall_length: "",
        tapping_roof_metall_width: "",
        tapping_cornice_prof: "",
        tapping_cornice_prof_length: "",
        tapping_cornice_prof_width: "",
        tapping_cornice_soffit: "",
        tapping_cornice_soffit_length: "",
        tapping_cornice_soffit_width: "",
        tapping_add_wood: "",
        tapping_add_wood_length: "",
        tapping_add_wood_width: "",
        tapping_add_metal: "",
        tapping_add_metal_length: "",
        tapping_add_metal_width: "",
        
        outer_corner: "",

        fasteners_add: "",
        fasteners_add_length: "",
        fasteners_add_width: "",
        fasteners_roof: "",
        fasteners_roof_length: "",
        fasteners_roof_width: "",

        insulation: [],
        metal_tile: [],

        paint: "",

        root_1c_id: "",
        roulette: "",
        scissors: "",
        sealant: "",
        soffit: "",
        soffit_corrug: "",
        soffit_metall: "",
        soffit_pvh: "",
        stapler: "",
        staples: "",
        top_seal: "",
        hydro: "",
        para: "",
        parahydro: "",

        insulation_5: "",
        insulation_10: "",
        insulation_15: "",
        insulation_20: "",
    },
    default_calc_roof_body: {
        corrugated_board: [],
        drill_bit: "",
        end_plank: "",
        skate_plank: "",

        metal_perimeter_plank: "",
        pvh_perimeter_plank: "",

        tapping_roof_wood: "",
        tapping_roof_wood_length: "",
        tapping_roof_wood_width: "",
        tapping_roof_metall: "",
        tapping_roof_metall_length: "",
        tapping_roof_metall_width: "",
        tapping_cornice_prof: "",
        tapping_cornice_prof_length: "",
        tapping_cornice_prof_width: "",
        tapping_cornice_soffit: "",
        tapping_cornice_soffit_length: "",
        tapping_cornice_soffit_width: "",
        tapping_add_wood: "",
        tapping_add_wood_length: "",
        tapping_add_wood_width: "",
        tapping_add_metal: "",
        tapping_add_metal_length: "",
        tapping_add_metal_width: "",
        
        outer_corner: "",

        fasteners_add: "",
        fasteners_add_length: "",
        fasteners_add_width: "",
        fasteners_roof: "",
        fasteners_roof_length: "",
        fasteners_roof_width: "",

        insulation: [],
        metal_tile: [],
        paint: "",
        root_1c_id: "",
        roulette: "",
        scissors: "",
        sealant: "",
        soffit: "",
        soffit_corrug: "",
        soffit_metall: "",
        soffit_pvh: "",
        stapler: "",
        staples: "",
        top_seal: "",
        hydro: "",
        para: "",
        parahydro: "",

        insulation_5: "",
        insulation_10: "",
        insulation_15: "",
        insulation_20: "",
    },

    isShowDrainageImgModal: false,
}

const getters = {
    message: state => state.message,
    formCalculatorRoof: state => state.formCalculatorRoof,
    calculateResponse: state => state.calculateResponse,
    formCalculatorFence: state => state.formCalculatorFence,
    formCalculatorDrainage: state => state.formCalculatorDrainage,
    calc_fence_body: state => state.calc_fence_body,
    calc_drain_body: state => state.calc_drain_body,
    calc_roof_body: state => state.calc_roof_body,
    isShowDrainageImgModal: state => state.isShowDrainageImgModal,
}

const actions = {
    calculateRoof: ({commit, getters}) => {
        let object = JSON.parse(JSON.stringify(getters.formCalculatorRoof))
        object.height_1 = object.height_1 * 1000
        object.height_2 = object.height_2 * 1000
        object.width_1 = object.width_1 * 1000
        object.width_2 = object.width_2 * 1000
        object.width_3 = object.width_3 * 1000
        object.k = object.k * 1000
        object.m = object.m * 1000
        object.length_list_1 = object.length_list_1 * 1000
        object.length_list_2 = object.length_list_2 * 1000
        if (object.left_shift_1) {
            object.left_shift_1 = object.left_shift_1 * 1000
        }
        if (object.left_shift_2) {
            object.left_shift_2 = object.left_shift_2 * 1000
        }
        object.insulation_thickness = Number(object.insulation_thickness)
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/calculators/roof", object, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCalculateResponse", response.data)
                    resolve(response)
                })
        })
    },
    calculateFence: ({commit, getters}) => {
        let object = JSON.parse(JSON.stringify(getters.formCalculatorFence))
        object.height = object.height * 1000
        object.perimeter = object.perimeter * 1000
        object.distance_pole = object.distance_pole * 1000
        object.height_pole = object.height_pole * 1000
        object.spacing_panks = object.spacing_panks * 1000
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/calculators/fence", object, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCalculateResponse", response.data)
                    resolve(response)
                })
        })
    },
    calculateDrainage: ({commit, getters}) => {
        let object = JSON.parse(JSON.stringify(getters.formCalculatorDrainage))
        object.width = object.width * 1000
        object.width_2 = object.width_2 * 1000
        object.height = object.height * 1000
        object.cornice_lentgh = object.cornice_lentgh * 1000
        object.distance = object.distance * 1000
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/calculators/gutter", object, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCalculateResponse", response.data)
                    resolve(response)
                })
        })
    },
    calculatePosition: ({commit}, object) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/position/calculate", [object.position], {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", {position: response.data.positions[0], index: object.index})
                    resolve(response)
                })
        })
    },
    getColors: ({commit}, colors) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/colors/search", {ID1cs: colors}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", response.data)
                    resolve(response)
                })
        })
    },
    getCardParameters: ({commit}, obj) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search", obj, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", response.data)
                    resolve(response)
                })
        })
    },
    getCalcFence: ({commit}, id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/calc_fence", {root_1c_id: id_1c}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCalcFenceBody", {id_1c: id_1c, value: response.data})
                    resolve(response)
                })
        })
    },
    updateCalcFence: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.put(Config.apiUrl + "/v1/calc_fence/change", getters.calc_fence_body, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", response.data)
                    resolve(response)
                })
        })
    },
    getCalcDrain: ({commit}, id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/calc_drain", {root_1c_id: id_1c}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCalcDrainBody", {id_1c: id_1c, value: response.data})
                    resolve(response)
                })
        })
    },
    updateCalcDrain: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.put(Config.apiUrl + "/v1/calc_drain/change", getters.calc_drain_body, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", response.data)
                    resolve(response)
                })
        })
    },
    getCalcRoof: ({commit}, id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/calc_roof", {root_1c_id: id_1c}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCalcRoofBody", {id_1c: id_1c, value: response.data})
                    resolve(response)
                })
        })
    },
    updateCalcRoof: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.put(Config.apiUrl + "/v1/calc_roof/change", getters.calc_roof_body, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", response.data)
                    resolve(response)
                })
        })
    },
}


const mutations = {
    setIsShowDrainageImgModal: (state, val) => {
        state.isShowDrainageImgModal = val
    },
    setMessage: (state, val) => {
        state.message = val
    },
    setFormCalculatorRoof: (state, val) => {
        state.formCalculatorRoof = val
    },
    setFormCalculatorFence: (state, val) => {
        state.formCalculatorFence = val
    },
    setFormCalculatorDrainage: (state, val) => {
        state.formCalculatorDrainage = val
    },
    setCalculateResponse: (state, val) => {
        state.calculateResponse = val
    },
    deletePosition: (state, index) => {
        state.calculateResponse.order.positions.map((element, idx) => {
            if (index == idx) {
                state.calculateResponse.order.positions.splice(idx, 1)
            }
        })
    },
    updatePosition: (state, object) => {
        state.calculateResponse.positions.map((element, idx) => {
            if (idx == object.index) {
                state.calculateResponse.positions[idx] = object.position
            }
        })
    },
    updatePositionParameter: (state, object) => {
        state.calculateResponse.positions.map((element, idx) => {
            if (idx == object.index) {
                element[object.name] = object.value
                element.root_id = object.root_id
            }
        })
    },
    savePosition: (state, object) => {
        state.calculateResponse.positions.map((el, idx) => {
            if (idx == object.index) {	
                el.id_1c = object.id_1c
                el.coating = object.coating
                el.thickness = object.thickness
                el.overall_width = object.overall_width
                el.color = object.color
                el.root_id = object.root_id
                if (object.name) {
                    el.name = object.name
                }
                if (object.images) {
                    el.link_img = object.images[0]
                }
            }
        })
    },
    setDefaultFormDrainage: (state) => {
        state.formCalculatorDrainage = {
            cornice_lentgh: null,
            distance: 0.6,
            facade_material: 10,
            height: null,
            is_mounted: false,
            roof_type: 10,
            view_gutter: null,
            color: null,
            width: null,
            width_2: null
        }
    },
    setDefaultFormRoof: (state, roof_type) => {
        let roof = 10
        if (roof_type) {
            roof = roof_type
        }
        state.formCalculatorRoof = {
            cornice_type: 10,
            height_1: null,
            height_2: null,
            insulation_thickness: null,
            is_cornice: false,
            is_heat: false,
            k: null,
            left_shift_1: null,
            left_shift_2: null,
            length_list_1: null,
            length_list_2: null,
            m: null,
            mounting_type: 10,
            manual_adjustment: false,
            roof_type: roof,
            view_material: "",
            width_1: null,
            width_2: null,
            width_3: null
        }
    },
    setDefaultFormFence: (state) => {
        state.formCalculatorFence = {
            amount_vein: null,
            distance_pole: null,
            fence_type: 10,
            height: null,
            height_pole: null,
            is_metal_poles: false,
            is_spacing_panks: false,
            perimeter: null,
            spacing_panks: null,
            view_material: ""
        }
    },
    setCalcFenceBody: (state, object) => {
        if (!Array.isArray(object.value) || object.value.length == 0) {
            state.calc_fence_body = state.default_calc_fence_body
            state.calc_fence_body.root_1c_id = object.id_1c
            return
        }
        let last_pos = object.value.length - 1

        if (!object.value[last_pos].fasteners_length) {
            object.value[last_pos].fasteners_length = null
        }
        if (!object.value[last_pos].fasteners_width) {
            object.value[last_pos].fasteners_width =  null
        }
        if (!object.value[last_pos].clips_length) {
            object.value[last_pos].clips_length =  null
        }
        if (!object.value[last_pos].clips_width) {
            object.value[last_pos].clips_width =  null
        }

        state.calc_fence_body = object.value[last_pos]
        
    },
    setCalcDrainBody: (state, object) => {
        if (!Array.isArray(object.value) || object.value.length == 0) {
            state.calc_drain_body = state.default_calc_drain_body
            state.calc_drain_body.root_1c_id = object.id_1c
            return
        }
        let last_pos = object.value.length - 1
        state.calc_drain_body = object.value[last_pos]
        
    },
    setCalcRoofBody: (state, object) => {
        if (!Array.isArray(object.value) || object.value.length == 0) {
            state.calc_roof_body = state.default_calc_roof_body
            state.calc_roof_body.root_1c_id = object.id_1c
            return
        }
        let last_pos = object.value.length - 1

        if (!object.value[last_pos].tapping_roof_wood_length) {
            object.value[last_pos].tapping_roof_wood_length = null
        }
        if (!object.value[last_pos].tapping_roof_wood_width) {
            object.value[last_pos].tapping_roof_wood_width =  null
        }
        if (!object.value[last_pos].tapping_roof_metall_length) {
            object.value[last_pos].tapping_roof_metall_length = null
        }
        if (!object.value[last_pos].tapping_roof_metall_width) {
            object.value[last_pos].tapping_roof_metall_width = null
        }
        if (!object.value[last_pos].tapping_cornice_prof_length) {
            object.value[last_pos].tapping_cornice_prof_length = null
        }
        if (!object.value[last_pos].tapping_cornice_prof_width) {
            object.value[last_pos].tapping_cornice_prof_width = null
        }
        if (!object.value[last_pos].tapping_cornice_soffit_length) {
            object.value[last_pos].tapping_cornice_soffit_length = null
        }
        if (!object.value[last_pos].tapping_cornice_soffit_width) {
            object.value[last_pos].tapping_cornice_soffit_width = null
        }
        if (!object.value[last_pos].tapping_add_wood_length) {
            object.value[last_pos].tapping_add_wood_length = null
        }
        if (!object.value[last_pos].tapping_add_wood_width) {
            object.value[last_pos].tapping_add_wood_width = null
        }
        if (!object.value[last_pos].tapping_add_metal_length) {
            object.value[last_pos].tapping_add_metal_length = null
        }
        if (!object.value[last_pos].tapping_add_metal_width) {
            object.value[last_pos].tapping_add_metal_width = null
        }
        if (!object.value[last_pos].fasteners_add_length) {
            object.value[last_pos].fasteners_add_length = null
        }
        if (!object.value[last_pos].fasteners_add_width) {
            object.value[last_pos].fasteners_add_width = null
        }
        if (!object.value[last_pos].fasteners_roof_length) {
            object.value[last_pos].fasteners_roof_length = null
        }
        if (!object.value[last_pos].fasteners_roof_width) {
            object.value[last_pos].fasteners_roof_width = null
        }

        state.calc_roof_body = object.value[last_pos]
        
    },
    changeBodyCalcFence: (state, obj) => {
        if (obj.do == 'add' && (obj.type == 'prof' || obj.type == 'stack')) {
            state.calc_fence_body[obj.type].push(obj.value)
        }else if (obj.do == 'delete' && (obj.type == 'prof' || obj.type == 'stack')) {
            state.calc_fence_body[obj.type].map((element, idx) => {
                if (element == obj.value) {
                    state.calc_fence_body[obj.type].splice(idx, 1)
                }
            })
        }else{
            state.calc_fence_body[obj.type] = obj.value
        }
    },
    changeBodyCalcDrain: (state, obj) => {
        if (obj.do == 'add' && obj.type == 'systems') {
            state.calc_drain_body[obj.type].push(obj.value)
        }else if (obj.do == 'delete' && obj.type == 'systems') {
            state.calc_drain_body[obj.type].map((element, idx) => {
                if (element == obj.value) {
                    state.calc_drain_body[obj.type].splice(idx, 1)
                }
            })
        }else{
            state.calc_drain_body[obj.type] = obj.value
        }
    },
    changeBodyCalcRoof: (state, obj) => {
        if (obj.do == 'add' && (obj.type == 'insulation' || obj.type == 'corrugated_board' || obj.type == 'metal_tile')) {
            state.calc_roof_body[obj.type].push(obj.value)
        }else if (obj.do == 'delete' && (obj.type == 'insulation' || obj.type == 'metal_tile' || obj.type == 'corrugated_board')) {
            state.calc_roof_body[obj.type].map((element, idx) => {
                if (element == obj.value) {
                    state.calc_roof_body[obj.type].splice(idx, 1)
                }
            })
        }else{
            state.calc_roof_body[obj.type] = obj.value
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
