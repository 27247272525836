<template>
    <div class="resume_block" id="resume_form_block">
        <p class="resume_header">Отправить резюме</p>
        <p class="resume_subheader">Не нашли подходяшую вакансию? Оставьте телефон или почту, и мы свяжемся с вами!</p>
        <div class="resume_form_block">
            <div class="resume_form_row">
                <label for="name">Ваше имя</label>
                <input v-model="form.name" type="text" id="name" placeholder="ФИО">
            </div>
            <div class="resume_form_row_full">
                <div>
                    <label for="email">E-mail</label>
                    <input v-model="form.email" type="text" id="email" placeholder="primer@pochta.ru">
                </div>

                <div>
                    <label for="phone">Контактный телефон</label>
                    <input v-model="form.phone" type="text" id="phone" placeholder="+7 (999) 888-77-66">
                </div>

                <div>
                    <label for="city">Укажите город</label>
                    <input v-model="form.city" type="text" id="city" placeholder="город">
                </div>
            </div>
            <div class="resume_form_row">
                <label for="link">Ссылка на ваше резюмe</label>
                <input v-model="form.link_summary" type="text" id="link" placeholder="url">
            </div>
            <div class="resume_form_row_full ai-center">
                <label for="uploadResumeFile" style="cursor: pointer">
                    <img class="upload_resume_icon" src="@/assets/img/vacancy/resume_icon.png" alt="">
                </label>
                <input v-show="false" type="file" id="uploadResumeFile" ref="uploadResumeFile" @change="uploadFile">
                <p class="upload_resume_text">Прикрепить резюме</p>
                <p class="upload_resume_filename" v-if="form.file && form.file.name">{{form.file.name}}</p>
            </div>
            <div class="resume_form_row">
                <label for="comment">Комментарий</label>
                <input v-model="form.comment" type="text" id="comment" placeholder="Напишите комментарий или информацию о себе">
            </div>

            <button @click="send" class="button button--primary">Отправить заявку в отдель кадров</button>
            <p class="description_form">Нажимая на кнопку «Отправить резюме» вы даете согласие на обработку персональных данных</p>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            form: {
                name: null,
                email: null,
                phone: null,
                city: null,
                link_summary: null,
                file: null,
                comment: null,
            },

            isUploadedFile: false,
        }
    },
    methods: {
        ...Vuex.mapActions({
            sendResume: 'vacancy/sendResume'
        }),
        send() {
            this.sendResume(this.form)
                .then(() => {
                    this.form.name = null
                    this.form.email = null
                    this.form.phone = null
                    this.form.city = null
                    this.form.link_summary = null
                    this.form.file = null
                    this.form.comment = null
                })
        },
        uploadFile() {
            var file = this.$refs['uploadResumeFile'].files[0]
            this.form.file = file
        },
    },
}
</script>