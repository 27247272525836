<template>
    <div>
        <div class="section section--no-pt">
            <div class="container">
                <div class="inner">
                  <div class="heading">
                    <div class="heading-section">
                      <h2>Услуги</h2>
                    </div>
                  </div>
                </div>
                <ul class="list list--flex layout-cards -col-2">
                    <li 
                        class="list-item single-card-service" 
                        v-for="obj, i in legSrvices" 
                        :key="i" 
                        @click="openModal(obj.heading)"
                    >
                        <a class="card card--large" >
                            <div class="card-heading">
                                <div class="card-icon">
                                    <img :src="obj.img" alt="">
                                </div>
                                <div class="card-title">{{obj.heading}}</div>
                                <div class="card-description" v-html="obj.text"></div>
                            </div>
                            <div class="card-arrow">
                                <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                                    <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                                </svg>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <application 
          v-if="app == true" 
          :nameServiceForMessage="nameServiceForMessage"
        />
    </div>
</template>
<script>
import Vuex from 'vuex'

import application from '@/components/modals/application.vue'

export default {
    components: {
        application,
    },
    data() {
        return {
            nameServiceForMessage: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            legals: 'app/legals',
            serviсes: 'app/serviсes',
            fileUrl: 'app/fileUrl',
            app:'appmodal/app'
        }),
        legSrvices(){
            let dict = []
            if(!this.serviсes){
                return
            }
            if(!this.serviсes.serviсes){
                return
            }
            this.serviсes.serviсes.map((el)=>{
                if(el.from == 30 || el.from == null){
                    dict.push(el)
                    return
                }
            
            })
            return dict
        },
    },
    methods: {
        ...Vuex.mapMutations({
            setApp:'appmodal/setApp',
        }),
        openModal(name) {
            this.nameServiceForMessage = name 
            this.setApp(true)
        },
    },
}
</script>
<style lang="css">
    .single-card-service .card-heading{
      right: 32px;
    }
</style>