<template>
    <div class="index__admin">
        <span>Акция № {{index+1}}</span>
        <span 
            class="index__del" 
            @click="deleteUiPromotion(element.id)" 
        >
            Удалить Х
        </span>
        <input 
            type="text" 
            v-model="editElement.name"
            placeholder="Заголовок"
            @click="isChangeValues = true"
        >

        <div>
            <input 
                type="text" 
                v-model="editElement.link"
                placeholder="Ссылка"
            >
            <div class="row">
                <div>
                    <label>Начальная дата</label>
                    <date-picker 
                        @change="update"  
                        class="picker" 
                        v-model="startDate" 
                        value-type="format" 
                        :format="'DD.MM.YYYY'" 
                        :first-day-of-week="1"
                    ></date-picker>
                </div>
                <div>
                    <label>Конечная дата</label>
                    <date-picker 
                        @change="update" 
                        class="picker" 
                        v-model="closeDate" 
                        value-type="format" 
                        :format="'DD.MM.YYYY'" 
                        :first-day-of-week="1"
                    ></date-picker>
                </div>
                <div>
                    <label>Для кого акция</label>
                    <select  
                        class="picker"  
                        v-model="editElement.type" 
                        @change="update"
                    >
                        <option 
                            v-for="type in offersFrom" 
                            :key="type.id" 
                            :value="type.id"
                        >
                            {{type.name}}
                        </option>
                    </select>
                </div>
    
                <div>
                    <label>Для какого региона акция</label>
                    <select 
                        class="picker" 
                        v-model="editElement.root_id" 
                        @change="update"
                    >
                        <option :value="0">Акция по всем регионам</option>
                        <option v-for="reg, i in regions" :key="i" :value="reg.id_1c">{{reg.name}}</option>
                    </select>
                </div>
            </div>
    
            <span>Подзаголовок</span>
            <div>
                <vue-editor 
                    v-model="editElement.subheading"   
                    @text-change="update"
                    @paste="update"
                />
            </div>
    
            <span>Детали</span>
            <div>
                <vue-editor 
                    v-model="editElement.details"    
                    @text-change="update"
                />
            </div>
    
            <label>Ссылка на изображение</label>
            <input 
                type="text" 
                v-model="editElement.linkimg"  
                @change="update" 
                placeholder="Ссылка на изображение"
            >
            
            <div class="row">
                <label class="fl__img" >
                    <input 
                        :ref="'file'+ index" 
                        type="file" 
                        @change="uploadImg()"
                    >
                </label>
            </div>
    
            <img
                :src="editElement.linkimg" 
                width="50%"    
            >
            <br>
            <button @click="updateChanges" class="button button--primary">Сохранить изменения</button>
        </div>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import { VueEditor } from "vue2-editor"
import Vuex from 'vuex'
export default {
    components: {
        DatePicker, 
        VueEditor
    },
    props:['element', 'index', 'type'],
    data() {
        return {
            isChangeValues: false,
            startDate: null,
            closeDate: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            offers: 'app/offers',
            fileUrl: 'app/fileUrl',
            offersFrom:'dictionary/offersFrom',
            regions:'dictionary/topLevelCategory',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
        formatedStartDate() {
            return this.formatDate(this.editElement.start_date)
        },  
        formatedCloseDate() {
            return this.formatDate(this.editElement.close_date)
        },  
    },
    mounted() {
        this.startDate = this.formatedStartDate
        this.closeDate = this.formatedCloseDate
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            deleteUiPromotion: 'ui_promotion/deletePromotion',
            updateUiPromotion: 'ui_promotion/updatePromotion',
        }),
        ...Vuex.mapMutations({
            updateOffer: 'app/updateOffer',
            deleteOffer: 'app/deleteOffer',
        }),
        updateChanges() {
            this.update()
            this.isChangeValues = false
        },
        formatDate(date) {
            let splited = date.split('T')
            let splited_date = splited[0].split('-')
            return splited_date[2] + '.' + splited_date[1] + '.' + splited_date[0]
        },
        reformatDate(date) {
            let splited_date = date.split('.') 
            return splited_date[2] + '-' + splited_date[1] + '-' + splited_date[0] + 'T00:00:00.000Z' 
        },
        update() {
            this.editElement.root_id = this.editElement.root_id + ''
            this.editElement.type = this.editElement.type + ''

            this.editElement.start_date = this.reformatDate(this.startDate)
            this.editElement.close_date = this.reformatDate(this.closeDate)

            this.updateUiPromotion(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['file' + this.index].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.updateUiPromotion({
                        id: this.element.id,
                        close_date: this.editElement.close_date,
                        details: this.editElement.details,
                        link: this.editElement.link,
                        linkimg: this.fileUrl + response.data[0].link,
                        name: this.editElement.name,
                        root_id: this.editElement.root_id,
                        start_date: this.editElement.start_date,
                        subheading: this.editElement.subheading,
                        type: this.editElement.type,
                    })
                })
        },
    },
}
</script>