<template>
    <div class="index__admin">
        <input 
            type="text" 
            v-model="newPromotion.name" 
            placeholder="Заголовок"
        >
        <input 
            type="text" 
            v-model="newPromotion.link" 
            placeholder="Ссылка"
        >
        <div class="row">
            <div>
                <label>Начальная дата</label>
                <date-picker  
                    class="picker" 
                    v-model="newPromotion.start_date" 
                    value-type="format" 
                    :format="'DD.MM.YYYY'" 
                    :first-day-of-week="1"
                ></date-picker>
            </div>
            <div>
                <label>Конечная дата</label>
                <date-picker 
                    class="picker" 
                    v-model="newPromotion.close_date" 
                    value-type="format" 
                    :format="'DD.MM.YYYY'" 
                    :first-day-of-week="1"
                ></date-picker>
            </div>
            <div>
                <label>Для кого акция</label>
                <select  
                    class="picker"  
                    v-model="newPromotion.type"
                >
                    <option 
                        v-for="type in offersFrom" 
                        :key="type.id" 
                        :value="type.id"
                    >
                        {{ type.name }}
                    </option>
                </select>
            </div>

            <div>
                <label>Для какого региона акция</label>
                <select 
                    class="picker" 
                    v-model="newPromotion.root_id"
                >
                    <option :value="0">Акция по всем регионам</option>
                    <option 
                        v-for="reg, i in regions" 
                        :key="i" 
                        :value="reg.id_1c"
                    >
                        {{reg.name}}
                    </option>
                </select>
            </div>
        </div>

        <span>Подзаголовок</span>
        <div>
            <vue-editor 
                v-model="newPromotion.subheading"
            />
        </div>

        <span>Детали</span>
        <div>
            <vue-editor 
                v-model="newPromotion.details"
            />
        </div>

        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="newPromotion.linkimg" 
            placeholder="Ссылка на изображение"
        >
        <div class="row">
            <label class="fl__img" >
                <input 
                    ref="singleOfferFile" 
                    type="file" 
                    @change="uploadImg()"
                >
            </label>
        </div>

        <img
            :src="newPromotion.linkimg" 
            width="50%"
        >

        <button @click="createPromotion">
            Сохранить
        </button>
    </div>
</template>
<script>
import Vuex from 'vuex'
import DatePicker from 'vue2-datepicker'
import { VueEditor } from "vue2-editor"

export default {
    components: {
        DatePicker, 
        VueEditor
    },
    data() {
        return {
            newPromotion: {
                close_date: null,
                details: null,
                link: null,
                linkimg: null,
                name: null,
                root_id: 0,
                start_date: null,
                subheading: null,
                type: 0,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
            offersFrom:'dictionary/offersFrom',
            regions:'dictionary/topLevelCategory',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            createUiPromotion: 'ui_promotion/createPromotion',
        }),
        uploadImg() {
            let image = this.$refs['singleOfferFile'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newPromotion.linkimg = this.fileUrl + response.data[0].link
                })
        },
        reformatDate(date) {
            let splited_date = date.split('.') 
            return splited_date[2] + '-' + splited_date[1] + '-' + splited_date[0] + 'T00:00:00.000Z' 
        },
        createPromotion() {
            this.newPromotion.start_date = this.reformatDate(this.newPromotion.start_date)
            this.newPromotion.close_date = this.reformatDate(this.newPromotion.close_date)
            this.newPromotion.type = this.newPromotion.type + '' 
            this.newPromotion.root_id = this.newPromotion.root_id + '' 
            this.createUiPromotion(this.newPromotion)
                .then(() => {
                    this.$emit('changeForm', false)
                    this.newPromotion.close_date = null
                    this.newPromotion.details = null
                    this.newPromotion.link = null
                    this.newPromotion.linkimg = null
                    this.newPromotion.name = null
                    this.newPromotion.root_id = null
                    this.newPromotion.start_date = null
                    this.newPromotion.subheading = null
                    this.newPromotion.type = null
                })
        },
    },
}
</script>