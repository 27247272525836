<template>
    <main class="main">
        <div class="section section--no-pt">
          <bread-crumbs :pages-array="pagesSupports" />
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Поддержка</h2>
                </div>
              </div>
            </div>
            <div class="layout layout--page layout--page-wide layout--section-gap">
              <div class="layout-aside">
                <ul class="list navigation-aside">
                  <li class="list-item"><a class="list-link" @click="push('documents/litsenzii')">Сертификаты<br> и лицензии</a></li>
                  <li class="list-item"><a class="list-link" @click="push('support/technicals')">Технические решения</a></li>
                  <li class="list-item -active"><a class="list-link" href="#">Видео и учебные<br> материалы</a></li>
                   <li class="list-item"><a class="list-link" @click="push('support/faq')">FAQ</a></li>
                </ul>
              </div>
              <div class="layout-section">
                <div class="list layout-videos">
                    <div class="list-item" v-for="(obj, i) in uiSupportsVideo" :key="i" >
                        <a class="video video--large"  @click="openVideo(obj.link)" data-modal-video>
                            <div class="video-preview" :style="{ backgroundImage: 'url(' + obj.linkimg + ')' }">
                                <div class="video-button">Смотреть</div>
                            </div>
                            <div class="video-body">
                                <!-- <div class="video-date">{{ getDate(obj.dateAdd) }}</div> -->
                                <div class="video-title">{{ obj.header }}</div>
                            </div>
                        </a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="video != null" >
            <modal-video :video="video"/>
            
        </div>
    </main>
</template>

<script>
import Vuex from 'vuex'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Functions from '@/utils/functions'
import ModalVideo from './videoModal.vue'
export default {
  mixins: [Functions],
  components:{
      ModalVideo,
      breadCrumbs
  },
  data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters({
            supports: 'app/supports',
            video:'videomodal/video',
            pagesSupports: 'dictionary/pagesSupports',
            uiSupports: 'ui_support/supports',
        }),
        uiSupportsVideo() {
            let video_array = []
            this.uiSupports.map((element) => {
                if (element.type == 20) {
                    video_array.push(element)
                }
            })
            return video_array
        },
    },
    methods:{
        ...Vuex.mapMutations({
            setVideo: 'videomodal/setVideo',
            
        }),
        getDate(datetime) {
            if (!datetime) {
                return null
            }
            var options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',

            }
            var date = new Date(datetime)
            var d = date.toLocaleString("ru", options)
            return d
        },
        openVideo(val){
            this.setVideo(val)
        }
    }

}
</script>