<template>
    <div class="admin-settings index__admin">
        <div class="admin-index_header">
            <h3>Настройки</h3>
            <button 
                class="button button--primary save_btn" 
                @click="save"
            >
                Сохранить
            </button>
        </div>
        <div class="content">
            <div class="setting-emails">
                <h4>Настройка почт</h4>
                <div class="email-block">
                    <h4>Почты для претензий</h4>
                    <single-email v-for="(claim, index) in claim_emails" :key="claim.id" :email="claim" :index="index" :emailName="'claim_emails'" />
                    <button @click="addEmail('claim_emails')">Добавить</button>
                </div>

                <div class="email-block">
                    <h4>Почты для заявок</h4>
                    <single-email v-for="(common, index) in common_emails" :key="index" :email="common" :index="index" :emailName="'common_emails'" />
                    <button @click="addEmail('common_emails')">Добавить</button>
                </div>

                <div class="email-block">
                    <h4>Почты для отзывов</h4>
                    <single-email v-for="(review, index) in review_emails" :key="index" :email="review" :index="index" :emailName="'review_emails'" />
                    <button @click="addEmail('review_emails')">Добавить</button>
                </div>

                <div class="email-block">
                    <h4>Почты для заказа проекта</h4>
                    <single-email v-for="(project, index) in project_emails" :key="index" :email="project" :index="index" :emailName="'project_emails'" />
                    <button @click="addEmail('project_emails')">Добавить</button>
                </div>

                <div class="email-block">
                    <h4>Почты для технических вопросов</h4>
                    <single-email v-for="(support, index) in support_emails" :key="index" :email="support" :index="index" :emailName="'support_emails'" />
                    <button @click="addEmail('support_emails')">Добавить</button>
                </div>

                <div class="email-block">
                    <h4>Почты для актов сверки</h4>
                    <single-email v-for="(support, index) in reconciliation_act" :key="index" :email="support" :index="index" :emailName="'reconciliation_act'" />
                    <button @click="addEmail('reconciliation_act')">Добавить</button>
                </div>

                <div class="email-block">
                    <h4>Почты для отдела кадров</h4>
                    <single-email v-for="(support, index) in hr_department" :key="index" :email="support" :index="index" :emailName="'hr_department'" />
                    <button @click="addEmail('hr_department')">Добавить</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import singleEmail from './singleEmail.vue'


export default {
    components: {
        singleEmail,
    },
    computed: {
        ...Vuex.mapGetters({
            claim_emails: 'app/claim_emails',
            common_emails: 'app/common_emails',
            review_emails: 'app/review_emails',
            project_emails: 'app/project_emails',
            support_emails: 'app/support_emails',
            reconciliation_act: 'app/reconciliation_act',
            hr_department: 'app/hr_department',
        }),
    },
    created() {
        this.getTechnicalEmails()
    },
    methods: {
        ...Vuex.mapActions({
            getTechnicalEmails: 'app/getTechnicalEmails',
            updateTechnicalEmails: 'app/updateTechnicalEmails',
            updateCastomizeCard: 'catalog/updateCastomizeCard',
        }),
        ...Vuex.mapMutations({
            addTechnicalEmail: 'app/addTechnicalEmail',
        }),
        addEmail(type) {
            this.addTechnicalEmail(type)
        },
        save() {
            this.reloadEmails()
            // this.updateCastomizeCard()
        },
        reloadEmails() {
            let claim_array = []
            if (this.claim_emails.length != 0) {
                this.claim_emails.map(element => {
                    claim_array.push(element.value)
                })
            }

            let common_array = []
            if (this.common_emails.length != 0) {
                this.common_emails.map(element => {
                    common_array.push(element.value)
                })
            }
            
            let review_array = []
            if (this.review_emails.length != 0) {
                this.review_emails.map(element => {
                    review_array.push(element.value)
                })
            }

            let project_array = []
            if (this.project_emails.length != 0) {
                this.project_emails.map(element => {
                    project_array.push(element.value)
                })
            }

            let support_array = []
            if (this.support_emails.length != 0) {
                this.support_emails.map(element => {
                    support_array.push(element.value)
                })
            }

            let act_array = []
            if (this.reconciliation_act.length != 0) {
                this.reconciliation_act.map(element => {
                    act_array.push(element.value)
                })
            }

            let hr_array = []
            if (this.hr_department.length != 0) {
                this.hr_department.map(element => {
                    hr_array.push(element.value)
                })
            }
        
            this.updateTechnicalEmails({
                claim_emails: claim_array,
                common_emails: common_array,
                review_emails: review_array,
                project_emails: project_array,
                support_emails: support_array,
                reconciliation_act: act_array,
                hr_department: hr_array,
            })
        },
    }    
}
</script>