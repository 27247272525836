<template>
    <main class="admin-profile">
        <div class="admin-profile_header">
            <h3>Профиль</h3>
        </div>
        <div class="admin-profile_content">
            <div class="profile-img">
                <img src="@/assets/img/ava.svg" alt="">
            </div>
            <div class="profile-name">
                <input 
                    type="text" 
                    placeholder="Имя"
                    v-model="userName"
                >
            </div>
            <div
                class="profile-message_block" 
            >
                <div 
                    v-show="isShowMessageBlock"
                    class="success-message"
                >
                    <p>{{ message }}</p>
                    <img 
                        class="close-btn" 
                        src="@/assets/img/close.svg"
                        @click="isShowMessageBlock = false"
                    >
                </div>
            </div>
            <div class="profile-password">
                <label for="">Изменить пароль</label>
                <input 
                    type="password" 
                    placeholder="Новый пароль"
                    v-model="form.password"
                >
                <input 
                    type="password" 
                    placeholder="Подтверждение пароля"
                    v-model="form.confirm"
                >
                <button class="button button--primary" @click="changePassword">Сменить пароль</button>
            </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            form: {
                password: null,
                confirm: null,
            },
            userName: null,
            isShowMessageBlock: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            message: 'auth/message',
            user: 'auth/user',
        }),
    },
    mounted() {
        this.userName = this.user.name
    },
    methods: {
        ...Vuex.mapActions({
            changePasswordStore: 'auth/changePassword',   
        }),
        changePassword() {
            this.changePasswordStore(this.form)
                .then(() => {
                    this.form.password = null
                    this.form.confirm = null
                    this.isShowMessageBlock = true
                })
        }
    }
}
</script>