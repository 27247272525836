<template>
  <section>
    <div class="single_char">
        <input type="text" :value="nameCharById" readonly>
        <input type="text" v-model="value" @keyup="updateValueChar">

        <button class="button button--primary" @click="deleteChar">Удалить</button>
    </div>
  </section>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            value: '',
        }
    },
    computed: {
        ...Vuex.mapGetters({
            chars: 'coatingChars/chars',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
        nameCharById() {
            var name = null
            this.chars.map((element) => {
                if (element.id == this.editElement.char_id) {
                    name = element.name
                }
            })
            return name
        }
    },
    mounted() {
        this.value = this.editElement.value
    },
    methods: {
        updateValueChar() {
            this.$emit('updateValueChar', {char_id: this.editElement.char_id, value: this.value})
        },
        deleteChar() {
            this.$emit('deleteChar', this.editElement.char_id)
        }
    },
}
</script>