<template>
    <div class="admin-documents_single-document index__admin">
        <span>Категория</span>
        <span 
            class="index__del"
            @click="deleteUiDocument(element.id)"  
        >
            Удалить Х
        </span>
        <select 
            id="categories"
            v-model="editElement.category" 
            name="categories" 
            @change="update"
        >
            <option 
                v-for="category in categories" 
                :key="category.id"
                :value="category.id"
            >
                {{ category.name }}
            </option>
        </select>
        <input 
            v-model="editElement.name"
            type="text" 
            placeholder="Наименование документа"
            @change="update"
        >
        <input 
            v-model="editElement.link"
            type="text" 
            placeholder="Ссылка на файл"
            @change="update"
        >
        <div class="row">
            <label class="fl__doc">
                <input 
                    :ref="'fileSingleDocument'+ index" 
                    type="file" 
                    @change="uploadFile"
                >
            </label>
            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
        </div>

        <img 
            :id="'fileDisplay' + index"
            :src="editElement.link" 
            alt=""
            class="document-image"
        >

        <input 
            v-model="editElement.img"
            type="text" 
            placeholder="Ссылка на изображение"
            @change="update"
        >
        <div class="row">
            <label class="fl__doc fl__preview">
                <input 
                    :ref="'fileSingleDocumentImg'+ index" 
                    type="file" 
                    @change="uploadImg"
                >
            </label>
            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
        </div>

        <img 
            :id="'fileDisplay' + index"
            :src="editElement.img" 
            alt=""
            class="document-image"
        >
    </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...Vuex.mapGetters({
            categories: 'dictionary/documentsCats',
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            deleteUiDocument: 'ui_document/deleteDocument',
            updateUiDocument: 'ui_document/updateDocument',
        }),
        update() {
            this.updateUiDocument(this.editElement)
        },
        uploadFile() {
            let file = this.$refs['fileSingleDocument' + this.index].files[0]
            this.upload({file: file})
                .then((response) => {
                    this.updateUiDocument({
                        id: this.editElement.id,
                        link: this.fileUrl + response.data[0].link,
                        name: this.editElement.name,
                        root_id: this.editElement.root_id,
                        category: this.editElement.category,
                        img: this.editElement.img,
                    })
                })
        },
        uploadImg() {
            let file = this.$refs['fileSingleDocumentImg' + this.index].files[0]
            this.upload({file: file})
                .then((response) => {
                    this.updateUiDocument({
                        id: this.editElement.id,
                        link: this.editElement.link,
                        name: this.editElement.name,
                        root_id: this.editElement.root_id,
                        category: this.editElement.category,
                        img: this.fileUrl + response.data[0].link,
                    })
                })
        },
    },
}
</script>