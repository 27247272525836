<template>
  <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup">
                            <p>Заказ заполнен некорректно,</p>
                            <p>невозможно повторить.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        close() {
            this.$emit('closeModal')
        },
    }
}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>