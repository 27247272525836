<template>
    <div class="navigation " :class="getClassMenu()" data-navigation>
        <div class="navigation-main">
            <div class="navigation-main-header">
                <button class="navigation-main-button-toggle" data-navigation-toggle="short" @click="changeStateLeftMenu('short')">
                    <span class="navigation-main-button-toggle-icon" />
                    <span class="navigation-main-button-toggle-title">
                        <span>Полное меню</span>
                        <span>Скрыть меню</span>
                    </span>
                </button>
            </div>
            <div class="navigation-main-footer">
                <button class="navigation-main-button-catalog" data-navigation-toggle="full" @click="changeStateLeftMenu('full')">
                    <span>Каталог</span>
                    <span>Открыть каталог
                        <svg class="icon" width="12" height="9" viewBox="0 0 12 9">
                            <use xlink:href="@/assets/img/icons.svg#navigation_arrow"></use>
                        </svg>
                    </span>
                    <span>
                        <svg class="icon" width="12" height="9" viewBox="0 0 12 9">
                            <use xlink:href="@/assets/img/icons.svg#navigation_arrow"></use>
                        </svg>
                        Скрыть каталог
                    </span>
                </button>
            </div>
            <div class="navigation-main-body">
                <ul class="list layout-navigation-main">
                    <li class="list-item" @click="pushToCalculators">
                        <a class="navigation-link">
                            <div class="navigation-link-icon">
                                <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                    <use xlink:href="@/assets/img/icons.svg#navigation_calc"></use>
                                </svg>
                            </div>
                            <div class="navigation-link-title">Конструктор</div>
                        </a>
                    </li>
                    <li class="list-item" @click="pushToProject('calculators/roof')">
                        <a class="navigation-link">
                            <div class="navigation-link-icon">
                                <svg class="icon" width="16.877" height="18" viewBox="0 0 16.877 18">
                                    <use xlink:href="@/assets/img/icons.svg#navigation_roof"></use>
                                </svg>
                            </div>
                            <div class="navigation-link-title">Кровля</div>
                        </a>
                    </li>
                    <li class="list-item" @click="pushToProject('calculators/drainage')">
                        <a class="navigation-link">
                            <div class="navigation-link-icon">
                                <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                    <use xlink:href="@/assets/img/icons.svg#navigation_drop"></use>
                                </svg>
                            </div>
                            <div  class="navigation-link-title">Водостоки</div>
                        </a>
                    </li>
                    <li class="list-item" @click="pushToProject('calculators/fencing')">
                        <a class="navigation-link">
                            <div class="navigation-link-icon">
                                <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                    <use xlink:href="@/assets/img/icons.svg#navigation_fence"></use>
                                </svg>
                            </div>
                            <div  class="navigation-link-title">Ограждения</div>
                        </a>
                    </li>
                    <li class="list-item" @click="pushToProject('paydelivery')">
                        <a class="navigation-link">
                            <div class="navigation-link-icon">
                                <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                    <use xlink:href="@/assets/img/icons.svg#navigation_delivery"></use>
                                </svg>
                            </div>
                            <div class="navigation-link-title">Оплата и доставка</div>
                        </a>
                    </li>
                    <li class="list-item" @click="pushToLogin">
                        <a class="navigation-link">
                            <div class="navigation-link-icon">
                                <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                    <use xlink:href="@/assets/img/icons.svg#navigation_avatar"></use>
                                </svg>
                            </div>
                            <div class="navigation-link-title">Личный кабинет</div>
                        </a>
                    </li>
                    <li class="list-item" @click="pushToProject('basket')">
                        <a class="navigation-link">
                            <div class="navigation-link-icon">
                                <div class="flycart" v-if="order.positions.length != 0">{{ order.positions.length }}</div>
                                <svg class="icon" width="18" height="18" viewBox="0 0 18 18">
                                    <use xlink:href="@/assets/img/icons.svg#navigation_cart"></use>
                                </svg>
                            </div>
                            <div class="navigation-link-title">Корзина</div>
                        </a>
                    </li>
                    <li class="list-item" @click="changeStateLeftMenu('short')">
                        <a class="navigation-link navigation-link--back" data-navigation-toggle="short">
                            <div class="navigation-link-icon">
                                <svg class="icon" width="12" height="9" viewBox="0 0 12 9">
                                    <use xlink:href="@/assets/img/icons.svg#navigation_arrow"></use>
                                </svg>
                            </div>
                            <div class="navigation-link-title">Свернуть</div>
                        </a>
                    </li>
                </ul>
            </div>
            
        </div>
        <div class="navigation-sub">
            <div class="navigation-sub-body">
                <ul class="list layout-navigation-sub">
                    <li class="list-item" v-for="category in secondLevelCategory" :key="category.id" @click="pushToCatalog(category.id_1c)">
                        <a class="navigation-link">
                            <div class="navigation-link-icon">
                                <img 
                                    v-if="category.preview"
                                    :src="yandexCloud + category.preview"
                                    class="category-preview-img"
                                    alt=""
                                >
                                <svg 
                                    v-else
                                    class="icon" 
                                    width="60" 
                                    height="60" 
                                    viewBox="0 0 60 60"
                                >
                                    <use xlink:href="@/assets/img/icons.svg#roof_systems"></use>
                                </svg>
                            </div>
                            <div class="navigation-link-title">{{ category.name }}</div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    data() {
        return {
            
        }
    },
    computed: {
        ...Vuex.mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            stateLeftMenu: 'app/stateLeftMenu',
            searchForm: 'catalog/form',
            order: 'basket/order',
            region: 'auth/city',
            yandexCloud: 'app/yandexCloud',
            secondLevelCategory: 'dictionary/secondLevelCategory',
        }),
        form() {
            return Object.assign({}, this.searchForm)
        },
    },
    methods: {
        ...Vuex.mapMutations({
            setStateLeftMenu: 'app/setStateLeftMenu',
            setForm: 'catalog/setForm',
        }),
        ...Vuex.mapActions({
            search: 'catalog/search',
        }),
        pushToLogin() {
            this.setStateLeftMenu('')
            if (this.isAuthenticated) {
                this.push('nlk')
            }else{
                this.push('login')
            }
        },
        changeStateLeftMenu(type) {
            if (type == 'short') {
                if (this.stateLeftMenu) {
                    this.setStateLeftMenu('')
                }else{
                    this.setStateLeftMenu('short')
                }
            }else if (type == 'full') {
                if (this.stateLeftMenu && this.stateLeftMenu == 'full') {
                    this.setStateLeftMenu('short')
                }else{
                    this.setStateLeftMenu('full')
                }
            }
        },
        getClassMenu() {
            let response = ''
            if (this.stateLeftMenu == 'short') {
                response = 'navigation--short'
            }else if (this.stateLeftMenu == 'full') {
                response = 'navigation--short navigation--full'
            }
            return response
        },
        pushToCatalog(id_1c) {
            this.setStateLeftMenu('')
            this.form.parent_id = id_1c
            this.setForm(this.form)
            this.search()
            this.$router.push('/catalog/' + id_1c).then(() => {})
        },
        pushToProject(str) {
            if (!this.region) {
                return
            }
            this.setStateLeftMenu('')
            this.push(str)
        },
        pushToCalculators() {
            if (!this.region) {
                return
            }
            this.setStateLeftMenu('')
            this.push('')

            setTimeout(() => {
                let element = document.getElementById('main_page_calcurators')
                let top = element.offsetTop
                window.scrollTo(0, top)
            }, 500)
        },
    }
}
</script>
<style lang="css">
    .navigation-link-icon{
        position: relative;
    }

    .flycart{
        position: absolute;
        top: -15px;
        right: 9px;
        font-size: 1.0rem;
    }
    .category-preview-img{
        height: 60px;
        width: 60px;
    }
</style>