<template>
    <section class="admin-coatings-page admin-index index__admin">
        <div class="admin-index_header">
            <h3>Вакансии</h3>
        </div>

        <div class="buttons-block">
            <button class="button button--primary" @click="isShowCreateForm = true">Добавить</button>
        </div>
        <div class="main_block">
            <div v-if="isShowCreateForm" class="single-coating-block">
                <div class="create-coating">
                    <div class="single_vacancy_admin_block">
                        <p class="header_create_block">Главный блок</p>
                        <label for="">Заголовок</label>
                        <input 
                            type="text" 
                            v-model="createForm.main_block.header"
                        >
                        <label for="">Подзаголовок</label>
                        <input 
                            type="text" 
                            v-model="createForm.main_block.subheader"
                        >
                        <label for="">Ссылка на hh.ru</label>
                        <input 
                            type="text" 
                            v-model="createForm.main_block.hh_link"
                        >
                        <label for="">Изображения</label>
                        <div class="list_imgs">
                            <div class="single_img" v-for="singleImg, idx in createForm.main_block.list_imgs" :key="idx">
                                <img :src="singleImg" alt="" class="coating_main_img">
                                <i class="fas fa-times" @click="deleteImgGallery(singleImg)"></i>
                            </div>
                        </div>
                        <div class="row">
                            <label class="fl__img">
                                <input 
                                    ref="vacancyMainBlockGalleryUpload" 
                                    type="file" 
                                    @change="uploadGalleryImg"
                                >
                            </label>
                            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                        </div>
                    </div>


                    <div class="single_vacancy_admin_block">
                        <p class="header_create_block">Блок достижений</p>
                        <label for="">Заголовок</label>
                        <input 
                            type="text" 
                            v-model="createForm.progress_block.header"
                        >
                        <label for="">Описание</label>
                        <textarea 
                            class="custom_textarea"
                            type="text" 
                            v-model="createForm.progress_block.description"
                            rows="3"
                        ></textarea>

                        <button v-if="!isShowCreateFormProgress" class="button button--primary" @click="isShowCreateFormProgress = true">Добавить достижение</button>

                        <div v-if="isShowCreateFormProgress">
                            <p class="header_create_block">Новое достижение</p>
                            <label for="">Название</label>
                            <input 
                                type="text" 
                                v-model="newProgress.name"
                            >
                            <label for="">Описание</label>
                            <input 
                                type="text" 
                                v-model="newProgress.description"
                            >
                            <label for="">Изображение</label>
                            <input 
                                type="text" 
                                v-model="newProgress.img"
                            >
                            <img v-if="newProgress.img" :src="newProgress.img" alt="">
                            <div class="row">
                                <label class="fl__img">
                                    <input 
                                        ref="vacancyProgressBlockUpload" 
                                        type="file" 
                                        @change="uploadProgressImage"
                                    >
                                </label>
                                <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                            </div>
                            <button class="button button--primary" @click="createProgress">Добавить</button>
                        </div>

                        <div class="progress_list_block">
                            <p class="header_create_block">Список достижений</p>

                            <div class="progress_elements">
                                <div  
                                    v-for="single_progress, idx in createForm.progress_block.list_progress"
                                    :key="idx"
                                    class="progress_elem"
                                >
                                    <img v-if="single_progress.img" class="progress_elem_image" :src="single_progress.img" alt="">
                                    <p v-if="single_progress.name" class="progress_elem_name">{{ single_progress.name }}</p>
                                    <p v-if="single_progress.description" class="progress_elem_description">{{ single_progress.description }}</p>
                                    <i class="fas fa-times" @click="deleteProgress(idx)"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="single_vacancy_admin_block">
                        <p class="header_create_block">Блок с видео</p>
                        <label for="">Ссылка на видео</label>
                        <input 
                            type="text" 
                            v-model="createForm.video_link"
                        >
                    </div>

                    <div class="single_vacancy_admin_block">
                        <p class="header_create_block">Блок преимуществ</p>
                        <button v-if="!isShowCreateFormAdvantage" class="button button--primary" @click="isShowCreateFormAdvantage = true">Добавить преимущество</button>
                        <div v-if="isShowCreateFormAdvantage">
                            <p class="header_create_block">Новое преимущество</p>

                            <label for="">Название</label>
                            <input 
                                type="text" 
                                v-model="newAdvantage.name"
                            >

                            <label for="">Ссылка на изображение</label>
                            <input 
                                type="text" 
                                v-model="newAdvantage.img"
                            >

                            <img v-if="newAdvantage.img" :src="newAdvantage.img" alt="">
                            <div class="row">
                                <label class="fl__img">
                                    <input 
                                        ref="vacancyAdvantageBlockUpload" 
                                        type="file" 
                                        @change="uploadAdvantageImage"
                                    >
                                </label>
                                <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                            </div>
                            <button class="button button--primary" @click="createAdvantage">Добавить</button>
                        </div>

                        <div class="advantages_list_block">
                            <div 
                                v-for="single_advantage, idx in createForm.advantages_list"
                                :key="idx"
                                class="single_advantage"
                            >
                                <p v-if="single_advantage.name" class="single_advantage_name">{{ single_advantage.name }}</p>
                                <img v-if="single_advantage.img" :src="single_advantage.img" alt="">
                                <i class="fas fa-times" @click="deleteAdvantage(idx)"></i>
                            </div>
                        </div>
                    </div>

                    <div class="single_vacancy_admin_block">
                        <p class="header_create_block">Блок с генеральным директором</p>
                        <label for="">Заголовок</label>
                        <input 
                            type="text" 
                            v-model="createForm.general_block.header"
                        >
                        <label for="">Описание</label>
                        <textarea 
                            class="custom_textarea"
                            name="" 
                            id="" 
                            rows="5"
                            v-model="createForm.general_block.description"
                        ></textarea>

                        <label for="">Цитата</label>
                        <textarea 
                            class="custom_textarea"
                            name="" 
                            id="" 
                            rows="5"
                            v-model="createForm.general_block.quote"
                        ></textarea>

                        <label for="">Изображение</label>
                        <input 
                            type="text" 
                            v-model="createForm.general_block.img"
                        >

                        <img v-if="createForm.general_block.img" :src="createForm.general_block.img" alt="">
                        <div class="row">
                            <label class="fl__img">
                                <input 
                                    ref="vacancyGeneralBlockUpload" 
                                    type="file" 
                                    @change="uploadGeneralImage"
                                >
                            </label>
                            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                        </div>
                    </div>

                    <div class="single_vacancy_admin_block">
                        <p class="header_create_block">Блок с баннером</p>
                        <label for="">Заголовок</label>
                        <input 
                            type="text" 
                            v-model="createForm.banner_block.header"
                        >
                        <label for="">Ссылка на изображение</label>
                        <input 
                            type="text" 
                            v-model="createForm.banner_block.img"
                        >
                        <img v-if="createForm.banner_block.img" :src="createForm.banner_block.img" alt="">
                        <div class="row">
                            <label class="fl__img">
                                <input 
                                    ref="vacancyBannerBlockUpload" 
                                    type="file" 
                                    @change="uploadBannerImage"
                                >
                            </label>
                            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                        </div>
                    </div>

                    <div class="single_vacancy_admin_block">
                        <p class="header_create_block">Блок с вопросами</p>
                        <button v-if="!isShowCreateFormQuestion" class="button button--primary" @click="isShowCreateFormQuestion = true">Добавить вопрос</button>
                        <div v-if="isShowCreateFormQuestion">
                            <p class="header_create_block">Новый вопрос</p>

                            <label for="">Вопрос</label>
                            <input 
                                type="text" 
                                v-model="newQuestion.question"
                            >
                            <label for="">Ответ</label>
                            <input 
                                type="text" 
                                v-model="newQuestion.answer"
                            >

                            <button class="button button--primary" @click="createQuestion">Добавить</button>
                        </div>

                        <p class="header_create_block">Вопросы</p>
                        <div class="question_list_block">
                            
                            <div
                                v-for="single_question, idx in createForm.questions_list"
                                :key="idx"
                                class="single_question_block"
                            >
                                <p class="question_block_question">{{ single_question.question }}</p>
                                <p class="question_block_answer">{{ single_question.answer }}</p>
                                <i class="fas fa-times" @click="deleteQuestion(idx)"></i>
                            </div>
                        </div>
                    </div>

                    <div class="single_vacancy_admin_block">
                        <p class="header_create_block">Блок с интервью</p>
                        <button v-if="!isShowCreateFormInterview" class="button button--primary" @click="isShowCreateFormInterview = true">Добавить интервью</button>
                        <div v-if="isShowCreateFormInterview">
                            <p class="header_create_block">Новое интервью</p>

                            <label for="">Имя</label>
                            <input 
                                type="text" 
                                v-model="newInterview.name"
                            >

                            <label for="">Должность</label>
                            <input 
                                type="text" 
                                v-model="newInterview.post"
                            >

                            <label for="">Текст</label>
                            <input 
                                type="text" 
                                v-model="newInterview.text"
                            >

                            <label for="">Фото</label>
                            <input 
                                type="text" 
                                v-model="newInterview.img"
                            >
                            <img v-if="newInterview.img" :src="newInterview.img" alt="">
                            <div class="row">
                                <label class="fl__img">
                                    <input 
                                        ref="vacancyInterviewBlockUpload" 
                                        type="file" 
                                        @change="uploadInterviewImage"
                                    >
                                </label>
                                <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                            </div>

                            <button class="button button--primary" @click="createInterview">Добавить</button>
                        </div>

                        <p class="header_create_block">Список интервью</p>
                        <div class="interview_list_block">
                            <div 
                                v-for="single_interview, idx in createForm.interview_list"
                                :key="idx"
                                class="single_interview"
                            >
                                <img v-if="single_interview.img" :src="single_interview.img" alt="">
                                <p class="single_interview_name">{{ single_interview.name }}</p>
                                <p class="single_interview_post">{{ single_interview.post }}</p>
                                <p class="single_interview_text">{{ single_interview.text }}</p>
                                <i class="fas fa-times" @click="deleteInterview(idx)"></i>
                            </div>
                        </div>
                    </div>

                    

                    <p class="header_create_block">Регионы</p>
                    <div class="form_new_char">
                        <select name="" id="" v-model="selectedRegion">
                            <option value="null">Выбрать регион</option>
                            <option :value="region.id_1c" v-show="!checkIssetRegion(region.id_1c)" v-for="region, idx in regions" :key="idx">{{ region.name }}</option>
                        </select>
            
                        <button class="button button--primary" @click="addRegion">Добавить</button>
                    </div>
                    <div class="region_list">
                        <div v-for="singleRegion, idx in createForm.root_ids" :key="idx" class="single_region">
                            <p>{{ getRegionNameById1c(singleRegion) }}</p>
                            <i class="fas fa-times" @click="deleteRegion(singleRegion)"></i>
                        </div>  
                    </div>
                </div>

                <button class="button button--primary" @click="createVacancyMain">Сохранить</button>
            </div>

            <single 
                v-for="vacancy, idx in vacanciesMainPage"
                :key="idx"
                :element="vacancy"
            />
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'
import single from './singleVacancy.vue'

export default {
    components: {
        single,
    },
    data () {
        return {
            isShowCreateForm: false,

            createForm: {
                main_block: {
                    header: null,
                    subheader: null,
                    hh_link: null,
                    list_imgs: [],
                },

                video_link: null,

                progress_block: {
                    header: null,
                    description: null,
                    list_progress: [],
                },

                general_block: {
                    header: null,
                    description: null,
                    img: null,
                    quote: null
                },

                banner_block: {
                    header: null,
                    img: null
                },

                advantages_list: [],
                questions_list: [],
                interview_list: [],
                root_ids: [],
            },

            selectedRegion: null,

            isShowCreateFormProgress: false,
            newProgress: {
                name: null,
                description: null,
                img: null,
            },

            isShowCreateFormAdvantage: false,
            newAdvantage: {
                name: null,
                img: null,
            },

            isShowCreateFormQuestion: false,
            newQuestion: {
                question: null,
                answer: null,
            },

            isShowCreateFormInterview: false,
            newInterview: {
                name: null,
                text: null,
                post: null,
                img: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            vacanciesMainPage: 'vacancy/vacanciesMainPage',
            fileUrl: 'app/fileUrl',
            regions:'dictionary/topLevelCategory',
        }),
    },
    mounted() {
        this.getVacanciesMainPage()
    },
    methods: {
        ...Vuex.mapActions({
            getVacanciesMainPage: 'vacancy/search',
            upload: 'app/upload',
            createVacancyMainPage: 'vacancy/createVacancy',
        }),
        createProgress() {
            if (!this.createForm.progress_block.list_progress) {
                this.createForm.progress_block.list_progress = []
            }
            this.createForm.progress_block.list_progress.push({
                name: this.newProgress.name,
                description: this.newProgress.description,
                img: this.newProgress.img,
            })

            this.newProgress.name = null
            this.newProgress.description = null
            this.newProgress.img = null

            this.isShowCreateFormProgress = false
        },
        createAdvantage() {
            if (!this.createForm.advantages_list) {
                this.createForm.advantages_list = []
            }
            this.createForm.advantages_list.push({
                name: this.newAdvantage.name,
                img: this.newAdvantage.img
            })

            this.newAdvantage.name = null
            this.newAdvantage.img = null
            this.isShowCreateFormAdvantage = false
        },
        createQuestion() {
            if (!this.createForm.questions_list) {
                this.createForm.questions_list = []
            }
            this.createForm.questions_list.push({
                question: this.newQuestion.question,
                answer: this.newQuestion.answer,
            })

            this.newQuestion.question = null
            this.newQuestion.answer = null
            this.isShowCreateFormQuestion = false
        },
        createInterview() {
            if (!this.createForm.interview_list) {
                this.createForm.interview_list = []
            }
            this.createForm.interview_list.push({
                name: this.newInterview.name,
                post: this.newInterview.post,
                text: this.newInterview.text,
                img: this.newInterview.img,
            })

            this.newInterview.name = null
            this.newInterview.post = null
            this.newInterview.text = null
            this.newInterview.img = null
            this.isShowCreateFormInterview = false
        },
        deleteQuestion(index) {
            this.createForm.questions_list.splice(index, 1)
        },
        deleteImgGallery(link) {
            this.createForm.main_block.list_imgs.map((element, idx) => {
                if (element == link) {
                    this.createForm.main_block.list_imgs.splice(idx)
                }
            })
        },
        uploadGalleryImg() {
            let image = this.$refs['vacancyMainBlockGalleryUpload'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.createForm.main_block.list_imgs.push(this.fileUrl + response.data[0].link)
                })
        },
        uploadProgressImage() {
            let image = this.$refs['vacancyProgressBlockUpload'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newProgress.img = this.fileUrl + response.data[0].link
                })
        },
        uploadAdvantageImage() {
            let image = this.$refs['vacancyAdvantageBlockUpload'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newAdvantage.img = this.fileUrl + response.data[0].link
                })
        },
        uploadGeneralImage() {
            let image = this.$refs['vacancyGeneralBlockUpload'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.createForm.general_block.img = this.fileUrl + response.data[0].link
                })
        },
        uploadBannerImage() {
            let image = this.$refs['vacancyBannerBlockUpload'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.createForm.banner_block.img = this.fileUrl + response.data[0].link
                })
        },
        uploadInterviewImage() {
            let image = this.$refs['vacancyInterviewBlockUpload'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newInterview.img = this.fileUrl + response.data[0].link
                })
        },
        deleteProgress(index) {
            this.createForm.progress_block.list_progress.splice(index, 1)
        },
        deleteAdvantage(index) {
            this.createForm.advantages_list.splice(index, 1)
        },
        deleteInterview(index) {
            this.createForm.interview_list.splice(index, 1)
        },
        getRegionNameById1c(id_1c) {
            var name = null
            this.regions.map((element) => {
                if (element.id_1c == id_1c) {
                    name = element.name
                }
            })
            return name
        },
        checkIssetRegion(id_1c) {
            var isset = false
            this.createForm.root_ids.map((element) => {
                if (element == id_1c) {
                    isset = true
                }
            })
            return isset
        },
        deleteRegion(id_1c) {
            this.createForm.root_ids.map((element, idx) => {
                if (element == id_1c) {
                    this.createForm.root_ids.splice(idx, 1)
                }
            })
        },
        addRegion() {
            if (this.selectedRegion == null) {
                return
            }
            var region_id = JSON.stringify(this.selectedRegion)
            region_id = JSON.parse(region_id)
            this.createForm.root_ids.push(region_id)
            this.selectedRegion = null
        },
        createVacancyMain() {
            this.createVacancyMainPage(this.createForm)
                .then(() => {
                    this.isShowCreateForm = false
                    
                    this.createForm = {
                        main_block: {
                            header: null,
                            subheader: null,
                            hh_link: null,
                            list_imgs: [],
                        },

                        video_link: null,

                        progress_block: {
                            header: null,
                            description: null,
                            list_progress: [],
                        },

                        general_block: {
                            header: null,
                            description: null,
                            img: null,
                            quote: null
                        },

                        banner_block: {
                            header: null,
                            img: null
                        },

                        advantages_list: [],
                        questions_list: [],
                        interview_list: [],
                        root_ids: [],
                    }
                })
        },
    },
}
</script>