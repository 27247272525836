
<template>
	<div class="modal-iframe">
		<div class="modal modal--form modal--opened modal--visible" id="modal_callback">
			<div class="modal-container">
				<button class="modal-close" @click="close">×</button>
				<div class="state">
					<div class="state-item">
						<h3>Оставить заявку</h3>
						<div class="form" name="callback-form-popup">
							<h4 class="message-block" style="display: none; margin-top: -5px;"></h4>
							<div class="form-fieldset">
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">ФИО</div>
											<div class="field-input">
												<input type="text" v-model="name" placeholder="Введите ФИО">
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Номер телефона</div>
											<div class="field-input">
												<the-mask v-model="phone" mask="+7 (###) ###-####" placeholder="Phone number" type="tel" />
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Сообщение</div>
											<div class="field-input">
												<input type="text" v-model="message" placeholder="Введите название услуги">
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-item">
										<label class="checkbox-plus">
											<input 
												v-model="check"
												type="checkbox" 
												name="privacy"
											><b></b>
												<span>
													Согласен (согласна) на обработку моих персональных данных.
													<br> Ознакомлен с 
													<a href="https://storage.yandexcloud.net/dev-mayakmetall/images/BeEwJiJ_Jc.pdf" target="_blank">Политикой</a>
													и
													<a href="https://storage.yandexcloud.net/dev-mayakmetall/images/ZhlKe-a2dH.pdf" target="_blank">согласием</a>
													на обработку персональных данных
												</span>
										</label>
									</div>
								</div>
							</div>
							<div class="form-fieldset form-fieldset--medium">
								<div class="form-group">
									<div class="form-item">
										<button :class="onlyNumbers() && name != '' && check == true ? 'button button--primary' :'button button'" @click="sendApp()">Отправить заявку</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="sended == true" >
						<h3>Заявка отправлена</h3>
						<p><img  alt=""></p>
						<p>Наши менеджеры обработают вашу заявку и свяжутся с вами в ближайшее время</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import Functions from '@/utils/functions'
import Vuex from 'vuex'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
export default {
	props:['route', 'nameServiceForMessage'],
    mixins: [Functions],
    data () {
		return {
			name: '',
			phone: '',
			message: '',
			check: false,
			sended: false,
		}
    },
    computed: {
        ...Vuex.mapGetters({
			hrefApp:'appmodal/hrefApp',
			app:'appmodal/app',
		}),
	},
	mounted() {
		this.setMessage()
	},
    methods: {
         ...Vuex.mapMutations({
            setApp:'appmodal/setApp'
        }),
		...Vuex.mapActions({
            sentApp:'appmodal/sentApp'
        }),
        close(){
            this.setApp(false)
        },
        onlyNumbers() {
            return !/^[\d]{10}$/.test(this.phone) ? false : true
        },
        sendApp(){
            if(this.onlyNumbers() && this.name != '' && this.check == true){
				this.sended = true
				this.sentApp(
					{ 
						name: this.name, 
						telephone: this.phone,
						pageTitle: this.hrefApp, 
						message: this.message, 
						type: 'bid', 
						bannerTitle: '',
						email: '' 
					}
				)
			}
        },
		setMessage() {
			if (!this.nameServiceForMessage) {
				return
			}
			this.message = this.nameServiceForMessage
		},
    },
  }
</script>


