<template>
    <div class="section section--no-pt section--iterators">
        <div class="container">
            <div class="inner">
                <div class="heading heading--no-offset">
                    <div class="heading-section">
                        <h2>Как мы работаем</h2>
                    </div>
                </div>
            </div>
            <div class="list-offset -col-1">
                <ul class="list list--flex layout-iterators">
                    <li class="list-item"  v-for="obj, i in serviсes.works" :key="i">
                        <a class="iterator">
                            <div class="iterator-number">{{obj.number}}</div>
                            <div class="iterator-content">
                                <div class="iterator-title">{{obj.heading}}</div>
                                <div class="iterator-description">{{obj.text}}</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
    components:{
    },
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            app:'appmodal/app'
        }),
    },
    methods: {
         ...Vuex.mapMutations({
            setApp:'appmodal/setApp'
        }),
        openModalApp(){
            this.setApp(true) 
        },
    },
}
</script>