import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    messageStatus: null,
    form: {
        excludedIds: [],
        id_1c: "",
        id: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    partners: [],
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    form: state => state.form,
    partners: state => state.partners,
}

const actions = {
    getPartners: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_partners/search", getters.form)
                .then(response => {
                    commit("setPartners", response.data)
                    resolve(response)
                })
        })
    },
    createPartner: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_partners/admin", payload)
                .then(response => {
                    commit("pushPartner", response.data)
                    resolve(response)
                })
        })
    },
    deletePartner: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_partners/admin/" + id)
                .then(response => {
                    commit("splicePartner", id)
                    resolve(response)
                })
        })
    },
    updatePartner: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_partners/admin/" + payload.id, payload)
                .then(response => {
                    commit("changePartner", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setPartners: (state, val) => {
        state.partners = val
    },
    splicePartner: (state, id) => {
        state.partners.map((element, idx) => {
            if (id == element.id) {
                state.partners.splice(idx, 1)
            }
        })
    },
    changePartner: (state, val) => {
        state.partners.map((element) => {
            if (element.id == val.id) {
                element.link = val.link
            }
        })
    },
    pushPartner: (state, val) => {
        state.partners.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
