<template>
    <div class="support_single">
        <div 
            v-if="type && type == 10 && element.type == 10"
            class="index__admin tech_single"
        >
            <span>Техническое решение № {{ index + 1 }}</span>
            <span 
                class="index__del" 
                @click="deleteUiSupport(element.id)" 
            >
                Удалить Х
            </span>

            <input 
                type="text" 
                placeholder="Заголовок" 
                v-model="editElement.header"
                @change="update"
            >
            <div>
                <vue-editor 
                    v-model="editElement.link" 
                    @text-change="update" 
                />
            </div>
        </div>

        <div 
        v-if="type && type == 20 && element.type == 20"
            class="index__admin video_single" 
        >
            <span>Видео № {{ index + 1 }}</span>
            <span 
                class="index__del" 
                @click="deleteUiSupport(element.id)" 
            >
                Удалить Х
            </span>
            <input 
                type="text"  
                placeholder="Заголовок" 
                v-model="editElement.header"
                @change="update"
            >
            <input 
                type="text" 
                placeholder="Ссылка на видео" 
                v-model="editElement.link"
                @change="update"
            >
            <input 
                type="text" 
                placeholder="Ссылка на изображение" 
                v-model="editElement.linkimg"
                @change="update"
            >
                <div class="row">
                <label class="fl__img">
                    <input 
                        :ref="'singleFileSupport'" 
                        type="file" 
                        @change="uploadImg()"
                    >
                </label>
                <p> Изображение должно быть размером 600х540 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат. </p>
            </div>
            <div 
                v-if="editElement.linkimg"
                class="img_block"
            >
                <img 
                    :src="editElement.linkimg" 
                    width="30%"
                    alt=""
                >
            </div>
        </div>

        <div 
            v-if="type && type == 30 && element.type == 30"
            class="index__admin faq_single" 
        >
                <span>FAQ № {{ index + 1 }}</span>
                <span 
                    class="index__del" 
                    @click="deleteUiSupport(element.id)"
                >
                    Удалить Х
                </span>
            <input 
                type="text"  
                placeholder="Заголовок" 
                v-model="editElement.header"
                @change="update"
            >
            <div>
                <vue-editor 
                    v-model="editElement.link"
                    @text-change="update" 
                />
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import { VueEditor } from "vue2-editor"

export default {
    props: ['element', 'type', 'index'],
    components: {
        VueEditor,
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            deleteUiSupport: 'ui_support/deleteSupport',
            updateUiSupport: 'ui_support/updateSupport',
            upload: 'app/upload',
        }),
        update() {
            this.updateUiSupport(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['singleFileSupport' + this.index].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.updateUiSupport({
                        id: this.editElement.id,
                        header: this.editElement.header,
                        link: this.editElement.link,
                        root_id: this.editElement.root_id,
                        type: this.editElement.type,
                        linkimg: this.fileUrl + response.data[0].link
                    })
                })
        },
    },
}
</script>