<template>
    <div 
        class="cl-prod-item nlk-horizontal-scroll"
    >
        <div class="cl-prod-item-row cl-prod-item-row_full p-40">
            <div class="cl-prod-item__img mr-70" />
            <div class="cl-prod-item__text">
                <div class="cl-prod-item__title">{{ position.name }}</div>
                <ul class="cl-prod-item__list">
                    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                    <li 
                        v-for="(singleParameter, index) in getCardStructure(position.parent_id)"
                        :key="index"
                        v-if="singleParameter.show_in_basket == true"
                        style="display: flex; align-items: center;"
                    >
                        <p v-if="singleParameter.type != 30" style="margin: 0">
                            {{ singleParameter.name }} : {{ (singleParameter.base_name == 'Length' && singleParameter.delimiter ? getTrueBigSum(position.length / singleParameter.delimiter) + ' ' + position.base_unit_of_measure : position[currentNameParameter(singleParameter.base_name)]) }}
                        </p>
                        <p v-if="singleParameter.type == 30" style="margin: 0">
                            Цвет: 
                        </p>
                        <label 
                            v-if="!isEmptyObject(currentColor) && singleParameter.type == 30"
                            class="color"
                        >
                            <input 
                                type="radio"
                                name="color3"
                            >
                            <span 
                                v-if="currentColor.code"
                                class="color-preview" 
                                :style="'border-color: ' + currentColor.code + '; background: ' + currentColor.code + ';'"
                            >
                                <span 
                                    :style="'border-color: ' + currentColor.code + ';'"
                                />
                            </span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="cl-prod-item__last pr-40">
                <div class="cl-prod-item__price">{{ position.price || 0 }} {{ regionCurrency[regionCode].symbol }}</div>
                <button 
                    class="btn-close"
                    @click="deletePosition(index)"
                />
            </div>
        </div>
        <div class="cl-prod-item-row cl-prod-item-row_bottom flex_jcsb p-32">
            <div class="cl-prod-item__col">Количество, шт: {{ position.amount }}</div>
            <div class="cl-prod-item__all">
                <div class="cl-prod-item__price fs-18">{{ position.sell || 0 }} {{ regionCurrency[regionCode].symbol }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    props: {
        position: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0,
        },
        changeElementIndex: {
            type: Number,
            default: null
        },
        roofMaterial: {
            type: Number,
            default: null
        },
        arrayNomenclaturesResult: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            elements: [],
            color: null,
            colorName: null,
            form: {
                query: "",
                coating: null,
                colors: "",
                thickness: null,
                is_show_zero_price: false,
                is_group: false,
            },
            cardColors: [],
            thicknessArray: [],
            coatingArray: [],
            choosenElement: {},
            parameters: [],
            isShowElementsList: false,
            currentColor: {},
        }
    },
    computed: {
        ...Vuex.mapGetters({
            formCalculatorFence: 'calculator/formCalculatorFence',
            formCalculatorRoof: 'calculator/formCalculatorRoof',
            yandexCloud: 'app/yandexCloud',
            region: 'auth/city',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
        }),
        imgUrl() {
            let img = null
            if (this.elements[0]) {
                if (this.elements[0].links) {
                    img = this.yandexCloud + this.elements[0].links[0]
                }
            }
            return img
        },
        formCoating() {
            return this.form.coating
        },
        formThickness() {
            return this.form.thickness
        },
    },
    watch: {
        formCoating() {
            if (this.elements[0]) {
                this.$emit('updatePosition', this.elements[0], this.index, this.form.colors)
            }
        },
        formThickness() {
            if (this.elements[0]) {
                this.$emit('updatePosition', this.elements[0], this.index, this.form.colors)
            }
        },
        changeElementIndex(val) {
            if (val != this.index) {
                return 
            }
            if (this.isEmptyObject(this.choosenElement)) {
                return
            }
            this.setParameters(this.choosenElement)
            this.getCurrentColors()
        }
    },
    created() {
        // this.setDefaultName()
        // this.getElements('start')
        this.getCurrentColor()
    },
    methods: {
        ...Vuex.mapMutations({
            updatePositionParameter: 'calculator/updatePositionParameter',

            deletePosition: 'calculator/deletePosition',
        }),
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
            getColors: 'catalog/getColors',
            getCardParameters: 'calculator/getCardParameters',
            getOneColor: 'catalog/getOneColor',
        }),
        getCurrentColor() {
            if (this.position.color_external != null) {
                this.getOneColor(this.position.color_external)
                    .then((response) => {
                        this.currentColor = response.data[0]
                    })
            }
        },

        getLists() {
            this.elements = []
            this.setDefaultName()
            this.getElements()
            this.$emit('setChangeElementIndex', this.index)
        },
        setDefaultName() {
            if (!this.form.query) {
                if (this.position.name == 'Профнастил') {
                    this.form.query = 'Профлист ' + this.formCalculatorFence.view_material
                }else if (this.position.name == 'лист') {
                    if (this.roofMaterial == 10) {
                        this.form.query = this.formCalculatorRoof.view_material
                    }else if (this.roofMaterial == 20) {
                        this.form.query = 'Профлист ' + this.formCalculatorRoof.view_material
                    }
                }else{
                    this.form.query = this.position.name
                }
            }
        },
        getThicknessArray() {
            this.elements.map((el) => {
                if (el.thickness) {
                    if (!this.issetInArray('Выберите толщину', this.thicknessArray)) {
                        this.thicknessArray.push({id: 'Выберите толщину', name: 'Выберите толщину'})
                    }
                    if (!this.issetInArray(el.thickness, this.thicknessArray)) {
                        this.thicknessArray.push({id: el.thickness, name: el.thickness})
                    }
                }
            })
        },
        getCoatingArray() {
            this.elements.map((el) => {
                if (el.coating) {
                    if (!this.issetInArray('Выберите покрытие', this.coatingArray)) {
                        this.coatingArray.push({id: 'Выберите покрытие', name: 'Выберите покрытие'})
                    }
                    if (!this.issetInArray(el.coating, this.coatingArray)) {
                        this.coatingArray.push({id: el.coating, name: el.coating})
                    }
                }
            })
        },
        getElements(type) {
            if (this.form.thickness == 'Выберите толщину') {
                this.form.thickness = null
            }
            if (this.form.coating == 'Выберите покрытие') {
                this.form.coating = null
            }
            if (this.form.query.length < 3) {
                return
            }
            this.form.root_id = this.region
            this.searchNavbar(this.form)
                .then((resp) => {
                    this.elements = resp.data
                    if (type) {
                        if (type == 'coating') {
                            this.getThicknessArray()
                            this.getCurrentColors()
                        }else if (type == 'thickness') {
                            this.getCoatingArray()
                            this.getCurrentColors()
                        }else if (type == 'start') {
                            if (resp.data.length != 0) {
                                this.chooseProduct(resp.data[0])
                            }
                        }
                    }else{
                        this.getThicknessArray()
                        this.getCoatingArray()
                        this.getCurrentColors()
                    }
                })
        },
        issetInArray(val, array) {
            let isset = false

            array.map((element) => {
                if (element.name == val) {
                    isset = true
                }
            })

            return isset
        },
        getCurrentColors() {
            let colors = ["0"]
            this.choosenElement.colors.map((el) => {
                if (!this.isIssetColor(colors, el)) {
                    colors.push(el)
                }
            })
            this.getColors(colors)
                .then((response) => {
                    this.cardColors = response.data
                })
        },
        setColor(cardColors) {
            this.updatePositionParameter({
                index: this.index,
                name: 'color',
                value: cardColors.id_1c,
                root_id: this.region,
            })
        },
        isIssetColor(array, value) {
            let response = false
            array.map((element) => {
                if (element == value) {
                    response = true
                }
            })
            return response
        },
        chooseProduct(element) {
            this.$emit('updatePosition', element, this.index, this.form.colors, element.name)
            this.choosenElement = element
            this.setParameters(element)
            this.getCurrentColors()
            this.isShowElementsList = false
        },
        setParameters(element) {
            this.parameters = []
            this.getCardParameters(element.parent_id)
                .then((response) => {
                    let array = response.data.value
                    array.map((el) => {
                        this.parameters.push(JSON.parse(el))
                    })
                })
        },
        save() {
            this.$emit('setChangeElementIndex', null)
        },
        updateCalculatorPosition(name, value) {
            this.updatePositionParameter({
                index: this.index,
                name: name,
                value: value,
                root_id: this.region,
            })
            this.$emit('calculate', this.index)
        },
        getCardStructure(id_1c) {
			let card_structure = {}
			this.arrayNomenclaturesResult.map((element) => {
				if (element.id_1c == id_1c) {
					card_structure = JSON.parse(element.card_structure)
				}
			})
			return card_structure
		},
        currentNameParameter(name) {
            let lower_name = name[0].toLowerCase() + name.substring(1)

            lower_name = lower_name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

            lower_name = lower_name.trim()
			if (lower_name == 'color') {
				lower_name = 'color_external'
			}
            return lower_name
        },
    }
}
</script>
<style lang="scss">
    .cl-prod-item__list{
        li{
            &:last-child{
                border-right: unset !important;
            }
        }
    }
</style>
<style scoped src="../../../css/nlk-style.css"></style>