<template>
    <div class="cart-empty"><img src="@/assets/img/cart-empty.svg" alt="">
        <h4>Здесь пока ничего нет</h4>
        <p>Вы можете начать свой выбор с главной страницы или<br> воспользоваться поиском, если ищете что-то конкретное.</p>
        <ul class="list list--flex layout-buttons">
        <li class="list-item"><a class="button button--primary" @click="push('')">Перейти на главную</a></li>
        <li class="list-item"><a class="button button--default" @click="push('catalog')">Каталог продукции</a></li>
        </ul>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
  mixins: [Functions],
  data () {
    return {
    }
  },
  computed: {
        ...Vuex.mapGetters({
            pagesBasket: 'dictionary/pagesBasket',
        }),
    },
  methods: {
      
  },
}
</script>