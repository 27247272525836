import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    messageStatus: null,
    form: {
        excludedIds: [],
        id_1c: "",
        id: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    clients: [],
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    form: state => state.form,
    clients: state => state.clients,
}

const actions = {
    getClients: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_clients/search", getters.form)
                .then(response => {
                    commit("setClients", response.data)
                    resolve(response)
                })
        })
    },
    createClient: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_clients/admin", payload)
                .then(response => {
                    commit("pushClient", response.data)
                    resolve(response)
                })
        })
    },
    deleteClient: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_clients/admin/" + id)
                .then(response => {
                    commit("spliceClient", id)
                    resolve(response)
                })
        })
    },
    updateClient: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_clients/admin/" + payload.id, payload)
                .then(response => {
                    commit("changeClient", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setClients: (state, val) => {
        state.clients = val
    },
    spliceClient: (state, id) => {
        state.clients.map((element, idx) => {
            if (id == element.id) {
                state.clients.splice(idx, 1)
            }
        })
    },
    changeClient: (state, val) => {
        state.clients.map((element) => {
            if (element.id == val.id) {
                element.link = val.link
            }
        })
    },
    pushClient: (state, val) => {
        state.clients.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
