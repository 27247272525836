<template>
  <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup">
                            <p>
                                На {{ successRegistryEmail }} было отправлено письмо с ссылкой для подтверждения регистрации. 
                                Для завершения регистрации перейдите по ссылке из полученного письма.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
            successRegistryEmail: 'app/successRegistryEmail',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setSuccessRegistryEmail: 'app/setSuccessRegistryEmail',
            setShowSuccessRegistry: 'app/setShowSuccessRegistry'
        }),
        close() {
            this.setSuccessRegistryEmail(null)
            this.setShowSuccessRegistry(false)
            this.push('login')
        }
    }
}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>