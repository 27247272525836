<template>
    <div class="layout-section">
        <form class="form form--checkout" action="#" method="post" onsubmit="return false">
            <div class="form-fieldset">
                <div class="form-group">
                    <div class="form-item">
                        <p>Введите данные</p>
                    </div>
                </div>
                <div class="form-group form-row" v-if="order.account_type == 30">
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">Название компании</div>
                            <div class="field-input">
                                <input 
                                    v-model="editOrder.organization_name" 
                                    type="text" 
                                    placeholder="Введите название компании" 
                                    @keyup="update"
                                    :disabled="!isCanChangeOrgName"   
                                >
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">Адрес компании</div>
                            <div class="field-input">
                                <input 
                                    v-model="editOrder.organization_address" 
                                    type="text" 
                                    placeholder="Введите адрес компании" 
                                    @keyup="update"
                                    :disabled="!isCanChangeOrgAddress" 
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group form-row" v-if="order.account_type == 30">
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">ИНН</div>
                            <div class="field-input">
                                <input 
                                    v-model="editOrder.inn" 
                                    type="text" 
                                    placeholder="Введите ИНН" 
                                    @keyup="update"
                                    :disabled="!isCanChangeInn"     
                                >
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">КПП</div>
                            <div class="field-input">
                                <input 
                                    v-model="editOrder.kpp" 
                                    type="text" 
                                    placeholder="Введите КПП" 
                                    @keyup="update"
                                    :disabled="!isCanChangeKpp"     
                                >
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="form-group form-row">
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">{{ (order.account_type == 20 ? 'Контактное лицо *' : 'ФИО *') }}</div>
                            <div class="field-input">
                                <input :class="{'error_input' : isIsset('fio', errorArray)}" v-model="editOrder.fio" type="text" :placeholder="(order.account_type == 20 ? 'Введите контактное лицо' : 'Введите ФИО')" @keyup="update" @change="checkError('fio')">
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">E-mail *</div>
                            <div class="field-input">
                                <input :class="{'error_input' : isIsset('email', errorArray)}" v-model="editOrder.email" type="email" placeholder="Введите E-mail" @keyup="update" @change="checkError('email')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group form-row">
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">Телефон *</div>
                            <div class="field-input">
                                <the-mask :class="{'error_input' : isIsset('phone', errorArray)}" v-model="editOrder.phone" mask="+7 (###) ###-####" placeholder="Phone number" type="tel"  @keyup="update" @keydown.native="checkError('phone')"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">Комментарий к заказу</div>
                            <div class="field-input">
                                <input v-model="editOrder.comment" type="text" placeholder="Например, доставка после 2-х" @keyup="update">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="order.account_type != 30"     
                class="form-fieldset form-fieldset--medium"
            >
                <div class="form-group">
                    <div class="form-item">
                        <label class="checkbox-plus">
                            <input v-model="isAgree" type="checkbox" name="privacy">
                            <b></b>
                            <span>
                                Согласен (согласна) на обработку моих персональных данных.
                                <br> Ознакомлен с 
                                <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/BeEwJiJ_Jc.pdf" target="_blank">Политикой</a>
                                и
                                <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/ZhlKe-a2dH.pdf" target="_blank">согласием</a>
                                на обработку персональных данных
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-fieldset form-fieldset--medium">
                <div class="form-group">
                    <div class="form-item">
                        <ul class="list list--flex layout-buttons">
                            <li class="list-item">
                                <button class="button button--primary" :disabled="(!isAgree && order.account_type != 30) || !isCanOrder" @click="registry">Оформить заказ</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
        <text-block />
    </div>
</template>
<script>
import Vuex from 'vuex'
import Vue from 'vue'

import Functions from '@/utils/functions'
import textBlock from '../text-block.vue'

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

export default {
    mixins: [Functions],
    components: {
        textBlock,
    },
    data () {
        return {
            isAgree: false,
            requireValues: [
                'phone',
                'email',
                'fio',
            ],
            errorArray: [],
            isCanChangeOrgName: true,
            isCanChangeOrgAddress: true,
            isCanChangeInn: true,
            isCanChangeKpp: true,
        }
    },
    watch: {
        currentCompany() {
            console.log('current company setted')
            this.setValuesFromCompany()
        }
    },
    computed: {
        ...Vuex.mapGetters({
            order: 'basket/order',
            user: 'auth/user',
            userCompanyId: 'auth/userCompanyId',
            companies: 'companies/companies',
        }),
        editOrder() {
            return Object.assign({}, this.order)
        },
        fullSum() {
            let sum = 0

            if (this.order.positions.length != 0) {
                this.order.positions.map((element) => {
                    sum += element.sell
                })
            }

            return sum
        },
        isCanOrder() {
            return this.validation()
        },
        currentCompany() {
            let company = null
            this.companies.map((element) => {
                if (element.id == this.userCompanyId) {
                    company = element
                }
            })
            return company
        },
    },
    mounted() {
        if (this.currentCompany) {
            this.setValuesFromCompany()
        }
    },
    methods: {
        ...Vuex.mapActions({
            updateOrder: 'basket/updateOrder',
            orderRegistration: 'basket/orderRegistration',
        }),
        ...Vuex.mapMutations({
            changeOrder: 'basket/changeOrder',
            setOrder: 'basket/setOrder',
            setFinishedOrder: 'basket/setFinishedOrder',
            setShowSuccessCreateOrder: 'app/setShowSuccessCreateOrder',
        }),
        setValuesFromCompany() {
            if (this.currentCompany.name) {
                this.editOrder.organization_name = this.currentCompany.name
                this.isCanChangeOrgName = false
            }else{
                this.editOrder.organization_name = null
                this.isCanChangeOrgName = TextTrackCueList
            }
            if (this.currentCompany.adress) {
                this.editOrder.organization_address = this.currentCompany.adress
                this.isCanChangeOrgAddress = false
            }else{
                this.editOrder.organization_address = null
                this.isCanChangeOrgAddress = true
            }
            if (this.currentCompany.inn) {
                this.editOrder.inn = this.currentCompany.inn
                this.isCanChangeInn = false
            }
            else{
                this.editOrder.inn = null
                this.isCanChangeInn = true
            }
            if (this.currentCompany.kpp) {
                this.editOrder.kpp = this.currentCompany.kpp
                this.isCanChangeKpp = false
            }else{
                this.editOrder.kpp = null
                this.isCanChangeKpp = true
            }
            this.update()
        },
        checkError(val) {
            if (this.editOrder[val] != null && this.editOrder[val] != '' || (val == 'phone' && !this.checkPhoneValid()))  {
                this.errorArray.map((element, idx) => {
                    if (element == val) {
                        this.errorArray.splice(idx, 1)
                    }
                })
            }
        },
        isIsset(val, array) {
            let isset = false
            array.map((element) => {
                if (element == val) {
                    isset = true
                }
            })
            return isset
        },
        checkPhoneValid() {
            let isValid = true
            if (this.editOrder.phone == null || this.editOrder.phone == '' || this.editOrder.phone.length < 10) {
                isValid = false
            }
            return isValid
        },
        validation() {
            let isCan = true
            this.requireValues.map((element) => {
                if (this.editOrder[element] == null || this.editOrder[element] == '' || (element == 'phone' && !this.checkPhoneValid())) {
                    isCan = false
                    if (!this.isIsset(element, this.errorArray)) {
                        this.errorArray.push(element)
                    }
                }
            })
            return isCan
        },
        update() {
            this.changeOrder(this.editOrder)
        },
        goToLastStep() {
            this.updateOrder(this.editOrder)
                .then(() => {
                    this.push('basket/checkout/last-step')
                })
        },
        getFullName(surname, name, midname) {
            let full = ''
            if (surname) {
                full += surname + ' '
            }
            if (name) {
                full += name + ' '
            }
            if (midname) {
                full += midname
            }
            return full
        },
        registry() {
            if (!this.validation()) {
                return
            }
            this.editOrder.company_id = Number(this.userCompanyId)
            this.updateOrder(this.editOrder)
                .then(() => {
                    this.orderRegistration()
                        .then(() => {
                            this.setFinishedOrder({
                                pay_type: this.order.pay_type,
                                sum: this.fullSum,
                            }) 
                            this.setOrder({
                                id: null,
                                positions: [],
                                
                                account_type: null,
                                location: null,
                                postcode: null,
        
                                delivery_type: null,
                                pay_type: null,
        
                                organization_name: null,
                                organization_address: null,
                                inn: null,
                                kpp: null,
        
                                fio: null,
                                email:null,
                                phone: null,
                                comment: null,
                            })
                            if (localStorage.mayakOrderId) {
                                localStorage.removeItem("mayakOrderId")
                            }                   
                            this.push('basket/checkout_finish')
                            this.setShowSuccessCreateOrder(true)
                        })
                })
        },
    }
}
</script>