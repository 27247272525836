<template>
  <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup">
                            <img 
                                :src="require('@/assets/img/drainages/' + link)"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        link: {
            type: String,
            default: ""
        },
    },
    computed: {},
    methods: {
        ...Vuex.mapMutations({
            setIsShowDrainageImgModal: 'calculator/setIsShowDrainageImgModal',
        }),
        close() {
            this.setIsShowDrainageImgModal(false)
        },
    }
}
</script>