<template>
    <div class="section section--no-pt">
        <div class="container"> 
            <div class="inner">
                <div class="heading">
                    <div class="heading-section">
                        <h2>Услуги</h2>
                    </div>
                </div>
            </div>              
            <ul class="list list--flex layout-cards -col-3">
                <li class="list-item" v-for="obj, i in uiServices" :key="i">
                    <a class="card card--medium card--no-arrow">
                        <div class="card-heading">
                            <div class="card-icon">
                                <img :src="obj.link" alt="">
                            </div>
                            <div class="card-title">{{ obj.name }}</div>
                            <div class="card-description"><p v-html="obj.descriptions"></p></div>
                        </div>
                        <div class="card-arrow">
                            <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                                <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                            </svg>
                        </div></a>
                </li>
            </ul>
            <div class="load-more load-more--small"><a class="button button--primary" @click="openModalApp">Оставить заявку</a></div>
            <application v-if="app == true" />
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import application from '../../modals/application.vue'
export default {
    components:{
        application
    },
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters({
            serviсes: 'app/serviсes',
            app:'appmodal/app',
            uiServices: 'ui_service/services',
        }),
    },
    methods: {
         ...Vuex.mapMutations({
            setApp:'appmodal/setApp',
            setHrefApp:'appmodal/setHrefApp',
        }),
        openModalApp(){
            this.setHrefApp(window.location.href)
            this.setApp(true) 
        },
    },
}
</script>