<template>
    <main class="main">
        <div class="section section--breadcrumbs">
          <div class="container">
            <div class="inner">
              <ul class="list list--flex breadcrumbs">
                <li class="list-item"><a class="list-link" href="#">
                    <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                      <use xlink:href="@/assets/img/icons.svg#home"></use>
                    </svg></a></li>
                <li class="list-item"><a class="list-link" href="#">Вход</a></li>
                <li class="list-item"><span class="list-current">Восстановление пароля</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Восстановление пароля</h2>
                </div>
              </div>
            </div>
            <div class="row row--recovery">
              <div class="col">
                <form class="form" action="#" method="post" onsubmit="return false">
                  <div class="form-fieldset">
                    <div class="form-group">
                      <div class="form-item">
                        <div class="field">
                          <div class="field-label">E-mail</div>
                          <div class="field-input">
                            <input :class="errorClass" type="email" placeholder="Введите e-mail" v-model="form.email" @keyup="setErrorClass(false)">
                            <p style="color: red; margin-bottom: 0;">{{ errorMessage }}</p>
                          </div>
                        </div>
                        <!-- field-->
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-item">
                        <button class="button button--primary" @click="recovery">Отправить</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col">
                <p v-if="!message">Введите e-mail и на него вышлем письмо для<br> восстановления пароля. Указывайте тот же e-mail,<br> что и при регистрации</p>
                <p v-else>{{ message }}</p>
              </div>
            </div>
          </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            form: {
                email: null
            },
            errorClass: '',
            errorMessage: '',
        }
    },
    computed: {
        ...Vuex.mapGetters({
            message: 'auth/message',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            recoveryStore: 'auth/recovery',
        }),
        ...Vuex.mapMutations({
          setShowRecoverySended: 'app/setShowRecoverySended',
          setSendedEmail: 'auth/setSendedEmail',
        }),
        recovery() {
            if (!this.form.email) {
              this.setErrorClass(true)
              return
            }
            this.recoveryStore(this.form)
              .then(() => {
                this.setSendedEmail(this.form.email)
                this.setShowRecoverySended(true)
              })
        },
        setErrorClass(val) {
          if (val == true) {
            this.errorClass = 'error_red_border_input'
            this.errorMessage = 'Введите почту'
          }else{
            this.errorClass = ''
            this.errorMessage = ''
          }
        },
    },
}
</script>
<style lang="scss">
    .error_red_border_input{
      border-color: red !important;
    }
</style>