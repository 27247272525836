import { render, staticRenderFns } from "./one-tr-roof.vue?vue&type=template&id=61f9344f&scoped=true&"
import script from "./one-tr-roof.vue?vue&type=script&lang=js&"
export * from "./one-tr-roof.vue?vue&type=script&lang=js&"
import style0 from "../../../../css/nlk-style.css?vue&type=style&index=0&id=61f9344f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f9344f",
  null
  
)

export default component.exports