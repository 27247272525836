<template>
    <ul 
        class="list list--flex breadcrumbs"
        :class="{'breadcrumbs-wrap' : windowWidth <= 700}"
    >
    <li class="list-item" @click="push('catalog')">
        <a class="list-link">
            <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                <use xlink:href="@/assets/img/icons.svg#home"></use>
            </svg>
        </a>
    </li>
    <div v-if="!isFirstLevelCatalog" style="display: contents">
        <li 
            class="list-item" 
            v-for="(category, i) in catalogCurrentPath" 
            :key="category"
            @click="pushToCatalog(JSON.parse(category))"
        >
            <a 
              class="list-link"
              v-if="i != catalogCurrentPath.length - 1 || (i == catalogCurrentPath.length - 1 && productName != '')"
            >
              {{ JSON.parse(category).name }}
            </a>
            <span class="list-current" v-if="i == catalogCurrentPath.length - 1 && productName == ''">{{ JSON.parse(category).name }}</span>
        </li>
    </div>
    <li class="list-item list-item-breadcrumbs_nd" v-if="productName">
        <span class="list-current">{{ productName }}</span>
    </li>
  </ul>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    props: {
        productName: {
            type: String,
            default: ""
        },
        isFirstLevelCatalog: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...Vuex.mapGetters({
            catalogCurrentPath: 'catalog/catalogCurrentPath',
            searchForm: 'catalog/form',
        }),
        form() {
            return Object.assign({}, this.searchForm)
        },
        windowWidth() {
            return window.innerWidth
        }
    },
    watch: {
        catalogCurrentPath() {
            this.setCatalogHeader()
        },
    },
    mounted() {
        this.setCatalogHeader()
    },
    methods: {
        ...Vuex.mapActions({
            search: 'catalog/search',
        }),
        ...Vuex.mapMutations({
            setForm: 'catalog/setForm',
            setHeader: 'catalog/setHeader',
        }),
        pushToCatalog(category) {
            this.form.parent_id = category.id_1c
            this.setForm(this.form)
            this.search({from: 'catalog'})
            if (category.is_sub_sign) {
                this.$router.push('/cardProduct/' + category.id).catch(() => {})
            }else{
                this.$router.push('/catalog/' + category.id_1c).catch(() => {})
            }
        },
        setCatalogHeader() {
            if (!this.catalogCurrentPath) {
                return
            } 
            this.catalogCurrentPath.map((element, i) => {
                if (i == this.catalogCurrentPath.length - 1 && this.productName == '') {
                    this.setHeader(JSON.parse(element).name)
                }
            })
        },
    },
}
</script>
<style lang="css" scoped>
    .breadcrumbs-wrap{
        flex-wrap: wrap;
    }
</style>