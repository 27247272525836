<template>
    <div class="index__admin">
        <span>Услуга № {{ index + 1 }}</span>
        <span 
            class="index__del" 
            @click="deleteUiService(element.id)" 
        >
            Удалить Х
        </span>

        <input 
            type="text" 
            v-model="editElement.name" 
            placeholder="Заголовок"
            @change="update" 
        >
        <div class="row">
            <div>
                <label>Для кого услуга</label>
                <select  
                    class="picker"  
                    v-model="editElement.type" 
                    @change="update"  
                >
                    <option 
                        v-for="type in offersFrom" 
                        :key="type.id" 
                        :value="type.id"
                    >
                        {{ type.name }}
                    </option>
                </select>
            </div>

            <div>
                <label>Для какого региона услуга</label>
                <select 
                    class="picker" 
                    v-model="editElement.root_id" 
                    @change="update" 
                >
                    <option :value="'null'">Услуга для всех регионов</option>
                    <option 
                        v-for="reg, i in regions" 
                        :key="i" 
                        :value="reg.id_1c"
                    >
                        {{ reg.name }}
                    </option>
                </select>
            </div>    
        </div>

        <div>
            <vue-editor 
                v-model="editElement.descriptions"    
                @text-change="update"
            />
        </div>

        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="editElement.link" 
            placeholder="Ссылка на изображение"
            @keyup="update" 
        >

        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'singleFileService'+ index" 
                    type="file" 
                    @change="uploadImg()"
                >
            </label>
            <p>Изображение должно быть размером 80х90 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат.</p>
        </div>

        <img 
            :src="editElement.link" 
            alt=""
        >
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor"
import Vuex from 'vuex'

export default {
    components: {
        VueEditor
    },
    props:['element', 'index'],
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
            offersFrom:'dictionary/offersFrom',
            regions:'dictionary/topLevelCategory',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            updateUiService: 'ui_service/updateService',
            deleteUiService: 'ui_service/deleteService',
        }),
        update() {
            this.updateUiService(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['singleFileService' + this.index].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.updateUiService({
                        id: this.editElement.id,
                        type: this.editElement.type,
                        root_id: this.editElement.root_id,
                        name: this.editElement.name,
                        descriptions: this.editElement.descriptions,
                        link: this.fileUrl + response.data[0].link
                    })
                })
        },
    },
}
</script>