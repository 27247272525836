<template>
    <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
					<div class="state-item">
						<h3>Добавить utm</h3>
						<div class="form" name="callback-form-popup">
							<h4 class="message-block" style="display: none; margin-top: -5px;"></h4>
							<div class="form-fieldset">
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Root 1c id</div>
											<div class="field-input">
												<input type="text" v-model="createForm.root_1c_id" placeholder="Введите root_1c_id">
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Utm</div>
											<div class="field-input">
												<input type="text" v-model="createForm.utm" placeholder="Введите utm">
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Телефон</div>
											<div class="field-input">
												<the-mask v-model="createForm.phone" mask="+7 (###) ###-####" placeholder="Phone number" type="tel" />
											</div>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-item">
										<div class="field">
											<div class="field-label">Email</div>
											<div class="field-input">
												<input type="text" v-model="createForm.email" placeholder="Введите email">
											</div>
										</div>
									</div>
								</div>
							</div>
							<div 
								v-if="message"
								class="message-error-block"
							>
								Пользователь с такой почтой уже существует
							</div>
							<div class="form-fieldset form-fieldset--medium">
								<div class="form-group">
									<div class="form-item">
										<!-- <button :class="onlyNumbers() && name != '' && check == true ? 'button button--primary' :'button button'" @click="sendApp()">Отправить заявку</button> -->
										<button class="button button--primary" @click="create">Добавить</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            createForm: {
                phone: "",
                root_1c_id: "",
                utm: "",
                email: "",
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
			message: 'utm/message',
			messageStatus: 'utm/messageStatus',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setCreateUtmModal: 'app/setCreateUtmModal',
        }),
        ...Vuex.mapActions({
            createUtm: 'utm/createUtm',
			searchUtm: 'utm/search',
        }),
        close() {
            this.setCreateUtmModal(false)
        },
        create() {
			this.createForm.phone = '7' + this.createForm.phone
            this.createUtm(this.createForm)
                .then(() => {
                    this.searchUtm()
                    this.createForm.phone = ""
                    this.createForm.root_1c_id = ""
                    this.createForm.utm = ""
                    this.createForm.email = ""
					this.close()
                })
        },
    },
}
</script>
<style lang="scss">
	.message-error-block{
		background-color: lightcoral;
		border-radius: 10px;
		padding: 10px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
</style>