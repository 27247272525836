<template>
  <section class="nlk-content">
    <h1 class="nlk-content__title">АКТ СВЕРКИ</h1>
    <form 
        class="nlk-filter"
        onsubmit="return false"
    >
        <div class="field field_data">
            <div class="field-label">Начальная дата</div>
            <div class="field-input">
                <input type="date" placeholder="Дата" v-model="startDate" @change="update">
            </div>
        </div>
        <div class="field field_data">
            <div class="field-label">Конечная дата</div>
            <div class="field-input">
                <input type="date" placeholder="Дата" v-model="endDate" @change="update">
            </div>
        </div>
        <div class="field flex_g-1 field_mr-15">
            <div class="field-label">Юр.лицо</div>
            <div class="field-input">
                <input type="text" placeholder="ООО РОСПРОМСТРОЙМЕТАЛЛ (ИНН 98745632107)" v-model="searchForm.client" @keyup="update">
            </div>
        </div>
        <div class="field flex">
            <button 
                type="submit" 
                class="filter-btn"
                @click="search"
            >
                Запросить
            </button>
        </div>
    </form>
    
    <!-- <div class="nlk-horizontal-scroll nlk-vertical-scroll">
        <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
            <tr>
                <th>НОМЕР</th>
                <th>ДАТА</th>
                <th>ДОКУМЕНТ</th>
                <th>ПРЕДСТАВЛЕНИЕ</th>
                <th>ДЕБЕТ ({{ regionCurrency[regionCode].currency }})</th>
                <th>КРЕДИТ ({{ regionCurrency[regionCode].currency }})</th>
            </tr>
            <tr>
                <td colspan="4" class="text-left"><b>Сальдо начальное</b></td>
                <td><b>{{ act.opening_balance_debit }}</b></td>
                <td>{{ act.opening_balance_credit }}</td>
            </tr>

            <tr 
                v-show="act.documents"
                v-for="(document, idx) in act.documents"
                :key="idx"
            >
                <td>{{ idx+1 }}</td>
                <td>{{ getDateNormalFormat(document.pay_date) }}</td>
                <td>{{ document.document }}</td>
                <td>{{ document.performance }}</td>
                <td>{{ document.debet }}</td>
                <td>{{ document.credit }}</td>
            </tr>
            <tr>
                <td colspan="4" class="text-left"><b>Обороты за период</b></td>
                <td><b>{{ act.transactions_debit }}</b></td>
                <td><b>{{ act.transactions_credit }}</b></td>
            </tr>
            <tr>
                <td colspan="4" class="text-left"><b>Сальдо конечное</b></td>
                <td>{{ act.final_balance_debit }}</td>
                <td>{{ act.final_balance_credit }}</td>
            </tr>
        </table>
    </div> -->
</section>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            startDate: "2022-01-02",
			endDate: "2024-01-02",
        }
    },
    computed: {
        ...Vuex.mapGetters({
            formAct: 'reports/formAct',
            act: 'reports/act',
            user: 'auth/user',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
        }),
        searchForm() {
            return Object.assign({}, this.formAct)
        },
    },
    mounted() {
        this.setDefaultClient()
    },
    created() {
        this.update()
        this.search()
    },
    methods: {
        ...Vuex.mapActions({
            getAct: 'reports/getAct',
        }),
        ...Vuex.mapMutations({
            setActForm: 'reports/setActForm',
            setShowSendedModal: 'app/setShowSendedModal',
        }),
        getDateNormalFormat(date) {
            let response
            let splited_date = date.split('-')
            let splitedDay = splited_date[2].split('T')
            response = splitedDay[0] + '.' + splited_date[1] + '.' + splited_date[0]
            return response
        },
        update() {
            if (this.startDate) {
				let today = new Date(this.startDate)
				this.searchForm.date_start = today.toISOString()
			}else{
				this.searchForm.date_start = null
			}

			if (this.endDate) {
				let today = new Date(this.endDate)
				this.searchForm.date_end = today.toISOString()
			}else{
				this.searchForm.date_end = null
			}
            this.setActForm(this.searchForm)
        },
        search() {
            this.getAct()
                .then(() => {
                    this.setShowSendedModal(true)
                })
        },
        setDefaultClient() {
            if (this.user.organization_name) {
                this.searchForm.client = this.user.organization_name + '(' + this.user.inn + ')'
                this.setActForm(this.searchForm)
            }
        },
    },
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>