<template>
    <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container modal-container_users">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup">
                            <div class="nlk-content">
                                <div class="nlk-filter">
                                    <div class="field df">
                                        <div class="field-input">
                                            <input type="text" v-model="newUser.email" placeholder="Почта">
                                        </div>
                                        <button @click="createUser" class="button button--primary">Добавить</button>
                                    </div>
                                </div>


                                <div class="content">
                                    <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                                        <tr>
                                            <td>id</td>
                                            <td>Почта</td>
                                            <td>ФИО</td>
                                            <td></td>
                                        </tr>
                                        <tr v-for="user, idx in users" :key="idx">
                                            <td>{{ user.id }}</td>
                                            <td>{{ user.email }}</td>
                                            <td>{{ user.name }}</td>
                                            <td><i @click="deleteUser(user.id)" class="fas fa-trash"></i></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    props: {
        users: {
            type: Array,
            default: () => []
        },
        company_id: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            newUser: {
                email: null,
                company_id: null,
            }
        }
    },
    computed: {
        ...Vuex.mapGetters({
            companies: 'companies/companies',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setShowModalUser: 'companies/setShowModalUser',
        }),
        ...Vuex.mapActions({
            deleteStoreUser: 'companies/deleteUser',
            createStoreUser: 'companies/createUser',
        }),
        close() {
            this.setShowModalUser(false)
        },
        deleteUser(id) {
            this.deleteStoreUser(id)
                .then(() => {
                    this.$emit('spliceUser', id)
                })
        },
        createUser() {
            this.newUser.company_id = this.company_id
            this.createStoreUser(this.newUser)
                .then((response) => {
                    this.$emit('addUser', response.data)
                    this.newUser.email = null
                })
        },
    }
}
</script>
<style scoped src="@/css/nlk-style.css"></style>
<style lang="scss">
    .modal-container_users{
        max-width: 1000px !important;

        .df{
            display: flex;
        }

        .fa-trash{
            cursor: pointer;
        }
    }
</style>