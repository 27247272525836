<template>
  <section class="nlk-content orders_page try_custom_width" style="margin-bottom: 5rem;">
		<!-- <h1 class="nlk-content__title mb-50">МОИ ЗАКАЗЫ</h1> -->
		<form 
			class="nlk-filter"
			onsubmit="return false"
		>
			<div class="field field_data">
				<div class="field-label">Начальная дата</div>
				<div class="field-input">
					<input type="date" placeholder="Дата" v-model="startDate" @change="search">
				</div>
			</div>
			<div class="field field_data">
				<div class="field-label">Конечная дата</div>
				<div class="field-input">
					<input type="date" placeholder="Дата" v-model="endDate" @change="search">
				</div>
			</div>
			<div class="field field_data field_active_orders">
				<div class="field-label">Активные заказы</div>
				<li class="list-item">
					<a 
						class="site-type"
						:class="( is_active_orders == true ? 'site-type--toggle-left' : 'site-type--toggle-right')"
						@click="changeActiveOrders"
					>
						<div class="site-type-label"></div>
					</a>
				</li>
			</div>
			<div class="field field_flex-bottom">
				<div class="field-input w-149">
					<input type="text" placeholder="Номер заказа" v-model="searchOrderForm.query" @keyup="search">
				</div>
			</div>
			<div class="field field_flex-bottom">
				<div class="nlk-field-select">
					<select v-model="search_status" @change="changeStatus">
						<option :value="null">Статус</option>
						<option v-for="orderStatus in orderStatuses" :key="orderStatus.id" :value="orderStatus.name">{{ orderStatus.name }}</option>
					</select>
				</div>
			</div>
		</form>

		<div class="nlk-horizontal-scroll">
			<table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac nlk-table_mobil-none nlk-table-order">
				<tr>
					<th class="first_column">НОМЕР И ДАТА</th>
					<th class="second_column">СУММА С НДС</th>
					<th class="third_column">СУММА ОПЛАЧЕНО</th>
					<th class="fourth_column">СУММА ОСТАТОК</th>
					<th class="fiveth_column">СТАТУС</th>
					<th class="sixth_column">ОТВЕТСТВЕННЫЙ МЕНЕДЖЕР</th>
					<th class="seventh_column"></th>
				</tr>
			</table>
		</div>
		<div class="nlk-vertical-scroll" @wheel="checkPositionWindow" id="orders_block_scroll">
			<order 
				v-for="order in orders" 
				:key="order.id" 
				:order="order" 
				@openModal="openModal"
			/>
		</div>

		<error-repeat-order 
            v-if="isShowModalError" 
            @closeModal="closeModal"
        />
	</section>
</template>

<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import Order from '@/components/pages/nlk/order.vue'
import errorRepeatOrder from '@/components/modals/errorRepeatOrder'
export default {
	mixins: [Functions],
	components: {
		Order,
		errorRepeatOrder,
	},
	data() {
		return {
			startDate: null,
			endDate: null,
			window: null,
			isShowModalError: false,
			search_status: null
		}
	},
	computed: {
		...Vuex.mapGetters({
			searchForm: 'basket/searchForm',
			orders: 'basket/orders',
			orderStatuses: 'dictionary/orderStatuses',
			windowPositionScroll: 'app/windowPositionScroll',
			isCanSearchOrder: 'basket/isCanSearchOrder',
			isSearchOrdersEnded: 'basket/isSearchOrdersEnded',
		}),
		searchOrderForm() {
			return Object.assign({}, this.searchForm)
		},
		windowClosed() {
			let response = true
			if (this.window && this.window.closed) {
				response = this.window.closed
			}
			return response
		},
		is_active_orders() {
			let is_active = false
			if (this.searchOrderForm.status.length == 3) {
				is_active = true
			}
			return is_active
		},
		formQuery() {
			return this.searchOrderForm.query 
		},
	},
	watch: {
		startDate() {
			this.searchOrderForm.page = 1
			this.changeSearchForm(this.searchOrderForm)
		},
		endDate() {
			this.searchOrderForm.page = 1
			this.changeSearchForm(this.searchOrderForm)
		},
		formQuery() {
			this.searchOrderForm.page = 1
			this.changeSearchForm(this.searchOrderForm)
		},
	},
	destroyed() {
		this.setDefaultForm()
	},
	mounted() {
		this.setCanSearchOrder(true)
		this.searchOrderForm.page = 1
		this.changeSearchForm(this.searchOrderForm)
		this.searchOrder()
		let scrollBlock = document.getElementById('orders_block_scroll')
		scrollBlock.addEventListener('scroll', this.checkPositionWindow)
	},
	methods: {
		...Vuex.mapMutations({
			changeSearchForm: 'basket/changeSearchForm',
			setCanSearchOrder: 'basket/setCanSearchOrder'
		}),
		...Vuex.mapActions({
			searchOrder: 'basket/searchOrder',
			searchNextPageOrder: 'basket/searchNextPageOrder',
		}),
		setDefaultForm() {
			this.searchOrderForm.page = 1
			this.searchOrderForm.status = []
			this.searchOrderForm.date_create = null
			this.searchOrderForm.date_before = null
			this.changeSearchForm(this.searchOrderForm)
		},
		checkPositionWindow() {
			let positionScroll = document.getElementById('orders_block_scroll')
			if (positionScroll.scrollHeight - positionScroll.scrollTop === positionScroll.clientHeight) {
				if (this.isSearchOrdersEnded == false) {
					return
				}
				this.searchOrderForm.page += 1
				this.changeSearchForm(this.searchOrderForm)
				this.searchNextPageOrder()
			}
		},
		changeStatus() { 
			this.searchOrderForm.page = 1
			if (this.search_status) {
				this.searchOrderForm.status = [this.search_status]
			}else{
				this.searchOrderForm.status = []
			}
			this.changeSearchForm(this.searchOrderForm)
			this.search()
		},
		search() {
			let positionScroll = document.getElementById('orders_block_scroll')
			positionScroll.scrollTo(0, 0)

			if (this.startDate) {
				let today = new Date(this.startDate)
				this.searchOrderForm.date_create = today.toISOString()
			}else{
				this.searchOrderForm.date_create = null
			}

			if (this.endDate) {
				let today = new Date(this.endDate)
				this.searchOrderForm.date_before = today.toISOString()
			}else{
				this.searchOrderForm.date_before = null
			}

			this.setCanSearchOrder(true)
			
			this.changeSearchForm(this.searchOrderForm)
			this.searchOrder()
		},
		testDownload() {
			this.window = window.open("https://storage.yandexcloud.net/dev-mayakmetall/images/EmVQJ5ViFM", "_self")
		},
		openModal() {
			this.isShowModalError = true
		},
		closeModal() {
            this.isShowModalError = false
        },
		changeActiveOrders() {
			if (this.is_active_orders == true) {
				this.searchOrderForm.status = []
				this.search_status = null
			}else{
				this.searchOrderForm.status = [
					'Оформлен',
					'Подтвержден',
					'В производстве',
				]
				this.search_status = null
			}
			this.searchOrderForm.page = 1
			this.changeSearchForm(this.searchOrderForm)
			this.search()
		},
	},
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>
<style scoped>
	.active{
		background-color: unset;
	}
	.w-149{
		width: 14.9rem;
	}
	.field_active_orders .list-item{
		list-style-type: none;
		margin-top: 3rem;
	}

	.field_active_orders .list-item .site-type--toggle-right:before {
		background: #868d86;
	}
	/* @media only screen and (max-width: 1600px){
		.nlk-accordion{
			min-width: 895px !important;
		}
	} */
</style>
<style lang="scss">
	.nlk-vertical-scroll{
		overflow-y: auto;
	}
	@media only screen and (max-width: 1600px){
		.nlk-table-order{
			min-width: 60rem !important;
		}
		.nlk-accordion{
			min-width: 60rem !important;
		}
	}
	@media only screen and (min-width: 1200px) and (max-width: 1400px){
		.nlk-vertical-scroll{
			max-height: 32rem;
		}
	}
	@media only screen and (min-width: 1400px) and (max-width: 1800px){
		.nlk-vertical-scroll{
			max-height: 60rem;
		}
	}
	@media only screen and (min-width: 1800px){
		.nlk-vertical-scroll{
			max-height: 73rem;
		}
	}
	.try_custom_width{
		.field_data{
			width: 23.6rem !important;
		}
	}
	.orders_page{
		.nlk-order-header__top{
			justify-content: space-around;
		}

		.pl-0{
			padding-left: 0 !important;
		}

		.pr-0{
			padding-right: 0 !important;
		}

		.mar-0{
			margin: 0 !important;
		}

		.first_tab{
			width: 12rem;
		}

		.first_column{
			width: 15%;
		}

		.second_tab{
			width: 12rem;
		}

		.second_column{
			width: 15%;
		}

		.third_tab{
			width: 12rem;
		}

		.third_column{
			width: 15%;
		}

		.fourth_tab{
			width: 12rem;
		}

		.fourth_column{
			width: 15%;
		}

		.fiveth_tab{
			width: 12rem;
		}

		.fiveth_column{
			width: 15%;
		}

		.sixth_tab{
			width: 12rem;
		}

		.sixth_column{
			width: 15%;
		}

		.seventh_column{
			width: 5%;
		}
	}
</style>