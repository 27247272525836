import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    cardProduct: {},
    colorCardProduct: null,
    adminRootId: null,
    arrayCardProductColors: [],
    arrayCardProductColorsIn: [],

    form: {
        coating: "",
        сolors: "",
        colors_in: "",
        excludedIds: [],
        geometry: null,
        id_1c: "",
        id_1cs: [],
        ids: [],
        insulation_thickness: null,
        internal_thickness: null,
        is_group: null,
        is_show_zero_price: true,
        limit: null,
        length_of_characteristics: null,
        page: 1,
        parent_id: "",
        parent_ids: [],
        query: "",
        rolling_side: "",
        rolling_the_inner_lining: "",
        rolling_the_outer_cladding: "",
        sort: "10",
        thickness: null,
        root_id: null,  
        diameter: null,
        head_type: null,
        shelf_a: null,
        shelf_b: null,
        tip_type: null,
        is_deleted: null,
    },
    formPayments: {
        ClientID1c: "",
        OrderID1c: [],
        excludedIds: [],
        ids: [],
        limit: null,
        page: 1,
        pay_date_before: null,
        pay_date_create: null,
        query: ""
    },
    products: [],
    catalogCurrentPath: null,
    topCategory: [],
    amountPages: 0,
    header: null,

    cardStructure: null,
    cardFormula: null,
    adminCardId1c: null,

    cardColors: [],
    payments: [],
    filters: {},
    cardProductErrorArray: [],
    isCanSearchPayments: true,
}

const getters = {
    message: state => state.message,
    cardProduct: state => state.cardProduct,
    form: state => state.form,
    products: state => state.products,
    catalogCurrentPath: state => state.catalogCurrentPath,
    topCategory: state => state.topCategory,
    amountPages: state => state.amountPages,
    cardStructure: state => state.cardStructure,
    cardColors: state => state.cardColors,
    header: state => state.header,
    payments: state => state.payments,
    formPayments: state => state.formPayments,
    colorCardProduct: state => state.colorCardProduct,
    arrayCardProductColors: state => state.arrayCardProductColors,
    arrayCardProductColorsIn: state => state.arrayCardProductColorsIn,
    adminRootId: state => state.adminRootId,
    filters: state => state.filters,
    cardProductErrorArray: state => state.cardProductErrorArray,
    isCanSearchPayments: state => state.isCanSearchPayments,
    cardFormula: state => state.cardFormula,
    adminCardId1c: state => state.adminCardId1c,
}

const actions = {
    search: ({commit, getters}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        let form = getters.form
        if (payload && payload.from && payload.from == 'catalog') {
            form.is_deleted = false
        }
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search", form, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setProducts", response.data)
                    commit("setCurrentPath", response.data[0].navigation_path)
                    commit("setAmountPages", response.pagination)
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getFilters: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search/filter", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setFilters", response.data)
                    resolve(response)
                })
        })
    },
    getProduct: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setProduct", response.data[0])
                    commit("setCurrentPath", response.data[0].navigation_path)
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getCategories: ({commit}, obj) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search", {parent_id: obj.parent_id, root_id: obj.root_id}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", "success")
                    resolve(response)
                })
        })
    },
    searchNavbar: ({commit}, obj) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search", obj, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", "success")
                    resolve(response)
                })
        })
    },
    getTopCategory: ({commit}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/nomenclature/top", {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setTopCategory", response.data)
                    resolve(response)
                })
        })
    }, 
    getCardStructure: ({commit}, obj) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search", obj, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCardStructure", {value: response.data, id_1c: obj.id_1c})
                    resolve(response)
                })
        })
    },
    getColors: ({commit}, colors) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/colors/search", {ID1cs: colors}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCardColors", response.data)
                    resolve(response)
                })
        })
    },
    getColorsArray: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/colors/search", {ID1cs: payload.colors, componentFrom: payload.componentFrom}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setArrayCardProductColors", {type: payload.type, colors: response.data})
                    resolve(response)
                })
        })
    },
    getPayments: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/payments", getters.formPayments, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setPayments", response.data)
                    resolve(response)
                })
        })
    },
    getNewPagePayments: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        if (!getters.isCanSearchPayments) {
            return
        }
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/payments", getters.formPayments, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("addNewPagePayments", response.data)
                    resolve(response)
                })
        })
    },
    getParentForNavigationPath: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCurrentPathForSingleProduct", response.data[0])
                    resolve(response)
                })
        })
    },
    getOneColor: ({commit}, id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/colors/search", {ID1c: id_1c}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success')
                    resolve(response)
                })
        })
    },
}

const mutations = {
    cleatSearchForm: (state) => {
        state.form = {
            coating: "",
            сolors: "",
            colors_in: "",
            excludedIds: [],
            geometry: null,
            id_1c: "",
            id_1cs: [],
            ids: [],
            insulation_thickness: null,
            internal_thickness: null,
            is_group: null,
            is_show_zero_price: true,
            limit: null,
            length_of_characteristics: null,
            page: 1,
            parent_id: "",
            parent_ids: [],
            query: "",
            rolling_side: "",
            rolling_the_inner_lining: "",
            rolling_the_outer_cladding: "",
            sort: "10",
            thickness: null,
            root_id: null,  
            diameter: null,
            head_type: null,
            shelf_a: null,
            shelf_b: null,
            tip_type: null,
        }
    },
    setCardStructure: (state, obj) => {
        state.adminCardId1c = obj.id_1c
        if (obj.value[0]) {
            state.cardFormula = obj.value[0].formula
            state.cardStructure = JSON.parse(obj.value[0].card_structure)
        }
    },
    setCanSearchPayments: (state, val) => {
        state.isCanSearchPayments = val
    },
    setCardProductErrorArray: (state, val) => {
        state.cardProductErrorArray = val
    },
    setFilters: (state, val) => {
        state.filters = val
    },
    setMessage: (state, val) => {
        state.message = val
    },
    setProduct: (state, val) => {
        state.cardProduct = val
    },
    setProducts: (state, array) => {
        state.products = array
    },
    setForm: (state, val) => {
        state.form = val
    },
    setCurrentPath: (state, val) => {
        state.catalogCurrentPath = val
    },
    setAdminRootId: (state, val) => {
        state.adminRootId = val
    },
    setCurrentPathForSingleProduct: (state, product) => {
        let array = product.navigation_path
        let id_1c_last = JSON.parse(array[array.length-1]).id_1c
        array.push(JSON.stringify({id_1c: product.id_1c, name: product.name, parent_id: id_1c_last, is_sub_sign: product.is_sub_sign, id: product.id}))
        state.catalogCurrentPath = array
    },
    setTopCategory: (state, val) => {
        state.topCategory = val
    },
    setAmountPages: (state, val) => {
        let total = val.total
        let limit = val.limit
        state.amountPages = Math.ceil(total / limit)
    },
    setCardColors: (state, array) => {
        state.cardColors = array
    },
    setHeader: (state, val) => {
        state.header = val
    },
    setPayments: (state, val) => {
        state.payments = val
    },
    addNewPagePayments: (state, val) => {
        if (val.payments == null || val.payments.length == 0) {
            state.isCanSearchPayments = false
            return
        }
        let currentValue = state.payments.payments
        let newValue = currentValue.concat(val.payments)
        state.payments.payments = newValue
    },
    setFormPayments: (state, val) => {
        state.formPayments = val
    },
    setColorCardProduct: (state, val) => {
        state.colorCardProduct = val
    },
    setArrayCardProductColors: (state, object) => {
        if (object.type == 'colors') {
            state.arrayCardProductColors = object.colors
        }else if (object.type == 'colors_in') {
            state.arrayCardProductColorsIn = object.colors
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
