<template>
     <div class="admin-index-clients">
        <h4>Наши клиенты</h4>
        <button
            v-if="!isShowForm"
            @click="isShowForm = true"
        >
            Добавить лого
        </button>

        <div 
            v-if="isShowForm"
            class="client"
        >
            <div>
                <label>Ссылка на изображение</label>
                <input 
                    type="text" 
                    v-model="newClient.link"
                >
                <label class="fl__img">
                    <input 
                        ref="fileDefaultClient" 
                        type="file" 
                        @change="uploadImg"
                    >
                </label>
                <p class="label_fileupload">
                    Изображение должно быть размером до 300x200 px, формат PNG. Для правильного отображения соблюдайте размер и формат
                </p>
            </div>
            <img
                :src="newClient.link"
            >

            <button 
                @click="createClient" 
            >
                Сохранить
            </button>
        </div>

        <div class="row">
            <client 
                v-for="(client, idx) in uiClients"
                :key="idx"
                :element="client"
                :index="idx" 
            />
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import client from './client.vue'

export default {
    components: {
        client,
    },
    data() {
        return {
            newClient: {
                name: null,
                link: null,
                root_id: null,
            },
            isShowForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiClients: 'ui_client/clients',
            fileUrl: 'app/fileUrl',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            createUiClient: 'ui_client/createClient',
        }),
        uploadImg() {
            let image = this.$refs['fileDefaultClient'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newClient.link = this.fileUrl + response.data[0].link
                })
        },
        createClient() {
            if (this.newClient.link == null) {
                return
            }
            this.createUiClient(this.newClient)
                .then(() => {
                    this.isShowForm = false
                    this.newClient.link = null
                    this.newClient.name = null
                    this.newClient.root_id = null
                })
        },
    },
}
</script>