<template>
    <section class="admin-coatings-page admin-index index__admin">
        <div class="admin-index_header">
            <h3>Главная покрытий</h3>
        </div>

        <div class="buttons-block">
            <button class="button button--primary" @click="isShowCreateForm = true">Добавить покрытие</button>
            <button class="button button--primary" @click="push('admin/coatings')">Покрытия</button>
        </div>

        <div class="main_block">
            <div v-if="isShowCreateForm" class="single-coating-block">
                <input type="text" v-model="newCoating.image">
                <img v-if="newCoating.image != null" :src="newCoating.image" alt="" class="coating_main_img">
                <div class="row">
                    <label class="fl__img">
                        <input 
                            ref="coatingMainMainImg" 
                            type="file" 
                            @change="uploadImg"
                        >
                    </label>
                    <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                </div>

                <div class="form_new_char">
                    <select name="" id="" v-model="selectedRegion">
                        <option value="null">Выбрать регион</option>
                        <option :value="region.id_1c" v-show="!checkIssetRegion(region.id_1c)" v-for="region, idx in regions" :key="idx">{{ region.name }}</option>
                    </select>
        
                    <button class="button button--primary" @click="addRegion">Добавить</button>
                </div>

                <div class="region_list">
                    <div v-for="singleRegion, idx in newCoating.root_ids" :key="idx" class="single_region">
                        <p>{{ getRegionNameById1c(singleRegion) }}</p>
                        <i class="fas fa-times" @click="deleteRegion(singleRegion)"></i>
                    </div>  
                </div>

                <button class="button button--primary delete_button" @click="closeForm">Отменить</button>
                <button class="button button--primary delete_button" @click="addCoating">Добавить</button>
            </div>
            <single v-show="!isShowCreateForm" v-for="single, idx in coatings" :key="idx" :element="single" />
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import single from './singleMain.vue'

export default {
    mixins: [Functions],
    components: {
        single,
    },
    data() {
        return {
            isShowCreateForm: false,
            newCoating: {
                image: null,
                root_ids: [],
            },
            selectedRegion: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            coatings: 'coatings/coatingsMain',
            fileUrl: 'app/fileUrl',
            regions:'dictionary/topLevelCategory',
        }),
    },
    mounted() {
        this.getCoatings()
    },
    methods: {
        ...Vuex.mapActions({
            getCoatings: 'coatings/searchMain',
            upload: 'app/upload',
            createCoating: 'coatings/createCoatingMain',
        }),
        closeForm() {
            this.isShowCreateForm = false
            this.newCoating.image = null
            this.newCoating.root_ids = []
        },
        uploadImg() {
            let image = this.$refs['coatingMainMainImg'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newCoating.image = this.fileUrl + response.data[0].link
                })
        },
        addCoating() {
            this.createCoating(this.newCoating)
                .then(() => {
                    this.closeForm()
                })
        },
        checkIssetRegion(id_1c) {
            var isset = false
            this.newCoating.root_ids.map((element) => {
                if (element == id_1c) {
                    isset = true
                }
            })
            return isset
        },
        addRegion() {
            if (this.selectedRegion == null) {
                return
            }
            var region_id = JSON.stringify(this.selectedRegion)
            region_id = JSON.parse(region_id)
            this.newCoating.root_ids.push(region_id)
            this.selectedRegion = null
        },
        deleteRegion(id_1c) {
            this.newCoating.root_ids.map((element, idx) => {
                if (element == id_1c) {
                    this.newCoating.root_ids.splice(idx, 1)
                }
            })
        },
        getRegionNameById1c(id_1c) {
            var name = null
            this.regions.map((element) => {
                if (element.id_1c == id_1c) {
                    name = element.name
                }
            })
            return name
        },
    },
}
</script>