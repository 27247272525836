<template>
    <select ref="select">
        <option value="">{{ testValue }}</option>
    </select>
</template>
<script>
import Vuex from 'vuex'
import Choices from 'choices.js'

export default {
    props: ['elements', 'value', 'defaultValue', 'typeSelect', 'from'],
    data() {
        return {
            selectOptions: {
                classNames: {
                    containerOuter: 'choices choices--' + this.typeSelect + ' choices-' + this.from,
                },
                shouldSort: true,
                sorter: function(a, b) {
                    return b.sort - a.sort
                },
                searchEnabled: false,
            },
            testValue: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            region: 'auth/city',
        }),
        textValue() {
            let response = null
            this.elements.map(element => {
                if (element.id == this.value) {
                    response = element.name
                }
            })
            if (!response) {
                response = this.defaultValue
            }
            return response
        },
    },
    watch: {
        elements() {
            this.setChoices()  
        },
        region(val) {
            this.setTestValue(val)
        },
    },
    mounted() {
        this.choicesInstance = new Choices(this.$refs.select, this.selectOptions)
        this.$refs.select.addEventListener('addItem', this.handleSelectChange)
        this.setChoices()
        this.setTestValue()
    },
    created() {
        
    },
    destroyed: function () {
        this.choicesInstance.destroy();
    },
    methods: {
        handleSelectChange(e) {
            if (Number(e.target.value)) {
                this.$emit('input', Number(e.target.value))
            }else{
                this.$emit('input', e.target.value)
            }
            this.$emit('change')
        },
        setChoices() {
            this.choicesInstance.setChoices(this.elements, 'id_1c', 'name', true);
        },
        setTestValue(val) {
            let response = null
            let value = null

            if (val) {
                value = val
            }else{
                value = this.value
            }

            this.elements.map(element => {
                if (element.id_1c == value) {
                    response = element.name
                }
            })
            if (!response) {
                response = this.defaultValue
            }
            this.testValue = response
            
            this.changeInnerText()
        },
        changeInnerText() {
            let select = document.getElementsByClassName('choices-' + this.from)
            let test_select = document.getElementsByClassName('choices-footer')
            if (!select || select.length == 0) {
                return
            }
            if (test_select[1]) {
                let test_element = test_select[1].getElementsByClassName('choices__list--single')
                test_element[0].innerHTML = this.testValue
            }
            let element = select[0].getElementsByClassName('choices__item')
            element[0].innerHTML = this.testValue
        },
    },

}
</script>
<style lang="scss">
    .disable-select{
        .choices__list--dropdown{
            display: none !important;
        }
    }
    .choices-footer{
        max-width: 21rem;
    }
</style>