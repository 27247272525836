<template>
  <section class="">

        <h1 class="nlk-content__title mb-50">Отчёт по логам</h1>

        <form class="nlk-filter">
            <div class="field field_data">
                <div class="field-label">Начальная дата</div>
                <div class="field-input">
                    <input type="date" placeholder="Дата" v-model="frm" >
                </div>
            </div>
            <div class="field field_data">
                <div class="field-label">Конечная дата</div>
                <div class="field-input">
                    <input type="date" placeholder="Дата"  v-model="t">
                </div>
            </div>
        </form>
    <div class="content">
          <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <th>ДАТА</th>
                    <th>СОБЫТИЕ</th>
                    <th>ТИП</th>
                    <th>ПОЛЬЗОВАТЕЛЬ</th>
                    <th>СООБЩЕНИЕ</th>
                </tr>
              <tr v-for="log in logs" :key="log.id">
                <td>{{getDate(log.created_at)}}</td>
                <td>{{log.event}}</td>
                <td>{{log.type}}</td>
                <td>{{log.user.email}}</td>
                <td>{{log.message}}</td>
              </tr>
            </table>
        </div>


    </section>
</template>

<script>
import Vuex from 'vuex'

export default {
  data() {
    return {
      frm:null,
      t:null
    }
  },
  watch: {
    frm() {
      this.reset()
    },
    t() {
      this.reset()
    },
  },
	computed: {
		...Vuex.mapGetters({
			logs: 'logs/logs',
      form: 'logs/form'
		}),
	},
	mounted() {
		this.searchOrder()
	},
	methods: {
		...Vuex.mapMutations({
			changeSearchForm: 'logs/set',
      setForm: 'logs/setForm',
		}),
		...Vuex.mapActions({
			searchOrder: 'logs/find',
		}),
    reset(){
      let form = Object.assign({}, this.form)
      form.from = this.frm
      form.to = this.t
      this.setForm(form)
      this.searchOrder()
    },
    getDate(v) {
      let date = null
      if (v) {
        let splited = v.split('-')
        let splitedTime = splited[2].split('T')
        date = splitedTime[0] + '-' + splited[1] + '-' + splited[0]
      }
      return date
    },
	},
}
</script>
<style scoped>
	.active{
		background-color: unset;
	}
</style>
<style scoped src="@/css/nlk-style.css"></style>