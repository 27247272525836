<template>
    <main class="main">
        <bread-crumbs :pages-array="pagesPartner" />
        <div class="section section--no-pt">
            <div class="container">
                <div class="inner">
                    <div class="heading">
                        <div class="heading-section">
                            <h2>Стань партнером</h2>
                        </div>
                    </div>
                </div>
                <div class="row row--partners">
                    <div class="col">
                        <h3>Преимущества<br> нашего<br> сотрудничества</h3>
                        <ul class="list list--flex layout-buttons layout-buttons--links">
                            <li class="list-item" v-for="typeBenefit in listBenefitTypes" :key="typeBenefit.id" @click="activeType = typeBenefit.id">
                                <a 
                                    class="button" 
                                    :class="(activeType == typeBenefit.id ? 'button--primary' : 'button--default')"
                                >
                                    {{ typeBenefit.name }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col">
                        <ul class="list list--flex layout-advantages -col-3">
                            <single-benefit 
                                v-for="(benefit, i) in becomePartner.benefits" 
                                v-show="benefit.type == activeType"
                                :key="i" 
                                :benefit="benefit" 
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div 
            v-if="activeType == 10"
            class="section section--no-pt section--no-pb" 
        >
            <div class="container">
                <div class="inner">
                    <div class="heading">
                        <div class="heading-section">
                            <h2>Наши дилеры на карте</h2>
                        </div>
                    </div>
                </div>
                <yandex-map
                    id="map"
                    :coords="center"
                    :settings="settings"
                    :zoom="zoom"
                    :scroll-zoom="false"
                >
                    <ymapMarker 
                        v-for="(gmp, index) in dealersPoints"
                        :key="index"
                        :coords="[gmp.latitude, gmp.longitude]"
                        :markerId="index"
                        :hint-content="getTextAddressForHint(gmp.site_contacts)"
                        :balloon="getObjectAddressForBalloon(gmp.site_contacts)" 
                        @click="center = [gmp.latitude, gmp.longitude]"
                    />
                </yandex-map>
            </div>
        </div>
        <div class="section" style="padding-top: 3rem">
            <div class="container">
                <ul class="list list--flex layout-map-contacts">
                    <li 
                        v-for="(address, idx) in dealersPoints"
                        :key="idx"
                        class="list-item"
                    >
                        <div class="map-contact">
                            <div 
                                v-for="(address_pos, i) in address.site_contacts"
                                :key="i"
                                :class="[
                                    i == 0 ? 'map-contact-title' : 'map-contact-address'
                                ]"
                            >
                                {{ address_pos }}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'

import singleBenefit from './singleBenefit.vue'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
    components: {
        breadCrumbs,
        singleBenefit,
        yandexMap, 
        ymapMarker,
    },
    data() {
        return {
            center: [ 52.56543518842296, 70.05213952539054 ],
            zoom: 4, 
            activeType: 10,

            settings: {
                apiKey: '72b24e1d-06ad-424f-af9b-44d28d93d4d6',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            },
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: require('@/assets/img/map-marker.svg'),
                imageSize: [43, 43],
                imageOffset: [0, 0],
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            // contacts: 'app/contacts',
            becomePartner: 'app/becomePartner',
            listBenefitTypes: 'dictionary/listBenefitTypes',
            pagesPartner: 'dictionary/pagesPartner',
            dealersPoints: 'app/dealersPoints',
            region: 'auth/city',
        }),
    },
    mounted() {
        this.getDealersPoints(this.region)
    },
    methods: {
        ...Vuex.mapActions({
            getDealersPoints: 'app/getDealersPoints',
        }),
        getObjectAddressForBalloon(array) {
            let object = {header: '', body: ''}
            array.map((element, i) => {
                if (i == 0) {
                    object.header = element
                }else{
                    object.body += '<p style="margin-bottom: 0">' + element + '</p>'
                }
            })
            return object
        },
        getTextAddressForHint(array) {
            let text = ''
            array.map((element, i) => {
                if (i == 0) {
                    text += '<p style="margin-bottom: 0"><b>' + element + '</b></p>'
                }else{
                    text += '<p style="margin-bottom: 0">' + element + '</p>'
                }
            })
            return text
        },
    },
}
</script>