import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    messageStatus: null,
    form: {
        excludedIds: [],
        id_1c: "",
        id: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    documents: [],
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    form: state => state.form,
    documents: state => state.documents,
}

const actions = {
    getDocuments: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_document/search", getters.form)
                .then(response => {
                    commit("setDocuments", response.data)
                    resolve(response)
                })
        })
    },
    createDocument: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_document/admin", payload)
                .then(response => {
                    commit("pushDocument", response.data)
                    resolve(response)
                })
        })
    },
    deleteDocument: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_document/admin/" + id)
                .then(response => {
                    commit("spliceDocument", id)
                    resolve(response)
                })
        })
    },
    updateDocument: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_document/admin/" + payload.id, payload)
                .then(response => {
                    commit("changeDocument", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setDocuments: (state, val) => {
        val.sort((a, b) => Number(a.id) - Number(b.id))
        state.documents = val
    },
    spliceDocument: (state, id) => {
        state.documents.map((element, idx) => {
            if (id == element.id) {
                state.documents.splice(idx, 1)
            }
        })
    },
    changeDocument: (state, val) => {
        state.documents.map((element) => {
            if (element.id == val.id) {
                element.link = val.link
                element.img = val.img
            }
        })
    },
    pushDocument: (state, val) => {
        state.documents.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
