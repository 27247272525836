<template>
    <tr>
        <td class="left-td one_tr_name_block">
            <i 
                v-if="single_element" 
                class="fas fa-times one_tr_icon_delete" 
                title="Очистить поле" 
                @click="clear"
            ></i>
            <b>{{ getNameOfType() }}</b> 
            <br>
            {{ (single_element ? single_element.name : '') }}
        </td>
        <td>
            <div 
                class="search-field"
            >
                <input 
                    class="search-input input-for-id"
                    type="text" 
                    placeholder="Поиск по id_1c"
                    v-model="id_1c"
                    @keyup="update('id_1c')"
                >
                <input 
                    class="search-input input-for-name"
                    type="text" 
                    placeholder="Поиск по названию"
                    v-model="query"
                    @keyup="update('name')"
                >
                <button 
                    class="search-submit" 
                    @click="getElements()"
                >
                    <span class="search-submit-label">найти</span>
                </button>
            </div>
            <div 
                v-if="query.length > 3 || id_1c.length > 3"
                class="block-elements"
            >
                <div class="search-results"  data-search-results>
                    <div 
                        class="search-result"
                        v-for="product in elements"
                        :key="product.id"
                    >
                        <div class="search-result-title">
                            <a class="search-result-link">{{ product.name }}</a>
                        </div>
                        <div class="search-result-button">
                            <button 
                                class="button button--primary button--cart"
                                @click="setItem(product.id_1c)"
                            >
                                Выбрать
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        name: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            elements: [],
            query: '',
            id_1c: '',
            single_element: {},
        }
    },
    computed: {
        ...Vuex.mapGetters({
            calc_drain_body: 'calculator/calc_drain_body',
        }),
        currentParameter() {
            return this.calc_drain_body[this.name]
        },
    },
    watch: {
        currentParameter(val) {
            this.searchNavbar({root_id: this.calc_drain_body.root_1c_id, is_group: null, id_1cs: [val], is_show_zero_price: false})
                .then((response) => {
                    this.single_element = response.data[0]
                })
        },
    },
    mounted() {
        if (this.currentParameter) {
            this.searchNavbar({root_id: this.calc_drain_body.root_1c_id, is_group: null, id_1cs: [this.currentParameter], is_show_zero_price: false})
                .then((response) => {
                    this.single_element = response.data[0]
                })
        }
    },
    methods: {
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
        }),
        ...Vuex.mapMutations({
            changeBodyCalcDrain: 'calculator/changeBodyCalcDrain',
        }),
        update(type) {
            if (type == 'name') {
                this.id_1c = ''
            }else if (type == 'id_1c') {
                this.query = ''
            }
            this.getElements()
        },
        getElements() {
            this.searchNavbar({root_id: this.calc_drain_body.root_1c_id, id_1c: this.id_1c, query: this.query, is_group: null, is_show_zero_price: false})
                .then((response) => {
                    this.elements = response.data
                })
        },
        clear() {
            this.changeBodyCalcDrain({type: this.name, value: null})
        },
        setItem(id_1c) {
            this.changeBodyCalcDrain({type: this.name, value: id_1c})
            this.query = ''
        },
        getNameOfType() {
            let response = ''
            if (this.name == 'sealant') {
                response = 'Герметик'
            }else if (this.name == 'pistol') {
                response = 'Пистолет'
            }else if (this.name == 'corrector') {
                response = 'Корректор'
            }
            return response
        },
    },
}
</script>