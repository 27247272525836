<template>
  <section class="nlk-content">

    <h1 class="nlk-content__title">ПРОФИЛЬ</h1>

    <div class="nlk-content-company nlk-content-company_mb-120">
        <div class="nlk-content-company__item">
            <div class="nlk-content-company__label">Название компании</div>
            <p>{{ user.organization_name }}</p>
        </div>
        <div class="nlk-content-company__item">
            <div class="nlk-content-company__label">ИНН</div>
            <p>{{ user.inn }}</p>
        </div>
        <div class="nlk-content-company__item">
            <div class="nlk-content-company__label">КПП</div>
            <p>{{ user.kpp }}</p>
        </div>
        <div class="nlk-content-company__item">
            <div class="nlk-content-company__label">Контактное лицо</div>
            <p>{{ user.surname }} {{ user.name }} {{ user.midname }}</p>
        </div>
        <div class="nlk-content-company__item">
            <div class="nlk-content-company__label">Контактный телефон</div>
            <p>{{ user.phone }}</p>
        </div>
        <div class="nlk-content-company__item">
            <div class="nlk-content-company__label">Электронная почта</div>
            <p>{{ user.email }}</p>
        </div>
    </div>

    <h2 class="nlk-h2 nlk-h2_mb-10">Договор</h2>

    <div 
        v-if="user.contracts"
        class="nlk-horizontal-scroll"
    >
        <table class="nlk-table nlk-table_w-100 nlk-table_tac nlk-table_p-13 nlk-table_th-fz-14 nlk-table_border-collapse">
            <tr>
                <th>НОМЕР</th>
                <th>Дата</th>
                <th>ЮРЛИЦО</th>
                <th>СУММА ДОГОВОРА</th>
                <!-- <th>СУММА ОСТАТОК</th> -->
            </tr>
            <tr 
                v-for="(contract, idx) in user.contracts"
                :key="idx"
            >
                <td><b>{{ JSON.parse(contract).number }}</b></td>
                <td><b>от {{ getNormalFormatedDate(JSON.parse(contract).date_at) }}</b></td>
                <td>{{ JSON.parse(contract).organization }}</td>
                <td><b>{{ JSON.parse(contract).sum_contract }} {{ regionCurrency[regionCode].currency }}.</b></td>
                <!-- <td><b>{{ JSON.parse(contract).sum_balance }} {{ regionCurrency[regionCode].currency }}.</b></td> -->
            </tr>
        </table>
    </div>
</section>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
            user: 'auth/user',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
        }),
    },
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>