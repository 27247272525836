<template>
    <div>
        <swiper 
            :options="swiperOption" 
            :breakpoints="swiperOption.breakpoints"
            ref="swiperThumbs"
        >
            <swiper-slide v-for="(element , i) in products" :key="i"> 
                <single-card :product="element" />
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
import Vuex from 'vuex'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import singleCard from '@/components/pages/home/single-card-slider.vue'

export default {
    props: {
        elements: {
            type: Array,
            default: () => []
        },
    },
    components: {
        Swiper,
        SwiperSlide,
        singleCard,
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOption: {
                spaceBetween: 0,
                slidesPerView: 'auto',
                slideToClickedSlide: true,
                // breakpoints: {
                //     768: {
                //         slidesPerView: 3.1
                //     },
                //     1260: {
                //         slidesPerView: 4.1
                //     },
                //     1680: {
                //         slidesPerView: 4.1
                //     },
                //     1770: {
                //         slidesPerView: 4.6
                //     },
                // },
            },
            products: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            currentWindowWidth: 'app/currentWindowWidth',
            region: 'auth/city',
        }),
        categoryID1cs() {
            let category_id1cs = []
            
            if (this.elements && this.elements.length != 0) {
                this.elements.map((element) => {
                    category_id1cs.push(element.link)
                })
            }
            
            return category_id1cs
        },
    },
    watch: {
        elements() {
            this.getCategories() 
        }
    },
    mounted() {
        if (this.elements.length != 0) {
            this.getCategories()
        }
    },
    methods: {
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
        }),
        getCategories() {
            this.searchNavbar({id_1cs: this.elements, root_id: this.region, is_show_zero_price: true})
                .then((response) => {
                    this.products = response.data
                })
        }
    },
}
</script>
<style scoped>
    .swiper-container{
        padding: 10px;
    }
    .swiper-slide{ 
        width: 25%; 
    }
    .section--products--item{
        min-width: unset !important;
        margin-right: 15px !important;
    }
</style>
<style scoped src="../../css/castom.css"></style>