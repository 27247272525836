<template>
  <tr class="single_utm_tr">
    <td>{{ position.id }}</td>
    <td>
        <the-mask 
            v-if="isChangePhone" 
            v-model="newPhoneValue" 
            mask="+7 (###) ###-####" 
            placeholder="Phone number" 
            type="tel" 
            :style="widthPhoneTd != null ? 'width:' + widthPhoneTd + 'px' : 'width: 100px'" 
            style="height: 20px; padding: 5px; margin: 0;"    
        />
        <p v-else @click="isChangePhone = true" style="margin: 0;">
            {{ position.phone }}
        </p>
    </td>
    <td>
        <p v-if="!isChangeRoot1cId" @click="isChangeRoot1cId = true" style="margin: 0;">
            {{ position.root_1c_id }}
        </p>

        <select 
            v-if="isChangeRoot1cId"  
            v-model="newRoot1cIdValue" 
            class="region_utm_select"
            :style="widthRoot1cTd != null ? 'width:' + widthRoot1cTd + 'px' : 'width: 100px'" 
            style="height: 30px; padding: 5px; margin: 0;"
        >
            <option 
                :value="region.id_1c" 
                v-for="region in topLevelCategory" 
                :key="region.id"
            >
                {{ region.name }}
            </option>
        </select>
    </td>
    <td>
        <p v-if="!isChangeUtm" @click="isChangeUtm = true" style="margin: 0;">
            {{ position.utm }}
        </p>
        <div class="field-input">
            <input 
                type="text" 
                v-if="isChangeUtm" 
                v-model="newUtmValue" 
                :style="widthUtmTd != null ? 'width:' + widthUtmTd + 'px' : 'width: 100px'" 
                style="height: 20px; padding: 5px; margin: 0;"
            >
        </div>
    </td>
    <td>
        <p v-if="!isChangeEmail" @click="isChangeEmail = true" style="margin: 0;">
            {{ position.email }}
        </p>
        <div class="field-input">
            <input 
                type="text" 
                v-if="isChangeEmail" 
                v-model="newEmailValue" 
                :style="widthEmailTd != null ? 'width:' + widthEmailTd + 'px' : 'width: 100px'" 
                style="height: 20px; padding: 5px; margin: 0;"
            >
        </div>
    </td>
    <td v-if="changeRowId != position.id"><i @click="setRowId(position.id)" class="fa fa-pen"></i></td>
    <td v-if="changeRowId == position.id"><i @click="save()" class="fa fa-save"></i></td>
    <td ><i @click="deletePos(position.id)" class="fa fa-trash"></i></td>
  </tr>
</template>
<script>
import Vuex from 'vuex'
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

export default {
    props: {
        position: {
            type: Object,
            default: () => {},
        },
        widthUtmTd: {
            type: Number,
            default: 0,
        },
        widthPhoneTd: {
            type: Number,
            default: 0,
        },
        widthRoot1cTd: {
            type: Number,
            default: 0,
        },
        widthEmailTd: {
            type: Number,
            default: 0,
        },
        changeRowId: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            isChangeUtm: false,
            newUtmValue: "",
            isChangePhone: false,
            newPhoneValue: "",
            isChangeRoot1cId: false,
            newRoot1cIdValue: "",
            isChangeEmail: false,
            newEmailValue: "",
        }
    }, 
    computed: {
        ...Vuex.mapGetters({
            topLevelCategory: 'dictionary/topLevelCategory',
        }),
    },
    watch: {
        changeRowId(val) {
            if (val == this.position.id) {
                this.isChangeUtm = true
                this.isChangePhone = true
                this.isChangeRoot1cId = true
                this.isChangeEmail = true
            }else{
                this.isChangeUtm = false
                this.isChangePhone = false
                this.isChangeRoot1cId = false
                this.isChangeEmail = false
            }
        },
        isChangeUtm(val) {
            if (val == true) {
                this.newUtmValue = this.position.utm
            }else{
                this.newUtmValue = ""
            }
        },
        isChangePhone(val) {
            if (val == true) {
                this.newPhoneValue = this.position.phone
            }else{
                this.newPhoneValue = ""
            }
        },
        isChangeRoot1cId(val) {
            if (val == true) {
                this.newRoot1cIdValue = this.position.root_1c_id
            }else{
                this.newRoot1cIdValue = ""
            }
        },
        isChangeEmail(val) {
            if (val == true) {
                this.newEmailValue = this.position.email
            }else{
                this.newEmailValue = ""
            }
        },
    },
    methods: {
        ...Vuex.mapActions({
            deleteUtm: 'utm/deleteUtm',
            updateUtm: 'utm/updateUtm',
        }),
        setRowId(id) {
            this.$emit('setRowId', id)
        },
        deletePos(id) {
            this.deleteUtm(id)
        },
        save() {
            this.updateUtm({
                id: this.position.id,
                object: {
                    phone: '7' + this.newPhoneValue,
                    root_1c_id: this.newRoot1cIdValue,
                    utm: this.newUtmValue,
                    email: this.newEmailValue,
                }
            }).then(() => {
                this.setRowId('')
                this.$emit('searchUtm')
            })
        },
    },
}
</script>
<style lang="scss">
    .single_utm_tr{
        td{
            padding: 0.7rem;
            border: 1px solid #F4F3F8;
            height: 45px;
        }
    }
</style>