
<template>
    <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <h3>Вы действительно хотите удалить этот заказ?</h3>
                        <div class="form" name="callback-form-popup">
                            <div class="form-fieldset form-fieldset--medium">
                                <div class="form-group">
                                    <div class="form-item">
                                        <button class="button button--primary" @click="confirm">Да</button>
                                        <button class="button button" @click="close">Отмена</button>
                                    </div>
                                    <p class="red_text" v-if="isShowAccessDeniedMessage">Вы не можете удалить этот заказ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Functions from '@/utils/functions'
import Vuex from 'vuex'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
export default {
	props:['route'],
    mixins: [Functions],
    data () {
      return {
          name: '',
          phone:'',
          email:'',
          check: false,
          sended: false,

          isShowAccessDeniedMessage: false,
      }
    },
    computed: {
        ...Vuex.mapGetters({
			order: 'basket/order',
            deleteOrderId: 'app/deleteOrderId',
		}),
    },
    methods: {
         ...Vuex.mapMutations({
            setShowDeleteOrderModal: 'app/setShowDeleteOrderModal',
            setDeleteOrderId: 'app/setDeleteOrderId',
            setOrder: 'basket/setOrder',
        }),
		...Vuex.mapActions({
			deleteStoreOrder: 'basket/deleteOrder',
            searchOrder: 'basket/searchOrder',
        }),
        close(){
            this.isShowAccessDeniedMessage = false
            this.setShowDeleteOrderModal(false)
            this.setDeleteOrderId(null)
        },
        confirm() {
            if (this.deleteOrderId == this.order.id) {
                this.setOrder({
                    id: null,
                    positions: [],
                    
                    account_type: null,
                    location: null,
                    postcode: null,

                    delivery_type: null,
                    pay_type: null,

                    organization_name: null,
                    organization_address: null,
                    inn: null,
                    kpp: null,

                    fio: null,
                    email:null,
                    phone: null,
                    comment: null,
                })
                if (localStorage.mayakOrderId) {
                    localStorage.removeItem("mayakOrderId")
                } 
            }
            this.deleteStoreOrder(this.deleteOrderId)
                .then(() => {
                    this.searchOrder()
                    this.close()
                })
                .catch(err => {
                    if (err.response.status == 403) {
                        this.isShowAccessDeniedMessage = true
                    }
                })
        },
    },
  }
</script>
<style lang="scss">
    .red_text{
        color: red;
        margin-top: 2rem;
    }
</style>