<template>
    <div class="block-fence setting-cards">
        <div class="table table_wscroll">
            <div class="category" :class="{'-active' : currentRootId == category.id_1c}" v-for="category in topCategory" :key="category.id" @click="getCalcFence(category.id_1c)">
                <p>{{ category.name }}</p>
            </div>
        </div>
        <div class="cl-top-option nlk-horizontal-scroll">
            <label 
                class="cl-icon-radio"
                v-for="fenceType in fenceTypes"
                :key="fenceType.id"
                @click="setFenceType(fenceType.id)"
            >
                <input 
                    type="radio" 
                    name="option1" 
                    :checked="(fence_type == fenceType.id ? true : false)"
                >
                <div class="cl-icon-radio__icon">
                    <img :src="require('@/assets/img/option2/o' + fenceType.num + '.svg')" class="cl-icon-radio__img-1">
                    <img :src="require('@/assets/img/option2/o' + fenceType.num + '-a.svg')" alt="" class="cl-icon-radio__img-2">
                </div>
                <div class="cl-icon-radio__btn">{{ fenceType.name }}</div>
            </label>
        </div>

        <div class="block_array" v-if="fence_type == 10 && calc_fence_body.prof.length != 0">
            <label>Список профнастила: </label>
            <p v-for="prof, idx in calc_fence_body.prof" :key="idx">
                <span>{{ prof }} / {{ getOneProfName(prof) }}</span>
                <span style="margin-left: 5px; color: red; cursor: pointer" @click="deleteFence(prof)">X</span>
            </p>
        </div>
        <div class="block_array" v-if="fence_type == 20 && calc_fence_body.stack.length != 0">
            <label>Список штакетника: </label>
            <p v-for="stack, idx in calc_fence_body.stack" :key="idx">
                <span>{{ stack }} / {{ getOneStackName(stack) }}</span>
                <span style="margin-left: 5px; color: red; cursor: pointer" @click="deleteFence(stack)">X</span>
            </p>
        </div>
        <div 
            v-if="fence_type"
            class="search-field mb-10"
        >
            <input 
                class="search-input"
                type="text" 
                v-model="query"
                @keyup="getElements()"
            >
            <button 
                class="search-submit" 
                @click="getElements()"
            >
                <span class="search-submit-label">найти</span>
            </button>
        </div>
        <div 
            v-if="query.length > 3"
            class="block-elements mb-10"
        >
            <div class="search-results"  data-search-results>
                <div 
                    class="search-result"
                    v-for="product in elements"
                    :key="product.id"
                >
                    <div class="search-result-title">
                        <a class="search-result-link">{{ product.name }}</a>
                    </div>
                    <div class="search-result-button">
                        <button 
                            class="button button--primary button--cart"
                            @click="addFence(product.id_1c)"
                        >
                            Выбрать
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="additional-block mb-10">
            <label>Столбы и прожилины:</label>
        
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <th>
                        Название
                    </th>
                    <th>
                        Поиск
                    </th>
                </tr>
                <one-tr-fence :name="'pillar'" :typeSearch="'product'" />
                <one-tr-fence :name="'vein'" :typeSearch="'product'" />
            </table>
        </div>
        
        <div class="additional-block mb-10">
            <label>Доборные элементы:</label>
        
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac">
                <tr>
                    <th>
                        Название
                    </th>
                    <th>
                        Поиск
                    </th>
                </tr>
                <one-tr-fence :name="'additional'" :typeSearch="'group'" />
                <one-tr-fence :name="'fasteners'" :typeSearch="'group'" :isLengthWidth="true" />
                <one-tr-fence :name="'nozzle'" :typeSearch="'product'" />
                <one-tr-fence :name="'clips'" :typeSearch="'group'" :isLengthWidth="true" />
                <one-tr-fence :name="'zaklep'" :typeSearch="'product'" />
                <one-tr-fence :name="'paint'" :typeSearch="'group'" />
                <one-tr-fence :name="'scissors'" :typeSearch="'product'" />
                <one-tr-fence :name="'cut_round'" :typeSearch="'product'" />
                <one-tr-fence :name="'roulette'" :typeSearch="'product'" />
            </table>
        </div>
        <calculator-step :step="'step1'" :calculatorType="'calculatorFence'" />
        <calculator-step :step="'step2'" :calculatorType="'calculatorFence'" />
        <calculator-step :step="'step3'" :calculatorType="'calculatorFence'" />
    </div>
</template>
<script>
import calculatorStep from './calculator-step.vue'
import oneTrFence from './one-tr-fence.vue'
import Vuex from 'vuex'

export default {
    components: {
        calculatorStep,
        oneTrFence,
    },
    data() {
        return {
            currentRootId: null,
            fence_type: null,
            fenceTypes: [
				{id: 10, name: 'Профнастил', num: 1},
				{id: 20, name: 'Штакетник', num: 2},
			],
            query: '',
            elements: [],
            profArray: [],
            stackArray: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            topCategory: 'catalog/topCategory',
            calc_fence_body: 'calculator/calc_fence_body',
            yandexCloud: 'app/yandexCloud',
        }),
        profArrayId1c() {
            return this.calc_fence_body.prof
        },
        stackArrayId1c() {
            return this.calc_fence_body.stack
        },
    },
    watch: {
        profArrayId1c() {
            this.getProfArray()
        },
        stackArrayId1c() {
            this.getStackArray()
        },
    },
    methods: {
        ...Vuex.mapActions({
            getStoreCalcFence: 'calculator/getCalcFence',
            searchNavbar: 'catalog/searchNavbar',
        }),
        ...Vuex.mapMutations({
            changeBodyCalcFence: 'calculator/changeBodyCalcFence',
        }),
        getCalcFence(id_1c) {
            this.changeBodyCalcFence({type: 'root_1c_id', value: id_1c})
            this.currentRootId = id_1c
            this.getStoreCalcFence(id_1c)
        },
        setFenceType(val) {
            this.fence_type = val
        },
        addFence(id_1c) {
            if (this.fence_type == 10) {
                this.changeBodyCalcFence({type: 'prof', do: 'add', value: id_1c})
            }else if (this.fence_type == 20) {
                this.changeBodyCalcFence({type: 'stack', do: 'add', value: id_1c})
            }
            this.query = ''
        },
        deleteFence(id_1c) {
            if (this.fence_type == 10) {
                this.changeBodyCalcFence({type: 'prof', do: 'delete', value: id_1c})
            }else if (this.fence_type == 20) {
                this.changeBodyCalcFence({type: 'stack', do: 'delete', value: id_1c})
            }
        },
        getElements() {
            if (this.query == null || this.query.length < 3) {
                return
            }
            this.searchNavbar({root_id: this.calc_fence_body.root_1c_id, query: this.query, is_group: true, is_show_zero_price: false})
                .then((response) => {
                    this.elements = response.data
                })
        },
        getProfArray() {
            this.searchNavbar({root_id: this.calc_fence_body.root_1c_id, id_1cs: this.profArrayId1c})
                .then((response) => {
                    this.profArray = response.data
                })
        },
        getOneProfName(id_1c) {
            let name = null
            this.profArray.map((element) => {
                if (id_1c == element.id_1c) {
                    name = element.name
                }
            })
            return name
        },
        getStackArray() {
            this.searchNavbar({root_id: this.calc_fence_body.root_1c_id, id_1cs: this.stackArrayId1c})
                .then((response) => {
                    this.stackArray = response.data
                })
        },
        getOneStackName(id_1c) {
            let name = null
            this.stackArray.map((element) => {
                if (id_1c == element.id_1c) {
                    name = element.name
                }
            })
            return name
        },
        issetInArray() {

        },
    },
}
</script>
<style scoped src="../../../../css/nlk-style.css"></style>
<style lang="scss">
    .block-fence{
        .mb-10{
            margin-bottom: 10rem;
        }
        .cl-top-option{
            margin-bottom: 10rem;

            .cl-icon-radio{
                max-width: 200px;
            
                .cl-icon-radio__btn{
                    height: 60px;
                }
            }
        }

        .block_array{
            margin-top: 20px;

            span{
                font-size: 15px;
            }
        }

        .search-field{
            width: 50%;

            .search-input{
                width: 100%;
                height: 48px;
                padding: 17px 0 17px 23px;
                background: #f5f6fa;
                color: #353b48;
                font-size: 1.2rem;
                margin: 0;
                border: 0;
                border-radius: 4px;
                font-weight: 700;
            }
            .search-submit{
                position: absolute;
                width: 18px;
                height: 18px;
                background: url('~@/img/search-button.svg') 0 0/100% 100% no-repeat;
                border: 0;
                padding: 0;
                margin: 0;
                top: 50%;
                margin-top: -9px;
                right: 26px;
            }
        }

        .block-elements{
            width: 50rem;
            
            .search-results{
                display: block;

                .search-result{
                    padding: 15px;
                    
                    .button{
                        padding: 5px;
                    }
                }
            }
        }

        .nlk-table{
            .search-field{
                width: 100%;
            }
            .left-td{
                width: 40%;
            }

            td{
                border: 1px solid #bdbec1;
            }

            .block-elements{
                position: absolute;
                background: #fff;
                z-index: 100;
                width: 63rem;

                .search-results{
                    margin-top: 0;
                    
                }
            }
        }
    }
</style>