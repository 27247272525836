<template>
    <div class="index__admin">
        <span>Объект № {{ index + 1 }}</span>
        <span 
            class="index__del" 
            @click="deleteUiObject(element.id)" 
        >
            Удалить Х
        </span>
        
        <label>Наименование</label>
        <input 
            type="text" 
            v-model="editElement.name" 
            placeholder="Наименование"
            @change="update" 
        >
        
        <label>Ссылка на изображение</label>
        <input 
            type="text" 
            v-model="editElement.linkimg"
            placeholder="Ссылка на изображение"
            @change="update" 
        >
        <div class="row">
            <label class="fl__img">
                <input 
                    :ref="'singleFileObject'+ index" 
                    type="file" 
                    @change="uploadImg()"
                >
            </label>
            <p > Изображение должно быть размером 810х460 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат. </p>
        </div>
        <img 
            :src="editElement.linkimg" 
            alt=""
        >
        <input 
            type="text" 
            v-model="editElement.link" 
            placeholder="Ссылка"
            @change="update" 
        >
    </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    props:['element', 'index'],
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
            updateUiObject: 'ui_object/updateObject',
            deleteUiObject: 'ui_object/deleteObject',
        }),
        update() {
            this.updateUiObject(this.editElement)
        },
        uploadImg() {
            let image = this.$refs['singleFileObject' + this.index].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.updateUiObject({
                        id: this.editElement.id,
                        root_id: this.editElement.root_id,
                        name: this.editElement.name,
                        link: this.editElement.link,
                        linkimg: this.fileUrl + response.data[0].link,
                    })
                })
        },
    },
}
</script>