<template>
  <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup">
                            <p>Пользователь с указанным email уже зарегистрирован!</p>
                            <div 
                                class="form-item"
                                @click="forget"  
                            >
                                <div class="label">
                                    <a>Забыли пароль?</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    methods: {
        ...Vuex.mapMutations({
            setShowUserExistModal: 'app/setShowUserExistModal',
        }),
        close() {
            this.setShowUserExistModal(false)
        },
        forget() {
            this.close()
            this.push('recovery')
        },
    }
}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>