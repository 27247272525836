<template>
    <section class="admin-coating-chars-page admin-index index__admin">
        <div class="admin-index_header">
            <h3>Характеристики покрытий</h3>
        </div>

        <div class="main_block">
            <div class="button_block">
                <button 
                    class="button button--primary" 
                    @click="openModal()"
                >
                    Добавить характеристику
                </button>

                <button 
                    class="button button--primary" 
                    @click="push('admin/coatings')"
                >   Покрытия
                </button>
            </div>
            <table class="nlk-table nlk-table_w-100">
                <tr>
                    <td>Id</td>
                    <td>Название</td>
                    <td>Тип</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr v-for="char, i in chars" :key="i">
                    <td><p>{{ char.id }}</p></td>
                    <td>
                        <p v-if="idEditChar != char.id">{{ char.name }}</p>
                        <input v-else type="text" v-model="editElement.name">
                    </td>
                    <td>
                        <p v-if="idEditChar != char.id">{{ char.type }}</p>
                        <input v-else type="text" v-model="editElement.type">
                    </td>
                    <td v-if="idEditChar != char.id">
                        <i class="fas fa-pen" @click="setChangeElement(char)"></i>
                    </td>
                    <td v-if="idEditChar == char.id">
                        <i class="fas fa-save" @click="updateChar()"></i>
                    </td>
                    <td><i class="fas fa-trash" @click="deleteChar(char.id)"></i></td>
                </tr>
            </table>
        </div>

        <create-modal v-if="isShowModalCreate" />
    </section>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import createModal from './modal/createChar.vue'

export default {
    mixins: [Functions],
    components: {
        createModal,
    },
    data() {
        return {
            idEditChar: null,
            editElement: {
                name: null,
                type: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            chars: 'coatingChars/chars',
            isShowModalCreate: 'coatingChars/isShowModalCreate',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            getChars: 'coatingChars/search',
            updateStoreChar: 'coatingChars/updateChar',
            deleteStoreChar: 'coatingChars/deleteChar',
        }),
        ...Vuex.mapMutations({
            setShowModalCreate: 'coatingChars/setShowModalCreate'
        }),
        openModal() {
            this.setShowModalCreate(true)
        },
        deleteChar(id) {
            this.deleteStoreChar(id)
        },
        setChangeElement(element) {
            this.idEditChar = element.id
            this.editElement.name = element.name
            this.editElement.type = element.type
        },
        updateChar() {
            this.updateStoreChar(
                {
                    id: this.idEditChar,
                    object: this.editElement
                })
                .then(() => {
                    this.idEditChar = null
                })
        },
    },
    mounted() {
        this.getChars()
    },
}
</script>
<style scoped src="../../../../css/nlk-style.css"></style>