import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    messageStatus: null,
    users: [],
    form: {
        email: "",
        excludedIds: [],
        ids: [],
        limit: 100,
        page: 1,
        query: ""
    },
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    users: state => state.users,
    form: state => state.form,
}

const actions = {
    getUsers: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/user", getters.form, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setUsers", response.data)
                    resolve(response)
                })
        })
    },
    createUser: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/user/create", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", null)
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                    commit("setMessageStatus", err.response.status)
                    if (err.message) {
                        commit("setMessage", err.message)
                    }
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setUsers: (state, val) => {
        state.users = val
    }, 
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
