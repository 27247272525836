<template>
    <section>
        <div class="single-coating-block">
            <div class="create-coating">
                <div class="single_vacancy_admin_block">
                    <p class="header_create_block">Главный блок</p>
                    <label for="">Заголовок</label>
                    <input 
                        type="text" 
                        v-model="editForm.main_block.header"
                        @change="update()"
                    >
                    <label for="">Подзаголовок</label>
                    <input 
                        type="text" 
                        v-model="editForm.main_block.subheader"
                        @change="update()"
                    >
                    <label for="">Ссылка на hh.ru</label>
                    <input 
                        type="text" 
                        v-model="editForm.main_block.hh_link"
                        @change="update()"
                    >
                    <label for="">Изображения</label>
                    <div class="list_imgs">
                        <div class="single_img" v-for="singleImg, idx in editForm.main_block.list_imgs" :key="idx">
                            <img :src="singleImg" alt="" class="coating_main_img">
                            <i class="fas fa-times" @click="deleteImgGallery(singleImg)"></i>
                        </div>
                    </div>
                    <div class="row">
                        <label class="fl__img">
                            <input 
                                :ref="'vacancyMainBlockGalleryUpload' + element.id" 
                                type="file" 
                                @change="uploadGalleryImg"
                            >
                        </label>
                        <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                    </div>
                </div>


                <div class="single_vacancy_admin_block">
                    <p class="header_create_block">Блок достижений</p>
                    <label for="">Заголовок</label>
                    <input 
                        type="text" 
                        v-model="editForm.progress_block.header"
                        @change="update()"
                    >
                    <label for="">Описание</label>
                    <textarea 
                        class="custom_textarea"
                        type="text" 
                        v-model="editForm.progress_block.description"
                        rows="3"
                        @change="update()"
                    ></textarea>

                    <button v-if="!isShowCreateFormProgress" class="button button--primary" @click="isShowCreateFormProgress = true">Добавить достижение</button>

                    <div v-if="isShowCreateFormProgress">
                        <p class="header_create_block">Новое достижение</p>
                        <label for="">Название</label>
                        <input 
                            type="text" 
                            v-model="newProgress.name"
                        >
                        <label for="">Описание</label>
                        <input 
                            type="text" 
                            v-model="newProgress.description"
                        >
                        <label for="">Изображение</label>
                        <input 
                            type="text" 
                            v-model="newProgress.img"
                        >
                        <img v-if="newProgress.img" :src="newProgress.img" alt="">
                        <div class="row">
                            <label class="fl__img">
                                <input 
                                    :ref="'vacancyProgressBlockUpload' + element.id" 
                                    type="file" 
                                    @change="uploadProgressImage"
                                >
                            </label>
                            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                        </div>
                        <button class="button button--primary" @click="createProgress">Добавить</button>
                    </div>

                    <div class="progress_list_block">
                        <p class="header_create_block">Список достижений</p>

                        <div class="progress_elements">
                            <div  
                                v-for="single_progress, idx in editForm.progress_block.list_progress"
                                :key="idx"
                                class="progress_elem"
                            >
                                <img v-if="single_progress.img" class="progress_elem_image" :src="single_progress.img" alt="">
                                <p v-if="single_progress.name" class="progress_elem_name">{{ single_progress.name }}</p>
                                <p v-if="single_progress.description" class="progress_elem_description">{{ single_progress.description }}</p>
                                <i class="fas fa-times" @click="deleteProgress(idx)"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="single_vacancy_admin_block">
                    <p class="header_create_block">Блок с видео</p>
                    <label for="">Ссылка на видео</label>
                    <input 
                        type="text" 
                        v-model="editForm.video_link"
                        @change="update()"
                    >
                </div>

                <div class="single_vacancy_admin_block">
                    <p class="header_create_block">Блок преимуществ</p>
                    <button v-if="!isShowCreateFormAdvantage" class="button button--primary" @click="isShowCreateFormAdvantage = true">Добавить преимущество</button>
                    <div v-if="isShowCreateFormAdvantage">
                        <p class="header_create_block">Новое преимущество</p>

                        <label for="">Название</label>
                        <input 
                            type="text" 
                            v-model="newAdvantage.name"
                        >

                        <label for="">Ссылка на изображение</label>
                        <input 
                            type="text" 
                            v-model="newAdvantage.img"
                        >

                        <img v-if="newAdvantage.img" :src="newAdvantage.img" alt="">
                        <div class="row">
                            <label class="fl__img">
                                <input 
                                    :ref="'vacancyAdvantageBlockUpload' + element.id" 
                                    type="file" 
                                    @change="uploadAdvantageImage"
                                >
                            </label>
                            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                        </div>
                        <button class="button button--primary" @click="createAdvantage">Добавить</button>
                    </div>

                    <div class="advantages_list_block">
                        <div 
                            v-for="single_advantage, idx in editForm.advantages_list"
                            :key="idx"
                            class="single_advantage"
                        >
                            <p v-if="single_advantage.name" class="single_advantage_name">{{ single_advantage.name }}</p>
                            <img v-if="single_advantage.img" :src="single_advantage.img" alt="">
                            <i class="fas fa-times" @click="deleteAdvantage(idx)"></i>
                        </div>
                    </div>
                </div>

                <div class="single_vacancy_admin_block">
                    <p class="header_create_block">Блок с генеральным директором</p>
                    <label for="">Заголовок</label>
                    <input 
                        type="text" 
                        v-model="editForm.general_block.header"
                        @change="update()"
                    >
                    <label for="">Описание</label>
                    <textarea 
                        class="custom_textarea"
                        name="" 
                        id="" 
                        rows="5"
                        v-model="editForm.general_block.description"
                        @change="update()"
                    ></textarea>

                    <label for="">Цитата</label>
                    <textarea 
                        class="custom_textarea"
                        name="" 
                        id="" 
                        rows="5"
                        v-model="editForm.general_block.quote"
                        @change="update()"
                    ></textarea>

                    <label for="">Изображение</label>
                    <input 
                        type="text" 
                        v-model="editForm.general_block.img"
                        @change="update()"
                    >

                    <img v-if="editForm.general_block.img" :src="editForm.general_block.img" alt="">
                    <div class="row">
                        <label class="fl__img">
                            <input 
                                :ref="'vacancyGeneralBlockUpload' + element.id" 
                                type="file" 
                                @change="uploadGeneralImage"
                            >
                        </label>
                        <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                    </div>
                </div>

                <div class="single_vacancy_admin_block">
                    <p class="header_create_block">Блок с баннером</p>
                    <label for="">Заголовок</label>
                    <input 
                        type="text" 
                        v-model="editForm.banner_block.header"
                        @change="update()"
                    >
                    <label for="">Ссылка на изображение</label>
                    <input 
                        type="text" 
                        v-model="editForm.banner_block.img"
                        @change="update()"
                    >
                    <img v-if="editForm.banner_block.img" :src="editForm.banner_block.img" alt="">
                    <div class="row">
                        <label class="fl__img">
                            <input 
                                :ref="'vacancyBannerBlockUpload' + element.id" 
                                type="file" 
                                @change="uploadBannerImage"
                            >
                        </label>
                        <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                    </div>
                </div>

                <div class="single_vacancy_admin_block">
                    <p class="header_create_block">Блок с вопросами</p>
                    <button v-if="!isShowCreateFormQuestion" class="button button--primary" @click="isShowCreateFormQuestion = true">Добавить вопрос</button>
                    <div v-if="isShowCreateFormQuestion">
                        <p class="header_create_block">Новый вопрос</p>

                        <label for="">Вопрос</label>
                        <input 
                            type="text" 
                            v-model="newQuestion.question"
                        >
                        <label for="">Ответ</label>
                        <input 
                            type="text" 
                            v-model="newQuestion.answer"
                        >

                        <button class="button button--primary" @click="createQuestion">Добавить</button>
                    </div>

                    <p class="header_create_block">Вопросы</p>
                    <div class="question_list_block">
                        
                        <div
                            v-for="single_question, idx in editForm.questions_list"
                            :key="idx"
                            class="single_question_block"
                        >
                            <p class="question_block_question">{{ single_question.question }}</p>
                            <p class="question_block_answer">{{ single_question.answer }}</p>
                            <i class="fas fa-times" @click="deleteQuestion(idx)"></i>
                        </div>
                    </div>
                </div>

                <div class="single_vacancy_admin_block">
                    <p class="header_create_block">Блок с интервью</p>
                    <button v-if="!isShowCreateFormInterview" class="button button--primary" @click="isShowCreateFormInterview = true">Добавить интервью</button>
                    <div v-if="isShowCreateFormInterview">
                        <p class="header_create_block">Новое интервью</p>

                        <label for="">Имя</label>
                        <input 
                            type="text" 
                            v-model="newInterview.name"
                        >

                        <label for="">Должность</label>
                        <input 
                            type="text" 
                            v-model="newInterview.post"
                        >

                        <label for="">Текст</label>
                        <!-- <input 
                            type="text" 
                            v-model="newInterview.text"
                        > -->
                        <vue-editor 
                            v-model="newInterview.text"
                            class="editor"
                        />

                        <label for="">Фото</label>
                        <input 
                            type="text" 
                            v-model="newInterview.img"
                        >
                        <img v-if="newInterview.img" :src="newInterview.img" alt="">
                        <div class="row">
                            <label class="fl__img">
                                <input 
                                    :ref="'vacancyInterviewBlockUpload' + element.id" 
                                    type="file" 
                                    @change="uploadInterviewImage"
                                >
                            </label>
                            <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                        </div>

                        <button class="button button--primary" @click="createInterview">Добавить</button>
                    </div>

                    <p class="header_create_block">Список интервью</p>
                    <div class="interview_list_block">
                        <div 
                            v-for="single_interview, idx in editForm.interview_list"
                            :key="idx"
                            class="single_interview"
                        >
                            <div class="custom_interview_avatar_block_admin">
                                <img v-if="single_interview.img" :src="single_interview.img" alt="">
                            </div>
                            <p class="single_interview_name">{{ single_interview.name }}</p>
                            <p class="single_interview_post">{{ single_interview.post }}</p>
                            <p class="single_interview_text">{{ single_interview.text }}</p>
                            <i class="fas fa-times" @click="deleteInterview(idx)"></i>
                        </div>
                    </div>
                </div>

                

                <p class="header_create_block">Регионы</p>
                <div class="form_new_char">
                    <select name="" id="" v-model="selectedRegion">
                        <option value="null">Выбрать регион</option>
                        <option :value="region.id_1c" v-show="!checkIssetRegion(region.id_1c)" v-for="region, idx in regions" :key="idx">{{ region.name }}</option>
                    </select>
        
                    <button class="button button--primary" @click="addRegion">Добавить</button>
                </div>
                <div class="region_list">
                    <div v-for="singleRegion, idx in editForm.root_ids" :key="idx" class="single_region">
                        <p>{{ getRegionNameById1c(singleRegion) }}</p>
                        <i class="fas fa-times" @click="deleteRegion(singleRegion)"></i>
                    </div>  
                </div>

                <button class="button button--primary" @click="deleteVacancyMainPage">Удалить</button>
            </div>
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'
import { VueEditor } from "vue2-editor"

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        VueEditor,
    },
    data() {
        return {
            editForm: {
                main_block: {
                    header: null,
                    subheader: null,
                    hh_link: null,
                    list_imgs: [],
                },

                video_link: null,

                progress_block: {
                    header: null,
                    description: null,
                    list_progress: [],
                },

                general_block: {
                    header: null,
                    description: null,
                    img: null,
                    quote: null
                },

                banner_block: {
                    header: null,
                    img: null
                },

                advantages_list: [],
                questions_list: [],
                interview_list: [],
                root_ids: [],
            },

            selectedRegion: null,

            isShowCreateFormProgress: false,
            newProgress: {
                name: null,
                description: null,
                img: null,
            },

            isShowCreateFormAdvantage: false,
            newAdvantage: {
                name: null,
                img: null,
            },

            isShowCreateFormQuestion: false,
            newQuestion: {
                question: null,
                answer: null,
            },

            isShowCreateFormInterview: false,
            newInterview: {
                name: null,
                text: null,
                post: null,
                img: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
            regions:'dictionary/topLevelCategory',
        }),
    },
    mounted() {
        this.setValues()
    },
    methods: {
        ...Vuex.mapActions({
            updateVacancy: 'vacancy/updateVacancy',
            deleteVacancy: 'vacancy/deleteVacancy',
            upload: 'app/upload',
        }),
        update() {
            this.updateVacancy({
                id: this.element.id,
                main_block: this.editForm.main_block,
                progress_block: this.editForm.progress_block,
                video_link: this.editForm.video_link,
                advantages_list: this.editForm.advantages_list,
                general_block: this.editForm.general_block,
                banner_block: this.editForm.banner_block,
                questions_list: this.editForm.questions_list,
                interview_list: this.editForm.interview_list,
                root_ids: this.editForm.root_ids,
            })
        },
        setValues() {
            this.editForm.main_block = Object.assign({}, this.element.main_block)
            this.editForm.progress_block = Object.assign({}, this.element.progress_block)
            this.editForm.general_block = Object.assign({}, this.element.general_block)
            this.editForm.banner_block = Object.assign({}, this.element.banner_block)
            this.editForm.video_link = this.element.video_link
            this.editForm.advantages_list = this.element.advantages_list
            this.editForm.questions_list = this.element.questions_list
            this.editForm.interview_list = this.element.interview_list
            this.editForm.root_ids = this.element.root_ids
        },
        createProgress() {
            if (!this.editForm.progress_block.list_progress) {
                this.editForm.progress_block.list_progress = []
            }
            this.editForm.progress_block.list_progress.push({
                name: this.newProgress.name,
                description: this.newProgress.description,
                img: this.newProgress.img,
            })

            this.update()

            this.newProgress.name = null
            this.newProgress.description = null
            this.newProgress.img = null

            this.isShowCreateFormProgress = false
        },
        createAdvantage() {
            if (!this.editForm.advantages_list) {
                this.editForm.advantages_list = []
            }
            this.editForm.advantages_list.push({
                name: this.newAdvantage.name,
                img: this.newAdvantage.img
            })

            this.update()

            this.newAdvantage.name = null
            this.newAdvantage.img = null
            this.isShowCreateFormAdvantage = false
        },
        createQuestion() {
            if (!this.editForm.questions_list) {
                this.editForm.questions_list = []
            }
            this.editForm.questions_list.push({
                question: this.newQuestion.question,
                answer: this.newQuestion.answer,
            })

            this.update()

            this.newQuestion.question = null
            this.newQuestion.answer = null
            this.isShowCreateFormQuestion = false
        },
        createInterview() {
            if (!this.editForm.interview_list) {
                this.editForm.interview_list = []
            }
            this.editForm.interview_list.push({
                name: this.newInterview.name,
                post: this.newInterview.post,
                text: this.newInterview.text,
                img: this.newInterview.img,
            })

            this.update()

            this.newInterview.name = null
            this.newInterview.post = null
            this.newInterview.text = null
            this.newInterview.img = null
            this.isShowCreateFormInterview = false
        },
        deleteQuestion(index) {
            this.editForm.questions_list.splice(index, 1)
            this.update()
        },
        deleteImgGallery(link) {
            this.editForm.main_block.list_imgs.map((element, idx) => {
                if (element == link) {
                    this.editForm.main_block.list_imgs.splice(idx)
                    this.update()
                }
            })
        },
        uploadGalleryImg() {
            let image = this.$refs['vacancyMainBlockGalleryUpload' + this.element.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editForm.main_block.list_imgs.push(this.fileUrl + response.data[0].link)
                    this.update()
                })
        },
        uploadProgressImage() {
            let image = this.$refs['vacancyProgressBlockUpload' + this.element.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newProgress.img = this.fileUrl + response.data[0].link
                })
        },
        uploadAdvantageImage() {
            let image = this.$refs['vacancyAdvantageBlockUpload' + this.element.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newAdvantage.img = this.fileUrl + response.data[0].link
                })
        },
        uploadGeneralImage() {
            let image = this.$refs['vacancyGeneralBlockUpload' + this.element.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editForm.general_block.img = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
        uploadBannerImage() {
            let image = this.$refs['vacancyBannerBlockUpload' + this.element.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editForm.banner_block.img = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
        uploadInterviewImage() {
            let image = this.$refs['vacancyInterviewBlockUpload' + this.element.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newInterview.img = this.fileUrl + response.data[0].link
                })
        },
        deleteProgress(index) {
            this.editForm.progress_block.list_progress.splice(index, 1)
            this.update()
        },
        deleteAdvantage(index) {
            this.editForm.advantages_list.splice(index, 1)
            this.update()
        },
        deleteInterview(index) {
            this.editForm.interview_list.splice(index, 1)
            this.update()
        },
        getRegionNameById1c(id_1c) {
            var name = null
            this.regions.map((element) => {
                if (element.id_1c == id_1c) {
                    name = element.name
                }
            })
            return name
        },
        checkIssetRegion(id_1c) {
            var isset = false
            this.editForm.root_ids.map((element) => {
                if (element == id_1c) {
                    isset = true
                }
            })
            return isset
        },
        deleteRegion(id_1c) {
            this.editForm.root_ids.map((element, idx) => {
                if (element == id_1c) {
                    this.editForm.root_ids.splice(idx, 1)
                }
            })
        },
        addRegion() {
            if (this.selectedRegion == null) {
                return
            }
            var region_id = JSON.stringify(this.selectedRegion)
            region_id = JSON.parse(region_id)
            this.editForm.root_ids.push(region_id)
            this.selectedRegion = null
            this.update()
        },
        deleteVacancyMainPage() {
            this.deleteVacancy(this.element.id)
        },
    },
}
</script>
<style lang="scss">
    .custom_textarea{
        width: 100%;
    }

    .single_vacancy_admin_block{
        .button{
            padding: 11px;
            font-size: 15px;
            margin-right: 20px;
        }
    }

    .header_create_block{
        font-weight: 600;
        font-size: 20px !important;
        line-height: 25px !important;
        margin-left: 0 !important;
    }

    .progress_list_block{
        .progress_elements{
            display: flex;
            gap: 20px;

            .progress_elem{
                position: relative;
                border: 1px solid black;
                border-radius: 10px;
                padding: 20px;
                width: 23%;

                .progress_elem_name{
                    margin-left: 0;
                    margin-bottom: 10px;
                    font-size: 15px;
                }
                .progress_elem_description{
                    margin-left: 0;
                    margin-bottom: 10px;
                    font-size: 15px;
                }
                // .progress_elem_image{

                // }

                i{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    color: #ED1C24;
                    cursor: pointer;
                }
            }
        }
    }

    .advantages_list_block{
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .single_advantage{
            position: relative;
            border: 1px solid black;
            border-radius: 10px;
            padding: 20px;
            width: 23%;
            margin-bottom: 20px;

            .single_advantage_name{
                margin-left: 0;
                margin-bottom: 10px;
                font-size: 15px;
            }

            i{
                position: absolute;
                top: 10px;
                right: 10px;
                color: #ED1C24;
                cursor: pointer;
            }
        }
    }

    .question_list_block{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .single_question_block{
            position: relative;
            border: 1px solid black;
            border-radius: 10px;
            padding: 20px;
            width: 23%;
            margin-bottom: 20px;
    
            .question_block_question{
                margin-left: 0;
                margin-bottom: 10px;
                font-size: 17px;
            }
    
            .question_block_answer{
                margin-left: 0;
                margin-bottom: 10px;
                font-size: 15px;
                color: grey;
            }
    
            i{
                position: absolute;
                top: 10px;
                right: 10px;
                color: #ED1C24;
                cursor: pointer;
            }
        }
    }

    .interview_list_block{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .single_interview{
            position: relative;
            border: 1px solid black;
            border-radius: 10px;
            padding: 20px;
            width: 23%;
            margin-bottom: 20px;

            .single_interview_name{
                margin-left: 0;
                margin-bottom: 10px;
                font-size: 17px;
                text-align: center;
            }

            .single_interview_post{
                margin-left: 0;
                margin-bottom: 10px;
                font-size: 15px;
                text-align: center;
            }

            i{
                position: absolute;
                top: 10px;
                right: 10px;
                color: #ED1C24;
                cursor: pointer;
            }
        }
    }
</style>