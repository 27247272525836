import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    formAct: {
        client: null,
        date_end: null,
        date_start: null,
    },
    act: {},
    formImplementation: {
        date_end: "3006-01-02T15:04:05Z",
        date_start: "1970-01-02T15:04:05Z",
    },
    implementation: {},
    formBooking: {
        date_end: null,
        date_start: null,
        excludedIds: [],
        status: [],
        ids: [],
        limit: 100,
        page: 1,
        query: ""
    },
    bookingList: [],
}

const getters = {
    message: state => state.message,
    
    formAct: state => state.formAct,
    act: state => state.act,
    formImplementation: state => state.formImplementation,
    implementation: state => state.implementation,
    formBooking: state => state.formBooking,
    bookingList: state => state.bookingList,
}

const actions = {
    getAct: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/acts", getters.formAct, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setAct", response.data)
                    resolve(response)
                })
        })
    },
    getImplementation: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/implementation", getters.formImplementation, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setImplementation", response.data)
                    resolve(response)
                })
        })
    },
    getBookingList: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/booking", getters.formBooking, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setBookingList", response.data)
                    resolve(response)
                })
        })
    },
    getPriceList: ({commit}, id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/prices", {id_1c: id_1c}, {responseType: 'blob', headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success')
                    resolve(response)
                })
        })
    },
    getStatusOrderInProduction: ({commit}, order_id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/statusorder/inproduction", {order_id_1c: [order_id_1c]}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success')
                    resolve(response)
                })
        })
    },
    getStatusOrderInStock: ({commit}, order_id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/statusorder/instock", {order_id_1c: [order_id_1c]}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success')
                    resolve(response)
                })
        })
    },
    getStatusOrderInWork: ({commit}, order_id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/statusorder/inwork", {order_id_1c: [order_id_1c]}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success')
                    resolve(response)
                })
        })
    },
    getStatusOrderInShipped: ({commit}, order_id_1c) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/statusorder/inshipped", {order_id_1c: [order_id_1c]}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success')
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },

    setAct: (state, val) => {
        state.act = val
    },
    setActForm: (state, val) => {
        state.formAct = val
    },
    setImplementation: (state, val) => {
        state.implementation = val
    },
    setImplementationForm: (state, val) => {
        state.formImplementation = val
    },
    setBookingList: (state, val) => {
        state.bookingList = val
    },  
    setBookingForm: (state, val) => {
        state.formBooking = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
