<template>
    <section class="admin-coatings-page admin-index index__admin">
        <div class="admin-index_header">
            <h3>Покрытия</h3>
        </div>

        <div class="buttons-block">
            <button class="button button--primary" @click="isShowCreateForm = true">Добавить покрытие</button>
            <button class="button button--primary" @click="push('admin/coatings/main')">Главная</button>
            <button class="button button--primary" @click="push('admin/coatings/characteristics')">Характеристики</button>
            <button class="button button--primary" @click="push('admin/coatings/colors')">Цвета</button>
        </div>

        <div class="main_block">
            <div
                v-if="isShowCreateForm == true" 
                class="single-coating-block"
            >
                <div class="create-coating">
                    <label for="">Название</label>
                    <input 
                        type="text" 
                        v-model="name"
                        @keyup="updateFieldCreateForm('name')"
                    >
            
                    <label for="">Короткое описание</label>
                    <input 
                        type="text" 
                        v-model="short_description"
                        @keyup="updateFieldCreateForm('short_description')"
                    >
            
                    <label for="">Описание</label>
                    <input 
                        type="text" 
                        v-model="description"
                        @keyup="updateFieldCreateForm('description')"
                    >
            
                    <label for="">Главное изображение</label>
                    <img v-if="mainImage != null" :src="mainImage" alt="" class="coating_main_img">
                    <input 
                        v-model="mainImage" 
                        type="text" 
                        placeholder="Ссылка на изображение" 
                    >
                    <div class="row">
                        <label class="fl__img">
                            <input 
                                ref="coatingMainImg" 
                                type="file" 
                                @change="uploadImg"
                            >
                        </label>
                        <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                    </div>
            
                    <label for="">Изображения</label>
                    <div class="list_imgs">
                        <div class="single_img" v-for="singleImg, idx in createForm.link_image" :key="idx">
                            <img :src="singleImg" alt="" class="coating_main_img">
                            <i class="fas fa-times" @click="deleteImgGallery(singleImg)"></i>
                        </div>
                    </div>
                    <div class="row">
                        <label class="fl__img">
                            <input 
                                ref="coatingMainGalleryImg" 
                                type="file" 
                                @change="uploadGalleryImg"
                            >
                        </label>
                        <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
                    </div>

            
                    <label for="">Цвета</label>
                    <div class="color_list">
                        <div 
                            class="single_color"
                            v-for="color, i in colors"
                            :key="i" 
                            @click="addColor(color.id)"   
                        >
                            <p>{{ color.sub_name }}</p>
                            <label class="checkbox-plus">
                                <div class="checkbox_nd">
                                    <img v-if="!checkIssetColor(color.id)" class="check_false" src="~@/img/checkbox_false1.png" alt="">
                                    <img v-if="checkIssetColor(color.id)" class="check_true" src="~@/img/checkbox_true.png" alt="">
                                </div>
                            </label>
                            <div v-if="color.code" class="background_color_block" :style="color.code == '#ffffff' ? 'background-color:' + color.code + '; border: 1px solid black' : 'background-color:' + color.code"></div>
                            <img v-if="color.image" :src="color.image" class="background_color_block" alt="">
                        </div>
                    </div>
            
                    <label for="">Характеристики</label>
                    <div class="form_new_char">
                        <select name="" id="" v-model="selectedChar">
                            <option value="null">Выбрать характеристику</option>
                            <option :value="char.id" v-show="!checkIssetChar(char.id)" v-for="char, idx in chars" :key="idx">{{ char.name }}</option>
                        </select>
            
                        <button class="button button--primary" @click="addChar">Добавить</button>
                    </div>
                    
                    <div class="char_list">
                        <single-char 
                            v-for="charL, idx in createForm.list_chars" 
                            :key="idx" 
                            :element="charL" 
                            @updateValueChar="updateValueChar"
                            @deleteChar="deleteChar" 
                        />
                    </div>
            
                    <label for="">Регионы</label>
                    <div class="form_new_char">
                        <select name="" id="" v-model="selectedRegion">
                            <option value="null">Выбрать регион</option>
                            <option :value="region.id_1c" v-show="!checkIssetRegion(region.id_1c)" v-for="region, idx in regions" :key="idx">{{ region.name }}</option>
                        </select>
            
                        <button class="button button--primary" @click="addRegion">Добавить</button>
                    </div>

                    <div class="region_list">
                        <div v-for="singleRegion, idx in createForm.root_ids" :key="idx" class="single_region">
                            <p>{{ getRegionNameById1c(singleRegion) }}</p>
                            <i class="fas fa-times" @click="deleteRegion(singleRegion)"></i>
                        </div>  
                    </div>

                    <button class="button button--primary delete_button" @click="isShowCreateForm = false">Отменить</button>
                    <button class="button button--primary delete_button" @click="createCoating">Добавить</button>
                </div>
            </div>

            <single v-show="isShowCreateForm == false" v-for="coating, i in coatings" :key="i" :element="coating" />
        </div>
    </section>
</template>
<script>
import Functions from '@/utils/functions'
import Vuex from 'vuex'
import single from './single.vue'
import singleChar from './singleChar.vue'

export default {
    mixins: [Functions],
    components: {
        single,
        singleChar,
    },
    data() {
        return {
            selectedChar: null,
            selectedRegion: null,
            isShowCreateForm: false,
            mainImage: null,

            name: '',
            description: '',
            short_description: '',
        }
    },
    computed: {
        ...Vuex.mapGetters({
            coatings: 'coatings/coatings',
            createStoreForm: 'coatings/createForm',
            colors: 'coatingColors/colors',
            chars: 'coatingChars/chars',
            fileUrl: 'app/fileUrl',
            regions:'dictionary/topLevelCategory',
        }),
        createForm() {
            return Object.assign({}, this.createStoreForm)
        },
    },
    watch: {
        isShowCreateForm() {
            this.mainImage = null
            this.clearCreateForm()
            this.name = ''
            this.description = ''
            this.short_description = ''
        }
    },
    mounted() {
        this.getColors()
        this.getChars()
        this.getCoatings()
    },
    methods: {
        ...Vuex.mapActions({
            getCoatings: 'coatings/search',
            getColors: 'coatingColors/search',
            getChars: 'coatingChars/search',
            createStoreCoating: 'coatings/createCoating',
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            clearCreateForm: 'coatings/clearCreateForm',
            changeFieldCreateForm: 'coatings/changeFieldCreateForm',
        }),
        createCoating() {
            this.createForm.image[0] = this.mainImage

            this.createStoreCoating(this.createForm)
                .then(() => {
                    this.isShowCreateForm = false
                })
        },
        addColor(color_id) {
            var isset_color = false

            this.createForm.list_colors.map((element, idx) => {
                if (element.color_id == color_id) {
                    this.createForm.list_colors.splice(idx, 1)
                    isset_color = true
                }
            })
            if (isset_color) {
                return
            }
            this.createForm.list_colors.push({color_id: color_id, sort: 1})
        },
        addChar() {
            if (this.selectedChar == null) {
                return
            }
            var char_id = JSON.stringify(this.selectedChar)
            char_id = JSON.parse(char_id)
            this.createForm.list_chars.push({char_id: char_id, value: null, sort: 1})
            this.selectedChar = null
        },
        addRegion() {
            if (this.selectedRegion == null) {
                return
            }
            var region_id = JSON.stringify(this.selectedRegion)
            region_id = JSON.parse(region_id)
            this.createForm.root_ids.push(region_id)
            this.selectedRegion = null
        },
        deleteRegion(id_1c) {
            this.createForm.root_ids.map((element, idx) => {
                if (element == id_1c) {
                    this.createForm.root_ids.splice(idx, 1)
                }
            })
        },
        checkIssetChar(id) {
            var isset = false
            this.createForm.list_chars.map((element) => {
                if (element.char_id == id) {
                    isset = true
                }
            })
            return isset
        },
        checkIssetColor(id) {
            var isset = false
            this.createForm.list_colors.map((element) => {
                if (element.color_id == id) {
                    isset = true
                }
            })
            return isset
        },
        checkIssetRegion(id_1c) {
            var isset = false
            this.createForm.root_ids.map((element) => {
                if (element == id_1c) {
                    isset = true
                }
            })
            return isset
        },
        uploadImg() {
            let image = this.$refs['coatingMainImg'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.mainImage = this.fileUrl + response.data[0].link
                })
        },
        uploadGalleryImg() {
            let image = this.$refs['coatingMainGalleryImg'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.createForm.link_image.push(this.fileUrl + response.data[0].link)
                })
        },
        getRegionNameById1c(id_1c) {
            var name = null
            this.regions.map((element) => {
                if (element.id_1c == id_1c) {
                    name = element.name
                }
            })
            return name
        },
        updateFieldCreateForm(field) {
            this.changeFieldCreateForm({field: field, value: this[field]})
        },
        deleteImgGallery(link) {
            this.createForm.link_image.map((element, idx) => {
                if (element == link) {
                    this.createForm.link_image.splice(idx, 1)
                }
            })
        },
        updateValueChar(object) {
            this.createForm.list_chars.map((element) => {
                if (element.char_id == object.char_id) {
                    element.value = object.value
                }
            })
        },
        deleteChar(id) {
            this.createForm.list_chars.map((element, idx) => {
                if (element.char_id == id) {
                    this.createForm.list_chars.splice(idx, 1)
                }
            })
        },
    },
}
</script>