<template>
    <main class="main">
        <div class="section section--breadcrumbs">
          <div class="container">
            <div class="inner">
              <ul class="list list--flex breadcrumbs">
                <li class="list-item"><a class="list-link" href="#">
                    <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                      <use xlink:href="@/assets/img/icons.svg#home"></use>
                    </svg></a></li>
                <li class="list-item"><span class="list-current">Частным клиентам</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="section section--no-pt">
          <div class="container">
            <div class="post">
              <div class="post-image"><img src="@/assets/img/company.jpg" alt=""></div>
              <div class="post-title">
                <h2>Частным клиентам</h2>
              </div>
              <div class="post-text">
                <p>Мы стремимся стать признанным лидером на рынке металлопроката и металлоконструкций, поэтому уделяем максимум внимания качеству сырья и выпускаемой продукции, совершенствованию сервисного обслуживания клиентов и усилению конкурентных преимуществ компании в целом. Все это позволяет предоставить нашим клиентам продукцию высшего качества с длительными гарантийными обязательствами.</p>
                <p>У нас работает розничный интернет-магазин. Вы можете приобрести товар в любом объеме по доступным ценам.</p>
              </div>
            </div>
          </div>
        </div>
        <!-- section-->
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Каталог товаров</h2>
                </div>
              </div>
            </div>
            <ul class="list list--flex layout-cards -col-4">
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#roof_systems"></use>
                      </svg>
                    </div>
                    <div class="card-title">Кровельные<br> системы</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#glass_wall"></use>
                      </svg>
                    </div>
                    <div class="card-title">Фасадные<br> системы</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#fence"></use>
                      </svg>
                    </div>
                    <div class="card-title">Системы<br> ограждений</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#beam"></use>
                      </svg>
                    </div>
                    <div class="card-title">Металлопрокат</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#bracket"></use>
                      </svg>
                    </div>
                    <div class="card-title">Профили<br> для гипсокартона</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#flood"></use>
                      </svg>
                    </div>
                    <div class="card-title">Сэндвич<br> панели</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#tools"></use>
                      </svg>
                    </div>
                    <div class="card-title">Сопутствующие<br> товары</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
              <li class="list-item"><a class="card" href="#">
                  <div class="card-heading">
                    <div class="card-icon">
                      <svg class="icon" width="60" height="60" viewBox="0 0 60 60">
                        <use xlink:href="@/assets/img/icons.svg#beam_2"></use>
                      </svg>
                    </div>
                    <div class="card-title">Сортовой и фасонный<br> прокат</div>
                  </div>
                  <div class="card-arrow">
                    <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                      <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                    </svg>
                  </div></a>
              </li>
            </ul>
          </div>
        </div>
        <!-- section-->
        <div class="section section--no-pt section--events">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Акции</h2>
                </div>
                <div class="heading-aside"><a class="button button--default" href="#">Смотреть все акции</a></div>
              </div>
            </div>
            <ul class="list list--flex layout-events">
              <li class="list-item list-item--double">
                <div class="event event--with-image">
                  <div class="event-header">
                    <div class="event-image"><img src="@/assets/img/event.jpg" alt=""></div>
                    <div class="event-date">25.05.2018</div>
                  </div>
                  <div class="event-body">
                    <div class="event-title">Монтаж водостоков в подарок при заказе крыши под ключ</div>
                    <div class="event-description">Уважаемые клиенты! В срок до конца июля мы предлагаем воспользоваться специальным предложением на…</div>
                  </div>
                  <div class="event-footer"><a class="button button--primary" href="#">Читать полностью</a></div>
                </div>
              </li>
              <li class="list-item">
                <div class="event">
                  <div class="event-header">
                    <div class="event-date">10.04.2018</div>
                  </div>
                  <div class="event-body">
                    <div class="event-preview"><img src="@/assets/img/event-image.jpg" alt=""></div>
                    <div class="event-title">Мы увеличили срок возврата неиспользованного товара!</div>
                    <div class="event-description">Подробные условия акции здесь.</div>
                  </div>
                  <div class="event-footer"><a class="button button--primary" href="#">Читать полностью</a></div>
                </div>
              </li>
              <li class="list-item">
                <div class="event">
                  <div class="event-header">
                    <div class="event-date">25.05.2018</div>
                  </div>
                  <div class="event-body">
                    <div class="event-preview"><img src="@/assets/img/event-image.jpg" alt=""></div>
                    <div class="event-title">Режим работы на время Чемпионата Мира по футболу</div>
                    <div class="event-description">Уважаемые клиенты! В связи с временным ограничением движения транспортных средств в период проведения чемпионата…</div>
                  </div>
                  <div class="event-footer"><a class="button button--primary" href="#">Читать полностью</a></div>
                </div>
              </li>
              <li class="list-item">
                <div class="event">
                  <div class="event-header">
                    <div class="event-date">03.06.2019</div>
                  </div>
                  <div class="event-body">
                    <div class="event-preview"><img src="@/assets/img/event-image.jpg" alt=""></div>
                    <div class="event-title">С доставкой ещё дешевле и надёжней!</div>
                    <div class="event-description">Закажи доставку и получи: дополнительную скидку на основную продукцию</div>
                  </div>
                  <div class="event-footer"><a class="button button--primary" href="#">Читать полностью</a></div>
                </div>
              </li>
            </ul>
            <div class="heading-bottom"><a class="button button--default" href="#">Смотреть все акции</a></div>
          </div>
        </div>
        <!-- section-->
        <div class="section section--no-pt">
          <div class="container">
            <div class="row row--faq">
              <div class="col">
                <h2>FAQ</h2>
                <p>Наши специалисты<br> подготовили ответы на <a href="#">часто<br> задаваемые вопросы</a></p>
              </div>
              <div class="col">
                <div class="accordion accordion--faq">
                  <div class="accordion-item">
                    <div class="accordion-header">Как узнать, есть ли интересующий меня товар на складе?</div>
                    <div class="accordion-body">
                      <p>Да, позвоните на бесплатную линию 8 800 770 01 98, и наши сотрудники оформят заказ.</p>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header">Как узнать, есть ли интересующий меня товар на складе?</div>
                    <div class="accordion-body">
                      <p>Да, позвоните на бесплатную линию 8 800 770 01 98, и наши сотрудники оформят заказ.</p>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header">Как узнать, есть ли интересующий меня товар на складе?</div>
                    <div class="accordion-body">
                      <p>Да, позвоните на бесплатную линию 8 800 770 01 98, и наши сотрудники оформят заказ.</p>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header">Как узнать, есть ли интересующий меня товар на складе?</div>
                    <div class="accordion-body">
                      <p>Да, позвоните на бесплатную линию 8 800 770 01 98, и наши сотрудники оформят заказ.</p>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header">Как узнать, есть ли интересующий меня товар на складе?</div>
                    <div class="accordion-body">
                      <p>Да, позвоните на бесплатную линию 8 800 770 01 98, и наши сотрудники оформят заказ.</p>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <div class="accordion-header">Как узнать, есть ли интересующий меня товар на складе?</div>
                    <div class="accordion-body">
                      <p>Да, позвоните на бесплатную линию 8 800 770 01 98, и наши сотрудники оформят заказ.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="heading-bottom"><a class="button button--default" href="#">Вопросы и ответы</a></div>
          </div>
        </div>
    </main>
</template>
<script>
export default {

}
</script>