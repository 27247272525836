<template>
  <main class="main">
    <hero-swiper />
    <cards />
    
    <new-calculator />

    <new-companies />

    <form-call-back />
    <popular-products />
    <promo-block />

    <build-block />
    <objects-block />
    <brands />    

    <excursion-block />
  </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from "@/utils/functions"
import HeroSwiper from './hero-swiper'
import Cards from './cards/cards.vue'

// import Companies from './companies/companies.vue'
import NewCompanies from './companies/new_companies.vue'

// import Calculators from './calculators/calculators.vue'
import NewCalculator from './calculators/new-calculator.vue'


import BuildBlock from './build/build.vue'
import ObjectsBlock from './objects/objects.vue'
import ExcursionBlock from './excursion/excursion.vue'
import PromoBlock from './promo/promo.vue'

import Brands from './brands/brands.vue'
import PopularProducts from './popular-products/popular-products.vue'
// import OffersCompany from '@/components/pages/offers/offersCompany.vue'
import formCallBack from '@/components/form-callback.vue'

export default {
  name: 'HelloWorld',
  components: {
    HeroSwiper,
    Cards,
    // Companies,
    // Calculators,
    Brands,
    PopularProducts,
    // OffersCompany,
    formCallBack,

    NewCalculator,
    NewCompanies,

    BuildBlock,
    ObjectsBlock,
    ExcursionBlock,
    PromoBlock,
  },
  mixins: [Functions],
  props: {
    msg: {
      type: String,
      default: ""
    }
  },
  watch: {
    offers: {
      handler: function () {
        this.getOffers()
      },
      deep: true
    }
  },
  data() {
    return {
      isShowHeroMessage: false,
      dataOffers: [],
    }
  },
  computed: {
    ...Vuex.mapGetters({
      offers: 'app/offers',
      isAuthenticated:'auth/isAuthenticated',
      user:'auth/user',
      main: 'app/main',
      uiPromotions: 'ui_promotion/promotions',
    }),
    firstPromo() {
      return Object.assign({}, this.uiPromotions[0])
    },
  },
  created(){
    if(this.offers == null){
      return
    }
    this.getOffers()
  },
  methods: {
    getOffers(){
        if(!this.offers || !this.offers.offers){
          return
        }
        this.offers.offers.map((el)=>{
           if(el == null){
                return
            }
          if(this.isAuthenticated == true){
            if(el.from == this.user.account_type || el.from == null){
                this.dataOffers.push(el)
                return
            }
          }else{
            if(el.from == null){
                this.dataOffers.push(el)
                return
            }
          }
        })
      }
  }
}
</script>
<style lang="css">
    .pl-50{
      padding-left: 50px;
    }
</style>