<template>
    <div class="modal-iframe callback-modal">
          <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
              <div class="modal-container">
                  <div class="state">
                      <div class="state-item">
                            <div v-if="!isSended" class="header_block">
                                <p>Заказать звонок</p>
                                <img @click="close" src="@/assets/img/close_modal_icon.png" alt="">
                            </div>
                            <div v-if="!isSended" class="callback_modal_form">
                                <label for="">Ваше имя</label>
                                <input type="text" placeholder="ФИО" v-model="name">

                                <label for="">Телефон</label>
                                <input type="text" placeholder="+7 (999)-678-58-00" v-model="phone" maxlength="11">

                                <label for="agree_callback_form" class="cl-check">
                                    <input 
                                        type="checkbox"
                                        id="agree_callback_form"
                                        v-model="isAgree"
                                    >
                                    <span>Согласен на обработку персональных данных</span>
                                </label>
                                
                                <button @click="submitForm" class="button button--primary">Отправить заявку</button>
                            </div>
                            <div v-if="isSended" class="success_message">
                                <p>{{ successMessage }}</p>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </template>
  <script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            name: null,
            phone: '',
            isAgree: false,
            isSended: false,
            isPhoneError: false,
            successMessage: 'Заявка отправлена'
        }
    },
    computed: {
        ...Vuex.mapGetters({
            sendedMessage: 'app/sendedMessage',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setShowCallbackModalForm: 'app/setShowCallbackModalForm',
        }),
        ...Vuex.mapActions({
            sentAppBackCall:'appmodal/sentAppBackCall'
        }),
        close() {
            this.setShowCallbackModalForm(false)
        },
        submitForm() {
            this.isSended = false
            if (!this.isAgree && this.phone.length != 10) {
                return
            }
            let formData = new FormData()
            formData.append('file', null)
            formData.append("email", null)
            formData.append("name", this.name)
            formData.append("telephone", this.phone)
            formData.append("type", 'backcall')
            formData.append("message", null)
            this.sentAppBackCall(formData)
                .then(() => {
                    this.isSended = true
                    this.name =  null
                    this.phone = ''
                    this.isAgree = false
                    setTimeout(() => {
                        this.close()
                    }, 2000)
                })
        },
    }
}
</script>
<style scoped src="../../css/nlk-style.css"></style>
<style scoped>
    .form p{
        text-align: center;
    }
</style>
<style lang="scss">
    .callback-modal{
        .modal-container{
            border-radius: 10px;
            padding: 20px !important;
            width: 359px;

            .header_block{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 40px;

                p{
                    margin-bottom: 0;
                    font-weight: 700;
                    font-size: 2.2rem;
                    line-height: 2.4rem;
                }

                img{
                    &:hover{
                        cursor: pointer;
                    }
                }
            }

            .callback_modal_form{
                display: flex;
                flex-direction: column;

                label{
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    margin-bottom: 4px;
                }

                input{
                    margin-bottom: 10px;
                    padding: 10px 20px;
                    border: 1px solid #d5d5d5;
                    border-radius: 10px;
                }

                .button{
                    padding: 8px 10px;
                    border-radius: 10px;
                    font-weight: 700;
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                }
            }

            .cl-check{
                margin-top: 10px;
                margin-bottom: 20px !important;

                span{
                    color: #353B48;
                    font-weight: 400;
                    font-size: 1.2rem;
                    line-height: 1.6rem;

                    &:before{
                        width: 1.6rem;
                        height: 1.6rem;
                        border-radius: 2px;
                    }
                }
            }
        }
    }    
</style>