<template>
        <profile-fiz v-if="user.account_type == null"/>
</template>

<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import ProfileFiz from './profileFiz.vue'

export default {
  mixins: [Functions],
    components: {
        ProfileFiz,
    },
    data() {
      return {
      }
    },
    computed: {
        ...Vuex.mapGetters({
            user:'auth/user',
        }),
    },
    methods: {

    },
}
</script>