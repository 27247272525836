<template>
  <main class="admin-index index__admin admin-documents">
    <div class="admin-index_header">
      <h3>Документы</h3>

      <button 
        v-if="!isShowForm"
        @click="isShowForm = true"
      >
        Добавить документ
      </button>

      <div 
        v-if="isShowForm"
        class="admin-documents_single-document index__admin"
      >
        <span>Категория</span>
        <select 
            id="categories"
            v-model="newDocument.category" 
            name="categories"
        >
            <option 
                v-for="category in categories" 
                :key="category.id"
                :value="category.id"
            >
                {{ category.name }}
            </option>
        </select>
        <input 
            v-model="newDocument.name"
            type="text" 
            placeholder="Наименование документа"
        >
        <input 
            v-model="newDocument.link"
            type="text" 
            placeholder="Ссылка на файл"
        >
        <div class="row">
            <label class="fl__doc">
                <input 
                    ref="defaultFileDocument" 
                    type="file" 
                    @change="uploadFile"
                >
            </label>
            <p>
              Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. 
              Для правильного отображения соблюдайте размер и формат
            </p>
        </div>

        <img 
            :src="newDocument.link" 
            alt=""
            class="document-image"
        >

        <input 
            v-model="newDocument.img"
            type="text" 
            placeholder="Ссылка на изображение"
        >
        <div class="row">
            <label class="fl__doc fl__preview">
                <input 
                    ref="defaultFileImg" 
                    type="file" 
                    @change="uploadImg"
                >
            </label>
            <p>
              Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. 
              Для правильного отображения соблюдайте размер и формат
            </p>
        </div>

        <!-- <img 
            v-if="editElement.typeFile == 'application/pdf'"
            src="@/assets/img/pdf-icon.png" 
            alt="" 
            class="default-pdf-iamge"
        > -->

        <img 
            :src="newDocument.img" 
            alt=""
            class="document-image"
        >

        <button @click="createDocument">
          Сохранить
        </button>
      </div>

      <div class="index__admin">
        <single-document
          v-for="(document, idx) in uiDocuments" 
          :key="idx"
          :index="idx"
          :element="document"
        />
      </div>
    </div>
  </main>
</template>
<script>
import Vuex from 'vuex'
import singleDocument from '@/components/admin/pages/documents/singleDocument.vue'

export default {
    components: {
        singleDocument,
    },
    data() {
        return {
            newDocument: {
              category: 0,
              link: null,
              name: null,
              root_id: null,
              img: null,
            },
            isShowForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            documents: 'app/documents',

            uiDocuments: 'ui_document/documents',
            categories: 'dictionary/documentsCats',
            fileUrl: 'app/fileUrl',
        }),
    },
    methods: {
        ...Vuex.mapActions({
          upload: 'app/upload',
          createUiDocument: 'ui_document/createDocument',
        }),
        ...Vuex.mapMutations({
            
        }),
        createDocument() {
          this.createUiDocument(this.newDocument)
            .then(() => {
              this.isShowForm = false
              this.newDocument.category = 0
              this.newDocument.root_id = null
              this.newDocument.name = null
              this.newDocument.link = null
            })
        },
        uploadFile() {
            let file = this.$refs['defaultFileDocument'].files[0]
            this.upload({file: file})
                .then((response) => {
                    this.newDocument.link = this.fileUrl + response.data[0].link
                })
        },
        uploadImg() {
            console.log('load preview')
            let file = this.$refs['defaultFileImg'].files[0]
            this.upload({file: file})
                .then((response) => {
                  this.newDocument.img = this.fileUrl + response.data[0].link
                  console.log('load preview success', this.newDocument.img)
                })
        },
    },
}
</script>