<template>
  <div class="layout-section">
    <div class="section section--no-pt section--no-pb">
        <div class="inner">
        <form 
            onsubmit="return false"
            class="form form--cabinet" 
            action="#" 
            method="post"
        >
            <div class="form-fieldset">
            <div class="form-group">
                <div class="form-item">
                <div class="row row--cabinet-form-subscribe">
                    <div class="col">
                    <p>Подпишитесь на подборки рассылок, чтобы быть<br> в курсе новостей, акций и новых поступлений.<br> Выберите рубрики рассылок, чтобы не получать<br> не интересные письма для вас</p>
                    </div>
                    <div class="col">
                    <div class="field">
                        <div class="field-label">E-mail</div>
                        <div class="field-input">
                        <input type="email" placeholder="Введите e-mail" value="">
                        </div>
                    </div>
                    <!-- field-->
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="form-fieldset">
            <div class="form-group">
                <div class="form-item">
                <h3>Выберите рубрику</h3>
                </div>
            </div>
            <div class="form-group form-row">
                <div class="form-item">
                <label class="checkbox checkbox--primary">
                    <input type="checkbox" name="add_1"><span>Акциии и спецпредложения</span>
                </label>
                </div>
                <div class="form-item">
                <label class="checkbox checkbox--primary">
                    <input type="checkbox" name="add_2"><span>Новые поступления</span>
                </label>
                </div>
                <div class="form-item">
                <label class="checkbox checkbox--primary">
                    <input type="checkbox" name="add_3"><span>Новости</span>
                </label>
                </div>
            </div>
            <div class="form-group">
                <div class="form-item">
                <label class="checkbox-plus">
                    <input type="checkbox" name="privacy" checked>
                    <b></b>
                    <span>
                        Согласен (согласна) на обработку моих персональных данных.
                        <br> Ознакомлен с 
                        <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/BeEwJiJ_Jc.pdf" target="_blank">Политикой</a>
                        и
                        <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/ZhlKe-a2dH.pdf" target="_blank">согласием</a>
                        на обработку персональных данных
                    </span>
                </label>
                </div>
            </div>
            </div>
            <div class="form-fieldset form-fieldset--medium">
            <div class="form-group">
                <div class="form-item">
                <button class="button button--primary">Подписаться</button>
                </div>
            </div>
            </div>
        </form>
        </div>
    </div>
    </div>
</template>
<script>
export default {

}
</script>