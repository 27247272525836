<template>
    <div class="modal-iframe">
          <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
              <div class="modal-container">
                  <button class="modal-close" @click="close">×</button>
                  <div class="state">
                      <div class="state-item">
                          <div class="form" name="callback-form-popup">
                              <p>{{ textMessage }}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {

        }
    },
    computed: {
        ...Vuex.mapGetters({
            message: 'auth/message',
        }),
        textMessage() {
            let new_message = ''
            if (this.message == 'login failed') {
                new_message = 'Неверно введён пароль. Проверьте пароль и попробуйте снова'
            }else if (this.message == 'sql: no rows in result set') {
                new_message = 'Пользователь с такой почтой не зарегистрирован'
            }
            return new_message
        },
    },
    methods: {
        ...Vuex.mapMutations({
            closeErrorForm: 'auth/closeErrorForm',
        }),
        close() {
            this.closeErrorForm()
        },
    }
}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>