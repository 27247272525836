<template>
    <div class="modal-iframe">
          <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
              <div class="modal-container">
                  <button class="modal-close" @click="close">×</button>
                  <div class="state">
                      <div class="state-item">
                          <div class="form" name="callback-form-popup">
                                <p>
                                    На почту {{ sendedEmail }} было направлено письмо с инструкцией для восстановления пароля. Проверьте свою почту.
                                </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </template>
  <script>
  import Vuex from 'vuex'
  import Functions from '@/utils/functions'
  
  export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
            sendedEmail: 'auth/sendedEmail',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setShowRecoverySended: 'app/setShowRecoverySended',
            setSendedEmail: 'auth/setSendedEmail',
        }),
        close() {
            this.setSendedEmail(null)
            this.setShowRecoverySended(false)
            this.push('login')
        }
      }
  }
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>