<template>
    <section>
        <div class="table table_wscroll">
            <div class="category" :class="{'-active' : currentParentId == category.id_1c}" v-for="category in categories" :key="category.id" @click="setCategory(category.id_1c)" v-show="category.is_group">
                <p>{{ category.name }}</p>
            </div>
        </div>

        <block-categories v-if="subcategories.length != 0" :categories="subcategories" :parentId="currentParentId" :parentCategories="categories" />
    </section>
</template>
<script>
import Vuex from 'vuex'
import blockCategories from './block-categories.vue'

export default {
    name: "block-categories",
    components: {
        blockCategories,
    },
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        parentId: {
            type: String,
            default: ""
        },
        parentCategories: {
            type: Array,
            default: () => []
        },
        isTopCategory: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            subcategories: [],
            currentParentId: null,
            currentRoot_id: null,
        }
    },
    watch: {
        parentId() {
            this.currentParentId = null
        },
        categories() {
            this.subcategories = []
        },
    },
    computed: {
        ...Vuex.mapGetters({
            region: 'auth/city',
            adminRootId: 'catalog/adminRootId',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setAdminRootId: 'catalog/setAdminRootId',
        }),
        ...Vuex.mapActions({
            getCardStructure: 'catalog/getCardStructure',
            getCategories: 'catalog/getCategories',
        }),
        setCategory(id_1c) {
            if (this.isTopCategory) {
                this.setAdminRootId(id_1c)
            }

            this.currentParentId = id_1c
            this.getCategories({ root_id: this.adminRootId, parent_id: id_1c })
                .then((response) => {
                    this.subcategories = response.data
                })
            this.getCardStructure({ root_id: this.adminRootId, id_1c: id_1c })
        }
    },
}
</script>
<style lang="css">
    .table_wscroll{
        overflow-x: auto;
        overflow-y: hidden;
    }
</style>