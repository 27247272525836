<template>
  <div class="index__admin">
    <span >Объект № {{index+1}}</span>
    <span class="index__del" @click="deleteObj(index)" >Удалить Х</span>
    <input type="text" v-model="editElement.name" @keyup="update" placeholder="Название">
    <input type="text" v-model="editElement.material" @keyup="update" placeholder="Материал">
    <input type="text" v-model="editElement.location" @keyup="update" placeholder="Местонахождение">
    <input type="text" v-model="editElement.volume" @keyup="update" placeholder="Объём поставки">
    <input type="text" v-model="editElement.works" @keyup="update" placeholder="Выполненные работы">
    <input type="text" v-model="editElement.linkObj" @keyup="update" placeholder="Ссылка">

    <div class="row">
        <label class="fl__img">
            <input 
                :ref="'file'+ index" 
                type="file" 
                @change="uploadImg()"
            >
        </label>
        <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
    </div>
    <img 
        :src="editElement.mainImg" 
        width="50%"
    >
    <label class="fl__imgs">
        <input 
            :ref="'files'+ index" 
            type="file" 
            @change="uploadImgs()"
        >
    </label>
    <div>
        <div 
            v-for="(img, i) in editElement.imgs" 
            :key="i"
        >
            <div 
                class="index_admin-imgs img_block"
            >
                <img 
                    class="remove_icon"
                    src="~@/assets/img/remove.svg" 
                    alt=""
                    @click="deleteImgs(i)"
                >
                <img
                    :src="img.link" 
                >
            </div>    
        </div>
    </div>          
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    components: {
    },
    props:['element', 'index', 'type'],
    computed: {
        ...Vuex.mapGetters({
            our_objects: 'app/our_objects',
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            updateObjects: 'app/updateObjects',
            deleteObjects: 'app/deleteObjects',
        }),
         update() {
            this.updateObjects(
                { 
                    idx:this.index,
                    name: this.editElement.name, 
                    link: this.editElement.link, 
                    linkObj:this.editElement.linkObj, 
                    material: this.editElement.material, 
                    location: this.editElement.location, 
                    volume: this.editElement.volume,
                    works: this.editElement.works,
                    category: this.editElement.category,
                    mainImg: this.editElement.mainImg,
                    imgs: this.editElement.imgs,
                })
        },
        deleteObj(){
            this.deleteObjects({index: this.index, link: this.element.link}) 
        },
        uploadImg() {
            let image = this.$refs['file' + this.index].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editElement.mainImg = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
        pushImgs(img) {
            let array = this.editElement.imgs.slice(0)
            array.push(img)
            this.editElement.imgs = array
            this.update()
        },
        deleteImgs(index) {
            let array = this.editElement.imgs.slice(0)
            array.splice(index, 1)
            this.editElement.imgs = array
            this.update()
        },
        uploadImgs(){
            let image = this.$refs['files' + this.index].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.pushImgs({link:this.fileUrl + response.data[0].link })
                    this.update()
                })
        }
    },
}
</script>