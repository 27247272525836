<template>
    <div>
        <catalog class="catalog-nd" />
    </div>
</template>

<script>
import Catalog from './catalog.vue'

export default {
    components: {
      Catalog
    },
    data() {
        return {
            
        }
    },
}
</script>