<template>
    <li 
        class="list-item" 
        :class="{'-opened' : isOpen}" 
        @click="openSubcategories"
    >
      <a class="list-link">
        <span>{{ category.name }}</span>
        <div class="list-button"></div>
      </a>
      <ul class="list sub">
        <li 
            class="list-item" 
            v-for="subcategory in subcategories" 
            :key="subcategory.id"
            v-show="subcategory.is_group"
        >
            <a 
                class="list-link" 
                @click="pushToCategory(subcategory)"
            >
                {{ subcategory.name }}
            </a>
        </li>
      </ul>
    </li>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        category: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isOpen: false,
            subcategories: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            searchForm: 'catalog/form',
            region: 'auth/city',
        }),
        form() {
            return Object.assign({}, this.searchForm)
        }
    },
    methods: {
        ...Vuex.mapMutations({
            setForm: 'catalog/setForm',
        }),
        ...Vuex.mapActions({
            getCategories: 'catalog/getCategories',
            search: 'catalog/search',
        }),
        openSubcategories() {
            this.isOpen = !this.isOpen
            if (this.subcategories.length == 0) {
                this.getCategories({ root_id: this.region, parent_id: this.category.id_1c })
                    .then((response) => {
                        this.subcategories = response.data
                    })
            }
        },
        checkCategory(subcategory) {
            this.getCategories({ root_id: this.region, parent_id: subcategory.id_1c })
        },
        pushToCategory(subcategory) {
            this.form.parent_id = subcategory.id_1c
            this.setForm(this.form)
            this.search()
            if (subcategory.is_group) {
                this.$router.push('/catalog/' + subcategory.id_1c).then(() => {})
            }else{
                this.$router.push('/cardProduct/' + subcategory.id).then(() => {})
            }
        }
    },
}
</script>
<style scoped>
    .level-3{
        font-size: 1.3rem;
        padding-left: 41px;
    }
</style>