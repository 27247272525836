<template>
    <div class="admin-services-object">
        <span>Наши объекты</span>

        <div class="index__admin">
            <button 
                v-if="!isShowForm"
                @click="isShowForm = true"
            >
                Добавить объект
            </button>
        </div>

        <div 
            v-if="isShowForm"
            class="index__admin"
        >
            <label>Наименование</label>
            <input 
                type="text" 
                v-model="newObject.name" 
                placeholder="Наименование"
            >

            <label>Ссылка на изображение</label>
            <input 
                type="text" 
                v-model="newObject.linkimg"
                placeholder="Ссылка на изображение" 
            >
            <div class="row">
                <label class="fl__img">
                    <input 
                        ref="defaultFileObject" 
                        type="file" 
                        @change="uploadImg()"
                    >
                </label>
                <p> Изображение должно быть размером 810х460 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат. </p>
            </div>
            <img 
                :src="newObject.linkimg" 
                alt=""
            >
            <input 
                type="text" 
                v-model="newObject.link" 
                placeholder="Ссылка"
            >

            <button @click="createObject">
                Сохранить
            </button>
        </div>

        <div 
            v-for="(obj, idx) in uiObjects" 
            :key="idx"
        >
            <single
                :key="idx"
                :index="idx"
                :element="obj"
            />
        </div>
        
    </div>    
</template>
<script>
import Vuex from 'vuex'
import single from './single.vue'

export default {
    components: {
        single,
    },
    data() {
        return {
            newObject: {
                link: null,
                linkimg: null,
                name: null,
                root_id: null
            },
            isShowForm: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            uiObjects: 'ui_object/objects',
            fileUrl: 'app/fileUrl',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            createUiObject: 'ui_object/createObject',
            upload: 'app/upload',
        }),
        createObject() {
            this.createUiObject(this.newObject)
                .then(() => {
                    this.isShowForm = false
                    this.newObject.link = null
                    this.newObject.linkimg = null
                    this.newObject.name = null
                    this.newObject.root_id = null
                })
        },
        uploadImg() {
            let image = this.$refs['defaultFileObject'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.newObject.linkimg = this.fileUrl + response.data[0].link
                })
        },
    },
}
</script>