import Http from "../utils/http"
import Config from "../config"

const state = {
    logs: [],
    form: {
        from: null,
        to: null
    }
}

const getters = {
    logs: state => state.logs,
    form: state => state.form,
}

const actions = {
    find: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/logs", getters.form)
                .then(response => {
                    commit("set", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    set: (state, val) => {
        state.logs = val
    },
    setForm: (state, val) => {
        if (val.from != null&& val.from != "") {
            val.from = new Date(val.from)
        }
        if (val.to != null&& val.to != "") {
            val.to = new Date(val.to)
        }
        state.form = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
