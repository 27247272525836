import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    messageStatus: null,
    users: [],
    form: {
        excludedIds: [],
        id_1c: "",
        id: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    categories: [],
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    users: state => state.users,
    form: state => state.form,
    categories: state => state.categories,
}

const actions = {
    getCategories: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_category/search", getters.form)
                .then(response => {
                    commit("setCategories", response.data)
                    resolve(response)
                })
        })
    },
    createCategory: ({commit}, payload) => {
        payload.sort = Number(payload.sort)
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_category/admin", payload)
                .then(response => {
                    commit("pushCategory", response.data)
                    resolve(response)
                })
        })
    },
    deleteCategory: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_category/admin/" + id)
                .then(response => {
                    commit("spliceCategory", id)
                    resolve(response)
                })
        })
    },
    updateCategory: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_category/admin/" + id)
                .then(response => {
                    commit("changeCategory", id)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setUsers: (state, val) => {
        state.users = val
    }, 
    pushCategory: (state, val) => {
        state.categories.push(val)
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setCategories: (state, val) => {
        state.categories = val
    },
    spliceCategory: (state, id) => {
        state.categories.map((element, idx) => {
            if (id == element.id) {
                state.categories.splice(idx, 1)
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
