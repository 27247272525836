<template>
    <div>
        <div class="section section--no-pt">
            <bread-crumbs :pages-array="pagesSupports" />
            <div class="container">
                <div class="row row--faq">
                    <div class="col">
                        <h2>FAQ</h2>
                        <p v-if="supports.FAQ_link">
                            Наши специалисты
                            <br> 
                            подготовили ответы на 
                            <a :href="supports.FAQ_link">
                                часто
                                <br> 
                                задаваемые вопросы
                            </a>
                        </p>
                    </div>
                    <div class="col">
                        <div class="accordion accordion--faq">
                            <div class="accordion-item"  v-for="(obj, i) in uiSupportsFaq" :key="i" >
                                <div class="accordion-header" @click="changeIndex(i)">{{ obj.header }}</div>
                                <div class="" v-if="i == index">
                                    <p v-html="obj.link"></p>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
export default {
  mixins: [Functions],
   components:{
      breadCrumbs
  },
  data() {
        return {
            index: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            supports: 'app/supports',
            pagesSupports: 'dictionary/pagesSupports',
            uiSupports: 'ui_support/supports',
        }),
        uiSupportsFaq() {
            let faq_array = []
            this.uiSupports.map((element) => {
                if (element.type == 30) {
                    faq_array.push(element)
                }
            })
            return faq_array
        },
    },
    methods:{
        changeIndex(i){
            this.index = i 
        }
    }

}
</script>