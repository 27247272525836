import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    messageStatus: null,
    form: {
        // excludedIds: [],
        // id_1c: "",
        // id: [],
        // limit: null,
        // link: "",
        // name: "",
        // page: 1,
        // query: "",
        // root_id: ""
    },
    contacts: [],
    offices: [],
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    form: state => state.form,
    contacts: state => state.contacts,
    offices: state => state.offices,
}

const actions = {
    getContacts: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_contacts/search", getters.form)
                .then(response => {
                    commit("setContacts", response.data)
                    resolve(response)
                })
        })
    },
    createContact: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_contacts/admin", payload)
                .then(response => {
                    commit("pushContact", response.data)
                    resolve(response)
                })
        })
    },
    deleteContact: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_contacts/admin/" + id)
                .then(response => {
                    commit("spliceContact", id)
                    resolve(response)
                })
        })
    },
    updateContact: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_contacts/admin/" + payload.id, payload)
                .then(response => {
                    commit("changeContact", response.data)
                    resolve(response)
                })
        })
    },
    geolocation: ({commit}) => {
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/geolocation")
                .then(response => {
                    commit("setOffices", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setContacts: (state, val) => {
        // val.sort((a, b) => Number(a.sort) - Number(b.sort))
        state.contacts = val
    },
    spliceContact: (state, id) => {
        state.contacts.map((element, idx) => {
            if (id == element.id) {
                state.contacts.splice(idx, 1)
            }
        })
    },
    changeContact: (state, val) => {
        state.contacts.map((element) => {
            if (element.id == val.id) {
                element.link = val.link
                element.img = val.img
            }
        })
    },
    pushContact: (state, val) => {
        state.contacts.push(val)
    },
    setOffices: (state, val) => {
        state.offices = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
