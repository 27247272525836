import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    messageStatus: null,
    form: {
        excludedIds: [],
        id_1c: "",
        id: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    networks: [],
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    form: state => state.form,
    networks: state => state.networks,
}

const actions = {
    getNetworks: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_networks/search", getters.form)
                .then(response => {
                    commit("setNetworks", response.data)
                    resolve(response)
                })
        })
    },
    createNetwork: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_networks/admin", payload)
                .then(response => {
                    commit("pushNetwork", response.data)
                    resolve(response)
                })
        })
    },
    deleteNetwork: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_networks/admin/" + id)
                .then(response => {
                    commit("spliceNetwork", id)
                    resolve(response)
                })
        })
    },
    updateNetwork: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_networks/admin/" + payload.id, payload)
                .then(response => {
                    commit("changeNetwork", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setNetworks: (state, val) => {
        state.networks = val
    },
    spliceNetwork: (state, id) => {
        state.networks.map((element, idx) => {
            if (id == element.id) {
                state.networks.splice(idx, 1)
            }
        })
    },
    changeNetwork: (state, val) => {
        state.networks.map((element) => {
            if (element.id == val.id) {
                element.link = val.link
            }
        })
    },
    pushNetwork: (state, val) => {
        state.networks.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
