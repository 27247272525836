<template>
  <div >
        <one-object v-if="our_objects && pagesObjects.length != 0" />
  </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import OneObject from  './oneObject.vue'
export default {
    mixins: [Functions],
    components:{
        OneObject,
    },
    computed: {
        ...Vuex.mapGetters({
            our_objects: 'app/our_objects',
            pagesObjects: 'app/pagesObjects',
        }),
    },
}
</script>