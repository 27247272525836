<template>
    <section class="container">
        <div class="vacancy_navbar">
            <div @click="push('')" class="logo_block">
                <img src="@/assets/img/vacancy/navbar_logo.png" alt="">
            </div>
            <div class="link_block">
                <p @click="push('vacancies')">Вакансии</p>
                <p @click="push('aboutcompany')">О компании</p>
            </div>
            <div class="city_block">
                <!-- // {{ citiesList }} -->
                <choices-select
                    data-select 
                    :elements="citiesList" 
                    v-model="vacancyCity" 
                    :defaultValue="defaultValue"
                    :typeSelect="'float'"
                    :componentFrom="'contactsSelects'"
                />
            </div>
        </div>
    </section>
</template>
<script>
import Functions from '@/utils/functions'
import choicesSelect from '@/components/choices-select/choices-select.vue'
import Vuex from 'vuex'

export default {
    mixins: [Functions],
    components: {
        choicesSelect,
    },
    data() {
        return {
            topLevelCategory: [
                {id: 10, name: 'Perm'},
                {id: 20, name: 'Kazan'},
                {id: 30, name: 'Moscow'},
            ],
            defaultValue: 'Город',
            vacancyCity: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            vacancies: 'vacancy/vacancies',
        }),
        citiesList() {
            var cities = []
            var id = 10
            this.vacancies.map((element) => {
                if (element.area && element.area.name) {
                    if (!this.checkIssetValueInArray(cities, element.area.name)) {
                        cities.push({id: id, name: element.area.name})
                        id += 10
                    }
                }
            })
            return cities
        },
    },
    mounted() {
        this.getVacancies()
    },
    methods: {
        ...Vuex.mapActions({
            getVacancies: 'vacancy/getVacancies',
        }),
        checkIssetValueInArray(array, value) {
            var isset = false
            array.map((element) => {
                if (element.name == value) {
                    isset = true
                }
            })
            return isset
        },
    },
}
</script>