import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    queryPage: null,
    productsNavbar: [],
    productsPage: [],
    form: {
        query: '',
        page: 1,
        limit: 12,
        sort: null,
        is_show_zero_price: false,
        is_deleted: false,
    },
    amountPages: 1,
}

const getters = {
    message: state => state.message,
    queryPage: state => state.queryPage,
    productsNavbar: state => state.productsNavbar,
    productsPage: state => state.productsPage,
    form: state => state.form,
    amountPages: state => state.amountPages,
}

const actions = {
    searchPage: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search", getters.form, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setProductsPage", response.data)
                    commit("setQueryPage", getters.form.query)
                    commit("setAmountPages", response.pagination)
                    resolve(response)
                })
        })
    },
    searchNavbar: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/nomenclature/search", getters.form, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setProductsNavbar", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setProductsPage: (state, val) => {
        state.productsPage = val 
    },
    setProductsNavbar: (state, val) => {
        state.productsNavbar = val
    },
    updateForm: (state, val) => {
        state.form = val
    },
    setQueryPage: (state, val) => {
        state.queryPage = val
    },
    setAmountPages: (state, val) => {
        let total = val.total
        let limit = val.limit
        state.amountPages = Math.ceil(total / limit)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
