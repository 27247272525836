<template>
    <section>
        <div class="single-coating-block">
            <input type="text" v-model="editElement.image" @keyup="update">
            <img v-if="editElement.image != null" :src="editElement.image" alt="" class="coating_main_img">
            <div class="row">
                <label class="fl__img">
                    <input 
                        :ref="'coatingMainMainImg' + this.editElement.id" 
                        type="file" 
                        @change="uploadImg"
                    >
                </label>
                <p>Изображение должно быть размером до 150МБ, доступные для загрузки форматы PDF, JPG, PNG. Для правильного отображения соблюдайте размер и формат</p>
            </div>

            <div class="form_new_char">
                <select name="" id="" v-model="selectedRegion">
                    <option value="null">Выбрать регион</option>
                    <option :value="region.id_1c" v-show="!checkIssetRegion(region.id_1c)" v-for="region, idx in regions" :key="idx">{{ region.name }}</option>
                </select>
    
                <button class="button button--primary" @click="addRegion">Добавить</button>
            </div>

            <div class="region_list">
                <div v-for="singleRegion, idx in editElement.root_ids" :key="idx" class="single_region">
                    <p>{{ getRegionNameById1c(singleRegion) }}</p>
                    <i class="fas fa-times" @click="deleteRegion(singleRegion)"></i>
                </div>  
            </div>

            <button class="button button--primary delete_button" @click="deleteCoating">Удалить</button>
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            selectedRegion: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            fileUrl: 'app/fileUrl',
            regions:'dictionary/topLevelCategory',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            updateCoating: 'coatings/updateCoatingMain',
            deleteCoatingMain: 'coatings/deleteCoatingMain',
            upload: 'app/upload',
        }),
        update() {
            this.updateCoating({id: this.editElement.id, obj: this.editElement})
        },
        deleteCoating() {
            this.deleteCoatingMain(this.editElement.id)
        },
        checkIssetRegion(id_1c) {
            var isset = false
            this.editElement.root_ids.map((element) => {
                if (element == id_1c) {
                    isset = true
                }
            })
            return isset
        },
        addRegion() {
            if (this.selectedRegion == null) {
                return
            }
            var region_id = JSON.stringify(this.selectedRegion)
            region_id = JSON.parse(region_id)
            this.editElement.root_ids.push(region_id)
            this.selectedRegion = null
            this.update()
        },
        deleteRegion(id_1c) {
            this.editElement.root_ids.map((element, idx) => {
                if (element == id_1c) {
                    this.editElement.root_ids.splice(idx, 1)
                }
            })
            this.update()
        },
        getRegionNameById1c(id_1c) {
            var name = null
            this.regions.map((element) => {
                if (element.id_1c == id_1c) {
                    name = element.name
                }
            })
            return name
        },
        uploadImg() {
            let image = this.$refs['coatingMainMainImg' + this.editElement.id].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editElement.image = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
    },
}
</script>