<template>
    <main class="admin-index index__admin" >
        <div class="admin-index_header">
            <h3>Поддержка</h3>
        </div>
        
        <div class="index__admin">
            <div>
                <label>Категория поддержки</label>
                <select   
                    class="picker" 
                    v-model="newSupport.type"  
                >
                    <option 
                        v-for="type in supportsType" 
                        :key="type.id" 
                        :value="type.id"
                    >
                        {{ type.name }}
                    </option>
                </select>
            </div>
            
            <div 
                v-if="newSupport.type == 10"
                class="index__admin" 
            >
                <input 
                    type="text"  
                    placeholder="Заголовок" 
                    v-model="newSupport.header"
                >
                <div>
                    <vue-editor v-model="newSupport.link" />
                </div>
                
                <button @click="createSupport">
                    Добавить материал
                </button>

                <single 
                    v-for="(singleSupport, index) in uiSupportsTech" 
                    :key="singleSupport.id"
                    :element="singleSupport"
                    :index="index"
                    :type="newSupport.type"
                />
            </div>

            <div 
                v-if="newSupport.type == 20"
                class="index__admin" 
            >
                <input 
                    type="text"  
                    placeholder="Заголовок" 
                    v-model="newSupport.header"
                >
                <input 
                    type="text" 
                    placeholder="Ссылка на видео" 
                    v-model="newSupport.link"
                >
                <input 
                    type="text" 
                    placeholder="Ссылка на изображение" 
                    v-model="newSupport.linkimg"
                >
                    <div class="row">
                    <label class="fl__img">
                        <input 
                            :ref="'defaultFileSupport'" 
                            type="file" 
                            @change="uploadImg()"
                        >
                    </label>
                    <p> Изображение должно быть размером 600х540 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат. </p>
                </div>
                <div 
                    v-if="newSupport.linkimg"
                    class="img_block"
                >
                    <img 
                        :src="newSupport.linkimg" 
                        width="30%"
                        alt=""
                    >
                </div>
                <button @click="createSupport">
                    Добавить материал
                </button>
                
                <single 
                    v-for="(singleSupport, index) in uiSupportsVideo" 
                    :key="singleSupport.id"
                    :element="singleSupport"
                    :index="index"
                    :type="newSupport.type"
                />
            </div>

            <div 
                v-if="newSupport.type == 30"
                class="index__admin" 
            >
                <input 
                    type="text"  
                    placeholder="Заголовок" 
                    v-model="newSupport.header"
                >
                <div>
                    <vue-editor v-model="newSupport.link" />
                </div>
                
                <button @click="createSupport">
                    Добавить материал
                </button>

                <span>Ссылка на часто задаваемые вопросы</span>
                <input 
                    type="text" 
                    placeholder="" 
                    v-model="supports.FAQ_link"
                >

                <single 
                    v-for="(singleSupport, index) in uiSupportsFaq" 
                    :key="singleSupport.id"
                    :element="singleSupport"
                    :index="index"
                    :type="newSupport.type"
                />
            </div>
        </div>
    </main>
</template>

<script>
import Vuex from 'vuex'
import { VueEditor } from "vue2-editor"

import single from './single.vue'


export default {
    components: {
        VueEditor,
        single,
    },
    // mixins: [Functions],
    data() {
        return {
            editObject: null,
            index: null,
            typeSupp: 1,
            dataVideos:[],
            dataFAQ:[],
            dataTechs:[],
            video:{
                name:'',
                link: '',
                img:'',
                dateAdd: '',
            },
            tech:{
                name:'',
                description: '',
            },
            faqs:{
                name:'',
                description: '',
            },

            isShowForm: false,
            newSupport: {
                header: null,
                link: null,
                linkimg: null,
                root_id: null,
                type: 10
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            supports: 'app/supports',
            fileUrl: 'app/fileUrl',
            supportsType:'dictionary/supportsType',

            uiSupports: 'ui_support/supports',
        }),
        newSupportType() {
            return this.newSupport.type
        },
        uiSupportsTech() {
            let tech_array = []
            this.uiSupports.map((element) => {
                if (element.type == 10) {
                    tech_array.push(element)
                }
            })
            return tech_array
        },
        uiSupportsVideo() {
            let video_array = []
            this.uiSupports.map((element) => {
                if (element.type == 20) {
                    video_array.push(element)
                }
            })
            return video_array
        },
        uiSupportsFaq() {
            let faq_array = []
            this.uiSupports.map((element) => {
                if (element.type == 30) {
                    faq_array.push(element)
                }
            })
            return faq_array
        },
    },
    watch: {
        newSupportType() {
            this.setFormDefaultValues()
        },
        supports() {
            this.setSupports()
        }
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            upload: 'app/upload',

            createUiSupport: 'ui_support/createSupport',
        }),
        ...Vuex.mapMutations({
            updateSupports: 'app/updateSupports',
            addSupportsVideo: 'app/addSupportsVideo',
        }),
        setFormDefaultValues() {
            this.newSupport.header = null
            this.newSupport.link = null
            this.newSupport.linkimg = null
            this.newSupport.root_id = null
        },
        createSupport() {
            this.createUiSupport(this.newSupport)
                .then(() => {
                    this.setFormDefaultValues()
                })
        },
        uploadImg() {
            let image = this.$refs['defaultFileSupport'].files[0]
            
            this.upload({file: image})
                .then((response) => {
                    this.newSupport.linkimg = this.fileUrl + response.data[0].link
                })
        },



        setSupports(){
            if(!this.supports.dataVideos){
               this.dataVideos = []
            }else{
                this.dataVideos = [...this.supports.dataVideos]
            }
            if(!this.supports.dataFAQ){
               this.dataFAQ = []
            }else{
                this.dataFAQ = [...this.supports.dataFAQ]
            }
            if(!this.supports.dataTechs){
               this.dataTechs = []
            }else{
                this.dataTechs = [...this.supports.dataTechs]
            }
        },
        updateData() {
            this.saveSupport()
            this.updateJson()
                .then(() => {
                   
                })
        },
        add(){
            if(this.typeSupp == 1){
                var video = this.video
                video.dateAdd = new Date()
                this.addSupportsVideo(video)
                this.video = {
                    name: '',
                    link: '',
                    img: '',
                    dateAdd: '',
                }
            }
            if(this.typeSupp == 0){
                this.dataTechs.push(this.tech)
                this.tech = {}
            }
            if(this.typeSupp == 2){
                this.dataFAQ.push(this.faqs)
                this.faqs = {}
            }
            
        },
        saveSupport(){
            var dist = {}
            dist.dataTechs = this.dataTechs
            dist.dataFAQ = this.dataFAQ
            this.updateSupports(dist)
        },
        deleteImg(idx) {
            this.dataVideos.map((element, index) => {
                if (index == idx) {
                    element.img = null
                }
            })
        },
        deleteVideo(i){
             this.dataVideos.splice(i,1)
             this.saveSupport()
        },
        deleteFaq(i){
             this.dataFAQ.splice(i,1)
             this.saveSupport()
        },
        deleteTech(i){
             this.dataTechs.splice(i,1)
             this.saveSupport()
        },
    },
    created(){
         this.setSupports()
    }
}
</script>
