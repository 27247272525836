import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    messageStatus: null,
    form: {
        excludedIds: [],
        ids: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    promotions: [],
    arrayLinks: [
        {
            name: 'Акции и предложения',
            url: 'offers', 
            fullUrl: 'offers',
        },
    ],
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    form: state => state.form,
    promotions: state => state.promotions,
    arrayLinks: state => state.arrayLinks,
}

const actions = {
    getPromotions: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_promotion/search", getters.form)
                .then(response => {
                    commit("setPromotions", response.data)
                    resolve(response)
                })
        })
    },
    createPromotion: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_promotion/admin", payload)
                .then(response => {
                    commit("pushPromotion", response.data)
                    resolve(response)
                })
        })
    },
    deletePromotion: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_promotion/admin/" + id)
                .then(response => {
                    commit("splicePromotion", id)
                    resolve(response)
                })
        })
    },
    updatePromotion: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_promotion/admin/" + payload.id, payload)
                .then(response => {
                    commit("changePromotion", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setPromotions: (state, val) => {
        state.promotions = val
        state.promotions.map((element) => {
            state.arrayLinks.push({
                name: element.name,
                url: element.link,
                fullUrl: 'offers/' + element.link,
            })
        })
    },
    splicePromotion: (state, id) => {
        state.promotions.map((element, idx) => {
            if (id == element.id) {
                state.promotions.splice(idx, 1)
            }
        })
    },
    changePromotion: (state, val) => {
        state.promotions.map((element) => {
            if (element.id == val.id) {
                element.linkimg = val.linkimg
            }
        })
    },
    pushPromotion: (state, val) => {
        state.promotions.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
