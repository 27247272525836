<template>
  <header class="admin-header">
    <div class="admin-navbar">
      <div 
        class="logo" 
        @click="push('')"
      >
        <img 
          src="@/assets/img/logotype.svg" 
          alt=""
        >
      </div>
      <div class="bar">
        <div class="head">
          <p>Политика конфиндециальности</p>
        </div>
        <div 
          class="user_block"
          @click="isOpenUserBlock = !isOpenUserBlock"
        >
          <div class="avatar">
            <img 
              src="@/assets/img/default_avatar.png" 
              alt=""
            >
          </div>
          <div class="about_user">
            <p class="name">
              {{ user.name }}
            </p>
            <p class="position">
              Администратор
            </p>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-if="isOpenUserBlock"
      class="user_block-content" 
    >
      <ul class="admin-list">
        <li @click="pushFromMenu('admin/profile')">
          <p>Профиль</p>
        </li>
        <li @click="pushFromMenu('admin/users')">
          <p>Пользователи</p>
        </li>
        <li @click="pushFromMenu('admin/permissions')">
          <p>Права</p>
        </li>
        <li @click="pushFromMenu('admin/utm')">
          <p>UTM</p>
        </li>
        <li @click="pushFromMenu('admin/widget')">
          <p>Настройка виджета</p>
        </li>
        <li @click="pushFromMenu('admin/delivery')">
          <p>Точки самовывоза</p>
        </li>
        <li @click="pushFromMenu('admin/companies')">
          <p>Компании</p>
        </li>
        <li @click="pushFromMenu('admin/settings')">
          <p>Настройки</p>
        </li>
        <li @click="exit">
          <p>Выйти</p>
        </li>
      </ul>
    </div>
  </header>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    data () {
        return {
            isOpenUserBlock: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            user: 'auth/user',
        }),
    },
    methods: {
      ...Vuex.mapActions({
        logout: 'auth/logout',
      }),
      exit() {
        this.logout()
          .then(() => {
            this.push('login')
          })
      },
      pushFromMenu(str) {
        this.push(str)
        this.isOpenUserBlock = false
      },
    }
}
</script>
<style lang="scss">
    .admin-header{
        height: 100%;
    }
    .admin-navbar{
        display: flex;
        border: 2px solid #a0a0a0;
        

        .logo{
            border-right: 2px solid #a0a0a0;
            width: 18%;
            padding: 10px;
            height: 51px;
        }

        .bar{
            width: 82%;
            display: flex;
            justify-content: space-between;
            
            .head{
                padding: 15px;
                font-size: 14px;
                font-weight: 600;
            }

            .user_block{
                padding: 4px;
                font-size: 14px;
                font-weight: 600;
                position: relative;
                display: flex;
                // width: 200px;

                &:hover{
                  cursor: pointer;
                }

                .avatar{
                  padding-right: 15px;
                  
                  img{
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                  }
                }

                .about_user{
                  p{
                    margin: 0;
                  }
                }
            }
        }
    }
    .user_block-content{
        position: absolute;
        top: 53px;
        right: 0;
        border: 2px solid #a0a0a0;
        width: 200px;
        z-index: 1000;
        background-color: #fff;

        .admin-list{
          list-style: none;
          padding: 10px;
          font-weight: 600;
          font-size: 14px;

          li:hover{
            cursor: pointer;
            color: #a0a0a0;
          }
        }
    }
</style>