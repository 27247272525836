<template>
  <section class="nlk-content basket">

        <h1 class="nlk-content__title mb-50">Отчёт по корзинам</h1>

        <form class="nlk-filter">
            <div class="field field_data">
                <div class="field-label">Начальная дата</div>
                <div class="field-input">
                    <input type="date" placeholder="Дата" value="">
                </div>
            </div>
            <div class="field field_data">
                <div class="field-label">Конечная дата</div>
                <div class="field-input">
                    <input type="date" placeholder="Дата" value="">
                </div>
            </div>
            <div class="field field_flex-bottom">
                <div class="field-input w-169">
                    <input type="text" placeholder="Номер заказа" value="">
                </div>
            </div>
            <div class="field field_flex-bottom">
                <div class="nlk-field-select">
                    <select data-select="">
                        <option value="">Статус</option>
                        <option value="">Двускатная</option>
                        <option value="">Двускатная</option>
                    </select>
                </div>
            </div>
        </form>

        <div class="nlk-horizontal-scroll">
            <table class="nlk-table nlk-table_w-100 nlk-table_p-7 nlk-table_th-fz-14 nlk-table_tac nlk-table_mobil-none">
                <tr>
                    <th>НОМЕР И ДАТА</th>
                    <th>СУММА С НДС</th>
                    <th>СУММА ОПЛАЧЕНО</th>
                    <th>СУММА ОСТАТОК</th>
                    <th>СТАТУС</th>
                    <th>ОТВЕТСТВЕННЫЙ МЕНЕДЖЕР</th>
                </tr>
            </table>
        </div>

        <order v-for="order in orders" :key="order.id" :order="order" />

       
                <div class="nlk-accordion-body">
                    
                </div>
    </section>
</template>

<script>
import Vuex from 'vuex'

import Order from '@/components/pages/nlk/order.vue'
export default {
	components: {
		Order,
	},
	computed: {
		...Vuex.mapGetters({
			searchForm: 'basket/searchForm',
			orders: 'basket/orders',
			orderStatuses: 'dictionary/orderStatuses',
		}),
		searchOrderForm() {
			return Object.assign({}, this.searchForm)
		}
	},
	mounted() {
		this.searchOrder()
	},
	methods: {
		...Vuex.mapMutations({
			changeSearchForm: 'basket/changeSearchForm',
		}),
		...Vuex.mapActions({
			searchOrder: 'basket/searchOrder',
		}),
		search() {
			if (this.searchOrderForm.date_before) {
				let today = new Date(this.searchOrderForm.date_before)
				this.searchOrderForm.date_before = today.toISOString()
			}

			this.changeSearchForm(this.searchOrderForm)
			this.searchOrder()
		}
	},
}
</script>
<style scoped src="../../../../css/nlk-style.css"></style>
<style scoped>
	.active{
		background-color: unset;
	}
    .basket{
        margin-left: 20px;
        margin-right: 20px;
    }
</style>