import Http from "../../utils/http"
import Config from "../../config"

const state = {
    message: null,
    messageStatus: null,
    form: {
        excludedIds: [],
        id_1c: "",
        id: [],
        limit: null,
        link: "",
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    slides: [],
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    form: state => state.form,
    slides: state => state.slides,
}

const actions = {
    getSlides: ({commit, getters}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_slide/search", getters.form)
                .then(response => {
                    commit("setSlides", response.data)
                    resolve(response)
                })
        })
    },
    createSlide: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/ui_slide/admin", payload)
                .then(response => {
                    commit("pushSlide", response.data)
                    resolve(response)
                })
        })
    },
    deleteSlide: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/ui_slide/admin/" + id)
                .then(response => {
                    commit("spliceSlide", id)
                    resolve(response)
                })
        })
    },
    updateSlide: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/ui_slide/admin/" + payload.id, payload)
                .then(response => {
                    commit("changeSlide", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setMessageStatus: (state, val) => {
        state.messageStatus = val
    },
    setSlides: (state, val) => {
        state.slides = val
    },
    spliceSlide: (state, id) => {
        state.slides.map((element, idx) => {
            if (id == element.id) {
                state.slides.splice(idx, 1)
            }
        })
    },
    changeSlide: (state, val) => {
        state.slides.map((element) => {
            if (element.id == val.id) {
                element.link = val.link
            }
        })
    },
    pushSlide: (state, val) => {
        state.slides.push(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
