<template>
    <div class="modal-iframe">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <h3>Ваша корзина не пуста. Прежде чем повторить заказ очистите корзину</h3>
                        <div class="form" name="callback-form-popup">
                            <div class="form-fieldset form-fieldset--medium">
                                <div class="form-group">
                                    <div class="form-item">
                                        <button class="button button--primary" @click="confirm">Очистить корзину</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
			order: 'basket/order',
		}),
	},
    methods: {
        ...Vuex.mapMutations({
            setShowBasketNotEmpty: 'app/setShowBasketNotEmpty',
        }),
        ...Vuex.mapActions({
            deletePosition: 'basket/deletePosition',
        }),
        close() {
            this.setShowBasketNotEmpty(false)
        },
        confirm() {
            this.order.positions.map((element) => {
                this.deletePosition(element.id)
            })
            this.close()
        },
    }
}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>