<template>
    <div class="modal-iframe nd_auth">
          <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
              <div class="modal-container">
                  <div class="auth_left_block">
                    <img 
                        @click="close"
                        class="auth_icon_back"
                        src="~@/img/icon_back.png" 
                        alt=""
                    >
                    <div class="heading">
                        <div class="heading-section">
                            <p>Введите код</p>
                        </div>
                    </div>
                    <div 
                        class="subheading"
                    >
                        <p class="second_step_info">Код отправлен на номер: </p>
                        <p class="second_step_value">{{  getFinallyPhone() }}</p>
                    </div>

                    <form 
                        class="form" 
                        onsubmit="return false;"
                    >
                        <div class="form-fieldset">
                            <div class="form-group form-row">
                                <div 
                                    class="form-item"
                                >
                                    <div class="field">
                                        <div class="field-input">
                                            <input 
                                                class="nd_auth_input"
                                                v-model="code"
                                                type="text" 
                                                placeholder="Код" 
                                                :class="isCodeError ? 'error_input' : ''"
                                            >
                                        </div>
                                        <p v-if="isCodeError" class="phone_message_error" style="text-align: start;">Проверьте правильность кода</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-item">
                                    <button 
                                        class="button button--primary nd-br nd_button" 
                                        @click="sendCode"
                                    >
                                        Продолжить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    data() {
        return {
            code: null,
            isCodeError: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            registryModalPhone: 'app/registryModalPhone',
            formSmsStore: 'auth/formSms',
        }),
        formSms() {
            return Object.assign({}, this.formSmsStore)
        },
    },
    methods: {
        ...Vuex.mapMutations({
            setShowSendCodeSmsModal: 'app/setShowSendCodeSmsModal',
            setFormSms: 'auth/setFormSms',
            setVerifyPhoneNumber: 'auth/setVerifyPhoneNumber',
        }),
        ...Vuex.mapActions({
            auth: 'auth/auth',
            checksms: 'auth/checksms',
            sms: 'auth/sms',
            verify: 'auth/verify',
        }),
        close() {
            this.setShowSendCodeSmsModal(false)
        },
        sendCode() {
            this.formSms.phone = this.getFinallyPhone()
            this.formSms.number_sms = Number(this.code)
            this.setFormSms(this.formSms)
            this.checksms()
                .then((response) => {
                    if (response.data.is_check == true) {
                        this.setVerifyPhoneNumber(true)
                        this.close()
                    }else{
                        this.isCodeError = true
                    }
                })
        },
        getFinallyPhone() {
            var finallyPhone = ''
            var phone = this.registryModalPhone
            var splitedPhone = phone.split('')
            splitedPhone.map((element) => {
                if (!isNaN(element) && element != ' ') {
                    finallyPhone += element
                }
            })       
            if (finallyPhone.length == 10) {
                finallyPhone = '+7' + finallyPhone
            }else if (finallyPhone.length == 11) {
                finallyPhone = '+' + finallyPhone
            }
            return finallyPhone
        },
    }
}
</script>
<style scoped>
.form p{
    text-align: center;
}
</style>