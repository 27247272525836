<template>
    <div class="modal-iframe color-create-modal">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <button class="modal-close" @click="close">×</button>
                <div class="state">
                    <div class="state-item">
                        <div class="form" name="callback-form-popup" onsubmit="return false;">
                            <label for="">Название</label>
                            <input type="text" v-model="form.name">

                            <label for="">Тип</label>
                            <input type="text" v-model="form.sub_name">
                            
                            <button class="button button--primary" @click="create">Добавить</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    computed: {
        ...Vuex.mapGetters({
            createForm: 'coatingChars/createForm',
        }),
        form() {
            return Object.assign({}, this.createForm)
        },
    },
    methods: {
        ...Vuex.mapMutations({
            setShowModalCreate: 'coatingChars/setShowModalCreate'
        }),
        ...Vuex.mapActions({
            createChar: 'coatingChars/createChar',
        }),
        close() {
            this.setShowModalCreate(false)
        },
        create() {
            this.createChar(this.form)
                .then(() => {
                    this.close()
                })
        },
    }
}
</script>
<style scoped>
    .form p{
        text-align: center;
    }
</style>