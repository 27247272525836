<template>
    <main class="main">
        <div class="section section--no-pt">
            <bread-crumbs :pages-array="pagesLegals" />
            <div class="container">
                <div class="post">
                    <div class="post-image">
                        <img src="@/assets/img/for-companies.jpg" alt="">
                    </div>
                    <div class="post-title">
                        <h2 >{{legals.heading}}</h2>
                    </div>
                    <div class="post-text">
                        <p v-html="legals.text"></p>
                    </div>
                </div>
            </div>
        </div>
        <legal-services/>
        <training-materials />
        <catalog />
        <!-- <legal-offers v-if="offers != null" /> -->
        <offers :subBlock="true" />
    </main>
</template>

<script>
import Vuex from 'vuex'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import LegalServices from './legalsServices.vue'
import TrainingMaterials from './trainingMaterials.vue'
import Catalog from './catalog.vue'
// import LegalOffers from './legalOffers.vue'
import offers from '@/components/pages/offers/offersCompany.vue'
export default {
    components: {
        LegalServices,
        TrainingMaterials,
        Catalog,
        // LegalOffers,
        breadCrumbs,
        offers,
    },
    // mixins: [Functions],
    data() {
        return {
        }
    },
    computed: {
        ...Vuex.mapGetters({
            offers: 'app/offers',
            legals: 'app/legals',
            serviсes: 'app/serviсes',
            fileUrl: 'app/fileUrl',
            pagesLegals: 'dictionary/pagesLegals',
        }),
    },
    methods: {
        ...Vuex.mapActions({
        }),
        ...Vuex.mapMutations({
        }),
    },
}
</script>
