
<template>
           <div class="modal-iframe">
               <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
		<div class="modal-container">
			<button class="modal-close" @click="close">×</button>
			<div class="state">
				<div class="state-item">
					<h3>{{reviewObjForModal.name}}</h3>
					<div class="form" name="callback-form-popup">
                    <p v-html="reviewObjForModal.text"></p>
				</div>
			</div>
		</div>
	</div>
     </div>
           </div>
</template>

<script>
import Vue from 'vue'
import Functions from '@/utils/functions'
import Vuex from 'vuex'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
export default {
    props:['obj'],
      mixins: [Functions],
    data () {
      return {
          name: '',
          phone:'',
          check: false,
          sended: false,
      }
    },
    computed: {
        ...Vuex.mapGetters({
             reviewModal:'appmodal/reviewModal',
             reviewObjForModal:'appmodal/reviewObjForModal',
        }),
    },
     methods: {
         ...Vuex.mapMutations({
            setReviewModal:'appmodal/setReviewModal'
        }),
        close(){
            this.setReviewModal(false)
        },
        
    },
  }
</script>


