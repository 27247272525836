<template>
    <tr>
        <td class="left-td one_tr_name_block">
            <i 
                v-if="single_element" 
                class="fas fa-times one_tr_icon_delete" 
                title="Очистить поле" 
                @click="clear"
            ></i>
            <b style="padding: 1.5rem">{{ getNameOfType() }}</b>
            <br>
            {{ (single_element ? single_element.name : '') }}
        </td>
        <td>
            <div 
                class="search-field"
            >
                <input 
                    class="search-input input-for-id"
                    type="text" 
                    placeholder="Поиск по id_1c"
                    v-model="id_1c"
                    @keyup="update('id_1c')"
                >
                <input 
                    class="search-input input-for-name"
                    type="text" 
                    placeholder="Поиск по названию"
                    v-model="query"
                    @keyup="update('name')"
                >
                <button 
                    class="search-submit" 
                    @click="getElements()"
                >
                    <span class="search-submit-label">найти</span>
                </button>
            </div>
            <div 
                v-if="query.length > 3 || id_1c.length > 3"
                class="block-elements"
            >
                <div class="search-results"  data-search-results>
                    <div 
                        class="search-result"
                        v-for="product in elements"
                        :key="product.id"
                    >
                        <div class="search-result-title">
                            <a class="search-result-link">{{ product.name }}</a>
                        </div>
                        <div class="search-result-button">
                            <button 
                                class="button button--primary button--cart"
                                @click="setItem(product.id_1c)"
                            >
                                Выбрать
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isLengthWidth" class="block_length_and_width">
                <label 
                    class="cl-input-group cl-input-group_no"
                >
                    <input style="margin: 0" type="number" v-model="length" @keyup="updateParameter('length')">
                    <span data-title="Введите длину">a</span>
                </label>
                <label 
                    class="cl-input-group cl-input-group_no"
                >
                    <input style="margin: 0" type="number" v-model="width" @keyup="updateParameter('width')">
                    <span data-title="Введите ширину">a</span>
                </label>
            </div>
        </td>
    </tr>
</template>
<script>
import Vuex from 'vuex'

export default {
    props: {
        name: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: ""
        },
        typeSearch: {
            type: String,
            default: "",
        },
        isLengthWidth: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            elements: [],
            query: '',
            id_1c: '',
            single_element: {},
            length: '',
            width: '',
        }
    },
    computed: {
        ...Vuex.mapGetters({
            calc_roof_body: 'calculator/calc_roof_body',
        }),
        currentParameter() {
            return this.calc_roof_body[this.name]
        },
    },
    watch: {
        currentParameter(val) {
            let is_group = false
            if (this.typeSearch == 'group') {
                is_group = true
            }else if (this.typeSearch == 'product') {
                is_group = false
            }else{
                is_group = null
            }
            this.searchNavbar({root_id: this.calc_roof_body.root_1c_id, is_group: is_group, id_1cs: [val], is_show_zero_price: false})
                .then((response) => {
                    this.single_element = response.data[0]
                })
        },
        calc_roof_body() {
            this.checkLengthWidth()
        },
    },
    mounted() {
        if (this.currentParameter) {
            let is_group = false
            if (this.typeSearch == 'group') {
                is_group = true
            }else if (this.typeSearch == 'product') {
                is_group = false
            }else{
                is_group = null
            }
            this.searchNavbar({root_id: this.calc_roof_body.root_1c_id, is_group: is_group, id_1cs: [this.currentParameter], is_show_zero_price: false})
                .then((response) => {
                    this.single_element = response.data[0]
                })
        }
        this.checkLengthWidth()
    },
    methods: {
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
        }),
        ...Vuex.mapMutations({
            changeBodyCalcRoof: 'calculator/changeBodyCalcRoof',
        }),
        checkLengthWidth() {
            if (this.isLengthWidth) {
                this.length = this.calc_roof_body[this.name + '_length']
                this.width = this.calc_roof_body[this.name + '_width']
            }
        },
        updateParameter(name){
            this.changeBodyCalcRoof({type: this.name + '_' + name, value: Number(this[name])})
        },
        update(type) {
            if (type == 'name') {
                this.id_1c = ''
                if (this.query.length >= 3) {
                    this.getElements()
                }
            }else if (type == 'id_1c') {
                this.query = ''
                if (this.id_1c.length >= 3) {
                    this.getElements()
                }
            }
        },
        getElements() {
            let is_group = false
            if (this.typeSearch == 'group') {
                is_group = true
            }else if (this.typeSearch == 'product') {
                is_group = false
            }else{
                is_group = null
            }
            this.searchNavbar({root_id: this.calc_roof_body.root_1c_id, id_1c: this.id_1c, query: this.query, is_group: is_group, is_show_zero_price: false})
                .then((response) => {
                    this.elements = response.data
                })
        },
        clear() {
            this.changeBodyCalcRoof({type: this.name, value: null})
        },
        setItem(id_1c) {
            this.changeBodyCalcRoof({type: this.name, value: id_1c})
            this.query = ''
            this.id_1c = ''
        },
        getNameOfType() {
            let response = ''
            if (this.name == 'skate_plank') {
                response = 'Планка конька по ширине кровли'
            }else if (this.name == 'end_plank') {
                response = 'Планка торцевая по длине кровли'
            }else if (this.name == 'top_seal') {
                response = 'Уплотнитель универсальный верхний'
            }else if (this.name == 'fasteners_roof') {
                response = 'Крепеж для крыши'
            }else if (this.name == 'fasteners_add') {
                response = 'Крепеж для доборных элементов'
            }else if (this.name == 'drill_bit') {
                response = 'Бита'
            }else if (this.name == 'sealant') {
                response = 'Силикон универсальный'
            }else if (this.name == 'paint') {
                response = 'Эмаль аэрозольная'
            }else if (this.name == 'scissors') {
                response = 'Ножницы по металлу рычажные'
            }else if (this.name == 'stapler') {
                response = 'Степлер мебельный'
            }else if (this.name == 'staples') {
                response = 'Скобы для степлера'
            }else if (this.name == 'roulette') {
                response = 'Рулетка'
            }else if (this.name == 'soffit_corrug') {
                response = 'Профнастил'
            }else if (this.name == 'soffit') {
                response = 'Софит'
            }else if (this.name == 'soffit_metall') {
                response = 'Софит-металл'
            }else if (this.name == 'soffit_pvh') {
                response = 'Софит-ПВХ'
            }else if (this.name == 'hydro') {
                response = 'Гидроизоляция'
            }else if (this.name == 'para') {
                response = 'Пароизоляция'
            }else if (this.name == 'parahydro') {
                response = 'Парогидроизоляция (выбирается в случае когда утепление не выбрано)'
            }else if (this.name == 'metal_perimeter_plank') {
                response = 'Планка по периметру кровли металл'
            }else if (this.name == 'pvh_perimeter_plank') {
                response = 'Планка по периметру кровли пвх'
            }else if (this.name == 'tapping_roof_wood') {
                response = 'Саморез кровля по дереву'
            }else if (this.name == 'tapping_roof_metall') {
                response = 'Саморез кровля металл'
            }else if (this.name == 'tapping_cornice_prof') {
                response = 'Саморез карниз профнастил'
            }else if (this.name == 'tapping_cornice_soffit') {
                response = 'Саморез карниз пвх'
            }else if (this.name == 'tapping_add_wood') {
                response = 'Саморез дерева доборка'
            }else if (this.name == 'tapping_add_metal') {
                response = 'Саморез метал доборка'
            }else if (this.name == 'outer_corner') {
                response = 'Уголок наружный'
            }else if (this.name == 'insulation_5') {
                response = 'Утеплитель с толщиной 50'
            }else if (this.name == 'insulation_10') {
                response = 'Утеплитель с толщиной 100'
            }else if (this.name == 'insulation_15') {
                response = 'Утеплитель с толщиной 150'
            }else if (this.name == 'insulation_20') {
                response = 'Утеплитель с толщиной 200'
            }
            return response
        },
    },
}
</script>
<style scoped src="../../../../css/nlk-style.css"></style>